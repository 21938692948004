import { Client, Maybe } from "@justplayfair/model";
import { TraineeAdvancement } from "@components/TraineeAdvancement";

interface TraineeSessionStatusProps {
  advancement?: Maybe<Client.Advancement>;
}
export function TraineeSessionStatus({
  advancement,
}: TraineeSessionStatusProps) {
  return (
    <div className="lg:flex lg:justify-between space-x-8 space-y-8 lg:space-y-0">
      <div className="flex justify-center items-center">
        {advancement && (
          <TraineeAdvancement advancement={advancement} withBackground />
        )}
      </div>
    </div>
  );
}
