import { XOutline } from "@graywolfai/react-heroicons";
import classnames from "clsx";
import { ReactNode } from "react";

export interface ChipProps {
  color: "primary" | "green" | "red" | "orange" | "transparent";
  label: string;
  title?: string;
  onDelete?: VoidFunction;
}

export function Chip({ color, label, title, onDelete }: ChipProps) {
  const chipClasses = classnames(
    "inline-flex max-w-xs items-center justify-center px-2 py-1 text-xs font-bold leading-none rounded-full overflow-hidden",
    {
      "text-gray-50 bg-primary": color === "primary",
      "text-green-50 bg-green-600": color === "green",
      "text-red-50 bg-red-600": color === "red",
      "text-yellow-50 bg-yellow-600": color === "orange",
      "text-gray-700 bg-transparent border border-gray-500":
        color === "transparent",
    }
  );
  return (
    <div
      className={chipClasses}
      style={{ maxWidth: "8rem" }}
      title={title || label}
    >
      <div className="w-full truncate select-all">{label}</div>{" "}
      {onDelete && (
        <button
          className="pl-0.5 opacity-70 hover:opacity-100"
          onClick={() => onDelete()}
        >
          <XOutline className="h-4 w-4" />
          <span className="sr-only">Delete</span>
        </button>
      )}
    </div>
  );
}

export function ChipContainer({ children }: { children: ReactNode }) {
  return <div className="space-x-1 space-y-1">{children}</div>;
}
