import { IconComponent } from "@components/Icon/Icon.model";
import {
  CheckCircleSolid,
  ExclamationSolid,
  InformationCircleSolid,
  XCircleSolid,
} from "@graywolfai/react-heroicons";
import classNames from "clsx";
import { useTranslation } from "react-i18next";
import { AlertType } from "../../model/Alert.model";
import { Button } from "../Button";

const ALERT_TYPE_TO_ICON: Record<AlertProps["type"], IconComponent> = {
  error: XCircleSolid,
  info: InformationCircleSolid,
  success: CheckCircleSolid,
  warning: ExclamationSolid,
};

interface AlertAction {
  name: string;
  callback: VoidFunction;
}
export interface AlertProps {
  type: AlertType;
  title: string;
  action?: AlertAction;
  description?: string;
  onDismiss?: VoidFunction;
}
export function Alert({
  type,
  title,
  action,
  description,
  onDismiss,
}: AlertProps) {
  const { t } = useTranslation();
  const containerClasses = classNames(
    "flex rounded-md p-2 max-w-lg space-x-6",
    {
      "bg-red-50": type === "error",
      "bg-yellow-50": type === "warning",
      "bg-blue-50": type === "info",
      "bg-green-50": type === "success",
    }
  );
  const iconClasses = classNames("h-5 w-5 pt-2", {
    "text-red-400": type === "error",
    "text-yellow-400": type === "warning",
    "text-blue-400": type === "info",
    "text-green-400": type === "success",
  });
  const titleClasses = classNames("text-xl", {
    "text-red-900": type === "error",
    "text-yellow-900": type === "warning",
    "text-blue-900": type === "info",
    "text-green-900": type === "success",
  });

  const descriptionClasses = classNames("flex items-center", {
    "text-red-800": type === "error",
    "text-yellow-800": type === "warning",
    "text-blue-800": type === "info",
    "text-green-800": type === "success",
  });
  const Icon = ALERT_TYPE_TO_ICON[type];
  return (
    <div className={containerClasses}>
      <div className={iconClasses}>
        <Icon />
      </div>
      <div className="w-full">
        <div className={titleClasses}>{title}</div>
        {description && <div className={descriptionClasses}>{description}</div>}
        <div className="flex items-center">
          {onDismiss && (
            <div className="ml-auto w-24">
              <Button title="dismiss" variant="transparent" onClick={onDismiss}>
                {t("alert.button.dismiss")}
              </Button>
            </div>
          )}
          {action && (
            <div className="ml-auto">
              <Button
                title={action.name}
                variant="secondary"
                onClick={action.callback}
              >
                {action.name}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
