import { NamedAvatar } from "@components/Avatar/NamedAvatar.component";
import { Button } from "@components/Button";
import { Toggle } from "@components/form/Toggle/Toggle.component";
import { PageContainer } from "@components/Layout";
import { Loader } from "@components/Loader";
import { Pagination } from "@components/Table/Pagination.component";
import {
  Table,
  TableCell,
  TableHeading,
} from "@components/Table/Table.component";
import { Client } from "@justplayfair/model";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AdminCreationButtonContainer } from "../components/AdminCreationButtonContainer.component";

const PAGE_SIZE = 10;

export function CompaniesPage() {
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [filterDeleted, setFilterDeleted] = useState(false);

  const skip = useMemo(() => (page - 1) * PAGE_SIZE, [page]);

  const [{ data, fetching }] = Client.useSearchCompaniesQuery({
    variables: {
      search: {
        take: PAGE_SIZE,
        skip,
        filters: {
          deleted: filterDeleted,
        },
      },
    },
  });

  function handlePreviousPage() {
    setPage(page - 1);
  }
  function handleNextPage() {
    setPage(page + 1);
  }

  return (
    <PageContainer title={t("admin.title.companies")} backButton="/admin">
      <div className="w-full mx-auto space-y-8">
        <AdminCreationButtonContainer>
          <Button
            link={{ to: "/admin/companies/create" }}
            title={t("admin.company.button.createCompany")}
          >
            {t("admin.company.button.createCompany")}
          </Button>
        </AdminCreationButtonContainer>
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <Toggle
            id="filterDeleted"
            checked={filterDeleted}
            onChange={(value) => setFilterDeleted(value)}
            label={t("common.toggle.displayDeleted")}
          />
          <Table
            heading={
              <>
                <TableHeading align="left">
                  {t("admin.company.heading.name")}
                </TableHeading>

                <TableHeading align="left">
                  {t("admin.company.heading.type")}
                </TableHeading>

                <TableHeading align="center">
                  {t("admin.company.heading.userNumber")}
                </TableHeading>

                <TableHeading align="center">
                  {t("admin.company.heading.programNumber")}
                </TableHeading>

                <TableHeading align="right">
                  <span className="sr-only">Edit</span>
                </TableHeading>
              </>
            }
          >
            {!fetching &&
              data?.companies.data.map((company) => (
                <tr key={company.id}>
                  <TableCell align="left">
                    <div className="flex items-center">
                      <NamedAvatar size="small" entity={company} />
                    </div>
                  </TableCell>

                  <TableCell align="left">
                    {t(`common.company.type.value.${company.type}`)}
                  </TableCell>

                  <TableCell align="center">
                    <Link
                      className="text-indigo-600 hover:text-indigo-900"
                      to={`/admin/companies/${company.id}/users/create`}
                      title={t("admin.user.button.createUser")}
                    >
                      {company.users?.length}
                    </Link>
                  </TableCell>

                  <TableCell align="center">
                    <Link
                      className="text-indigo-600 hover:text-indigo-900"
                      to={`/admin/companies/${company.id}/program/create`}
                      title={t("admin.company.button.createGroup")}
                    >
                      {company.programs?.length}
                    </Link>
                  </TableCell>

                  <TableCell align="right">
                    <Link
                      className="text-indigo-600 hover:text-indigo-900"
                      to={`/admin/companies/${company.id}`}
                    >
                      {t("common.button.edit.label")}
                    </Link>
                  </TableCell>
                </tr>
              ))}
          </Table>
          {fetching && <Loader size="large" />}
          <Pagination
            pageInfo={data?.companies.pageInfo as Client.PageInfo}
            onPrevious={handlePreviousPage}
            onNext={handleNextPage}
          />
        </div>
      </div>
    </PageContainer>
  );
}
