import { PageContainer } from "@components/Layout";
import { Loader } from "@components/Loader";
import { Client, ID } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { CreateSessionForm } from "./CreateSession.form";
import { UpdateSessionForm } from "./UpdateSession.form";

export function SessionDetailsPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const { sessionId } = useParams<{ sessionId?: string }>();

  const companyType: Client.CompanyType = "CUSTOMER";
  const [{ data: companiesData, fetching: fetchingCompanies }] =
    Client.useSearchCompaniesQuery({
      variables: {
        search: {
          filters: {
            deleted: false,
            query: new URLSearchParams({ type: companyType }).toString(),
          },
          take: 10000,
          skip: 0,
        },
      },
    });
  const [{ data: trainersData, fetching: fetchingTrainers }] =
    Client.useListTrainersQuery();

  const [{ fetching: isSavingSession }, createSession] =
    Client.useCreateSessionsMutation();

  const [{ fetching: isUpdatingSession }, updateSession] =
    Client.useUpdateSessionMutation();

  const [{ fetching: isDeletingSession }, deleteSession] =
    Client.useDeleteSessionMutation();

  async function handleCreateSession(
    sessionInput: Client.CreateSessionInput,
    appointmentDates?: Client.SessionAppointmentDates
  ) {
    const result = await createSession({ sessionInput, appointmentDates });
    if (!result.error) {
      history.push("/admin/sessions");
    }
  }

  async function handleUpdateSession(
    sessionInput: Client.UpdateSessionInput,
    appointmentDates?: Client.SessionAppointmentDates
  ) {
    if (!sessionId) {
      throw new Error("Must have a session ID for update it");
    }
    const result = await updateSession({
      sessionId,
      sessionInput,
      appointmentDates,
    });
    if (!result.error) {
      history.push("/admin/sessions");
    }
  }

  async function handleDeleteSession(sessionId: ID) {
    if (window.confirm("Etes vous sûr de vouloir supprimer cette session ?")) {
      const result = await deleteSession({ sessionId });
      if (!result.error) {
        history.push("/admin/sessions");
      }
    }
  }

  return (
    <PageContainer
      title={t("menu.admin")}
      subtitle={
        sessionId
          ? t("admin.session.title.updateSession")
          : t("admin.session.title.createSession")
      }
      backButton="/admin/sessions"
    >
      <div className="w-full max-w-4xl mx-auto pb-32">
        {!sessionId && (
          <CreateSessionForm
            companies={companiesData?.companies.data || []}
            trainers={trainersData?.listTrainers || []}
            onCreateSession={handleCreateSession}
          />
        )}
        {sessionId && (
          <UpdateSessionForm
            sessionId={sessionId}
            trainers={trainersData?.listTrainers || []}
            onUpdateSession={handleUpdateSession}
            onDeleteSession={handleDeleteSession}
          />
        )}
      </div>

      {(fetchingCompanies ||
        fetchingTrainers ||
        isSavingSession ||
        isUpdatingSession ||
        isDeletingSession) && <Loader size="medium" mode="overlay" />}
    </PageContainer>
  );
}
