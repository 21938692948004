import { devtoolsExchange } from "@urql/devtools";
import { multipartFetchExchange } from "@urql/exchange-multipart-fetch";
import {
  cacheExchange,
  createClient,
  dedupExchange,
  errorExchange,
  fetchExchange,
} from "urql";
import { getAuthHeader } from "./auth.config";
import { onError } from "./error.config";
import { fetchOptionsExchange } from "./fetchOptions.exchange";

const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT;
if (!GRAPHQL_ENDPOINT) {
  throw new Error("mandatory env variable 'REACT_APP_GRAPHQL_ENDPOINT'");
}

export const urqlClient = createClient({
  url: GRAPHQL_ENDPOINT,
  requestPolicy: "cache-and-network",
  fetchOptions: {
    mode: "cors",
    credentials: "include",
  },
  exchanges: [
    devtoolsExchange,
    dedupExchange,
    cacheExchange,
    errorExchange({
      onError,
    }),
    fetchOptionsExchange((fetchOptions: any) => {
      return {
        ...fetchOptions,
        headers: {
          Authorization: getAuthHeader(),
        },
      };
    }),
    multipartFetchExchange,
    fetchExchange,
  ],
});
