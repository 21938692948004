import { Loader } from "@components/Loader";
import { Title } from "@components/Title";
import { TraineeSessionTable } from "@components/TraineeSessionTable";
import { Client } from "@justplayfair/model";
import { useParams } from "react-router";

export function SessionTabContent() {
  const { traineeId } = useParams<{
    programId: string;
    traineeId: string;
  }>();

  const [{ data, fetching }] = Client.useTrainerTraineeDashboardQuery({
    variables: { traineeId },
    requestPolicy: "cache-only",
  });

  if (fetching) {
    return <Loader size="medium" />;
  }
  if (!data?.user) {
    return <Title size={6}>Introuvable</Title>;
  }

  return (
    <div>
      <TraineeSessionTable
        sessions={data.user.sessions || []}
        forRole="TRAINER"
      />
    </div>
  );
}
