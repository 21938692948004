import classnames from "clsx";
import { MouseEvent, ReactNode } from "react";
import { Link } from "react-router-dom";
import { LinkProps } from "../Button/Button.component";

type CardColor = "green" | "blue" | "gray";
interface BaseCardProps {
  children: ReactNode;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  link?: LinkProps;
  bgColor?: CardColor;
  borderColor?: CardColor;
  flat?: boolean;
  shadow?: "sm" | "md" | "lg" | "xl";
  rounded?: boolean;
}

interface SimpleCardProps extends BaseCardProps {
  bgColor?: undefined;
  borderColor?: undefined;
}

interface BgColoredCardProps extends BaseCardProps {
  bgColor: CardColor;
  borderColor?: undefined;
}

interface BorderColoredCardProps extends BaseCardProps {
  bgColor?: undefined;
  borderColor: CardColor;
}
export type CardProps =
  | SimpleCardProps
  | BgColoredCardProps
  | BorderColoredCardProps;

export function Card({
  bgColor,
  borderColor,
  onClick,
  link,
  flat,
  shadow,
  rounded = true,
  children,
}: CardProps) {
  const shadowClass = shadow ? `shadow-${shadow}` : "shadow";
  const classes = classnames("border-2 border-transparent", {
    [shadowClass]: !flat,
    "rounded-lg": rounded,
    "cursor-pointer hover:shadow-md": !!onClick,
    "bg-white": !bgColor,
    "bg-green-600 text-white": bgColor === "green",
    "bg-blue-600 text-white": bgColor === "blue",
    "bg-gray-200": bgColor === "gray",
    "border-green-600": borderColor === "green",
    "border-blue-600": borderColor === "blue",
    "border-gray-200": borderColor === "gray",
  });
  return (
    <div onClick={onClick} className={classes}>
      {link && <CardLink link={link}>{children}</CardLink>}
      {!link && children}
    </div>
  );
}

interface CardLinkProps {
  link: LinkProps;
  children: ReactNode;
}
function CardLink({ link, children }: CardLinkProps) {
  return link.external ? (
    <a href={link.to} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  ) : (
    <Link to={link.to}>{children}</Link>
  );
}
