import styled from "@emotion/styled";
import { sanitizeHtml } from "@justplayfair/model";
import {
  FunctionComponent,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

const HtmlDefaultStyle = styled.div<{
  hideOverflow?: boolean;
  isOverflow: boolean;
}>`
  position: relative;

  ${({ hideOverflow }) =>
    hideOverflow
      ? "height: 100%;  overflow: hidden;  text-overflow: ellipsis;   padding-right:10px;"
      : ""}
  ${({ isOverflow }) =>
    isOverflow
      ? '&:after { position: absolute; bottom: 0; right: 0; content: "…"; font-size: 1.1rem; }'
      : ""}
  
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.17em;
  }
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: 0.83em;
  }
  h6 {
    font-size: 0.67em;
  }

  ul {
    padding-left: 1.5em;
    list-style: inside;
    list-style-type: disc;
  }
`;

export const DangerousHtml: FunctionComponent<{
  html: string;
  hideOverflow?: boolean;
}> = ({ html, hideOverflow }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  const sanitized = useMemo(() => sanitizeHtml(html), [html]);

  useLayoutEffect(() => {
    const isOverflow =
      !!containerRef.current &&
      !!hideOverflow &&
      containerRef.current.offsetHeight < containerRef.current.scrollHeight;

    setIsOverflow(isOverflow);
  }, [hideOverflow]);

  return (
    <HtmlDefaultStyle
      ref={containerRef}
      hideOverflow={hideOverflow}
      isOverflow={isOverflow}
      dangerouslySetInnerHTML={{ __html: sanitized }}
    />
  );
};
DangerousHtml.displayName = "DangerousHtml";
