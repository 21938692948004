import { SelectOption } from "@components/form/Select/Select.model";
import { Client } from "@justplayfair/model";

export const LABEL_CATEGORIES = [
  "HIERARCHICAL_POSITION",
  "DEPARTMENT",
  "BUSINESS_UNIT",
  "CUSTOM",
] as Client.LabelCategory[];

export const LABEL_CATEGORIES_OPTIONS: SelectOption<string>[] = [
  { value: "HIERARCHICAL_POSITION" },
  { value: "DEPARTMENT" },
  { value: "BUSINESS_UNIT" },
  { value: "CUSTOM" },
];
