import { CHART_COLORS, DatavizMapper, JPF_PALETTE } from "@justplayfair/model";
import { AxisProps } from "@nivo/axes";
import {
  BarDatum,
  BarLayer,
  BarLegendProps,
  BarTooltipProps,
  ResponsiveBar,
} from "@nivo/bar";
import { useTheme } from "@nivo/core";
import classnames from "clsx";
import { SVGAttributes, useMemo } from "react";
import { RateComparisonTable } from "../RateComparisonTable.component";
import { BarStats } from "./BarStats.component";

const MAGIC_ROW_HEIGHT_RATIO = 1.02;
const TOP_MARGIN = 35;
const BOTTOM_MARGIN = 150;

function getAccentuatedColor(bar: any) {
  return bar.data.isAggregate
    ? CHART_COLORS.emphasisBlue
    : CHART_COLORS.primaryBlue;
}

interface BarChartProps {
  containerHeight: number;
  data: BarDatum[];
  keys: string[];
  xLegend: string;
  yLegend?: string;
  layout: "horizontal" | "vertical";
  indexBy: string;
  maxRate: number;
  colorTheme: "accentuate" | "default";
  withStats?: [valueName: string, comparisonValueName: string];
  onClickBarLegend?: (barId: string | number) => void;
  testId?: string;
}
export function BarChart({
  containerHeight,
  data,
  keys,
  xLegend,
  yLegend,
  layout,
  indexBy,
  maxRate,
  colorTheme,
  withStats,
  onClickBarLegend,
  testId = "BarChart",
}: BarChartProps) {
  const layers = [
    "grid",
    "axes",
    "bars",
    withStats ? BarStats : undefined,
    "markers",
    "legends",
  ].filter((layer): layer is BarLayer<any> => !!layer);

  const chartClasses = classnames("pt-1", {
    "max-w-5xl": layout === "horizontal" && !withStats,
    "col-span-5": !withStats,
    "col-span-4": withStats,
  });

  const rowHeight = useMemo(
    () =>
      (containerHeight - TOP_MARGIN - BOTTOM_MARGIN) /
      (data.length * MAGIC_ROW_HEIGHT_RATIO),
    [containerHeight, data]
  );

  const horizontalAxisLegend: AxisProps = {
    legend: xLegend,
    tickSize: 5,
    tickPadding: 5,
    legendPosition: "middle",
    legendOffset: 40,
    format: DatavizMapper.formatLabel,

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    renderTick:
      layout !== "horizontal"
        ? (data: any) => <CustomTick {...data} />
        : undefined,
  };

  return (
    <div className="h-full grid grid-cols-5 gap-1">
      <div className={chartClasses} data-testid={testId}>
        <ResponsiveBar
          keys={keys}
          data={data}
          layout={layout}
          indexBy={indexBy}
          margin={{
            top: TOP_MARGIN,
            right: layout === "vertical" ? 130 : 5,
            bottom: BOTTOM_MARGIN,
            left: 200,
          }}
          padding={0.3}
          groupMode="grouped"
          valueScale={{ type: "linear", min: 0, max: maxRate }}
          colors={
            colorTheme === "accentuate"
              ? (bar) => getAccentuatedColor(bar)
              : JPF_PALETTE
          }
          tooltip={CustomTooltip}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: layout !== "horizontal" ? yLegend : undefined,
            legendPosition: "middle",
            legendOffset: -40,
            format: DatavizMapper.formatLabel,
          }}
          enableGridX
          axisBottom={horizontalAxisLegend}
          axisTop={layout === "horizontal" ? horizontalAxisLegend : undefined}
          borderWidth={0}
          enableLabel={layout === "vertical"}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={
            colorTheme === "accentuate"
              ? "#fff"
              : {
                  from: "color",
                  modifiers: [["darker", 2]],
                }
          }
          layers={layers}
          theme={{
            axis: {
              legend: { text: { fontSize: 14, fontWeight: 600 } },
            },
            fontFamily: "Inter, ui-sans-serif, system-ui",
          }}
          legends={
            layout !== "horizontal"
              ? getDefaultLayout(keys, onClickBarLegend)
              : undefined
          }
          animate={true}
        />
      </div>

      {withStats && (
        <div className="col-span-1" style={{ paddingTop: 0 }}>
          <RateComparisonTable
            rowHeight={rowHeight}
            colNames={withStats}
            data={DatavizMapper.barDataToTableData(data as any, true)}
          />
        </div>
      )}
    </div>
  );
}

function getDefaultLayout(
  keys: string[],
  onClickBarLegend: ((barId: string | number) => void) | undefined
): BarLegendProps[] {
  return [
    {
      dataFrom: "keys",
      // data: keys.map((key) => ({
      //   id: key,
      //   label: key,
      // })),
      anchor: "bottom-right",
      direction: "column",
      justify: false,
      translateX: 200,
      translateY: 0,
      itemsSpacing: 2,
      itemWidth: 200,
      itemHeight: 20,
      itemDirection: "left-to-right",
      itemOpacity: onClickBarLegend ? 0.85 : 1,
      symbolSize: 20,
      effects: onClickBarLegend && [
        {
          on: "hover",
          style: {
            itemOpacity: 1,
          },
        },
      ],
      onClick: onClickBarLegend
        ? (data) => {
            if (onClickBarLegend) {
              const serieId = data.id;
              onClickBarLegend(serieId);
            }
          }
        : undefined,
    },
  ];
}

function CustomTooltip({ id, value }: BarTooltipProps<BarDatum>) {
  return (
    <div className="p-3 font-bold bg-gray-50 rounded border shadow-lg">
      {id}: {value}
    </div>
  );
}

interface CustomTickProps {
  value: string | number;
  x: number;
  y: number;
  textX: number;
  textY: number;
  opacity: number;
  textBaseline: SVGAttributes<SVGTextElement>["alignmentBaseline"];
  format?: (label: string | number) => string;
}
function CustomTick({
  value,
  x,
  y,
  textX,
  textY,
  opacity,
  textBaseline,
  format,
}: CustomTickProps) {
  const theme = useTheme();
  const labelValue = format ? format(value) : `${value}`;

  const splitedLabel = labelValue.match(/.{1,15}(\s|$)/g) || []; // Split word if more than 15 chars
  return (
    <g transform={`translate(${x},${y})`} style={{ opacity: opacity }}>
      <line x1="0" x2="0" y1="0" y2="5" style={theme.axis.ticks.line}></line>
      {splitedLabel.map((label, index) => (
        <text
          key={index}
          dominantBaseline={textBaseline}
          style={theme.axis.ticks.text}
          textAnchor="middle"
          transform={`translate(${textX},${textY + index * 16})`}
        >
          {label}
        </text>
      ))}
    </g>
  );
}
