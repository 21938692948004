import { Avatar } from "@components/Avatar";
import { AvatarSize } from "@components/Avatar/Avatar.component";
import {
  AtSymbolOutline,
  OfficeBuildingOutline,
} from "@graywolfai/react-heroicons";
import { Client } from "@justplayfair/model";
import classnames from "clsx";

type UserDescriptionSize = "dense" | "normal";
const USER_DESCRIPTION_SIZE_TO_AVATAR_SIZE: {
  [s in UserDescriptionSize]: AvatarSize;
} = {
  dense: "mini",
  normal: "small",
};
export interface UserDescriptionProps {
  user: Client.UserDetailsFieldsFragment;
  size?: "dense" | "normal";
  withBackground?: boolean;
}
export function UserDescription({
  user,

  size = "normal",
  withBackground,
}: UserDescriptionProps) {
  const containerClasses = classnames(
    "flex items-center w-fit-content max-w-sm",
    {
      "border border-gray-100 p-2 rounded rounded-sm bg-gray-100":
        withBackground,
    }
  );
  const avatarClassnames = classnames("flex-shrink-0", {
    "h-10 w-10": size === "normal",
    "h-6 w-6": size === "dense",
  });
  const dataClassnames = classnames({
    "ml-4": size === "normal",
    "text-xs": size === "dense",
  });
  const usernameClasses = classnames("text-gray-900", {
    "text-base font-medium ": size === "normal",
    "text-sm font-medium ": size === "dense",
  });
  const userInfosClasses = classnames({
    "text-sm": size === "normal",
    "text-xs": size === "dense",
  });
  return (
    <div className={containerClasses}>
      <div className={avatarClassnames}>
        <Avatar
          size={USER_DESCRIPTION_SIZE_TO_AVATAR_SIZE[size]}
          entity={user}
        />
      </div>
      <div className={dataClassnames}>
        <div className={usernameClasses}>{user.name}</div>
        <div className={userInfosClasses}>
          <div className="flex text-gray-500 items-center space-x-1">
            <AtSymbolOutline className="h-4 w-4" /> <span>{user.email}</span>
          </div>
          {user.company && (
            <div className="flex text-gray-500 items-center space-x-1">
              <OfficeBuildingOutline className="h-4 w-4" />{" "}
              <span>{user.company.name}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
