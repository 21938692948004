import { CompanyUserList } from "@components/CompanyUserList";
import { useAuthenticatedUser } from "@hooks/authenticatedUser/useAuthenticatedUser.hook";
import { Client, ID } from "@justplayfair/model";
import { useMemo, useState } from "react";

const PAGE_SIZE = 10;

export function ClientMembersTabContent() {
  const { user, fetching: fetchingUser } = useAuthenticatedUser();

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const skip = useMemo(() => (page - 1) * PAGE_SIZE, [page]);
  const [sort, setSort] = useState<Client.Sort | undefined>({
    name: "name",
    order: "asc",
  });

  const [{ data, fetching }] = Client.useWithAdvancementClientUserListQuery({
    pause: !user,
    variables: user?.company?.id
      ? {
          companyId: user.company.id,
          search: {
            skip,
            take: PAGE_SIZE,
            filters: {
              query: new URLSearchParams({
                search,
                companyId: user.company.id,
                roles: "TRAINEE",
              }).toString(),
              deleted: false,
            },
            sort,
          },
        }
      : undefined,
  });

  return (
    <CompanyUserList
      fetching={fetching || fetchingUser}
      users={data?.users.data}
      pageInfo={data?.users.pageInfo ?? null}
      page={page}
      setSearch={setSearch}
      setPage={setPage}
      sort={sort}
      setSort={setSort}
      getTraineeUrl={
        data?.company?.individualDataAccessDeactivated === false
          ? getTraineeUrl
          : undefined
      }
    />
  );
}

function getTraineeUrl(
  userId: ID,
  programId: ID | null,
  programIds: ID[]
): string | null {
  if (!programId && programIds.length > 1) {
    return null;
  }
  if (programId) {
    return `/client/trainee/details/${programId}/${userId}`;
  }
  return `/client/trainee/details/${programIds[0]}/${userId}`;
}
