import { Chip, ChipContainer } from "@components/Chip";
import { DisplayDateTime } from "@components/DateTime";
import { Title } from "@components/Title";
import { UserDescription } from "@components/UserDescription";
import { CalendarOutline } from "@graywolfai/react-heroicons";
import { Client } from "@justplayfair/model";
import { ReactNode } from "react";
import { i18nInstance } from "../../i18n";

type InfoFieldName =
  | "program"
  | "duration"
  | "status"
  | "trainer"
  | "trainee"
  | "skills";
type DateFieldName = "appointmentDate" | "evaluationDate";
type FieldName = InfoFieldName | DateFieldName;

export type SessionDetailsType = NonNullable<
  Client.GetSessionQuery["getSession"]
>;
export interface SessionDetailsProps {
  session: SessionDetailsType;
  omitFields?: FieldName[];
}

const SESSION_INFO_FIELDS = (
  session: SessionDetailsType
): { [fieldName in InfoFieldName]: Info } => ({
  duration: {
    label: "Durée",
    renderValue: (
      <Chip
        color="transparent"
        label={i18nInstance.t(
          `common.session.duration.value.${session.duration}`
        )}
      />
    ),
  },
  skills: {
    label: "Compétences",
    renderValue: session.skills ? (
      <ChipContainer>
        {session.skills.map((skill, index) => (
          <Chip key={index} color="transparent" label={skill.name} />
        ))}
      </ChipContainer>
    ) : undefined,
  },
  status: {
    label: "Status",
    renderValue: session.status ? (
      <Chip
        label={i18nInstance.t(`common.session.status.${session.status}`)}
        color="transparent"
      />
    ) : undefined,
  },

  trainer: {
    label: "Trainer",
    renderValue: session.trainer ? (
      <UserDescription size="dense" withBackground user={session.trainer} />
    ) : undefined,
  },
  trainee: {
    label: "Trainee",
    renderValue: session.trainee ? (
      <UserDescription size="dense" withBackground user={session.trainee} />
    ) : undefined,
  },
  program: {
    label: "Programme",
    renderValue: session.program?.name ? (
      <Chip label={session.program.name} color="primary" />
    ) : undefined,
  },
});

const SESSION_DATE_FIELDS = (
  session: SessionDetailsType
): { [fieldName in DateFieldName]: Info } => ({
  appointmentDate: {
    label: "RDV",
    value: session.appointment?.startDate,
  },
  evaluationDate: {
    label: "Eval",
    value: session.evaluationDate,
  },
});
export function SessionDetails({
  session,
  omitFields = [],
}: SessionDetailsProps) {
  const infosFields = Object.entries(SESSION_INFO_FIELDS(session))
    .filter(([name]) => !omitFields.includes(name as FieldName))
    .map(([, field]) => field);

  const dateFields = Object.entries(SESSION_DATE_FIELDS(session))
    .filter(
      ([name, field]) =>
        !!field.value && !omitFields.includes(name as FieldName)
    )
    .map(([, field]) => field);
  return (
    <div className="w-full max-w-3xl">
      <Title size={3}>{session.name}</Title>

      <div className="border-t border-gray-200 px-4 py-5 sm:px-6 space-y-8">
        <div>
          <Infos infos={infosFields} />
        </div>

        {dateFields.length > 0 &&
          (session.appointment?.startDate || session.evaluationDate) && (
            <div>
              <div className="text-sm font-medium text-gray-500">Dates</div>
              <div className="mt-1 text-sm text-gray-900">
                <ul className="divide-y divide-gray-200">
                  <DateInfos infos={dateFields} />
                </ul>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

interface Info {
  label: string;
  value?: string;
  renderValue?: ReactNode;
}
interface InfosProps {
  infos: Info[];
}
function Infos({ infos }: InfosProps) {
  return (
    <div className="flex flex-wrap  max-w-3xl mx-auto space-y-2">
      {infos.map((info, index) => (
        <div className="w-1/3" key={index}>
          <dt className="text-sm font-medium text-gray-500">{info.label}</dt>
          <dd className="pl-2 mt-1 text-sm text-gray-900">
            {info.renderValue ? info.renderValue : info.value}
          </dd>
        </div>
      ))}
    </div>
  );
}

function DateInfos({ infos }: InfosProps) {
  return (
    <>
      {infos
        .filter((info) => !!info.value)
        .map((info, index) => (
          <li
            key={index}
            className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
          >
            <div className="w-0 flex-1 flex items-center">
              <span>{info.label}</span>

              <CalendarOutline className="flex-shrink-0 h-5 w-5 text-gray-400" />
              <span className="ml-2 flex-1 w-0 truncate">
                {info.value && <DisplayDateTime date={new Date(info.value)} />}
              </span>
            </div>
          </li>
        ))}
    </>
  );
}
