import { Chip, ChipContainer } from "@components/Chip";
import {
  Table,
  TableCell,
  TableHeading,
} from "@components/Table/Table.component";
import { Client } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface ProgramListProps {
  programs: Client.ListProgramFieldsFragment[];
}
export function ProgramList({ programs }: ProgramListProps) {
  const { t } = useTranslation();

  return (
    <div className="shadow border-b border-gray-200 sm:rounded-lg">
      <Table
        heading={
          <>
            <TableHeading align="left">
              {t("admin.program.heading.name")}
            </TableHeading>
            <TableHeading align="left">
              {t("admin.program.heading.competencies")}
            </TableHeading>
            <TableHeading align="center">
              {t("admin.program.heading.type")}
            </TableHeading>
            <TableHeading align="center">
              {t("admin.program.heading.nbTrainees")}
            </TableHeading>

            <TableHeading align="right">
              <span className="sr-only">Edit</span>
            </TableHeading>
          </>
        }
      >
        {programs.map((program) => (
          <tr key={program.id}>
            <TableCell align="left">{program.name}</TableCell>
            <TableCell align="left">
              <ChipContainer>
                {program.skills?.map((skill) => (
                  <Chip key={skill.id} color="green" label={skill.name} />
                ))}
              </ChipContainer>
            </TableCell>
            <TableCell align="center">
              <Chip
                color="transparent"
                label={t(`common.programType.${program.type}`)}
              />
            </TableCell>
            <TableCell align="center">{program.nbTrainees}</TableCell>
            <TableCell align="right">
              <Link
                className="text-indigo-600 hover:text-indigo-900"
                to={`/admin/companies/${program.companyId}/program/${program.id}`}
              >
                {t("common.button.edit.label")}
              </Link>
            </TableCell>
          </tr>
        ))}
      </Table>
    </div>
  );
}
