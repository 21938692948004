import { Button } from "@components/Button";
import { Title } from "@components/Title";
import { ArrowLeftSolid, RefreshOutline } from "@graywolfai/react-heroicons";
import { useTranslation } from "react-i18next";
import { ReactComponent as KeepCalmSvg } from "./KeepCalm.svg";
import { ReactComponent as MaintenanceSvg } from "./Maintenance.svg";

interface ErrorPageProps {
  mode?: "maintenance" | "error";
}
export function ErrorPage({ mode = "error" }: ErrorPageProps) {
  const { t } = useTranslation();

  function handleRetry() {
    window.location.reload();
  }
  function handleGoBack() {
    window.history.back();
  }
  return (
    <div className="h-screen flex items-center justify-center space-x-8 m-4">
      {mode === "maintenance" && <MaintenanceSvg height="50vh" width="50vw" />}
      {mode === "error" && <KeepCalmSvg height="50vh" width="50vw" />}
      <div className="space-y-4">
        <Title size={1}>
          {mode === "maintenance"
            ? t("error.maintenance.title")
            : t("error.error.title")}
        </Title>

        {mode === "error" && (
          <div className="flex space-x-4">
            <div className="w-28">
              <Button
                preIcon={ArrowLeftSolid}
                title={t("error.button.goBack")}
                onClick={handleGoBack}
              >
                {t("error.button.goBack")}
              </Button>
            </div>
            <div className="w-32">
              <Button
                preIcon={RefreshOutline}
                title={t("error.button.retry")}
                onClick={handleRetry}
              >
                {t("error.button.retry")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
