import { Card } from "../../../../../components/Card";
import { Title } from "../../../../../components/Title";
import { QRCodeSVG } from "qrcode.react";
import { ID } from "@justplayfair/model";
import { Client } from "@justplayfair/model";
import { ArrowRightOutline } from "@graywolfai/react-heroicons";

interface EvaluationQrCodeCardProps {
  companyId: ID;
}
export function EvaluationQrCodeCard({ companyId }: EvaluationQrCodeCardProps) {
  const [{ data }] = Client.useGetSessionEvaluationUrlQuery({
    variables: {
      companyId,
    },
  });
  if (!data?.getSessionEvaluationUrl) {
    return null;
  }
  return (
    <Card>
      <div className="flex flex-col items-center  pt-2 pb-4 px-4 gap-y-4">
        <div>
          <Title size={5}>
            Veuillez évaluer votre session en scannant le QR-code
          </Title>
        </div>
        <div>
          <QRCodeSVG value={data.getSessionEvaluationUrl} />
        </div>
        <div className="w-full text-center">
          <a
            href={data.getSessionEvaluationUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-lg font-medium text-primary hover:text-primary-dark space-x-2"
          >
            <span>{data.getSessionEvaluationUrl}</span>

            <ArrowRightOutline className="inline h-4 w-4" />
          </a>
        </div>
      </div>
    </Card>
  );
}
