import { Card } from "@components/Card";
import { Title } from "@components/Title";
import { NumberUtilities } from "@justplayfair/model";

interface AchievementProps {
  title: string;
  value: number;
  label: string;
}
export function ProgramAchievement({ title, value, label }: AchievementProps) {
  return (
    <Card>
      <div className="flex flex-col justify-around p-2 h-24">
        <Title size={6}>{title}</Title>
        <div className="flex items-center space-x-1">
          <div className="text-3xl text-primary">
            {NumberUtilities.toPercentage(value)}%
          </div>
          <div className="text-gray-600">{label}</div>
        </div>
      </div>
    </Card>
  );
}
