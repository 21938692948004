import { IconComponent } from "@components/Icon/Icon.model";
import { Tooltip } from "@components/Tooltip";
import classNames from "clsx";
import { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as SpinnerSvg } from "../Spinner/Spinner.svg";

export interface FileButtonProps {
  title: string;
  tooltip?: string;
  type: "file";
  accept?: string;
  id: string;
  link?: undefined;
  disabled?: boolean;
  variant?: "secondary" | "warning" | "transparent";
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  children: ReactNode;
  loading?: boolean;
  preIcon?: IconComponent;
  postIcon?: IconComponent;
}

export interface LinkProps {
  to: string;
  external?: boolean;
}
interface ClassicButtonProps {
  id?: string;
  title: string;
  tooltip?: string;
  type?: "submit" | "button";
  accept?: undefined;
  link?: LinkProps;
  disabled?: boolean;
  variant?: "secondary" | "warning" | "transparent";
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => void;
  onChange?: undefined;
  children: ReactNode;
  loading?: boolean;
  preIcon?: IconComponent;
  postIcon?: IconComponent;
}
export type ButtonProps = FileButtonProps | ClassicButtonProps;

export function CoreButton({
  id,
  title,
  tooltip,
  type,
  accept,
  variant,
  disabled,
  onClick,
  onChange,
  preIcon,
  loading,
  postIcon,
  children,
}: Omit<ButtonProps, "link">) {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);

  const isDisabled = disabled || loading;
  const classes = classNames(
    "group relative w-full flex justify-center items-center py-2 px-4 text-sm rounded-md border-2 transition font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2",
    {
      "cursor-pointer": !isDisabled,
      // Variant primary
      "bg-primary focus:ring-primary border-transparent": !variant,
      "text-white hover:bg-primary-dark": !variant && !isDisabled,
      "text-gray-300": !variant && isDisabled,

      //Variant secondary
      "border-gray-400 border-2	focus:ring-gray-500": variant === "secondary",
      "text-gray-700 hover:bg-gray-400 hover:text-white":
        variant === "secondary" && !isDisabled,
      "text-gray-200":
        (variant === "secondary" || variant === "transparent") && isDisabled,

      //Variant warning
      "bg-red-400 focus:ring-red-500 border-transparent": variant === "warning",
      "text-white hover:bg-red-500": variant === "warning" && !isDisabled,
      "text-red-700": variant === "warning" && isDisabled,

      // Variant transparent
      "bg-transparent focus:ring-transparent border-transparent":
        variant === "transparent",
      "text-gray-600 hover:bg-gray-500 hover:bg-opacity-10":
        variant === "transparent" && !isDisabled,

      "cursor-default": isDisabled,
    }
  );

  if (type === "file") {
    return (
      <div ref={setReferenceElement}>
        <label className={classes}>
          <input
            id={id}
            title={title}
            type="file"
            accept={accept}
            className="hidden"
            disabled={isDisabled}
            onChange={onChange}
          />
          {preIcon && !loading && preIcon({ className: "h-5 w-5 mr-auto" })}
          {loading && (preIcon || (!preIcon && !postIcon)) && (
            <SpinnerSvg className="animate-spin h-5 w-5 mr-auto" />
          )}
          {children}
          {postIcon && !loading && postIcon({ className: "h-5 w-5 ml-auto" })}
          {postIcon && loading && (
            <SpinnerSvg className="animate-spin h-5 w-5 ml-auto" />
          )}
        </label>
        {tooltip && (
          <Tooltip referenceElement={referenceElement} label={tooltip} />
        )}
      </div>
    );
  }

  return (
    <div ref={setReferenceElement}>
      <button
        title={title}
        type={type}
        className={classes}
        disabled={isDisabled}
        onClick={onClick}
      >
        {preIcon && !loading && preIcon({ className: "h-5 w-5 mr-auto" })}
        {loading && (preIcon || (!preIcon && !postIcon)) && (
          <SpinnerSvg className="animate-spin h-5 w-5 mr-auto" />
        )}
        {children}
        {postIcon && !loading && postIcon({ className: "h-5 w-5 ml-auto" })}
        {postIcon && loading && (
          <SpinnerSvg className="animate-spin h-5 w-5 ml-auto" />
        )}
      </button>
      {tooltip && (
        <Tooltip referenceElement={referenceElement} label={tooltip} />
      )}
    </div>
  );
}
export function Button({
  title,
  disabled,
  children,
  link,
  variant,
  type = "button",
  accept,
  onClick,
  onChange,
  loading,
  preIcon,
  postIcon,
  tooltip,
}: ButtonProps) {
  if (link) {
    const Wrapper = link.external
      ? ({ children }: { children: ReactNode }) => (
          <a
            href={link.to}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClick}
          >
            {children}
          </a>
        )
      : ({ children }: { children: ReactNode }) => (
          <Link to={link.to} onClick={onClick}>
            {children}
          </Link>
        );
    return (
      <Wrapper>
        <CoreButton
          title={title}
          tooltip={tooltip}
          disabled={disabled}
          variant={variant}
          type={type}
          loading={loading}
          preIcon={preIcon}
          postIcon={postIcon}
        >
          {children}
        </CoreButton>
      </Wrapper>
    );
  }
  return (
    <CoreButton
      title={title}
      tooltip={tooltip}
      disabled={disabled}
      variant={variant}
      type={type}
      accept={accept}
      onClick={disabled ? () => null : onClick}
      onChange={disabled ? () => null : onChange}
      loading={loading}
      preIcon={preIcon}
      postIcon={postIcon}
    >
      {children}
    </CoreButton>
  );
}
