import { useState } from "react";
import { usePopper } from "react-popper";
import { useDebounce } from "react-use";

interface UseTooltipArgs {
  referenceElement: HTMLElement | null;
}
export function useTooltip({ referenceElement }: UseTooltipArgs) {
  const [isHover, setIsHover] = useState(false);
  const [tooltipShown, setTooltipShown] = useState(false);

  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);

  useDebounce(
    () => {
      setTooltipShown(isHover);
    },
    200,
    [isHover]
  );

  referenceElement?.addEventListener("mouseenter", () => setIsHover(true));
  referenceElement?.addEventListener("mouseleave", () => setIsHover(false));

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "arrow", options: { element: arrowElement } }],
  });

  return {
    setPopperElement,
    tooltipShown,
    setArrowElement,
    styles,
    attributes,
  };
}
