"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDayDateRange = exports.getWeekDateRange = exports.getMonthDateRange = exports.isNearDate = exports.dateToTimeStr = exports.getHoursInterval = exports.dateToHoursDecimal = exports.isValueADate = exports.parseJSONDate = exports.getWorkingDays = exports.formatToTextLocalDate = exports.formatToLocalDate = exports.formatToLocalDateTime = exports.getDistanceInWordsToNow = void 0;
const date_fns_1 = require("date-fns");
const en_US_1 = require("date-fns/locale/en-US");
const fr_1 = require("date-fns/locale/fr");
const LOCAL_TO_DATE_FNS_LOCAL = {
    fr: fr_1.default,
    en: en_US_1.default,
};
function getLocal() {
    return fr_1.default;
}
function getDistanceInWordsToNow(date, options) {
    options = options || {};
    options.locale = getLocal();
    return `Il y a ${(0, date_fns_1.formatDistanceToNow)(date, options)}`;
}
exports.getDistanceInWordsToNow = getDistanceInWordsToNow;
function formatToLocalDateTime(date) {
    return (0, date_fns_1.format)(date, "dd/MM/yyyy à HH:mm", {
        locale: getLocal(),
    });
}
exports.formatToLocalDateTime = formatToLocalDateTime;
function formatToLocalDate(date) {
    return (0, date_fns_1.format)(date, "dd/MM/yyyy", {
        locale: getLocal(),
    });
}
exports.formatToLocalDate = formatToLocalDate;
function formatToTextLocalDate(date, localKey = "en") {
    return (0, date_fns_1.format)(date, "MMMM yyyy", {
        locale: LOCAL_TO_DATE_FNS_LOCAL[localKey],
    });
}
exports.formatToTextLocalDate = formatToTextLocalDate;
function getWorkingDays(t) {
    return [
        { id: "monday", label: t("common.workingDay.monday") },
        { id: "tuesday", label: t("common.workingDay.tuesday") },
        { id: "wednesday", label: t("common.workingDay.wednesday") },
        { id: "thursday", label: t("common.workingDay.thursday") },
        { id: "friday", label: t("common.workingDay.friday") },
        { id: "saturday", label: t("common.workingDay.saturday") },
    ];
}
exports.getWorkingDays = getWorkingDays;
function parseJSONDate(date) {
    return (0, date_fns_1.parseJSON)(date);
}
exports.parseJSONDate = parseJSONDate;
function isValueADate(value) {
    return (0, date_fns_1.isDate)(value);
}
exports.isValueADate = isValueADate;
function dateToHoursDecimal(date) {
    const hours = (0, date_fns_1.getHours)(date);
    const minutes = (0, date_fns_1.getMinutes)(date);
    return hours + minutes / 60;
}
exports.dateToHoursDecimal = dateToHoursDecimal;
function getHoursInterval(startHour, hoursToAdd) {
    const startDate = (0, date_fns_1.set)(new Date(), { hours: startHour, minutes: 0 });
    const endDate = (0, date_fns_1.addHours)(startDate, hoursToAdd);
    return { start: (0, date_fns_1.getHours)(startDate), end: (0, date_fns_1.getHours)(endDate) };
}
exports.getHoursInterval = getHoursInterval;
function dateToTimeStr(date) {
    const hourPart = (0, date_fns_1.getHours)(date);
    const minutesPart = (0, date_fns_1.getMinutes)(date);
    return `${(hourPart + "").padStart(2, "0")}:${(minutesPart + "").padStart(2, "0")}`;
}
exports.dateToTimeStr = dateToTimeStr;
function isNearDate(date, quantity, timeUnit = "minutes") {
    const now = new Date();
    const interval = {
        start: (0, date_fns_1.sub)(now, {
            minutes: timeUnit === "minutes" ? quantity : 0,
            days: timeUnit === "days" ? quantity : 0,
            weeks: timeUnit === "weeks" ? quantity : 0,
        }),
        end: (0, date_fns_1.add)(now, {
            minutes: timeUnit === "minutes" ? quantity : 0,
            days: timeUnit === "days" ? quantity : 0,
            weeks: timeUnit === "weeks" ? quantity : 0,
        }),
    };
    return (0, date_fns_1.isWithinInterval)(date, interval);
}
exports.isNearDate = isNearDate;
function getMonthDateRange(date = new Date()) {
    return { start: (0, date_fns_1.startOfMonth)(date), end: (0, date_fns_1.endOfMonth)(date) };
}
exports.getMonthDateRange = getMonthDateRange;
function getWeekDateRange(date = new Date()) {
    return {
        start: (0, date_fns_1.startOfWeek)(date, { weekStartsOn: 1 }),
        end: (0, date_fns_1.endOfWeek)(date, { weekStartsOn: 1 }),
    };
}
exports.getWeekDateRange = getWeekDateRange;
function getDayDateRange(date = new Date()) {
    return { start: (0, date_fns_1.startOfDay)(date), end: (0, date_fns_1.endOfDay)(date) };
}
exports.getDayDateRange = getDayDateRange;
