import { formatToLocalDateTime, formatToLocalDate } from "@justplayfair/model";

interface DateTimeProps {
  date: Date;
}
export function DisplayDateTime({ date }: DateTimeProps) {
  return (
    <span className="italic text-gray-600">{formatToLocalDateTime(date)}</span>
  );
}

export function DisplayDate({ date }: DateTimeProps) {
  return (
    <span className="italic text-gray-600">{formatToLocalDate(date)}</span>
  );
}
