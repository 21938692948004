import { Card } from "@components/Card";
import { DisplayDateTime } from "@components/DateTime";
import { GridList } from "@components/GridList";
import { PageContainer } from "@components/Layout";
import { Loader } from "@components/Loader";
import { Title } from "@components/Title";
import { TraineeSessionTable } from "@components/TraineeSessionTable";
import { UserDescriptionTitle } from "@components/UserDescription";
import {
  Client,
  dateToTimeStr,
  formatToLocalDate,
  ID,
  NumberUtilities,
} from "@justplayfair/model";
import classnames from "clsx";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export function TraineeDashboardPage() {
  const { t } = useTranslation();

  const [{ data, fetching }] = Client.useTraineeDashboardQuery({});

  const traineeSessions = data?.me?.sessions;

  const pendingSessions = useMemo(() => {
    if (!traineeSessions) {
      return [];
    }
    return traineeSessions
      ?.filter((session) => ["CREATED", "PLANNED"].includes(session.status))
      .slice(0, 4);
  }, [traineeSessions]);

  if (fetching) {
    return (
      <PageContainer title={t("menu.dashboard")}>
        <Loader size="medium" />
      </PageContainer>
    );
  }

  if (!data?.me) {
    return (
      <PageContainer title={t("menu.dashboard")}>
        <Title size={6}>Introuvable</Title>
      </PageContainer>
    );
  }

  const user = data.me;
  const leftColumnClasses = classnames({
    "w-2/3 border-r pr-4": !!user.programs?.length,
    "w-full": !user.programs?.length,
  });

  return (
    <PageContainer title={<UserDescriptionTitle user={user} />}>
      <div className="w-full mx-auto space-y-10">
        <div className="flex">
          {/* LEFT COLUMN */}
          <div className={leftColumnClasses}>
            <GridList
              title={t("trainee.dashboard.title.myPendingSession")}
              nbCol={2}
            >
              {pendingSessions.map((pendingSession) => (
                <SessionCard key={pendingSession.id} session={pendingSession} />
              ))}
              {pendingSessions.length === 0 &&
                t("trainee.dashboard.text.noSessionExplaination")}
            </GridList>
          </div>

          {/* RIGHT COLUMN */}
          {user.programs && user.programs?.length > 0 && (
            <div className="flex flex-col items-center w-1/3 pl-4 space-y-4">
              <Title size={4}>{t("trainee.dashboard.title.myPrograms")}</Title>
              {user.programs.map((program) => (
                <ProgramCard
                  key={program.id}
                  traineeId={user.id}
                  program={program}
                />
              ))}
            </div>
          )}
        </div>

        <div className="space-y-4">
          <Title size={4}>{t("trainee.dashboard.title.sessionList")}</Title>
          <TraineeSessionTable
            sessions={traineeSessions || []}
            forRole="TRAINEE"
          />
        </div>
      </div>
      {fetching && <Loader mode="overlay" size="medium" />}
    </PageContainer>
  );
}

interface SessionCardProps {
  session: Client.ListSessionFieldsFragment;
}
function SessionCard({ session }: SessionCardProps) {
  const { t } = useTranslation();

  return (
    <Card
      link={{
        external: true,
        to:
          session.externalSessionLink && session.status !== "EVALUATED"
            ? session.externalSessionLink
            : `/trainee/session/${session.id}`,
      }}
    >
      <div className="p-4 min-h-10">
        <Title size={6}>{session.name}</Title>
        {session.status === "CREATED" && session.type === "WITH_TRAINER" && (
          <div>{t("trainee.dashboard.label.pleaseSchedule")}</div>
        )}
        {session.status === "PLANNED" && (
          <div className="text-lg">
            {session.appointment?.startDate && (
              <div className="text-primary">
                <b>
                  {formatToLocalDate(new Date(session.appointment.startDate))}
                </b>{" "}
                {`${t("trainee.dashboard.text.from")} ${dateToTimeStr(
                  new Date(session.appointment.startDate)
                )} ${t("trainee.dashboard.text.to")} ${dateToTimeStr(
                  new Date(session.appointment.endDate)
                )}`}
              </div>
            )}
          </div>
        )}
        {session.status === "EVALUATED" && (
          <div>
            {session.evaluationDate && (
              <>
                {t("trainee.dashboard.label.evaluatedAt")}{" "}
                <DisplayDateTime date={new Date(session.evaluationDate)} />
              </>
            )}
          </div>
        )}
      </div>
    </Card>
  );
}

interface ProgramCardProps {
  traineeId: ID;
  program: { id: ID; name: string; type: Client.ProgramType };
}
function ProgramCard({ traineeId, program }: ProgramCardProps) {
  const { t } = useTranslation();
  const [{ data, fetching }] =
    Client.useTraineeProgramTargetedLevelsAchievementQuery({
      variables: {
        traineeArgs: {
          programId: program.id,
          traineeId,
        },
      },
    });

  const isProgramAchievement = NumberUtilities.isNumber(
    data?.getTraineeProgramTargetedLevelsAchievement,
    true
  );

  return (
    <Card link={{ to: `/trainee/details/${program.id}/${traineeId}` }}>
      <div className="p-4 h-32 w-80 space-y-4">
        <div>
          <Title size={6}>{program.name}</Title>
          <div className="text-gray-600 text-sm">
            {t(`common.programType.${program.type}`)}
          </div>
        </div>
        <div className="space-x-1">
          {fetching && <Loader size="small" />}
          {!fetching && isProgramAchievement && (
            <>
              <span className="text-green-600 text-2xl font-semibold">
                {`${NumberUtilities.toPercentage(
                  data?.getTraineeProgramTargetedLevelsAchievement ?? 0
                )}%`}
              </span>
              <span className="text-sm text-gray-600">
                {t("client.dashboard.text.targetsAchieved")}
              </span>
            </>
          )}
        </div>
      </div>
    </Card>
  );
}
