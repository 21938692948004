import { Chip, ChipContainer } from "@components/Chip";
import { Title } from "@components/Title";
import {
  OfficeBuildingOutline,
  UsersOutline,
} from "@graywolfai/react-heroicons";
import { Client } from "@justplayfair/model";
import { useTranslation } from "react-i18next";

interface GroupDescriptionTitleProps {
  program: NonNullable<Client.ProgramDashboardQuery["program"]>;
}

export function ProgramDescriptionTitle({
  program,
}: GroupDescriptionTitleProps) {
  const { t } = useTranslation();

  return (
    <div className="w-full p-4 flex items-center space-x-8 divide-x divide-gray-200">
      <div className="text-sm font-medium text-gray-900">
        <Title size={5}>{program.name}</Title>
        <div className="text-gray-500">
          {t(`common.programType.${program.type}`)}
          {program.durationInMonths && (
            <span>
              {` - ${t("trainer.dashboard.text.duration")} : ${
                program.durationInMonths
              } ${t("trainer.dashboard.text.months")}`}
            </span>
          )}
        </div>
      </div>
      <dl className="grid grid-cols-1 gap-x-24 gap-y-2 sm:grid-cols-4 pl-8">
        <div className="sm:col-span-1">
          <dt className="flex text-sm font-medium text-gray-500">
            <OfficeBuildingOutline className="h-4 w-4 mr-1" />
            {t("trainer.dashboard.text.company")}
          </dt>
          <dd className="mt-0.5 text-sm text-gray-900">
            {program.company?.name}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="flex text-sm font-medium text-gray-500">
            <UsersOutline className="h-4 w-4 mr-1" />
            {t("trainer.dashboard.text.trainee")}
          </dt>
          <dd className="mt-0.5 text-sm text-gray-900">
            {program.users?.length}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="flex text-sm font-medium text-gray-500">
            {t("trainer.dashboard.text.skills")}
          </dt>
          <dd className="mt-0.5 text-sm ">
            <ChipContainer>
              {program.skills?.map(({ id, name }) => (
                <Chip key={id} color="primary" label={name} />
              ))}
            </ChipContainer>
          </dd>
        </div>
      </dl>
    </div>
  );
}
