import { Card } from "@components/Card";
import { Title } from "@components/Title";
import { ReactNode } from "react";

interface CardSectionProps {
  title: string;
  action?: ReactNode;
  flat?: boolean;
  children: ReactNode;
}
export function CardSection({
  title,
  action,
  flat,
  children,
}: CardSectionProps) {
  return (
    <Card flat={flat} rounded={false}>
      <div className="py-4 px-4 space-y-2">
        <div className="flex justify-between">
          <Title size={4}>{title}</Title>
          {action}
        </div>
        {children}
      </div>
    </Card>
  );
}
