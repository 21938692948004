import { Card } from "@components/Card";
import { CompanyCard } from "@components/CompanyCard";
import { DisplayDateTime } from "@components/DateTime";
import { GridList } from "@components/GridList";
import { PageContainer } from "@components/Layout";
import { Loader } from "@components/Loader";
import { CourseOfAction, Notif } from "@components/Notif";
import { Title } from "@components/Title";
import { UserDescription } from "@components/UserDescription";
import { CheckOutline, EmojiHappyOutline } from "@graywolfai/react-heroicons";
import { useAuthenticatedUser } from "@hooks/authenticatedUser/useAuthenticatedUser.hook";
import { Client, ID } from "@justplayfair/model";
import { useTranslation } from "react-i18next";

export function TrainerDashboardPage() {
  const { t } = useTranslation();

  return (
    <PageContainer title={t("trainer.dashboard.title.dashboard")}>
      <div className="w-full flex-grow flex-col-reverse xl:grid xl:grid-cols-12 space-x-8 xl:divide-x xl:divide-gray-200">
        <main className="xl:col-span-8 space-y-8">
          <TrainerNotifs />
          <TrainerCompanies />
        </main>
        <aside className="xl:col-span-4 pl-8">
          <div className="top-6 space-y-4">
            <Title size={5}>
              {t("trainer.dashboard.title.myScheduledSessions")}
            </Title>
            <ScheduledSessions />
          </div>
        </aside>
      </div>
    </PageContainer>
  );
}

function TrainerNotifs() {
  const { t } = useTranslation();

  const [{ data, fetching }] = Client.useListMyNotificationsQuery();
  const [, ackNotification] = Client.useAckNotificationMutation();

  function handleAcqNotification(notifId: ID) {
    ackNotification({ notifId });
  }
  return (
    <div>
      <GridList title={t("trainer.dashboard.title.myAnomalies")}>
        {fetching && <Loader size="medium" />}
        {!fetching && !data?.notifications.length && (
          <div className="rounded-md bg-green-50 p-2">
            <div className="flex items-center justify-center space-x-4">
              <CheckOutline className="h-5 w-5 text-green-900" />
              <div className="text-lg text-green-800">
                {t("trainer.dashboard.title.noNotif")}
              </div>
              <EmojiHappyOutline className="h-5 w-5 text-green-900" />
            </div>
          </div>
        )}
        {!fetching &&
          data?.notifications.map((notification, index) => (
            <div key={index} className="col-span-1">
              <Notif
                severity={notification.severity}
                title={notification.title}
                message={notification.message}
                courseOfAction={
                  notification.courseOfAction
                    ? courseOfActionMapper(notification.courseOfAction)
                    : undefined
                }
                onClick={() => {
                  handleAcqNotification(notification.id);
                }}
              />
            </div>
          ))}
      </GridList>
    </div>
  );
}

function TrainerCompanies() {
  const { t } = useTranslation();

  const companyType: Client.CompanyType = "CUSTOMER";
  const [{ data, fetching }] = Client.useSearchCompaniesQuery({
    variables: {
      search: {
        filters: {
          deleted: false,
          query: new URLSearchParams({ type: companyType }).toString(),
        },
        skip: 0,
        take: 1000,
      },
    },
  });

  return (
    <GridList title={t("trainer.dashboard.title.myCompanies")}>
      {fetching && <Loader size="medium" />}
      {!fetching && !data?.companies.data.length && (
        <Title size={5}>{t("common.text.noData")}</Title>
      )}
      {!fetching &&
        data?.companies.data.map((company, index) => (
          <CompanyCard
            key={company.id}
            company={company}
            link={{ to: `/trainer/dashboard/company/${company.id}` }}
            testId={`CompanyCard-${index}`}
          />
        ))}
    </GridList>
  );
}

function ScheduledSessions() {
  const { t } = useTranslation();
  const { user: trainer } = useAuthenticatedUser();

  const [{ data, fetching }] = Client.useSearchAppointmentsQuery({
    pause: !trainer,
    variables: {
      search: {
        skip: 0,
        take: 5,
        filters: {
          deleted: false,
          query: new URLSearchParams([
            ["trainerIds", trainer ? trainer.id : ""],
            ["status", "PLANNED"],
          ]).toString(),
        },
      },
    },
  });

  if (fetching) {
    return (
      <div>
        <Loader size="medium" />
      </div>
    );
  }

  return (
    <div className="w-full space-y-2">
      {data?.searchAppointments.data.length === 0 && (
        <div>{t("trainer.dashboard.text.noSession")}</div>
      )}

      {data?.searchAppointments.data.map((appointment) => (
        <Card
          key={appointment.id}
          link={{
            to: `/trainer/trainee/dashboard/${appointment.session?.program?.id}/${appointment.session?.trainee?.id}`,
          }}
        >
          <div className="flex flex-wrap w-full text-sm items-center justify-between py-2 px-4 divide-y divide-gray-200">
            <div className="flex flex-col space-x-2 py-2">
              <div className="font-bold">{appointment.session?.name}</div>
              {appointment.session?.trainee && (
                <UserDescription
                  user={appointment.session.trainee}
                  size="dense"
                />
              )}
            </div>
            <div className="flex flex-col items-end w-full text-xs pt-2">
              <div>
                {appointment.startDate && (
                  <DisplayDateTime date={new Date(appointment.startDate)} />
                )}
              </div>
              <div>
                {appointment?.endDate && (
                  <DisplayDateTime date={new Date(appointment.endDate)} />
                )}
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
}

function courseOfActionMapper(
  courseOfAction: Client.CourseOfActionFieldsFragment
): CourseOfAction | undefined {
  switch (courseOfAction.action) {
    case "CONTACT":
    case "SCHEDULE":
      return {
        action: courseOfAction.action,
        link: `/trainee/${courseOfAction.entityId}`,
      };
    case "DATA_ENTRY":
      return {
        action: courseOfAction.action,
        link: `/dataEntry/${courseOfAction.entityId}`,
      };
  }
}
