"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SaveAppointmentDocument = exports.useSaveTrainerAvailabilitiesMutation = exports.SaveTrainerAvailabilitiesDocument = exports.useGetTrainerSlotsQuery = exports.GetTrainerSlotsDocument = exports.useGetTrainerDayIntervalsQuery = exports.GetTrainerDayIntervalsDocument = exports.useListTraineeAppointmentQuery = exports.ListTraineeAppointmentDocument = exports.useListTrainerAppointmentQuery = exports.ListTrainerAppointmentDocument = exports.useSearchAppointmentsQuery = exports.SearchAppointmentsDocument = exports.OtpEnrollmentFieldsFragmentDoc = exports.PageInfoFieldsFragmentDoc = exports.AdminUserDetailsFieldsFragmentDoc = exports.AuthTokensFieldsFragmentDoc = exports.WithAdvancementUserFieldsFragmentDoc = exports.AdvancementFieldsFragmentDoc = exports.MeUserFieldsFragmentDoc = exports.ExternalAssessmentStateFieldsFragmentDoc = exports.SessionFieldsFragmentDoc = exports.ExternalSimulationFieldsFragmentDoc = exports.AppointmentFieldsFragmentDoc = exports.EvaluationFieldsFragmentDoc = exports.ExternalSessionMappingFieldsFragmentDoc = exports.ListSessionFieldsFragmentDoc = exports.ExternalSessionReplayLinkFieldsFragmentDoc = exports.ProgramFieldsFragmentDoc = exports.SkillDetailsFieldsFragmentDoc = exports.CapacityFieldsFragmentDoc = exports.PermissionRequestFieldsFragmentDoc = exports.PermissionTicketFieldsFragmentDoc = exports.NotificationFieldsFragmentDoc = exports.CourseOfActionFieldsFragmentDoc = exports.FileFieldsFragmentDoc = exports.TargetedLevelFieldsFragmentDoc = exports.SkillFieldsFragmentDoc = exports.SkillWithCapacitiesFieldsFragmentDoc = exports.SkillCapacityRatesFieldsFragmentDoc = exports.ListProgramFieldsFragmentDoc = exports.IndexedByKeyFieldsFragmentDoc = exports.IndexedByDateFieldsFragmentDoc = exports.RadarDataFieldsFragmentDoc = exports.SerieFieldsFragmentDoc = exports.LabelFieldsFragmentDoc = exports.CompanyFieldsFragmentDoc = exports.UserDetailsFieldsFragmentDoc = exports.SlotFieldsFragmentDoc = exports.DayIntervalFieldsFragmentDoc = void 0;
exports.ListSkillsDocument = exports.useGetSkillEvolutionsQuery = exports.GetSkillEvolutionsDocument = exports.useGetSkillsRatesQuery = exports.GetSkillsRatesDocument = exports.useGetRateDetailsQuery = exports.GetRateDetailsDocument = exports.useDeleteLabelMutation = exports.DeleteLabelDocument = exports.useUpdateLabelMutation = exports.UpdateLabelDocument = exports.useCreateLabelMutation = exports.CreateLabelDocument = exports.useSaveProgramNoteMutation = exports.SaveProgramNoteDocument = exports.useRmCompanyAvatarMutation = exports.RmCompanyAvatarDocument = exports.useUploadCompanyAvatarMutation = exports.UploadCompanyAvatarDocument = exports.useDeletecompanyMutation = exports.DeletecompanyDocument = exports.useUpdateCompanyAdminMutation = exports.UpdateCompanyAdminDocument = exports.useCreateCompanyMutation = exports.CreateCompanyDocument = exports.useGetSessionEvaluationUrlQuery = exports.GetSessionEvaluationUrlDocument = exports.useClientCompanyDashboardQuery = exports.ClientCompanyDashboardDocument = exports.useGetLabelQuery = exports.GetLabelDocument = exports.useSearchLabelsQuery = exports.SearchLabelsDocument = exports.useCompanyDashboardQuery = exports.CompanyDashboardDocument = exports.useCompanyStatsQuery = exports.CompanyStatsDocument = exports.useListCompaniesTraineesQuery = exports.ListCompaniesTraineesDocument = exports.useGetCompaniesProgramsQuery = exports.GetCompaniesProgramsDocument = exports.useGetCompanyQuery = exports.GetCompanyDocument = exports.useSearchCompaniesQuery = exports.SearchCompaniesDocument = exports.useCountSkillsQuery = exports.CountSkillsDocument = exports.useCountCompaniesQuery = exports.CountCompaniesDocument = exports.useSaveAppointmentMutation = void 0;
exports.UpdateMediaDocument = exports.useUploadMediaMutation = exports.UploadMediaDocument = exports.useGetUserGuideQuery = exports.GetUserGuideDocument = exports.useGetMediaQuery = exports.GetMediaDocument = exports.useSearchMediasQuery = exports.SearchMediasDocument = exports.useCountMediasQuery = exports.CountMediasDocument = exports.useSaveEvaluationReviewMutation = exports.SaveEvaluationReviewDocument = exports.useSaveEvaluationCommentMutation = exports.SaveEvaluationCommentDocument = exports.useDeleteProgramMutation = exports.DeleteProgramDocument = exports.useRemoveUsersFromProgramMutation = exports.RemoveUsersFromProgramDocument = exports.useAddUsersToProgramMutation = exports.AddUsersToProgramDocument = exports.useUpdateProgramMutation = exports.UpdateProgramDocument = exports.useCreateProgramMutation = exports.CreateProgramDocument = exports.useProgramDashboardQuery = exports.ProgramDashboardDocument = exports.useTraineeProgramTargetedLevelsAchievementQuery = exports.TraineeProgramTargetedLevelsAchievementDocument = exports.useTraineeDetailsDashboardQuery = exports.TraineeDetailsDashboardDocument = exports.useTraineeDashboardQuery = exports.TraineeDashboardDocument = exports.useTrainerTraineeDashboardQuery = exports.TrainerTraineeDashboardDocument = exports.useEvaluationNotesQuery = exports.EvaluationNotesDocument = exports.useEvaluateSessionMutation = exports.EvaluateSessionDocument = exports.useTraineeLastSkillsEvaluationsQuery = exports.TraineeLastSkillsEvaluationsDocument = exports.useGetSkillWithCapacitiesQuery = exports.GetSkillWithCapacitiesDocument = exports.useGetProgramQuery = exports.GetProgramDocument = exports.useListCapacitiesQuery = exports.ListCapacitiesDocument = exports.useListDetailedSkillsQuery = exports.ListDetailedSkillsDocument = exports.useListSkillsQuery = void 0;
exports.MeDocument = exports.useCountUsersQuery = exports.CountUsersDocument = exports.useNotifyTraineeCrAvailabilityMutation = exports.NotifyTraineeCrAvailabilityDocument = exports.useSaveSessionCommentMutation = exports.SaveSessionCommentDocument = exports.useDeleteSessionMutation = exports.DeleteSessionDocument = exports.useUpdateSessionStatusMutation = exports.UpdateSessionStatusDocument = exports.useUpdateSessionMutation = exports.UpdateSessionDocument = exports.useCreateSessionsMutation = exports.CreateSessionsDocument = exports.useExternalAssessmentStateQuery = exports.ExternalAssessmentStateDocument = exports.useExternalSessionMappingsQuery = exports.ExternalSessionMappingsDocument = exports.useSimulationUrlQuery = exports.SimulationUrlDocument = exports.useTraineeProgramAdvancementQuery = exports.TraineeProgramAdvancementDocument = exports.useGetSessionQuery = exports.GetSessionDocument = exports.useSessionListQuery = exports.SessionListDocument = exports.useCountSessionsQuery = exports.CountSessionsDocument = exports.useRemovePermissionTicketMutation = exports.RemovePermissionTicketDocument = exports.useRejectPermissionRequestMutation = exports.RejectPermissionRequestDocument = exports.useAcceptPermissionRequestMutation = exports.AcceptPermissionRequestDocument = exports.useAskCompanyPermissionsMutation = exports.AskCompanyPermissionsDocument = exports.useAskPermissionsMutation = exports.AskPermissionsDocument = exports.useListAuthorizationsQuery = exports.ListAuthorizationsDocument = exports.useAckNotificationMutation = exports.AckNotificationDocument = exports.useListMyNotificationsQuery = exports.ListMyNotificationsDocument = exports.useRestoreMediaMutation = exports.RestoreMediaDocument = exports.useDeleteMediaMutation = exports.DeleteMediaDocument = exports.useUpdateMediaMutation = void 0;
exports.useDeactivateOtpMutation = exports.DeactivateOtpDocument = exports.useActivateOtpMutation = exports.ActivateOtpDocument = exports.useGenerateUserOtpSecretMutation = exports.GenerateUserOtpSecretDocument = exports.useSaveTraineeNoteMutation = exports.SaveTraineeNoteDocument = exports.useRevokeTokenMutation = exports.RevokeTokenDocument = exports.useRmUserAvatarMutation = exports.RmUserAvatarDocument = exports.useUndeleteUserAdminMutation = exports.UndeleteUserAdminDocument = exports.useDeleteUserAdminMutation = exports.DeleteUserAdminDocument = exports.useUploadUserResumeMutation = exports.UploadUserResumeDocument = exports.useUploadUserAvatarMutation = exports.UploadUserAvatarDocument = exports.useSendChangePasswordEmailMutation = exports.SendChangePasswordEmailDocument = exports.useUpdateUserAdminMutation = exports.UpdateUserAdminDocument = exports.useUpdateUserMutation = exports.UpdateUserDocument = exports.useCreateUserMutation = exports.CreateUserDocument = exports.useGetUserQuery = exports.GetUserDocument = exports.useListTrainersQuery = exports.ListTrainersDocument = exports.useGetUserAdminQuery = exports.GetUserAdminDocument = exports.useWithAdvancementClientUserListQuery = exports.WithAdvancementClientUserListDocument = exports.useWithAdvancementUserListQuery = exports.WithAdvancementUserListDocument = exports.useUserListQuery = exports.UserListDocument = exports.useMeQuery = void 0;
const graphql_tag_1 = require("graphql-tag");
const Urql = require("urql");
exports.DayIntervalFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment DayIntervalFields on DayInterval {
  id
  day
  startHour
  duration
}
    `;
exports.SlotFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment SlotFields on Slot {
  day
  status
  timeSlots {
    startHour
    duration
    status
  }
}
    `;
exports.UserDetailsFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment UserDetailsFields on User {
  id
  email
  name
  hiringDate
  roles
  programs {
    id
    name
    type
  }
  avatarImages {
    small
    medium
    large
    original
  }
  activated
  updatedAt
  deleted
  createdAt
  company {
    id
    name
    maxRate
  }
  labels {
    id
    name
    category
  }
}
    `;
exports.CompanyFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment CompanyFields on Company {
  id
  name
  type
  email
  maxRate
  individualDataAccessDeactivated
  sessionEvaluationUrl
  avatarImages {
    small
    medium
    large
    original
  }
  users {
    ...UserDetailsFields
  }
  programs {
    id
    name
  }
}
    ${exports.UserDetailsFieldsFragmentDoc}`;
exports.LabelFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment LabelFields on Label {
  id
  name
  category
  protected
  updatedAt
  createdAt
  deleted
}
    `;
exports.SerieFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment SerieFields on Serie {
  id
  data {
    x
    y
  }
}
    `;
exports.RadarDataFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment RadarDataFields on RadarData {
  keys
  name
  maxRate
  nbMembersInGroup
  data {
    skillName
    name
    rate
  }
}
    `;
exports.IndexedByDateFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment IndexedByDateFields on IndexedByDateDatum {
  id
  type
  name
  keys
  maxRate
  byDate {
    date
    data {
      id
      key
      value
    }
  }
}
    `;
exports.IndexedByKeyFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment IndexedByKeyFields on IndexedByKeyDatum {
  id
  type
  name
  keys
  maxRate
  statName
  byKey {
    key
    isAggregate
    data {
      id
      key
      value
      stats {
        mean
        stdDeviation
      }
    }
  }
}
    `;
exports.ListProgramFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment ListProgramFields on Program {
  id
  name
  type
  companyId
  skills {
    id
    name
  }
  nbTrainees
  durationInMonths
  targetedLevelsAchievement
  assessmentAchievement
}
    `;
exports.SkillCapacityRatesFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment SkillCapacityRatesFields on SkillCapacityRates {
  skillId
  capacityRates {
    capacityId
    evaluationDate
    trainerRating
    iaRating
    globalRating
  }
}
    `;
exports.SkillWithCapacitiesFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment SkillWithCapacitiesFields on Skill {
  id
  name
  capacities {
    id
    nameKey
  }
}
    `;
exports.SkillFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment SkillFields on Skill {
  id
  name
}
    `;
exports.TargetedLevelFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment TargetedLevelFields on TargetedLevel {
  id
  programId
  skill {
    ...SkillFields
  }
  targetedRate
  updatedAt
  createdAt
  deleted
}
    ${exports.SkillFieldsFragmentDoc}`;
exports.FileFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment FileFields on File {
  id
  storageFileId
  name
  description
  type
  size
  labels
  owner {
    ...UserDetailsFields
  }
  updatedAt
  createdAt
  deleted
}
    ${exports.UserDetailsFieldsFragmentDoc}`;
exports.CourseOfActionFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment CourseOfActionFields on CourseOfAction {
  id
  entityId
  action
  acquitted
}
    `;
exports.NotificationFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment NotificationFields on Notification {
  id
  severity
  title
  message
  courseOfAction {
    ...CourseOfActionFields
  }
  acquitted
  createdAt
}
    ${exports.CourseOfActionFieldsFragmentDoc}`;
exports.PermissionTicketFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment PermissionTicketFields on PermissionTicket {
  id
  resource
  permissionScope
  user {
    id
    name
    email
    roles
  }
}
    `;
exports.PermissionRequestFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment PermissionRequestFields on PermissionRequest {
  id
  requester {
    id
    name
    email
    roles
  }
  user {
    id
    name
    email
    roles
  }
  permissionScope
  resourceId
  status
}
    `;
exports.CapacityFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment CapacityFields on Capacity {
  id
  nameKey
}
    `;
exports.SkillDetailsFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment SkillDetailsFields on Skill {
  id
  name
  capacities {
    ...CapacityFields
  }
}
    ${exports.CapacityFieldsFragmentDoc}`;
exports.ProgramFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment ProgramFields on Program {
  id
  name
  type
  companyId
  users {
    ...UserDetailsFields
  }
  skills {
    ...SkillDetailsFields
  }
  durationInMonths
  programCapacityLabels {
    id
    labelValue
    programId
    capacityId
  }
}
    ${exports.UserDetailsFieldsFragmentDoc}
${exports.SkillDetailsFieldsFragmentDoc}`;
exports.ExternalSessionReplayLinkFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment ExternalSessionReplayLinkFields on ExternalSessionReplayLink {
  name
  url
}
    `;
exports.ListSessionFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment ListSessionFields on Session {
  id
  type
  name
  duration
  status
  evaluationDate
  trainee {
    ...UserDetailsFields
  }
  trainer {
    ...UserDetailsFields
  }
  program {
    ...ProgramFields
  }
  appointment {
    startDate
    endDate
  }
  skills {
    id
    name
  }
  sessionComment {
    id
    value
  }
  externalSessionLink
  externalSessionReplayLinks {
    ...ExternalSessionReplayLinkFields
  }
  updatedAt
  createdAt
}
    ${exports.UserDetailsFieldsFragmentDoc}
${exports.ProgramFieldsFragmentDoc}
${exports.ExternalSessionReplayLinkFieldsFragmentDoc}`;
exports.ExternalSessionMappingFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment ExternalSessionMappingFields on ExternalSessionMapping {
  id
  name
  simulationId
}
    `;
exports.EvaluationFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment EvaluationFields on Evaluation {
  id
  trainerRating
  iaRating
  globalRating
  capacity {
    ...CapacityFields
  }
  skill {
    ...SkillFields
  }
}
    ${exports.CapacityFieldsFragmentDoc}
${exports.SkillFieldsFragmentDoc}`;
exports.AppointmentFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment AppointmentFields on Appointment {
  id
  session {
    name
    trainer {
      ...UserDetailsFields
    }
    trainee {
      ...UserDetailsFields
    }
    program {
      id
      name
    }
  }
  startDate
  endDate
}
    ${exports.UserDetailsFieldsFragmentDoc}`;
exports.ExternalSimulationFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment ExternalSimulationFields on ExternalSimulation {
  id
  rank
  withDebrief
  status
  externalSessionMappingId
}
    `;
exports.SessionFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment SessionFields on Session {
  id
  type
  name
  duration
  status
  evaluationDate
  trainee {
    ...UserDetailsFields
  }
  trainer {
    ...UserDetailsFields
  }
  skills {
    id
    name
  }
  evaluations {
    ...EvaluationFields
  }
  appointment {
    ...AppointmentFields
  }
  program {
    ...ProgramFields
  }
  sessionComment {
    id
    value
  }
  externalSessionLink
  externalSessionReplayLinks {
    ...ExternalSessionReplayLinkFields
  }
  externalSimulations {
    ...ExternalSimulationFields
  }
  updatedAt
  createdAt
}
    ${exports.UserDetailsFieldsFragmentDoc}
${exports.EvaluationFieldsFragmentDoc}
${exports.AppointmentFieldsFragmentDoc}
${exports.ProgramFieldsFragmentDoc}
${exports.ExternalSessionReplayLinkFieldsFragmentDoc}
${exports.ExternalSimulationFieldsFragmentDoc}`;
exports.ExternalAssessmentStateFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment ExternalAssessmentStateFields on ExternalAssessmentState {
  id
  name
  status
  withDebrief
  nextUrl
  session {
    ...SessionFields
  }
}
    ${exports.SessionFieldsFragmentDoc}`;
exports.MeUserFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment MeUserFields on User {
  id
  email
  name
  roles
  mfaActivated
  avatarImages {
    small
    medium
    large
    original
  }
  activated
  updatedAt
  deleted
  createdAt
  company {
    id
    name
    maxRate
  }
  resume {
    id
    name
    storageFileId
  }
}
    `;
exports.AdvancementFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment AdvancementFields on Advancement {
  nbSession
  nbSessionDone
}
    `;
exports.WithAdvancementUserFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment WithAdvancementUserFields on User {
  ...UserDetailsFields
  advancement {
    ...AdvancementFields
  }
}
    ${exports.UserDetailsFieldsFragmentDoc}
${exports.AdvancementFieldsFragmentDoc}`;
exports.AuthTokensFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment AuthTokensFields on AuthToken {
  id
  jwtId
  expiration
}
    `;
exports.AdminUserDetailsFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment AdminUserDetailsFields on User {
  ...UserDetailsFields
  mfaActivated
  authTokens {
    ...AuthTokensFields
  }
}
    ${exports.UserDetailsFieldsFragmentDoc}
${exports.AuthTokensFieldsFragmentDoc}`;
exports.PageInfoFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment PageInfoFields on PageInfo {
  from
  to
  count
  hasPrevious
  hasNext
}
    `;
exports.OtpEnrollmentFieldsFragmentDoc = (0, graphql_tag_1.default) `
    fragment OtpEnrollmentFields on OtpEnrollment {
  otpAuth
  otpSecret
}
    `;
exports.SearchAppointmentsDocument = (0, graphql_tag_1.default) `
    query SearchAppointments($search: Search!) {
  searchAppointments(search: $search) {
    pageInfo {
      ...PageInfoFields
    }
    data {
      ...AppointmentFields
    }
  }
}
    ${exports.PageInfoFieldsFragmentDoc}
${exports.AppointmentFieldsFragmentDoc}`;
function useSearchAppointmentsQuery(options) {
    return Urql.useQuery({ query: exports.SearchAppointmentsDocument, ...options });
}
exports.useSearchAppointmentsQuery = useSearchAppointmentsQuery;
;
exports.ListTrainerAppointmentDocument = (0, graphql_tag_1.default) `
    query ListTrainerAppointment($trainerId: ID!, $filters: AppointmentFilters!) {
  listTrainerAppointment(trainerId: $trainerId, filters: $filters) {
    ...AppointmentFields
  }
}
    ${exports.AppointmentFieldsFragmentDoc}`;
function useListTrainerAppointmentQuery(options) {
    return Urql.useQuery({ query: exports.ListTrainerAppointmentDocument, ...options });
}
exports.useListTrainerAppointmentQuery = useListTrainerAppointmentQuery;
;
exports.ListTraineeAppointmentDocument = (0, graphql_tag_1.default) `
    query ListTraineeAppointment($traineeId: ID!, $filters: AppointmentFilters!) {
  listTraineeAppointment(traineeId: $traineeId, filters: $filters) {
    ...AppointmentFields
  }
}
    ${exports.AppointmentFieldsFragmentDoc}`;
function useListTraineeAppointmentQuery(options) {
    return Urql.useQuery({ query: exports.ListTraineeAppointmentDocument, ...options });
}
exports.useListTraineeAppointmentQuery = useListTraineeAppointmentQuery;
;
exports.GetTrainerDayIntervalsDocument = (0, graphql_tag_1.default) `
    query GetTrainerDayIntervals($trainerId: ID!) {
  getTrainerDayIntervals(trainerId: $trainerId) {
    ...DayIntervalFields
  }
}
    ${exports.DayIntervalFieldsFragmentDoc}`;
function useGetTrainerDayIntervalsQuery(options) {
    return Urql.useQuery({ query: exports.GetTrainerDayIntervalsDocument, ...options });
}
exports.useGetTrainerDayIntervalsQuery = useGetTrainerDayIntervalsQuery;
;
exports.GetTrainerSlotsDocument = (0, graphql_tag_1.default) `
    query GetTrainerSlots($trainerId: ID!, $duration: SessionDuration!) {
  getTrainerSlots(trainerId: $trainerId, duration: $duration) {
    ...SlotFields
  }
}
    ${exports.SlotFieldsFragmentDoc}`;
function useGetTrainerSlotsQuery(options) {
    return Urql.useQuery({ query: exports.GetTrainerSlotsDocument, ...options });
}
exports.useGetTrainerSlotsQuery = useGetTrainerSlotsQuery;
;
exports.SaveTrainerAvailabilitiesDocument = (0, graphql_tag_1.default) `
    mutation SaveTrainerAvailabilities($trainerId: ID!, $availabilities: [TrainerAvailabilityInput!]!) {
  saveTrainerAvailabilities(
    trainerId: $trainerId
    availabilities: $availabilities
  )
}
    `;
function useSaveTrainerAvailabilitiesMutation() {
    return Urql.useMutation(exports.SaveTrainerAvailabilitiesDocument);
}
exports.useSaveTrainerAvailabilitiesMutation = useSaveTrainerAvailabilitiesMutation;
;
exports.SaveAppointmentDocument = (0, graphql_tag_1.default) `
    mutation SaveAppointment($input: AppointmentInput!) {
  saveAppointment(appointment: $input) {
    ...AppointmentFields
  }
}
    ${exports.AppointmentFieldsFragmentDoc}`;
function useSaveAppointmentMutation() {
    return Urql.useMutation(exports.SaveAppointmentDocument);
}
exports.useSaveAppointmentMutation = useSaveAppointmentMutation;
;
exports.CountCompaniesDocument = (0, graphql_tag_1.default) `
    query CountCompanies {
  countCompanies
}
    `;
function useCountCompaniesQuery(options) {
    return Urql.useQuery({ query: exports.CountCompaniesDocument, ...options });
}
exports.useCountCompaniesQuery = useCountCompaniesQuery;
;
exports.CountSkillsDocument = (0, graphql_tag_1.default) `
    query CountSkills {
  countSkills
}
    `;
function useCountSkillsQuery(options) {
    return Urql.useQuery({ query: exports.CountSkillsDocument, ...options });
}
exports.useCountSkillsQuery = useCountSkillsQuery;
;
exports.SearchCompaniesDocument = (0, graphql_tag_1.default) `
    query SearchCompanies($search: Search!) {
  companies(search: $search) {
    pageInfo {
      ...PageInfoFields
    }
    data {
      ...CompanyFields
    }
  }
}
    ${exports.PageInfoFieldsFragmentDoc}
${exports.CompanyFieldsFragmentDoc}`;
function useSearchCompaniesQuery(options) {
    return Urql.useQuery({ query: exports.SearchCompaniesDocument, ...options });
}
exports.useSearchCompaniesQuery = useSearchCompaniesQuery;
;
exports.GetCompanyDocument = (0, graphql_tag_1.default) `
    query GetCompany($id: ID!) {
  company(id: $id) {
    ...CompanyFields
  }
}
    ${exports.CompanyFieldsFragmentDoc}`;
function useGetCompanyQuery(options) {
    return Urql.useQuery({ query: exports.GetCompanyDocument, ...options });
}
exports.useGetCompanyQuery = useGetCompanyQuery;
;
exports.GetCompaniesProgramsDocument = (0, graphql_tag_1.default) `
    query GetCompaniesPrograms($companyIds: [ID!]!) {
  companiesPrograms(companyIds: $companyIds) {
    ...ListProgramFields
    users {
      ...UserDetailsFields
    }
  }
}
    ${exports.ListProgramFieldsFragmentDoc}
${exports.UserDetailsFieldsFragmentDoc}`;
function useGetCompaniesProgramsQuery(options) {
    return Urql.useQuery({ query: exports.GetCompaniesProgramsDocument, ...options });
}
exports.useGetCompaniesProgramsQuery = useGetCompaniesProgramsQuery;
;
exports.ListCompaniesTraineesDocument = (0, graphql_tag_1.default) `
    query ListCompaniesTrainees($companyIds: [ID!]!) {
  listCompaniesTrainees(companyIds: $companyIds) {
    ...UserDetailsFields
  }
}
    ${exports.UserDetailsFieldsFragmentDoc}`;
function useListCompaniesTraineesQuery(options) {
    return Urql.useQuery({ query: exports.ListCompaniesTraineesDocument, ...options });
}
exports.useListCompaniesTraineesQuery = useListCompaniesTraineesQuery;
;
exports.CompanyStatsDocument = (0, graphql_tag_1.default) `
    query CompanyStats($companyId: ID!) {
  companyStats(companyId: $companyId) {
    nbUsers
    nbPrograms
    doneSession
    plannedSession
    createdSession
  }
}
    `;
function useCompanyStatsQuery(options) {
    return Urql.useQuery({ query: exports.CompanyStatsDocument, ...options });
}
exports.useCompanyStatsQuery = useCompanyStatsQuery;
;
exports.CompanyDashboardDocument = (0, graphql_tag_1.default) `
    query CompanyDashboard($companyId: ID!) {
  company(id: $companyId) {
    id
    name
    email
    avatarImages {
      small
      medium
      large
      original
    }
  }
  companiesPrograms(companyIds: [$companyId]) {
    ...ListProgramFields
  }
}
    ${exports.ListProgramFieldsFragmentDoc}`;
function useCompanyDashboardQuery(options) {
    return Urql.useQuery({ query: exports.CompanyDashboardDocument, ...options });
}
exports.useCompanyDashboardQuery = useCompanyDashboardQuery;
;
exports.SearchLabelsDocument = (0, graphql_tag_1.default) `
    query SearchLabels($search: Search!) {
  searchLabels(search: $search) {
    pageInfo {
      ...PageInfoFields
    }
    data {
      ...LabelFields
    }
  }
}
    ${exports.PageInfoFieldsFragmentDoc}
${exports.LabelFieldsFragmentDoc}`;
function useSearchLabelsQuery(options) {
    return Urql.useQuery({ query: exports.SearchLabelsDocument, ...options });
}
exports.useSearchLabelsQuery = useSearchLabelsQuery;
;
exports.GetLabelDocument = (0, graphql_tag_1.default) `
    query GetLabel($labelId: ID!) {
  getLabel(labelId: $labelId) {
    ...LabelFields
  }
}
    ${exports.LabelFieldsFragmentDoc}`;
function useGetLabelQuery(options) {
    return Urql.useQuery({ query: exports.GetLabelDocument, ...options });
}
exports.useGetLabelQuery = useGetLabelQuery;
;
exports.ClientCompanyDashboardDocument = (0, graphql_tag_1.default) `
    query ClientCompanyDashboard($companyId: ID!) {
  company(id: $companyId) {
    id
    name
  }
  companiesPrograms(companyIds: [$companyId]) {
    ...ListProgramFields
  }
}
    ${exports.ListProgramFieldsFragmentDoc}`;
function useClientCompanyDashboardQuery(options) {
    return Urql.useQuery({ query: exports.ClientCompanyDashboardDocument, ...options });
}
exports.useClientCompanyDashboardQuery = useClientCompanyDashboardQuery;
;
exports.GetSessionEvaluationUrlDocument = (0, graphql_tag_1.default) `
    query GetSessionEvaluationUrl($companyId: ID!) {
  getSessionEvaluationUrl(id: $companyId)
}
    `;
function useGetSessionEvaluationUrlQuery(options) {
    return Urql.useQuery({ query: exports.GetSessionEvaluationUrlDocument, ...options });
}
exports.useGetSessionEvaluationUrlQuery = useGetSessionEvaluationUrlQuery;
;
exports.CreateCompanyDocument = (0, graphql_tag_1.default) `
    mutation CreateCompany($input: CreateCompanyInput!) {
  createCompany(input: $input) {
    ...CompanyFields
  }
}
    ${exports.CompanyFieldsFragmentDoc}`;
function useCreateCompanyMutation() {
    return Urql.useMutation(exports.CreateCompanyDocument);
}
exports.useCreateCompanyMutation = useCreateCompanyMutation;
;
exports.UpdateCompanyAdminDocument = (0, graphql_tag_1.default) `
    mutation UpdateCompanyAdmin($id: ID!, $input: UpdateCompanyInputAdmin!) {
  updateCompanyAdmin(id: $id, input: $input) {
    ...CompanyFields
  }
}
    ${exports.CompanyFieldsFragmentDoc}`;
function useUpdateCompanyAdminMutation() {
    return Urql.useMutation(exports.UpdateCompanyAdminDocument);
}
exports.useUpdateCompanyAdminMutation = useUpdateCompanyAdminMutation;
;
exports.DeletecompanyDocument = (0, graphql_tag_1.default) `
    mutation Deletecompany($id: ID!) {
  softDeleteCompany(companyId: $id)
}
    `;
function useDeletecompanyMutation() {
    return Urql.useMutation(exports.DeletecompanyDocument);
}
exports.useDeletecompanyMutation = useDeletecompanyMutation;
;
exports.UploadCompanyAvatarDocument = (0, graphql_tag_1.default) `
    mutation UploadCompanyAvatar($companyId: ID!, $file: Upload!, $cropData: CropData!) {
  uploadCompanyAvatar(companyId: $companyId, upload: $file, cropData: $cropData)
}
    `;
function useUploadCompanyAvatarMutation() {
    return Urql.useMutation(exports.UploadCompanyAvatarDocument);
}
exports.useUploadCompanyAvatarMutation = useUploadCompanyAvatarMutation;
;
exports.RmCompanyAvatarDocument = (0, graphql_tag_1.default) `
    mutation RmCompanyAvatar($companyId: ID!) {
  rmCompanyAvatar(companyId: $companyId) {
    id
  }
}
    `;
function useRmCompanyAvatarMutation() {
    return Urql.useMutation(exports.RmCompanyAvatarDocument);
}
exports.useRmCompanyAvatarMutation = useRmCompanyAvatarMutation;
;
exports.SaveProgramNoteDocument = (0, graphql_tag_1.default) `
    mutation SaveProgramNote($programId: ID!, $noteValue: String) {
  saveProgramNote(programId: $programId, noteValue: $noteValue) {
    id
    value
  }
}
    `;
function useSaveProgramNoteMutation() {
    return Urql.useMutation(exports.SaveProgramNoteDocument);
}
exports.useSaveProgramNoteMutation = useSaveProgramNoteMutation;
;
exports.CreateLabelDocument = (0, graphql_tag_1.default) `
    mutation CreateLabel($input: LabelInput!) {
  createLabel(input: $input) {
    ...LabelFields
  }
}
    ${exports.LabelFieldsFragmentDoc}`;
function useCreateLabelMutation() {
    return Urql.useMutation(exports.CreateLabelDocument);
}
exports.useCreateLabelMutation = useCreateLabelMutation;
;
exports.UpdateLabelDocument = (0, graphql_tag_1.default) `
    mutation UpdateLabel($labelId: ID!, $input: LabelInput!) {
  updateLabel(labelId: $labelId, input: $input) {
    ...LabelFields
  }
}
    ${exports.LabelFieldsFragmentDoc}`;
function useUpdateLabelMutation() {
    return Urql.useMutation(exports.UpdateLabelDocument);
}
exports.useUpdateLabelMutation = useUpdateLabelMutation;
;
exports.DeleteLabelDocument = (0, graphql_tag_1.default) `
    mutation DeleteLabel($labelId: ID!) {
  deleteLabel(labelId: $labelId)
}
    `;
function useDeleteLabelMutation() {
    return Urql.useMutation(exports.DeleteLabelDocument);
}
exports.useDeleteLabelMutation = useDeleteLabelMutation;
;
exports.GetRateDetailsDocument = (0, graphql_tag_1.default) `
    query GetRateDetails($filters: RateFilters!) {
  getRateDetails(filters: $filters) {
    ...IndexedByKeyFields
  }
}
    ${exports.IndexedByKeyFieldsFragmentDoc}`;
function useGetRateDetailsQuery(options) {
    return Urql.useQuery({ query: exports.GetRateDetailsDocument, ...options });
}
exports.useGetRateDetailsQuery = useGetRateDetailsQuery;
;
exports.GetSkillsRatesDocument = (0, graphql_tag_1.default) `
    query GetSkillsRates($filters: RateFilters!) {
  getSkillsRates(filters: $filters) {
    ...RadarDataFields
  }
}
    ${exports.RadarDataFieldsFragmentDoc}`;
function useGetSkillsRatesQuery(options) {
    return Urql.useQuery({ query: exports.GetSkillsRatesDocument, ...options });
}
exports.useGetSkillsRatesQuery = useGetSkillsRatesQuery;
;
exports.GetSkillEvolutionsDocument = (0, graphql_tag_1.default) `
    query GetSkillEvolutions($filters: RateFilters!) {
  getSkillEvolutions(filters: $filters) {
    ...IndexedByKeyFields
  }
}
    ${exports.IndexedByKeyFieldsFragmentDoc}`;
function useGetSkillEvolutionsQuery(options) {
    return Urql.useQuery({ query: exports.GetSkillEvolutionsDocument, ...options });
}
exports.useGetSkillEvolutionsQuery = useGetSkillEvolutionsQuery;
;
exports.ListSkillsDocument = (0, graphql_tag_1.default) `
    query ListSkills {
  listSkills(search: {skip: 0, take: 10000}) {
    pageInfo {
      ...PageInfoFields
    }
    data {
      ...SkillFields
    }
  }
}
    ${exports.PageInfoFieldsFragmentDoc}
${exports.SkillFieldsFragmentDoc}`;
function useListSkillsQuery(options) {
    return Urql.useQuery({ query: exports.ListSkillsDocument, ...options });
}
exports.useListSkillsQuery = useListSkillsQuery;
;
exports.ListDetailedSkillsDocument = (0, graphql_tag_1.default) `
    query ListDetailedSkills($search: Search!) {
  listSkills(search: $search) {
    pageInfo {
      ...PageInfoFields
    }
    data {
      ...SkillDetailsFields
    }
  }
}
    ${exports.PageInfoFieldsFragmentDoc}
${exports.SkillDetailsFieldsFragmentDoc}`;
function useListDetailedSkillsQuery(options) {
    return Urql.useQuery({ query: exports.ListDetailedSkillsDocument, ...options });
}
exports.useListDetailedSkillsQuery = useListDetailedSkillsQuery;
;
exports.ListCapacitiesDocument = (0, graphql_tag_1.default) `
    query ListCapacities {
  listCapacities {
    ...CapacityFields
  }
}
    ${exports.CapacityFieldsFragmentDoc}`;
function useListCapacitiesQuery(options) {
    return Urql.useQuery({ query: exports.ListCapacitiesDocument, ...options });
}
exports.useListCapacitiesQuery = useListCapacitiesQuery;
;
exports.GetProgramDocument = (0, graphql_tag_1.default) `
    query GetProgram($programId: ID!) {
  program(programId: $programId) {
    ...ProgramFields
    targetedLevels {
      ...TargetedLevelFields
    }
    users {
      ...UserDetailsFields
    }
  }
}
    ${exports.ProgramFieldsFragmentDoc}
${exports.TargetedLevelFieldsFragmentDoc}
${exports.UserDetailsFieldsFragmentDoc}`;
function useGetProgramQuery(options) {
    return Urql.useQuery({ query: exports.GetProgramDocument, ...options });
}
exports.useGetProgramQuery = useGetProgramQuery;
;
exports.GetSkillWithCapacitiesDocument = (0, graphql_tag_1.default) `
    query GetSkillWithCapacities($id: ID!) {
  getSkill(id: $id) {
    ...SkillWithCapacitiesFields
  }
}
    ${exports.SkillWithCapacitiesFieldsFragmentDoc}`;
function useGetSkillWithCapacitiesQuery(options) {
    return Urql.useQuery({ query: exports.GetSkillWithCapacitiesDocument, ...options });
}
exports.useGetSkillWithCapacitiesQuery = useGetSkillWithCapacitiesQuery;
;
exports.TraineeLastSkillsEvaluationsDocument = (0, graphql_tag_1.default) `
    query TraineeLastSkillsEvaluations($input: TraineeLastSkillsEvaluationsInput!) {
  traineeLastSkillsEvaluations(input: $input) {
    ...SkillCapacityRatesFields
  }
}
    ${exports.SkillCapacityRatesFieldsFragmentDoc}`;
function useTraineeLastSkillsEvaluationsQuery(options) {
    return Urql.useQuery({ query: exports.TraineeLastSkillsEvaluationsDocument, ...options });
}
exports.useTraineeLastSkillsEvaluationsQuery = useTraineeLastSkillsEvaluationsQuery;
;
exports.EvaluateSessionDocument = (0, graphql_tag_1.default) `
    mutation EvaluateSession($sessionId: ID!, $evaluationDate: Date, $input: [SessionEvaluationInput!]!) {
  evaluateSession(
    sessionId: $sessionId
    evaluationDate: $evaluationDate
    input: $input
  )
}
    `;
function useEvaluateSessionMutation() {
    return Urql.useMutation(exports.EvaluateSessionDocument);
}
exports.useEvaluateSessionMutation = useEvaluateSessionMutation;
;
exports.EvaluationNotesDocument = (0, graphql_tag_1.default) `
    query EvaluationNotes($traineeId: ID!, $programId: ID!, $sessionId: ID) {
  evaluationComment(
    traineeArgs: {traineeId: $traineeId, programId: $programId}
    sessionId: $sessionId
  ) {
    id
    value
  }
  evaluationReview(
    traineeArgs: {traineeId: $traineeId, programId: $programId}
    sessionId: $sessionId
  ) {
    id
    value
  }
}
    `;
function useEvaluationNotesQuery(options) {
    return Urql.useQuery({ query: exports.EvaluationNotesDocument, ...options });
}
exports.useEvaluationNotesQuery = useEvaluationNotesQuery;
;
exports.TrainerTraineeDashboardDocument = (0, graphql_tag_1.default) `
    query TrainerTraineeDashboard($traineeId: ID!) {
  user(id: $traineeId) {
    ...UserDetailsFields
    sessions {
      ...SessionFields
    }
    traineeNote {
      id
      value
    }
    advancement {
      ...AdvancementFields
    }
  }
}
    ${exports.UserDetailsFieldsFragmentDoc}
${exports.SessionFieldsFragmentDoc}
${exports.AdvancementFieldsFragmentDoc}`;
function useTrainerTraineeDashboardQuery(options) {
    return Urql.useQuery({ query: exports.TrainerTraineeDashboardDocument, ...options });
}
exports.useTrainerTraineeDashboardQuery = useTrainerTraineeDashboardQuery;
;
exports.TraineeDashboardDocument = (0, graphql_tag_1.default) `
    query TraineeDashboard {
  me {
    ...UserDetailsFields
    sessions {
      ...SessionFields
    }
  }
}
    ${exports.UserDetailsFieldsFragmentDoc}
${exports.SessionFieldsFragmentDoc}`;
function useTraineeDashboardQuery(options) {
    return Urql.useQuery({ query: exports.TraineeDashboardDocument, ...options });
}
exports.useTraineeDashboardQuery = useTraineeDashboardQuery;
;
exports.TraineeDetailsDashboardDocument = (0, graphql_tag_1.default) `
    query TraineeDetailsDashboard($traineeId: ID!, $programId: ID!, $sessionSearch: Search!) {
  userInProgram(userId: $traineeId, programId: $programId) {
    ...UserDetailsFields
  }
  traineeNoteInProgram(userId: $traineeId, programId: $programId) {
    id
    value
  }
  getTraineeProgramTargetedLevelsAchievement(
    traineeArgs: {traineeId: $traineeId, programId: $programId}
  )
  getTraineeProgramAdvancement(
    traineeArgs: {traineeId: $traineeId, programId: $programId}
  ) {
    ...AdvancementFields
  }
  searchSessions(search: $sessionSearch) {
    pageInfo {
      ...PageInfoFields
    }
    data {
      ...ListSessionFields
    }
  }
}
    ${exports.UserDetailsFieldsFragmentDoc}
${exports.AdvancementFieldsFragmentDoc}
${exports.PageInfoFieldsFragmentDoc}
${exports.ListSessionFieldsFragmentDoc}`;
function useTraineeDetailsDashboardQuery(options) {
    return Urql.useQuery({ query: exports.TraineeDetailsDashboardDocument, ...options });
}
exports.useTraineeDetailsDashboardQuery = useTraineeDetailsDashboardQuery;
;
exports.TraineeProgramTargetedLevelsAchievementDocument = (0, graphql_tag_1.default) `
    query TraineeProgramTargetedLevelsAchievement($traineeArgs: TraineeArgs!) {
  getTraineeProgramTargetedLevelsAchievement(traineeArgs: $traineeArgs)
}
    `;
function useTraineeProgramTargetedLevelsAchievementQuery(options) {
    return Urql.useQuery({ query: exports.TraineeProgramTargetedLevelsAchievementDocument, ...options });
}
exports.useTraineeProgramTargetedLevelsAchievementQuery = useTraineeProgramTargetedLevelsAchievementQuery;
;
exports.ProgramDashboardDocument = (0, graphql_tag_1.default) `
    query ProgramDashboard($programId: ID!) {
  program(programId: $programId) {
    ...ProgramFields
    targetedLevels {
      ...TargetedLevelFields
    }
    sessionsAdvancement {
      ...AdvancementFields
    }
    targetedLevelsAchievement
    assessmentAchievement
    note {
      id
      value
    }
    company {
      name
    }
  }
}
    ${exports.ProgramFieldsFragmentDoc}
${exports.TargetedLevelFieldsFragmentDoc}
${exports.AdvancementFieldsFragmentDoc}`;
function useProgramDashboardQuery(options) {
    return Urql.useQuery({ query: exports.ProgramDashboardDocument, ...options });
}
exports.useProgramDashboardQuery = useProgramDashboardQuery;
;
exports.CreateProgramDocument = (0, graphql_tag_1.default) `
    mutation CreateProgram($input: CreateProgramInput!) {
  createProgram(input: $input) {
    ...ProgramFields
  }
}
    ${exports.ProgramFieldsFragmentDoc}`;
function useCreateProgramMutation() {
    return Urql.useMutation(exports.CreateProgramDocument);
}
exports.useCreateProgramMutation = useCreateProgramMutation;
;
exports.UpdateProgramDocument = (0, graphql_tag_1.default) `
    mutation UpdateProgram($id: ID!, $input: UpdateProgramInput!) {
  updateProgram(id: $id, input: $input) {
    ...ProgramFields
  }
}
    ${exports.ProgramFieldsFragmentDoc}`;
function useUpdateProgramMutation() {
    return Urql.useMutation(exports.UpdateProgramDocument);
}
exports.useUpdateProgramMutation = useUpdateProgramMutation;
;
exports.AddUsersToProgramDocument = (0, graphql_tag_1.default) `
    mutation AddUsersToProgram($programId: ID!, $userIds: [ID!]!) {
  addUsersToProgram(programId: $programId, userIds: $userIds) {
    ...ProgramFields
  }
}
    ${exports.ProgramFieldsFragmentDoc}`;
function useAddUsersToProgramMutation() {
    return Urql.useMutation(exports.AddUsersToProgramDocument);
}
exports.useAddUsersToProgramMutation = useAddUsersToProgramMutation;
;
exports.RemoveUsersFromProgramDocument = (0, graphql_tag_1.default) `
    mutation RemoveUsersFromProgram($programId: ID!, $userIds: [ID!]!) {
  removeUsersFromProgram(programId: $programId, userIds: $userIds) {
    ...ProgramFields
  }
}
    ${exports.ProgramFieldsFragmentDoc}`;
function useRemoveUsersFromProgramMutation() {
    return Urql.useMutation(exports.RemoveUsersFromProgramDocument);
}
exports.useRemoveUsersFromProgramMutation = useRemoveUsersFromProgramMutation;
;
exports.DeleteProgramDocument = (0, graphql_tag_1.default) `
    mutation DeleteProgram($id: ID!) {
  softDeleteProgram(programId: $id)
}
    `;
function useDeleteProgramMutation() {
    return Urql.useMutation(exports.DeleteProgramDocument);
}
exports.useDeleteProgramMutation = useDeleteProgramMutation;
;
exports.SaveEvaluationCommentDocument = (0, graphql_tag_1.default) `
    mutation SaveEvaluationComment($evaluationId: ID!, $noteValue: String) {
  saveEvaluationComment(evaluationId: $evaluationId, noteValue: $noteValue) {
    id
    value
  }
}
    `;
function useSaveEvaluationCommentMutation() {
    return Urql.useMutation(exports.SaveEvaluationCommentDocument);
}
exports.useSaveEvaluationCommentMutation = useSaveEvaluationCommentMutation;
;
exports.SaveEvaluationReviewDocument = (0, graphql_tag_1.default) `
    mutation SaveEvaluationReview($reviewId: ID!, $noteValue: String) {
  saveEvaluationReview(reviewId: $reviewId, noteValue: $noteValue) {
    id
    value
  }
}
    `;
function useSaveEvaluationReviewMutation() {
    return Urql.useMutation(exports.SaveEvaluationReviewDocument);
}
exports.useSaveEvaluationReviewMutation = useSaveEvaluationReviewMutation;
;
exports.CountMediasDocument = (0, graphql_tag_1.default) `
    query CountMedias($withDeleted: Boolean) {
  countMedias(withDeleted: $withDeleted)
}
    `;
function useCountMediasQuery(options) {
    return Urql.useQuery({ query: exports.CountMediasDocument, ...options });
}
exports.useCountMediasQuery = useCountMediasQuery;
;
exports.SearchMediasDocument = (0, graphql_tag_1.default) `
    query SearchMedias($search: Search!) {
  searchMedias(search: $search) {
    pageInfo {
      ...PageInfoFields
    }
    data {
      ...FileFields
    }
  }
}
    ${exports.PageInfoFieldsFragmentDoc}
${exports.FileFieldsFragmentDoc}`;
function useSearchMediasQuery(options) {
    return Urql.useQuery({ query: exports.SearchMediasDocument, ...options });
}
exports.useSearchMediasQuery = useSearchMediasQuery;
;
exports.GetMediaDocument = (0, graphql_tag_1.default) `
    query GetMedia($mediaId: ID!) {
  getMedia(mediaId: $mediaId) {
    ...FileFields
  }
}
    ${exports.FileFieldsFragmentDoc}`;
function useGetMediaQuery(options) {
    return Urql.useQuery({ query: exports.GetMediaDocument, ...options });
}
exports.useGetMediaQuery = useGetMediaQuery;
;
exports.GetUserGuideDocument = (0, graphql_tag_1.default) `
    query GetUserGuide($role: Role!) {
  getUserGuide(role: $role) {
    ...FileFields
  }
}
    ${exports.FileFieldsFragmentDoc}`;
function useGetUserGuideQuery(options) {
    return Urql.useQuery({ query: exports.GetUserGuideDocument, ...options });
}
exports.useGetUserGuideQuery = useGetUserGuideQuery;
;
exports.UploadMediaDocument = (0, graphql_tag_1.default) `
    mutation UploadMedia($mediaInput: MediaInput!) {
  uploadMedia(input: $mediaInput)
}
    `;
function useUploadMediaMutation() {
    return Urql.useMutation(exports.UploadMediaDocument);
}
exports.useUploadMediaMutation = useUploadMediaMutation;
;
exports.UpdateMediaDocument = (0, graphql_tag_1.default) `
    mutation UpdateMedia($updateMedianput: UpdateMediaInput!) {
  updateMedia(input: $updateMedianput)
}
    `;
function useUpdateMediaMutation() {
    return Urql.useMutation(exports.UpdateMediaDocument);
}
exports.useUpdateMediaMutation = useUpdateMediaMutation;
;
exports.DeleteMediaDocument = (0, graphql_tag_1.default) `
    mutation DeleteMedia($mediaId: ID!) {
  deleteMedia(mediaId: $mediaId)
}
    `;
function useDeleteMediaMutation() {
    return Urql.useMutation(exports.DeleteMediaDocument);
}
exports.useDeleteMediaMutation = useDeleteMediaMutation;
;
exports.RestoreMediaDocument = (0, graphql_tag_1.default) `
    mutation RestoreMedia($mediaId: ID!) {
  restoreMedia(mediaId: $mediaId)
}
    `;
function useRestoreMediaMutation() {
    return Urql.useMutation(exports.RestoreMediaDocument);
}
exports.useRestoreMediaMutation = useRestoreMediaMutation;
;
exports.ListMyNotificationsDocument = (0, graphql_tag_1.default) `
    query ListMyNotifications($acquitted: Boolean) {
  notifications(acquitted: $acquitted) {
    ...NotificationFields
  }
}
    ${exports.NotificationFieldsFragmentDoc}`;
function useListMyNotificationsQuery(options) {
    return Urql.useQuery({ query: exports.ListMyNotificationsDocument, ...options });
}
exports.useListMyNotificationsQuery = useListMyNotificationsQuery;
;
exports.AckNotificationDocument = (0, graphql_tag_1.default) `
    mutation AckNotification($notifId: ID!) {
  ackNotification(notifId: $notifId)
}
    `;
function useAckNotificationMutation() {
    return Urql.useMutation(exports.AckNotificationDocument);
}
exports.useAckNotificationMutation = useAckNotificationMutation;
;
exports.ListAuthorizationsDocument = (0, graphql_tag_1.default) `
    query ListAuthorizations($status: [PermissionRequestStatus!]) {
  listMyGivenAuthorizations {
    ...PermissionTicketFields
  }
  listAuthorizationRequests(status: $status) {
    ...PermissionRequestFields
  }
}
    ${exports.PermissionTicketFieldsFragmentDoc}
${exports.PermissionRequestFieldsFragmentDoc}`;
function useListAuthorizationsQuery(options) {
    return Urql.useQuery({ query: exports.ListAuthorizationsDocument, ...options });
}
exports.useListAuthorizationsQuery = useListAuthorizationsQuery;
;
exports.AskPermissionsDocument = (0, graphql_tag_1.default) `
    mutation AskPermissions($input: AskPermissionsInput!) {
  askPermissions(input: $input)
}
    `;
function useAskPermissionsMutation() {
    return Urql.useMutation(exports.AskPermissionsDocument);
}
exports.useAskPermissionsMutation = useAskPermissionsMutation;
;
exports.AskCompanyPermissionsDocument = (0, graphql_tag_1.default) `
    mutation AskCompanyPermissions($input: AskCompanyPermissionsInput!) {
  askCompanyPermissions(input: $input)
}
    `;
function useAskCompanyPermissionsMutation() {
    return Urql.useMutation(exports.AskCompanyPermissionsDocument);
}
exports.useAskCompanyPermissionsMutation = useAskCompanyPermissionsMutation;
;
exports.AcceptPermissionRequestDocument = (0, graphql_tag_1.default) `
    mutation AcceptPermissionRequest($permissionRequestId: ID!) {
  acceptPermissionRequest(permissionRequestId: $permissionRequestId)
}
    `;
function useAcceptPermissionRequestMutation() {
    return Urql.useMutation(exports.AcceptPermissionRequestDocument);
}
exports.useAcceptPermissionRequestMutation = useAcceptPermissionRequestMutation;
;
exports.RejectPermissionRequestDocument = (0, graphql_tag_1.default) `
    mutation RejectPermissionRequest($permissionRequestId: ID!) {
  rejectPermissionRequest(permissionRequestId: $permissionRequestId)
}
    `;
function useRejectPermissionRequestMutation() {
    return Urql.useMutation(exports.RejectPermissionRequestDocument);
}
exports.useRejectPermissionRequestMutation = useRejectPermissionRequestMutation;
;
exports.RemovePermissionTicketDocument = (0, graphql_tag_1.default) `
    mutation RemovePermissionTicket($permissionTicketId: ID!) {
  removePermissionTicket(permissionTicketId: $permissionTicketId)
}
    `;
function useRemovePermissionTicketMutation() {
    return Urql.useMutation(exports.RemovePermissionTicketDocument);
}
exports.useRemovePermissionTicketMutation = useRemovePermissionTicketMutation;
;
exports.CountSessionsDocument = (0, graphql_tag_1.default) `
    query CountSessions($status: [SessionStatus!]!) {
  countSessions(status: $status)
}
    `;
function useCountSessionsQuery(options) {
    return Urql.useQuery({ query: exports.CountSessionsDocument, ...options });
}
exports.useCountSessionsQuery = useCountSessionsQuery;
;
exports.SessionListDocument = (0, graphql_tag_1.default) `
    query SessionList($search: Search!) {
  searchSessions(search: $search) {
    pageInfo {
      ...PageInfoFields
    }
    data {
      ...ListSessionFields
    }
  }
}
    ${exports.PageInfoFieldsFragmentDoc}
${exports.ListSessionFieldsFragmentDoc}`;
function useSessionListQuery(options) {
    return Urql.useQuery({ query: exports.SessionListDocument, ...options });
}
exports.useSessionListQuery = useSessionListQuery;
;
exports.GetSessionDocument = (0, graphql_tag_1.default) `
    query GetSession($id: ID!) {
  getSession(id: $id) {
    ...SessionFields
    evaluationComment {
      id
      value
    }
    sessionComment {
      id
      value
    }
    evaluationReview {
      id
      value
    }
    skills {
      ...SkillFields
      capacities {
        ...CapacityFields
      }
    }
  }
}
    ${exports.SessionFieldsFragmentDoc}
${exports.SkillFieldsFragmentDoc}
${exports.CapacityFieldsFragmentDoc}`;
function useGetSessionQuery(options) {
    return Urql.useQuery({ query: exports.GetSessionDocument, ...options });
}
exports.useGetSessionQuery = useGetSessionQuery;
;
exports.TraineeProgramAdvancementDocument = (0, graphql_tag_1.default) `
    query TraineeProgramAdvancement($traineeArgs: TraineeArgs!) {
  getTraineeProgramAdvancement(traineeArgs: $traineeArgs) {
    ...AdvancementFields
  }
}
    ${exports.AdvancementFieldsFragmentDoc}`;
function useTraineeProgramAdvancementQuery(options) {
    return Urql.useQuery({ query: exports.TraineeProgramAdvancementDocument, ...options });
}
exports.useTraineeProgramAdvancementQuery = useTraineeProgramAdvancementQuery;
;
exports.SimulationUrlDocument = (0, graphql_tag_1.default) `
    query SimulationUrl($sessionId: ID!) {
  getSimulationUrl(sessionId: $sessionId)
}
    `;
function useSimulationUrlQuery(options) {
    return Urql.useQuery({ query: exports.SimulationUrlDocument, ...options });
}
exports.useSimulationUrlQuery = useSimulationUrlQuery;
;
exports.ExternalSessionMappingsDocument = (0, graphql_tag_1.default) `
    query ExternalSessionMappings {
  getExternalSessionMappings {
    ...ExternalSessionMappingFields
  }
}
    ${exports.ExternalSessionMappingFieldsFragmentDoc}`;
function useExternalSessionMappingsQuery(options) {
    return Urql.useQuery({ query: exports.ExternalSessionMappingsDocument, ...options });
}
exports.useExternalSessionMappingsQuery = useExternalSessionMappingsQuery;
;
exports.ExternalAssessmentStateDocument = (0, graphql_tag_1.default) `
    query ExternalAssessmentState($externalSimulationId: ID!) {
  getExternalAssessmentState(externalSimulationId: $externalSimulationId) {
    ...ExternalAssessmentStateFields
  }
}
    ${exports.ExternalAssessmentStateFieldsFragmentDoc}`;
function useExternalAssessmentStateQuery(options) {
    return Urql.useQuery({ query: exports.ExternalAssessmentStateDocument, ...options });
}
exports.useExternalAssessmentStateQuery = useExternalAssessmentStateQuery;
;
exports.CreateSessionsDocument = (0, graphql_tag_1.default) `
    mutation CreateSessions($sessionInput: CreateSessionInput!, $appointmentDates: SessionAppointmentDates) {
  createSessions(input: $sessionInput, appointmentDates: $appointmentDates) {
    ...SessionFields
  }
}
    ${exports.SessionFieldsFragmentDoc}`;
function useCreateSessionsMutation() {
    return Urql.useMutation(exports.CreateSessionsDocument);
}
exports.useCreateSessionsMutation = useCreateSessionsMutation;
;
exports.UpdateSessionDocument = (0, graphql_tag_1.default) `
    mutation UpdateSession($sessionId: ID!, $sessionInput: UpdateSessionInput!, $appointmentDates: SessionAppointmentDates) {
  updateSession(
    sessionId: $sessionId
    input: $sessionInput
    appointmentDates: $appointmentDates
  ) {
    ...SessionFields
  }
}
    ${exports.SessionFieldsFragmentDoc}`;
function useUpdateSessionMutation() {
    return Urql.useMutation(exports.UpdateSessionDocument);
}
exports.useUpdateSessionMutation = useUpdateSessionMutation;
;
exports.UpdateSessionStatusDocument = (0, graphql_tag_1.default) `
    mutation UpdateSessionStatus($sessionId: ID!, $status: SessionStatus!) {
  updateSessionStatus(sessionId: $sessionId, status: $status) {
    ...SessionFields
  }
}
    ${exports.SessionFieldsFragmentDoc}`;
function useUpdateSessionStatusMutation() {
    return Urql.useMutation(exports.UpdateSessionStatusDocument);
}
exports.useUpdateSessionStatusMutation = useUpdateSessionStatusMutation;
;
exports.DeleteSessionDocument = (0, graphql_tag_1.default) `
    mutation DeleteSession($sessionId: ID!) {
  deleteSession(sessionId: $sessionId)
}
    `;
function useDeleteSessionMutation() {
    return Urql.useMutation(exports.DeleteSessionDocument);
}
exports.useDeleteSessionMutation = useDeleteSessionMutation;
;
exports.SaveSessionCommentDocument = (0, graphql_tag_1.default) `
    mutation SaveSessionComment($sessionId: ID!, $noteValue: String) {
  saveSessionComment(sessionId: $sessionId, noteValue: $noteValue) {
    id
    value
  }
}
    `;
function useSaveSessionCommentMutation() {
    return Urql.useMutation(exports.SaveSessionCommentDocument);
}
exports.useSaveSessionCommentMutation = useSaveSessionCommentMutation;
;
exports.NotifyTraineeCrAvailabilityDocument = (0, graphql_tag_1.default) `
    mutation NotifyTraineeCrAvailability($sessionId: ID!) {
  notifyTraineeCrAvailability(sessionId: $sessionId)
}
    `;
function useNotifyTraineeCrAvailabilityMutation() {
    return Urql.useMutation(exports.NotifyTraineeCrAvailabilityDocument);
}
exports.useNotifyTraineeCrAvailabilityMutation = useNotifyTraineeCrAvailabilityMutation;
;
exports.CountUsersDocument = (0, graphql_tag_1.default) `
    query CountUsers($withDeleted: Boolean) {
  countUsers(withDeleted: $withDeleted)
}
    `;
function useCountUsersQuery(options) {
    return Urql.useQuery({ query: exports.CountUsersDocument, ...options });
}
exports.useCountUsersQuery = useCountUsersQuery;
;
exports.MeDocument = (0, graphql_tag_1.default) `
    query Me {
  me {
    ...MeUserFields
  }
  notifications(acquitted: false) {
    ...NotificationFields
  }
}
    ${exports.MeUserFieldsFragmentDoc}
${exports.NotificationFieldsFragmentDoc}`;
function useMeQuery(options) {
    return Urql.useQuery({ query: exports.MeDocument, ...options });
}
exports.useMeQuery = useMeQuery;
;
exports.UserListDocument = (0, graphql_tag_1.default) `
    query UserList($search: Search!) {
  users(search: $search) {
    pageInfo {
      ...PageInfoFields
    }
    data {
      ...UserDetailsFields
    }
  }
}
    ${exports.PageInfoFieldsFragmentDoc}
${exports.UserDetailsFieldsFragmentDoc}`;
function useUserListQuery(options) {
    return Urql.useQuery({ query: exports.UserListDocument, ...options });
}
exports.useUserListQuery = useUserListQuery;
;
exports.WithAdvancementUserListDocument = (0, graphql_tag_1.default) `
    query WithAdvancementUserList($search: Search!) {
  users(search: $search) {
    pageInfo {
      ...PageInfoFields
    }
    data {
      ...WithAdvancementUserFields
    }
  }
}
    ${exports.PageInfoFieldsFragmentDoc}
${exports.WithAdvancementUserFieldsFragmentDoc}`;
function useWithAdvancementUserListQuery(options) {
    return Urql.useQuery({ query: exports.WithAdvancementUserListDocument, ...options });
}
exports.useWithAdvancementUserListQuery = useWithAdvancementUserListQuery;
;
exports.WithAdvancementClientUserListDocument = (0, graphql_tag_1.default) `
    query WithAdvancementClientUserList($search: Search!, $companyId: ID!) {
  users(search: $search) {
    pageInfo {
      ...PageInfoFields
    }
    data {
      ...WithAdvancementUserFields
    }
  }
  company(id: $companyId) {
    individualDataAccessDeactivated
  }
}
    ${exports.PageInfoFieldsFragmentDoc}
${exports.WithAdvancementUserFieldsFragmentDoc}`;
function useWithAdvancementClientUserListQuery(options) {
    return Urql.useQuery({ query: exports.WithAdvancementClientUserListDocument, ...options });
}
exports.useWithAdvancementClientUserListQuery = useWithAdvancementClientUserListQuery;
;
exports.GetUserAdminDocument = (0, graphql_tag_1.default) `
    query GetUserAdmin($id: ID!) {
  user(id: $id) {
    ...AdminUserDetailsFields
  }
}
    ${exports.AdminUserDetailsFieldsFragmentDoc}`;
function useGetUserAdminQuery(options) {
    return Urql.useQuery({ query: exports.GetUserAdminDocument, ...options });
}
exports.useGetUserAdminQuery = useGetUserAdminQuery;
;
exports.ListTrainersDocument = (0, graphql_tag_1.default) `
    query ListTrainers {
  listTrainers {
    ...UserDetailsFields
  }
}
    ${exports.UserDetailsFieldsFragmentDoc}`;
function useListTrainersQuery(options) {
    return Urql.useQuery({ query: exports.ListTrainersDocument, ...options });
}
exports.useListTrainersQuery = useListTrainersQuery;
;
exports.GetUserDocument = (0, graphql_tag_1.default) `
    query GetUser($id: ID!) {
  user(id: $id) {
    ...UserDetailsFields
  }
}
    ${exports.UserDetailsFieldsFragmentDoc}`;
function useGetUserQuery(options) {
    return Urql.useQuery({ query: exports.GetUserDocument, ...options });
}
exports.useGetUserQuery = useGetUserQuery;
;
exports.CreateUserDocument = (0, graphql_tag_1.default) `
    mutation CreateUser($input: UserInput!) {
  createUser(input: $input) {
    ...UserDetailsFields
  }
}
    ${exports.UserDetailsFieldsFragmentDoc}`;
function useCreateUserMutation() {
    return Urql.useMutation(exports.CreateUserDocument);
}
exports.useCreateUserMutation = useCreateUserMutation;
;
exports.UpdateUserDocument = (0, graphql_tag_1.default) `
    mutation UpdateUser($input: UpdateUserInputUser!) {
  updateUser(input: $input) {
    ...UserDetailsFields
  }
}
    ${exports.UserDetailsFieldsFragmentDoc}`;
function useUpdateUserMutation() {
    return Urql.useMutation(exports.UpdateUserDocument);
}
exports.useUpdateUserMutation = useUpdateUserMutation;
;
exports.UpdateUserAdminDocument = (0, graphql_tag_1.default) `
    mutation UpdateUserAdmin($input: UpdateUserInputAdmin!) {
  updateUserAdmin(input: $input) {
    ...UserDetailsFields
  }
}
    ${exports.UserDetailsFieldsFragmentDoc}`;
function useUpdateUserAdminMutation() {
    return Urql.useMutation(exports.UpdateUserAdminDocument);
}
exports.useUpdateUserAdminMutation = useUpdateUserAdminMutation;
;
exports.SendChangePasswordEmailDocument = (0, graphql_tag_1.default) `
    mutation SendChangePasswordEmail($userEmail: String!) {
  sendChangePasswordEmail(userEmail: $userEmail)
}
    `;
function useSendChangePasswordEmailMutation() {
    return Urql.useMutation(exports.SendChangePasswordEmailDocument);
}
exports.useSendChangePasswordEmailMutation = useSendChangePasswordEmailMutation;
;
exports.UploadUserAvatarDocument = (0, graphql_tag_1.default) `
    mutation UploadUserAvatar($file: Upload!, $cropData: CropData!) {
  uploadUserAvatar(upload: $file, cropData: $cropData)
}
    `;
function useUploadUserAvatarMutation() {
    return Urql.useMutation(exports.UploadUserAvatarDocument);
}
exports.useUploadUserAvatarMutation = useUploadUserAvatarMutation;
;
exports.UploadUserResumeDocument = (0, graphql_tag_1.default) `
    mutation UploadUserResume($file: Upload) {
  uploadUserResume(upload: $file)
}
    `;
function useUploadUserResumeMutation() {
    return Urql.useMutation(exports.UploadUserResumeDocument);
}
exports.useUploadUserResumeMutation = useUploadUserResumeMutation;
;
exports.DeleteUserAdminDocument = (0, graphql_tag_1.default) `
    mutation DeleteUserAdmin($id: ID!) {
  deleteUserAdmin(id: $id)
}
    `;
function useDeleteUserAdminMutation() {
    return Urql.useMutation(exports.DeleteUserAdminDocument);
}
exports.useDeleteUserAdminMutation = useDeleteUserAdminMutation;
;
exports.UndeleteUserAdminDocument = (0, graphql_tag_1.default) `
    mutation UndeleteUserAdmin($id: ID!) {
  undeleteUserAdmin(id: $id)
}
    `;
function useUndeleteUserAdminMutation() {
    return Urql.useMutation(exports.UndeleteUserAdminDocument);
}
exports.useUndeleteUserAdminMutation = useUndeleteUserAdminMutation;
;
exports.RmUserAvatarDocument = (0, graphql_tag_1.default) `
    mutation RmUserAvatar {
  rmUserAvatar {
    id
  }
}
    `;
function useRmUserAvatarMutation() {
    return Urql.useMutation(exports.RmUserAvatarDocument);
}
exports.useRmUserAvatarMutation = useRmUserAvatarMutation;
;
exports.RevokeTokenDocument = (0, graphql_tag_1.default) `
    mutation RevokeToken($tokenId: ID!) {
  revokeToken(tokenId: $tokenId)
}
    `;
function useRevokeTokenMutation() {
    return Urql.useMutation(exports.RevokeTokenDocument);
}
exports.useRevokeTokenMutation = useRevokeTokenMutation;
;
exports.SaveTraineeNoteDocument = (0, graphql_tag_1.default) `
    mutation SaveTraineeNote($traineeId: ID!, $noteValue: String) {
  saveTraineeNote(traineeId: $traineeId, noteValue: $noteValue) {
    id
    value
  }
}
    `;
function useSaveTraineeNoteMutation() {
    return Urql.useMutation(exports.SaveTraineeNoteDocument);
}
exports.useSaveTraineeNoteMutation = useSaveTraineeNoteMutation;
;
exports.GenerateUserOtpSecretDocument = (0, graphql_tag_1.default) `
    mutation GenerateUserOtpSecret {
  generateUserOtpSecret {
    ...OtpEnrollmentFields
  }
}
    ${exports.OtpEnrollmentFieldsFragmentDoc}`;
function useGenerateUserOtpSecretMutation() {
    return Urql.useMutation(exports.GenerateUserOtpSecretDocument);
}
exports.useGenerateUserOtpSecretMutation = useGenerateUserOtpSecretMutation;
;
exports.ActivateOtpDocument = (0, graphql_tag_1.default) `
    mutation ActivateOtp($otpSecret: String!) {
  activateOtp(otpSecret: $otpSecret)
}
    `;
function useActivateOtpMutation() {
    return Urql.useMutation(exports.ActivateOtpDocument);
}
exports.useActivateOtpMutation = useActivateOtpMutation;
;
exports.DeactivateOtpDocument = (0, graphql_tag_1.default) `
    mutation DeactivateOtp {
  deactivateOtp
}
    `;
function useDeactivateOtpMutation() {
    return Urql.useMutation(exports.DeactivateOtpDocument);
}
exports.useDeactivateOtpMutation = useDeactivateOtpMutation;
;
