import classnames from "clsx";
import { IconButton } from "@components/Button/IconButton.component";
import { ArrowLeftSolid } from "@graywolfai/react-heroicons";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Title } from "../Title";

interface PageHeaderProps {
  title: string | ReactNode;
  subtitle?: string;
  backButton?: boolean | string;
}

export function PageHeader({ title, subtitle, backButton }: PageHeaderProps) {
  const history = useHistory();
  const { t } = useTranslation();
  const isStringTitle = typeof title === "string";

  const containerClasses = classnames(
    "flex items-center 2xl:w-11/12 w-full mx-auto px-4 sm:px-6 lg:px-8 space-x-4",
    {
      "py-6": isStringTitle,
    }
  );

  function handleGoBack() {
    if (typeof backButton === "string") {
      history.push(backButton);
    }
    if (typeof backButton === "boolean") {
      history.goBack();
    }
  }
  return (
    <header className="bg-white shadow">
      <div className={containerClasses}>
        {backButton && (
          <IconButton
            icon={ArrowLeftSolid}
            size="medium"
            onClick={handleGoBack}
            title={t("common.button.back.label")}
            variant="transparent"
          />
        )}
        {isStringTitle && (
          <>
            <Title size={2}>{title}</Title>
            {subtitle && (
              <span className="ml-3">
                <Title size={3}> - {subtitle}</Title>
              </span>
            )}
          </>
        )}
        {!isStringTitle && title}
      </div>
    </header>
  );
}
