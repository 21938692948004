"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dayIntervalToDateInterval = exports.getWorkingDay = exports.SESSION_DURATION_TO_DECIMAL_HOUR = exports.DAY_NUMBER_TO_WORKINGDAY = exports.WORKINGDAY_TO_NUMBER = void 0;
const date_fns_1 = require("date-fns");
exports.WORKINGDAY_TO_NUMBER = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
};
exports.DAY_NUMBER_TO_WORKINGDAY = {
    1: "monday",
    2: "tuesday",
    3: "wednesday",
    4: "thursday",
    5: "friday",
    6: "saturday",
};
exports.SESSION_DURATION_TO_DECIMAL_HOUR = {
    TWENTY: 0.33,
    THIRTY: 0.5,
    FORTYFIVE: 0.75,
    NINETY: 1.5,
};
function getWorkingDay(date) {
    const dayNumber = (0, date_fns_1.getDay)(date);
    if (dayNumber === 0) {
        throw new Error("Sunday is not a working day");
    }
    return exports.DAY_NUMBER_TO_WORKINGDAY[dayNumber];
}
exports.getWorkingDay = getWorkingDay;
function dayIntervalToDateInterval({ startHour, duration, }) {
    const startMinutes = (startHour > 1 ? startHour % Math.floor(startHour) : startHour) * 60;
    const startDate = (0, date_fns_1.set)(new Date(), {
        hours: startHour,
        minutes: startMinutes,
    });
    const durationHours = duration > 1 ? Math.floor(duration) : 0;
    const durationMinutes = (duration > 1 ? duration % Math.floor(duration) : duration) * 60;
    const endDate = (0, date_fns_1.add)(startDate, {
        hours: durationHours,
        minutes: durationMinutes,
    });
    return {
        start: startDate,
        end: endDate,
    };
}
exports.dayIntervalToDateInterval = dayIntervalToDateInterval;
