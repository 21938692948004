export function getHashColor(str: string): string {
  let hash = 0;
  if (str.length === 0) return "rgba(50, 74, 165,1)";
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash; // Convert to 32bit integer
  }
  const shortened = hash % 360;
  return `hsl(${shortened}, 100%, 30%)`;
}
