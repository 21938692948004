import { DisplayDateTime } from "@components/DateTime";
import { FieldContainer } from "@components/form/FieldContainer";
import { LabelPosition } from "@components/form/FieldContainer/FieldContainer.component";
import { IconComponent } from "@components/Icon/Icon.model";
import { formatToLocalDate } from "@justplayfair/model";
import { useField, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { DatePicker } from "../DatePicker.component";

interface FormikDatePickerProps {
  editMode: boolean;
  id: string;
  label?: string;
  labelPosition?: LabelPosition;
  withSeparator?: boolean;
  icon?: IconComponent;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeIntervals?: number;
  timeCaption?: string;
  dateFormat?: string;
  timeFormat?: string;
  minTime?: Date;
  maxTime?: Date;
  isClearable?: boolean;
}
export function FormikDatePicker({
  editMode,
  id,
  label,
  labelPosition = "left",
  withSeparator,
  icon,
  showTimeSelect,
  showTimeSelectOnly,
  timeIntervals,
  timeCaption,
  dateFormat = "dd/MM/yyyy",
  timeFormat,
  minTime,
  maxTime,
  isClearable,
}: FormikDatePickerProps) {
  const { t } = useTranslation();

  const { submitCount } = useFormikContext();
  const [field, { error, touched }, { setValue, setTouched }] = useField(id);

  return (
    <FieldContainer
      id={id}
      label={label}
      labelPosition={labelPosition}
      withSeparator={withSeparator}
    >
      {editMode ? (
        <div className="w-full">
          <DatePicker
            id={id}
            icon={icon}
            isError={touched && !!error}
            {...field}
            onChange={(value) => {
              setValue(value);
              setTouched(true);
            }}
            showTimeSelect={showTimeSelect}
            showTimeSelectOnly={showTimeSelectOnly}
            timeIntervals={timeIntervals}
            timeCaption={timeCaption}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            minTime={minTime}
            maxTime={maxTime}
            isClearable={isClearable}
          />

          <div className="h-4 mt-0 text-xs text-red-400">
            {(touched || submitCount > 0) && error && t(error)}
          </div>
        </div>
      ) : (
        <DisplayReadValue
          value={field.value}
          showTimeSelect={!!showTimeSelectOnly}
        />
      )}
    </FieldContainer>
  );
}

interface DisplayReadValueProps {
  value?: string | Date;
  showTimeSelect: boolean;
}
function DisplayReadValue({ value, showTimeSelect }: DisplayReadValueProps) {
  const { t } = useTranslation();

  const classes = "mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2";
  if (!value) {
    return (
      <span className={classes}>
        {t("component.formikDatePicker.text.noValue")}
      </span>
    );
  }
  return (
    <span className={classes}>
      {showTimeSelect && <DisplayDateTime date={new Date(value)} />}
      {!showTimeSelect && formatToLocalDate(new Date(value))}
    </span>
  );
}
