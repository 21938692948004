import { BarChart } from "@components/Dataviz/chart/BarChart.component";
import { Loader } from "@components/Loader";
import { DatavizMapper } from "@justplayfair/model";
import { useTranslation } from "react-i18next";

interface ComparisonBarProps {
  comparisonData:
    | ReturnType<typeof DatavizMapper.byKeyBarDataComparisonMapper>[]
    | undefined;
  fetching: boolean;
  height: number;
}

export function ComparisonBarChart({
  comparisonData,
  fetching,
  height,
}: ComparisonBarProps) {
  const { t } = useTranslation();

  if (fetching) {
    return <Loader size="medium" />;
  }

  if (
    !comparisonData ||
    !comparisonData.some((detail) => detail.data.length > 0)
  ) {
    return <div className="text-gray-500">{t("common.text.noData")}</div>;
  }
  return (
    <>
      {comparisonData.map((comparison, index) => (
        <div key={index} style={{ height }}>
          <BarChart
            containerHeight={700}
            layout="vertical"
            xLegend=""
            data={comparison.data}
            keys={comparison.keys}
            maxRate={comparison.maxRate}
            indexBy="key"
            colorTheme="default"
          />
        </div>
      ))}
    </>
  );
}
