"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateExportSessionBody = exports.AuthBodyValidationSchema = void 0;
const Yup = require("yup");
exports.AuthBodyValidationSchema = Yup.object()
    .shape({
    start: Yup.date().required("Start date mandatory"),
    end: Yup.date().required("End date mandatory"),
})
    .required();
function validateExportSessionBody(body) {
    const exportSessionBody = exports.AuthBodyValidationSchema.validateSync(body, {
        abortEarly: false,
    });
    return exportSessionBody;
}
exports.validateExportSessionBody = validateExportSessionBody;
