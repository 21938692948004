import { SessionRest } from "@justplayfair/model";
import { saveAs } from "file-saver";
import ky from "ky";
import { alertService } from "../Alert/AlertService";
import { authService } from "../auth";
import { fileService } from "../file/file.service";

const SESSION_URL = process.env.REACT_APP_SESSION_URL;
if (!SESSION_URL) {
  throw new Error("Mandatory env variable 'REACT_APP_SESSION_URL'");
}
class ExportSessionService {
  async export(exportFields: SessionRest.ExportSessionBody) {
    try {
      const response = await ky.post(`${SESSION_URL}/export`, {
        mode: "cors",
        headers: {
          authorization: `Bearer ${authService.token}`,
        },
        json: exportFields,
      });
      const filename = fileService.getFilename(response.headers);

      const blob = await response.blob();
      saveAs(blob, filename ?? "sessions.csv");
    } catch (error) {
      console.error(error);
      alertService.addDefaultAlert();
    }
  }
}

export type ExportSessionServiceType = ExportSessionService;
export const exportSessionService = new ExportSessionService();
