import { Toggle } from "@components/form/Toggle/Toggle.component";
import { Loader } from "@components/Loader";
import { Pagination } from "@components/Table/Pagination.component";
import {
  Table,
  TableCell,
  TableHeading,
} from "@components/Table/Table.component";
import { LockClosedSolid } from "@graywolfai/react-heroicons";
import { Client, ID } from "@justplayfair/model";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PAGE_SIZE = 10;
export interface LabelListProps {
  companyId: ID;
}

export function LabelList({ companyId }: LabelListProps) {
  const { t } = useTranslation();

  const [filterDeleted, setFilterDeleted] = useState(false);
  const [page, setPage] = useState(1);
  const skip = useMemo(() => (page - 1) * PAGE_SIZE, [page]);

  const [{ data, fetching }] = Client.useSearchLabelsQuery({
    variables: {
      search: {
        skip,
        take: PAGE_SIZE,
        filters: {
          deleted: filterDeleted,
          query: new URLSearchParams({ companyId }).toString(),
        },
      },
    },
  });

  function handlePreviousPage() {
    setPage(page - 1);
  }
  function handleNextPage() {
    setPage(page + 1);
  }

  return (
    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <Toggle
        id="filterDeleted"
        checked={filterDeleted}
        onChange={(value) => setFilterDeleted(value)}
        label={t("common.toggle.displayDeleted")}
      />
      <Table
        heading={
          <>
            <TableHeading align="left" width="25%">
              {t("admin.label.heading.category")}
            </TableHeading>
            <TableHeading align="left">
              {t("admin.label.heading.name")}
            </TableHeading>

            <TableHeading align="right">
              <span className="sr-only">Edit</span>
            </TableHeading>
          </>
        }
      >
        {!fetching &&
          data?.searchLabels.data.map((label) => (
            <tr key={label.id}>
              <TableCell align="left">
                {t(`common.label.${label.category}`)}
              </TableCell>
              <TableCell align="left">
                <div
                  title={label.protected ? "Protégé" : ""}
                  className="flex space-x-1"
                >
                  {label.protected && (
                    <LockClosedSolid className="w-4 h-4 text-gray-400" />
                  )}
                  <span>{label.name}</span>
                </div>
              </TableCell>
              <TableCell align="right">
                {!label.protected && (
                  <Link
                    className="text-indigo-600 hover:text-indigo-900"
                    to={`/admin/companies/${companyId}/label/${label.id}`}
                  >
                    {t("common.button.edit.label")}
                  </Link>
                )}
              </TableCell>
            </tr>
          ))}
      </Table>
      {fetching && <Loader size="large" />}
      <Pagination
        pageInfo={data?.searchLabels.pageInfo ?? null}
        onPrevious={handlePreviousPage}
        onNext={handleNextPage}
      />
    </div>
  );
}
