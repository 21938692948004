import { Button } from "@components/Button";
import { DisplayDateTime } from "@components/DateTime";
import {
  Table,
  TableCell,
  TableHeading,
} from "@components/Table/Table.component";
import { Title } from "@components/Title";
import { User } from "@justplayfair/model";

interface AuthTokenListProps {
  tokens: User.AuthToken[];
  onRevokeToken: (tokenId: string) => void;
}
export function AuthTokenList({ tokens, onRevokeToken }: AuthTokenListProps) {
  return (
    <div>
      <Title size={3}>Token d'authentification</Title>
      <Table
        heading={
          <>
            <TableHeading align="left">Id</TableHeading>
            <TableHeading align="left">Expiration</TableHeading>
            <TableHeading align="right">
              <span className="sr-only">Edit</span>
            </TableHeading>
          </>
        }
      >
        {tokens.map((token) => (
          <tr key={token.id}>
            <TableCell align="left">{token.id}</TableCell>
            <TableCell align="left">
              <DisplayDateTime date={new Date(token.expiration)} />
            </TableCell>
            <TableCell align="right">
              <Button
                title="Revoke token "
                variant="secondary"
                onClick={() => onRevokeToken(token.id)}
              >
                Revoquer
              </Button>
            </TableCell>
          </tr>
        ))}
      </Table>
    </div>
  );
}
