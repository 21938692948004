import { Button } from "@components/Button";
import { PageContainer } from "@components/Layout";
import { Loader } from "@components/Loader";
import { Title } from "@components/Title";
import { useAuthenticatedUser } from "@hooks/authenticatedUser/useAuthenticatedUser.hook";
import { Client, formatToLocalDate, ID } from "@justplayfair/model";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export function NotificationPage() {
  const { t } = useTranslation();
  const { notifications } = useAuthenticatedUser();
  const [{ data: acquittedNotifData, fetching: fetchAcquittedNotifs }] =
    Client.useListMyNotificationsQuery({
      variables: { acquitted: true },
    });

  return (
    <PageContainer title={t("notifications.title")}>
      <div className="space-y-8">
        <div className="space-y-4">
          <Title size={5}>{t("notifications.text.newNotif")}</Title>
          {!notifications ||
            (notifications.length === 0 && (
              <Title size={6}>{t("common.text.noData")}</Title>
            ))}
          {notifications && (
            <ul className="bg-white divide-y divide-gray-200 max-w-4xl">
              {notifications?.map((notif) => (
                <NotificationItem key={notif.id} notification={notif} />
              ))}
            </ul>
          )}
        </div>
        <div className="space-y-4">
          <Title size={5}>{t("notifications.text.acquittedNotif")}</Title>

          {fetchAcquittedNotifs && <Loader size="small" />}

          {acquittedNotifData?.notifications && (
            <ul className="bg-white divide-y divide-gray-200 max-w-4xl">
              {acquittedNotifData.notifications.map((notif) => (
                <NotificationItem
                  key={notif.id}
                  notification={notif}
                  withAction={false}
                />
              ))}
            </ul>
          )}
        </div>
      </div>
    </PageContainer>
  );
}

interface NotificationItemProps {
  notification: Client.NotificationFieldsFragment;
  withAction?: boolean;
}
function NotificationItem({
  notification,
  withAction = true,
}: NotificationItemProps) {
  return (
    <li>
      <div className="flex items-center px-4 py-4 sm:px-6">
        <div className="min-w-0 flex-1 flex items-center">
          <div className="flex-shrink-0 text-lg font-bold">
            {notification.title}
          </div>
          <div className="flex min-w-0 flex-1 px-4 justify-between">
            <div className="flex items-center space-x-2">
              <p className="text-xs text-gray-500">
                {formatToLocalDate(new Date(notification.createdAt))}
              </p>
              <p className="text-sm font-medium text-gray-600 truncate">
                {notification.message}
              </p>
            </div>

            {withAction && notification.courseOfAction?.action && (
              <div className="flex flex-1 items-end justify-end">
                {COURSE_OF_ACTIONS[notification.courseOfAction.action](
                  notification.id
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
}

const COURSE_OF_ACTIONS: {
  [action in Client.CourseOfActionAction]: (notifId: ID) => ReactNode;
} = {
  CONTACT: () => <div></div>,
  DATA_ENTRY: () => <div></div>,
  NEXT_SESSION_EMAIL: () => null,
  REQUEST_PERMISSION_EMAIL: () => null,
  USER_REQUEST_PERMISSION: (notifId) => (
    <UserRequestPermissionCourseOfAction notifId={notifId} />
  ),
  SCHEDULE: () => <div></div>,
};

interface UserRequestPermissionCourseOfActionProps {
  notifId: ID;
}
function UserRequestPermissionCourseOfAction({
  notifId,
}: UserRequestPermissionCourseOfActionProps) {
  const { t } = useTranslation();
  const history = useHistory();

  const { reexecuteQuery } = useAuthenticatedUser();

  const [{ fetching: fetchAck }, ackNotif] =
    Client.useAckNotificationMutation();

  async function handleClick() {
    await ackNotif({ notifId });
    reexecuteQuery();
    history.push("/authorizations");
  }
  return (
    <div className="w-42">
      <Button
        title={t("notifications.button.consult")}
        onClick={handleClick}
        loading={fetchAck}
      >
        {t("notifications.button.examinate")}
      </Button>
    </div>
  );
}
