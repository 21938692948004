import { SelectOption } from "@components/form/Select/Select.model";

export const FILE_TYPE_OPTIONS: SelectOption<string>[] = [
  { value: "IMAGE" },
  { value: "VIDEO" },
  { value: "PDF" },
];

export const FILE_LABEL_OPTIONS: SelectOption<string>[] = [
  { value: "RESUME" },
  { value: "EDUCATIONAL_RESOURCE" },
  { value: "CLIENT_USER_GUIDE" },
  { value: "TRAINEE_USER_GUIDE" },
];

export function formatBytesToHumanReadable(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 octet";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["octects", "Ko", "Mo", "Go", "To", "Po", "Eo", "Zo", "Yo"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function byNameMediaNaturalySort(
  media1: { name: string },
  media2: { name: string }
) {
  return media1.name.localeCompare(media2.name, undefined, {
    numeric: true,
    sensitivity: "base",
  });
}
