import { NamedAvatar } from "@components/Avatar/NamedAvatar.component";
import { Button } from "@components/Button";
import { FormikCheckbox } from "@components/form/Checkbox/FormikCheckbox";
import { FormikInput } from "@components/form/Input/FormikInput";
import { FormikSelect } from "@components/form/Select/FormikSelect";
import { Client, ID } from "@justplayfair/model";
import { ExternalSimulationInput } from "@justplayfair/model/dist/generated/client";
import { Form, Formik } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  SESSION_DURATION_OPTIONS,
  SESSION_TYPE_OPTIONS,
} from "../../../../model/Session.model";
import { AppointmentFormFields } from "./AppointmentFormFields";
import { FormikExternalSessionsField } from "./components/FormikExternalSessionsField.component";
import { FormikProgramTraineesSelectField } from "./components/FormikProgramTraineesSelectField.component";
import {
  createAppointmentDates,
  ExternalSimulationSchema,
} from "./SessionDetails.model";

const CreateSessionSchema: Yup.SchemaOf<Client.CreateSessionInput> =
  Yup.object().shape({
    name: Yup.string().required("admin.session.error.nameMandatory"),
    type: Yup.mixed()
      .oneOf(["ONLINE_ASSESSMENT", "WITH_TRAINER"])
      .required("admin.session.error.typeMandatory"),
    duration: Yup.mixed()
      .oneOf(["TWENTY", "THIRTY", "FORTYFIVE", "NINETY"])
      .required("admin.session.error.durationMandatory"),
    programId: Yup.string().required("admin.session.error.programMandatory"),
    externalSimulations: Yup.mixed().when("type", {
      is: "WITH_TRAINER",
      then: Yup.array().optional(),
      otherwise: Yup.array()
        .of(ExternalSimulationSchema.required())
        .required("admin.session.error.externalSimulationsMandatory"),
    }),
    trainerId: Yup.string().required("admin.session.error.trainerMandatory"),
    companyId: Yup.string().required("admin.session.error.companyMandatory"),
    traineeIds: Yup.array().of(Yup.mixed().defined()),
  });

interface CreateSessionFormProps {
  trainers: Client.UserDetailsFieldsFragment[];
  companies: Client.CompanyFieldsFragment[];
  onCreateSession: (
    input: Client.CreateSessionInput,
    appointmenDates?: Client.SessionAppointmentDates
  ) => Promise<void>;
}

export function CreateSessionForm({
  companies,
  trainers,
  onCreateSession,
}: CreateSessionFormProps) {
  const { t } = useTranslation();

  const companyOptions = useMemo(
    () => companies.map(({ id }) => ({ value: id })),
    [companies]
  );

  const trainersOpts = useMemo(
    () =>
      trainers.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [trainers]
  );

  return (
    <Formik
      initialValues={{
        type: "WITH_TRAINER" as Client.SessionType,
        externalSimulations: undefined as unknown as ExternalSimulationInput[],
        companyId: "",
        duration: "THIRTY" as Client.SessionDuration,
        name: "",
        programId: "",
        trainerId: "",
        allFromGroup: true,
        traineeIds: [] as ID[],
        startDate: undefined as Date | undefined,
        recurrence: "ONCE" as Client.SessionRecurrenceRepeat,
        recurrenceNb: 1,
      }}
      onSubmit={async ({
        companyId,
        startDate,
        recurrence,
        recurrenceNb,
        allFromGroup,
        traineeIds,
        ...fields
      }) => {
        let appointmentDates: Client.SessionAppointmentDates | undefined =
          undefined;
        if (startDate) {
          appointmentDates = createAppointmentDates(
            startDate,
            fields.duration,
            { repeat: recurrence, nb: recurrenceNb }
          );
        }
        await onCreateSession(
          allFromGroup ? fields : { ...fields, traineeIds },
          appointmentDates
        );
      }}
      validationSchema={CreateSessionSchema}
    >
      {(props) => {
        const companyPrograms = companies?.find(
          (company) => props.values.companyId === company.id
        )?.programs;

        const companyGroupOpts =
          companyPrograms?.map((program) => ({
            value: program.id,
            label: program.name,
          })) || [];

        return (
          <Form className="space-y-2">
            <div className="flex space-x-2">
              <div className="w-3/4 bg-white shadow p-2">
                <FormikInput
                  editMode={true}
                  id="name"
                  label={t("common.session.name")}
                />
                <FormikSelect
                  editMode={true}
                  id="type"
                  multi={false}
                  label={t("common.session.type.label")}
                  options={SESSION_TYPE_OPTIONS}
                  renderValue={(value) =>
                    t(`common.session.type.value.${value}`)
                  }
                />

                {props.values.type !== "WITH_TRAINER" && (
                  <FormikExternalSessionsField id="externalSimulations" edit />
                )}

                <FormikSelect
                  editMode={true}
                  id="duration"
                  multi={false}
                  label={t("common.session.duration.label")}
                  options={SESSION_DURATION_OPTIONS}
                  renderValue={(value) =>
                    t(`common.session.duration.value.${value}`)
                  }
                />
                <FormikSelect
                  editMode={true}
                  id="trainerId"
                  multi={false}
                  label={t("common.session.trainer")}
                  options={trainersOpts}
                />
                <FormikSelect
                  editMode={true}
                  id="companyId"
                  label={t("common.session.company")}
                  options={companyOptions}
                  multi={false}
                  renderValue={(value) => {
                    const companyVal = companies?.find(
                      (company) => company.id === value
                    );
                    if (!companyVal) {
                      return;
                    }
                    return <NamedAvatar size="mini" entity={companyVal} />;
                  }}
                />
                <FormikSelect
                  editMode={!!props.values.companyId}
                  id="programId"
                  multi={false}
                  label={t("common.session.program")}
                  options={companyGroupOpts}
                />

                <FormikCheckbox
                  editMode={!!props.values.programId}
                  id="allFromGroup"
                  label={t(`admin.session.field.allFromGroup.label`)}
                />
                {!props.values.allFromGroup && (
                  <FormikProgramTraineesSelectField
                    editMode={!!props.values.programId}
                    fieldName="traineeIds"
                    label={t("common.session.trainee")}
                  />
                )}
              </div>
              <div className="w-1/4 bg-white shadow p-2">
                <AppointmentFormFields
                  values={{
                    recurrence: props.values.recurrence,
                    startDate: props.values.startDate,
                  }}
                />
              </div>
            </div>
            <div className="flex justify-end">
              <div className="w-56">
                <Button title={t("common.button.create.label")} type="submit">
                  {t("common.button.create.label")}
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
