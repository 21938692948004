import { ReportRest } from "@justplayfair/model";
import { alertService } from "@services/Alert/AlertService";
import { fileService } from "@services/file/file.service";
import { saveAs } from "file-saver";
import ky from "ky";
import { authService } from "../auth";

const REPORT_URL = process.env.REACT_APP_REPORT_URL;
if (!REPORT_URL) {
  throw new Error("Mandatory env variable 'REACT_APP_REPORT_URL'");
}

const HTTP_REPORT_API_TIMEOUT_MS = 45_000;

class ReportService {
  async downloadTraineeReport(
    traineeReportQuery: ReportRest.TraineeReportQuery
  ) {
    try {
      const response = await ky.post(`${REPORT_URL}/v2/trainee`, {
        mode: "cors",
        timeout: HTTP_REPORT_API_TIMEOUT_MS,
        headers: {
          authorization: `Bearer ${authService.token}`,
        },
        json: traineeReportQuery,
      });

      const filename = fileService.getFilename(response.headers);
      const blob = await response.blob();
      saveAs(blob, filename);
    } catch (error) {
      alertService.addDefaultAlert();
    }
  }

  async downloadProgramReport(programReportQuery: ReportRest.GroupReportQuery) {
    try {
      const response = await ky.post(`${REPORT_URL}/program`, {
        mode: "cors",
        timeout: HTTP_REPORT_API_TIMEOUT_MS,
        headers: {
          authorization: `Bearer ${authService.token}`,
        },
        json: programReportQuery,
      });

      const filename = fileService.getFilename(response.headers);
      const blob = await response.blob();
      saveAs(blob, filename);
    } catch (error) {
      alertService.addDefaultAlert();
    }
  }
}

export const reportService = new ReportService();
