import { User } from "@justplayfair/model";
import { authService } from "@services/auth";
import type * as H from "history";
import { TFunction } from "react-i18next";
import { match } from "react-router-dom";
import { MenuItemProps } from "../Dropdown";

interface NavbarLink {
  to: string;
  labelKey: string;
  mandatoryRole?: User.Role[];
  isActive?: (match: match | null, location: H.Location) => boolean;
  external?: boolean;
}

export const NAVBAR_LINKS: NavbarLink[] = [
  {
    to: "/dashboard",
    mandatoryRole: ["TRAINER", "MANAGER", "TRAINEE"],
    labelKey: "menu.dashboard",
    isActive: (match, location) => {
      return !!location.pathname.includes("/dashboard");
    },
  },
  {
    to: "/educational-resources",
    mandatoryRole: ["TRAINEE"],
    labelKey: "menu.educational-resources",
    isActive: (match, location) => {
      return !!location.pathname.includes("/educational-resources");
    },
  },
  {
    to: "/admin",
    mandatoryRole: ["ADMIN"],
    isActive: (match) => {
      return !!match?.url.startsWith("/admin");
    },
    labelKey: "menu.admin",
  },
  {
    to: "https://justplayfair.com/contact",
    mandatoryRole: ["MANAGER", "TRAINEE"],
    labelKey: "menu.contact",
    external: true,
  },
];

export function getUserMenuItems(t: TFunction): MenuItemProps[] {
  return [
    { label: t("menu.profile"), link: { to: "/profile" } },
    {
      label: t("menu.myAvailabilities"),
      link: { to: "/trainer/availabilities" },
      mandatoryRole: ["TRAINER"],
    },
    {
      label: t("menu.authorisations"),
      link: { to: "/authorizations" },
      mandatoryRole: ["TRAINEE"],
    },
    {
      label: t("menu.signout"),
      onClick: () => {
        authService.logout();
      },
    },
  ];
}
