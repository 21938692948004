import { Checkbox } from "@components/form/Checkbox";
import { Client, ID } from "@justplayfair/model";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SessionSelector } from "../SessionSelector/SessionSelector.component";
import { ChartFilters, FiltersValue } from "./ChartFilters.component";
import { ActivatedFields } from "./ChartFilters.model";

interface TraineeChartFiltersProps {
  forCompanyId: ID;
  traineeArgs: Client.TraineeArgs;
  nbMembersInGroup?: number;
  activatedFields: ActivatedFields;
  setGroupFilters: (values: FiltersValue) => void;
  setFilterSessionId?: (sessionId: ID) => void;
}
export function TraineeChartFilters({
  forCompanyId,
  traineeArgs,
  nbMembersInGroup,
  setGroupFilters,
  setFilterSessionId,
  activatedFields,
}: TraineeChartFiltersProps) {
  const { t } = useTranslation();

  const [compareGroups, setCompareGroups] = useState(false);

  useEffect(() => {
    if (!compareGroups) {
      setGroupFilters({});
    } else {
      setGroupFilters({ compareToCompany: true });
    }
  }, [compareGroups, setGroupFilters]);

  return (
    <div className="space-y-4">
      {setFilterSessionId && (
        <div className="bg-gray-50 rounded-md p-4">
          <SessionSelector
            traineeArgs={traineeArgs}
            onFiltersChange={(sessionId) => {
              setFilterSessionId(sessionId);
            }}
          />
        </div>
      )}

      <div className="flex space-x-4 items-start pl-2">
        <div className="pt-1">
          <Checkbox
            id="compareGroups"
            checked={compareGroups}
            onChange={(event) => setCompareGroups(event.target.checked)}
          />
        </div>
        <label htmlFor="compareGroups" className="text-sm">
          <div className="text-gray-900">
            {t("consult.filter.compareTo")} {t("consult.filter.a")}{" "}
            <b>groupe</b>
          </div>
          <div className="text-gray-500">
            {t("consult.filter.selectMembers")}
          </div>
        </label>
      </div>
      {compareGroups && (
        <div className="bg-gray-50 rounded-md p-4">
          <span className="text-gray-600">
            {nbMembersInGroup !== undefined
              ? `(${nbMembersInGroup} ${t("consult.text.persons")})`
              : undefined}
          </span>

          <ChartFilters
            forCompanyId={forCompanyId}
            forProgramId={traineeArgs.programId}
            forTraineeId={traineeArgs.traineeId}
            activatedFields={activatedFields}
            onFiltersChange={(filters) => {
              setGroupFilters(filters);
            }}
          />
        </div>
      )}
    </div>
  );
}
