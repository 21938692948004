import { Evaluation } from "@justplayfair/model";

export type StepStatus =
  | "creation"
  | "completed"
  | "current"
  | "planned"
  | "todo";

export const SESSION_STATUS_TO_STEP_STATUS: {
  [sessionStatus in Evaluation.SessionStatus]: StepStatus;
} = {
  CREATED: "todo",
  PLANNED: "planned",
  IN_PROGRESS: "todo",
  CANCELED: "completed",
  DONE: "completed",
  EVALUATED: "completed",
};
