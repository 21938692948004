import { ApiErrorCode, ArrayUtilities } from "@justplayfair/model";
import { alertService } from "@services/Alert/AlertService";
import { authService } from "@services/auth";
import { CombinedError } from "urql";
import { i18nInstance } from "../i18n";

export const onError = (error: CombinedError) => {
  console.error("[GRAPHQL]", { error });
  const redirectPath = window.location.pathname;

  if (error.response?.status === 401) {
    authService.logout(redirectPath);
    return;
  }
  const apiErrors = getCodesFromError(error);

  if (apiErrors.includes(ApiErrorCode.AUTHENT_UNAUTHORIZED)) {
    authService.logout(redirectPath);
    return;
  }
  if (error.networkError !== undefined) {
    alertService.addDefaultAlert();
  }
  if (apiErrors.includes(ApiErrorCode.API_ERROR)) {
    alertService.addDefaultAlert();
  }
  if (apiErrors.includes(ApiErrorCode.AUTHENT_ALREADY_EXISTING_USER)) {
    alertService.addAlert({
      type: "error",
      title: "Une erreur s'est produite",
      description: i18nInstance.t("common.error.userAlreadyExistWithThisEmail"),
    });
  }
};
export function getCodesFromError(error: CombinedError): ApiErrorCode[] {
  return error.graphQLErrors
    .map((gqlError) => gqlError?.extensions?.code as ApiErrorCode)
    .filter(ArrayUtilities.nullableFilterPredicate);
}
