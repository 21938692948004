import { Button } from "@components/Button";
import { DangerousHtml } from "@components/DangerousHtml";
import { Loader } from "@components/Loader";
import {
  textDeltaToHtml,
  TextEditor,
} from "@components/TextEditor/TextEditor.component";
import Delta from "quill-delta";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  isEmptyEditorValue,
  TemplateVariables,
} from "../../../../html/editor.utilities";

interface CommentTextEditorProps {
  content?: string;
  variables: Record<string, string>;
  getTemplateFn: (variables: TemplateVariables) => string;
  fallbackMessage: string;
  editable: boolean;
  isEditing: boolean;
  loading?: boolean;

  onSave: (htmlValue?: string) => void;
  onSetEditing: (isEditing: boolean) => void;
}
export function CommentTextEditor({
  content,
  variables,
  fallbackMessage,
  getTemplateFn,
  editable,
  isEditing,
  loading,

  onSave,
  onSetEditing,
}: CommentTextEditorProps) {
  const { t } = useTranslation();

  const value = useRef<Delta>();

  const initialContent = useMemo(() => {
    return isEmptyEditorValue(getTemplateFn(variables), content)
      ? getTemplateFn(variables)
      : content;
  }, [getTemplateFn, content, variables]);

  return (
    <div className="space-y-2">
      <div className="h-44">
        {isEditing && (
          <TextEditor
            content={initialContent}
            onChange={(val) => {
              value.current = val;
            }}
          />
        )}
        {!loading && content && !isEditing && (
          <div className="text-gray-700 h-full overflow-y-auto">
            <DangerousHtml html={content} />
          </div>
        )}
        {!loading && !content && !isEditing && (
          <div className="text-gray-500">{fallbackMessage}</div>
        )}
        {loading && <Loader size="medium" />}
      </div>
      <div className="flex justify-end space-x-2">
        {editable && isEditing && (
          <>
            <div className="w-32">
              <Button
                title={t("common.button.cancel.label")}
                onClick={() => {
                  onSetEditing(false);
                }}
                variant="transparent"
              >
                {t("common.button.cancel.label")}
              </Button>
            </div>
            <div className="w-32">
              <Button
                title={t("common.button.save.label")}
                onClick={() => {
                  onSave(
                    value.current ? textDeltaToHtml(value.current) : undefined
                  );
                  onSetEditing(false);
                }}
              >
                {t("common.button.save.label")}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
