import { AuthLayout } from "@components/AuthLayout";
import { AuthBody } from "@justplayfair/model";
import { authService } from "@services/auth";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { HTTPError } from "../../../http/client";
import { afterAuthentRedirect } from "../utilities/auth.utilities";
import { LoginForm } from "./Login.form";

export function LoginPage() {
  const [loading, setLoading] = useState(false);
  const [errorKey, setErrorKey] = useState<
    undefined | "error" | "deactivated"
  >();

  const history = useHistory();
  const location = useLocation();

  async function handleLogin(fields: AuthBody) {
    setLoading(true);
    try {
      const { needMfa } = await authService.login(fields);

      if (!needMfa) {
        afterAuthentRedirect(history, location);
      } else {
        history.push("/check-otp");
      }
    } catch (error) {
      const apiErrorCode =
        error instanceof HTTPError ? error.apiErrorCode : undefined;
      console.error(error);
      setErrorKey(
        apiErrorCode === "@authent/accountDeactivated" ? "deactivated" : "error"
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <AuthLayout
      titleKey="auth.login.title"
      errorKey={errorKey}
      loading={loading}
    >
      <LoginForm onLogin={handleLogin} />
    </AuthLayout>
  );
}
