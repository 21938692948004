import { ChangeEvent, ReactNode } from "react";
import classnames from "clsx";

interface CheckboxProps {
  id: string;
  label?: string | ReactNode;
  checked: boolean;
  isError?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export function Checkbox({
  id,
  label,
  checked,
  isError,
  onChange,
}: CheckboxProps) {
  const labelClassNames = classnames("ml-2 block text-sm text-gray-900", {
    "text-gray-900": !isError,
    "text-red-400": isError,
  });
  return (
    <div className="flex items-center">
      <input
        id={id}
        name={id}
        type="checkbox"
        checked={checked}
        onChange={
          onChange
            ? (event) => {
                onChange(event);
              }
            : undefined
        }
        className="h-4 w-4 text-primary border-primary focus:ring-primary rounded"
      />
      {label && (
        <label htmlFor={id} className={labelClassNames}>
          {label}
        </label>
      )}
    </div>
  );
}
