import { Button } from "@components/Button";
import { PageContainer } from "@components/Layout";
import { Loader } from "@components/Loader";
import { Title } from "@components/Title";
import { UserList } from "@components/UserList/UserList.component";
import { ApiErrorCode, Client } from "@justplayfair/model";
import { alertService } from "@services/Alert/AlertService";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { getCodesFromError } from "../../../../../graphql/error.config";
import { AdminCreationButtonContainer } from "../../../components/AdminCreationButtonContainer.component";
import { AddUserToProgramModal } from "./AddUserToProgramModal.component";
import { UpdateProgramForm } from "./UpdateProgramForm.component";

export function UpdateProgramPage() {
  const { t } = useTranslation();
  const { companyId, programId } = useParams<{
    companyId: string;
    programId: string;
  }>();

  const [isEdit, setIsEdit] = useState(false);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);

  const history = useHistory();

  const [{ data: companyData }] = Client.useGetCompanyQuery({
    variables: { id: companyId },
  });
  const [{ data, fetching: fetchingProgram }] = Client.useGetProgramQuery({
    variables: { programId },
  });

  const [, updateGroup] = Client.useUpdateProgramMutation();
  const [, deleteGroup] = Client.useDeleteProgramMutation();
  const [, addUsers] = Client.useAddUsersToProgramMutation();
  const [, removeUsers] = Client.useRemoveUsersFromProgramMutation();

  async function handleEditProgram(input: Client.UpdateProgramInput) {
    const result = await updateGroup({ id: programId, input });
    if (!result.error) {
      setIsEdit(false);
    }
  }

  async function handleDeleteGroup() {
    if (window.confirm("Etes vous sûr de vouloir supprimer ce programme ?")) {
      await deleteGroup({ id: programId });
      history.push(`/admin/companies/${companyData?.company?.id}`);
    }
  }

  function handleOpenAddUserModal() {
    setAddUserModalOpen(true);
    window.scrollTo({ top: 0 });
  }

  async function handleAddUsersToGroup(userIds: string[]) {
    const program = data?.program;
    if (!program) {
      console.error("Missing program");
      return;
    }
    const { error } = await addUsers({ programId: program.id, userIds });
    if (error) {
      const errorCodes = getCodesFromError(error);

      if (errorCodes.includes(ApiErrorCode.DATA_CONFLICT)) {
        alertService.addAlert({
          type: "error",
          title: "Une erreur s'est produite",
          description: "Cette utilisateur est déjà dans un programme actif",
        });
        return;
      }
    }
    setAddUserModalOpen(false);
  }

  async function handleRemoveUsersFromGroup(userIds: string[]) {
    const program = data?.program;
    if (!program) {
      console.error("Missing program");
      return;
    }
    await removeUsers({ programId: program.id, userIds });
  }
  if (fetchingProgram) {
    return (
      <PageContainer title={t("menu.admin")}>
        {<Loader size="medium" />}
      </PageContainer>
    );
  }
  if (!data) {
    return (
      <PageContainer title={t("menu.admin")}>
        {t("common.noData")}
      </PageContainer>
    );
  }

  return (
    <PageContainer
      title={t("menu.admin")}
      subtitle={t("admin.company.title.editProgram")}
      backButton={`/admin/companies/${companyId}`}
    >
      <div className="space-y-12">
        <div className="w-full max-w-6xl mx-auto">
          {!data?.program && "Introuvable"}
          {data?.program && (
            <>
              <Title size={4}>{`${t("admin.program.label.forCompany")} "${
                companyData?.company?.name
              }"`}</Title>
              <UpdateProgramForm
                program={data.program}
                targetedLevels={data.program.targetedLevels ?? undefined}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                onEditProgram={handleEditProgram}
                onDeleteProgram={handleDeleteGroup}
              />
            </>
          )}
        </div>
        <div className="w-full mx-auto space-y-2">
          <Title size={4}>{t("admin.program.title.users")}</Title>

          {(!data.program?.users || data.program.users?.length === 0) && (
            <Title size={5}>{t("admin.program.label.noUserYet")}</Title>
          )}
          {!!data.program?.users?.length && (
            <UserList
              users={data.program.users}
              customActions={(user) => (
                <div className="w-40 ml-auto">
                  <Button
                    title="Supprimer du programme"
                    variant="transparent"
                    onClick={() => handleRemoveUsersFromGroup([user.id])}
                  >
                    Supprimer du programme
                  </Button>
                </div>
              )}
            />
          )}

          <AdminCreationButtonContainer>
            <Button
              onClick={handleOpenAddUserModal}
              title={t("admin.program.button.addTrainees")}
            >
              {t("admin.program.button.addTrainees")}
            </Button>
            {data.program?.companyId && (
              <AddUserToProgramModal
                isOpen={addUserModalOpen}
                setIsOpen={setAddUserModalOpen}
                companyId={data.program.companyId}
                onAdd={handleAddUsersToGroup}
              />
            )}
          </AdminCreationButtonContainer>
        </div>
      </div>
    </PageContainer>
  );
}
