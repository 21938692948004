import { CustomSelect } from "@components/form/Select";
import { IconComponent } from "@components/Icon/Icon.model";
import classnames from "clsx";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";

export interface TabsProps {
  items: Omit<TabProps, "active">[];
}

export function Tabs({ items }: TabsProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  function handleChangeRoute(link: string) {
    history.push(link);
  }
  return (
    <div>
      <div className="sm:hidden">
        <CustomSelect
          id="mobile-tabs-select"
          label={t("component.tabs.text.selectTab")}
          value={location.pathname}
          options={items.map((item) => ({
            value: item.link,
            label: item.label,
          }))}
          onChange={(event) => handleChangeRoute(event.target.value)}
          multi={false}
        />
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {items.map((item, index) => (
              <Tab
                key={index}
                label={item.label}
                icon={item.icon}
                link={item.link}
                active={item.link === location.pathname}
                testId={item.testId}
              />
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

interface TabProps {
  icon: IconComponent;
  label: string;
  link: string;
  active?: boolean;
  testId?: string;
}
export function Tab({ icon, label, link, active, testId = "Tab" }: TabProps) {
  const linkClasses = classnames(
    "border-transparent hover:border-gray-300 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm",
    {
      "text-gray-500 hover:text-gray-700": !active,
      "border-primary text-primary-dark": active,
    }
  );

  const iconClasses = classnames("-ml-0.5 mr-2 h-5 w-5", {
    "text-gray-400 group-hover:text-gray-500": !active,
    "text-primary": active,
  });
  return (
    <Link to={link} className={linkClasses} data-testid={testId}>
      {icon({ className: iconClasses })}
      <span>{label}</span>
    </Link>
  );
}
