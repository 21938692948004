import {
  CheckCircleOutline,
  ExclamationCircleOutline,
  InformationCircleOutline,
} from "@graywolfai/react-heroicons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export interface CourseOfAction {
  link: string;
  action?: "DATA_ENTRY" | "SCHEDULE" | "CONTACT" | null;
}

export interface NotifProps {
  severity: "LOW" | "MODERATE" | "HIGH";
  title: string;
  message?: string;
  courseOfAction?: CourseOfAction;
  onClick: VoidFunction;
}

export function Notif({
  severity,
  title,
  message,
  courseOfAction,
  onClick,
}: NotifProps) {
  const { t } = useTranslation();
  return (
    <div className="w-full flex justify-center pointer-events-none">
      <div className="max-w-lg w-full bg-white shadow rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5">
        <div className="w-0 flex-1 p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0 pt-0.5">
              {severity === "HIGH" && (
                <ExclamationCircleOutline className="text-red-500 h-10 w-10" />
              )}
              {severity === "MODERATE" && (
                <InformationCircleOutline className="text-yellow-500 h-10 w-10" />
              )}
              {severity === "LOW" && (
                <CheckCircleOutline className="text-green-500 h-10 w-10" />
              )}
            </div>
            <div className="max-w-md ml-3 w-0 flex-1 items-center">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              {message && (
                <p className="mt-1 text-sm text-gray-500">{message}</p>
              )}
            </div>
          </div>
        </div>
        {courseOfAction && (
          <div className="w-28 flex border-l border-gray-200">
            <Link
              to={courseOfAction.link}
              onClick={onClick}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-primary hover:text-primary-dark focus:outline-none focus:ring-2 focus:ring-primary"
            >
              {t(`common.courseOfAction.action.${courseOfAction.action}`)}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
