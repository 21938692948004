import { Avatar } from "@components/Avatar";
import { PageContainer } from "@components/Layout";
import { Loader } from "@components/Loader";
import { Title } from "@components/Title";
import { useAuthenticatedUser } from "@hooks/authenticatedUser/useAuthenticatedUser.hook";
import { Client } from "@justplayfair/model";
import { FunctionComponent, Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../../components/Card";
import { AvatarImageModalLazy } from "./AvatarImageModal";
import { MfaManagement } from "./MfaManagement/MfaManagement.component";
import { UserInfo } from "./UserInfo";

const ProfileContainer: FunctionComponent = ({ children }) => {
  const { t } = useTranslation();

  return (
    <PageContainer title={t("menu.profile")}>
      <div className="w-9/12 mx-auto py-12 sm:px-6 lg:px-8 space-y-8">
        {children}
      </div>
    </PageContainer>
  );
};

export function ProfilePage() {
  const { t } = useTranslation();
  const [showAvatarImageModal, setShowAvatarImageModal] = useState(false);
  const [avatarImageModalLoading, setAvatarImageModalLoading] = useState(false);

  const { fetching, user, reexecuteQuery } = useAuthenticatedUser();

  const [, updateUser] = Client.useUpdateUserMutation();
  const [{ fetching: fetchingUploadResume }, uploadResume] =
    Client.useUploadUserResumeMutation();

  const [, uploadAvatar] = Client.useUploadUserAvatarMutation();
  const [, rmAvatar] = Client.useRmUserAvatarMutation();

  async function handleUpdateUser(input: Client.UpdateUserInputUser) {
    await updateUser({ input });
    reexecuteQuery({ requestPolicy: "network-only" });
  }

  async function handleUploadResume(file: File | null) {
    await uploadResume({
      file,
    });
    reexecuteQuery({ requestPolicy: "network-only" });
  }
  function handleAddImage() {
    setShowAvatarImageModal(true);
  }

  async function handleSaveAvatar(file: File, cropData: Client.CropData) {
    try {
      setAvatarImageModalLoading(true);
      await uploadAvatar({ file, cropData });
    } finally {
      setAvatarImageModalLoading(false);
    }
    reexecuteQuery({ requestPolicy: "network-only" });
    setShowAvatarImageModal(false);
  }

  async function handleRmAvatar() {
    try {
      setAvatarImageModalLoading(true);
    } finally {
      setAvatarImageModalLoading(false);
    }
    await rmAvatar({});
    reexecuteQuery({ requestPolicy: "network-only" });
    setShowAvatarImageModal(false);
  }

  if (!user) {
    return (
      <ProfileContainer>
        <Title size={3}>{t("common.text.noData")}</Title>
      </ProfileContainer>
    );
  }
  return (
    <ProfileContainer>
      <div className="space-y-2">
        <Avatar size="large" entity={user} onAddPicture={handleAddImage} />
        <div className="w-full max-w-4xl mx-auto">
          <Card>
            <div className="p-2">
              <UserInfo
                user={user}
                fetchingUploadResume={fetchingUploadResume}
                onUpdateUser={handleUpdateUser}
                onUploadResume={handleUploadResume}
              />
            </div>
          </Card>
        </div>
      </div>
      <div className="w-full max-w-4xl mx-auto">
        <Card>
          <div className="p-4">
            <MfaManagement user={user} onUpdate={reexecuteQuery} />
          </div>
        </Card>
      </div>
      <Suspense fallback={<Loader size="small" />}>
        <AvatarImageModalLazy
          isOpen={showAvatarImageModal}
          loading={avatarImageModalLoading}
          resizedImages={user.avatarImages}
          onSave={handleSaveAvatar}
          onRmAvatar={handleRmAvatar}
          onClose={() => setShowAvatarImageModal(false)}
        />
      </Suspense>
      {fetching && <Loader size="medium" mode="overlay" />}
    </ProfileContainer>
  );
}
