import { Button } from "@components/Button";
import { ProgramFilters } from "@components/Consult/ChartFilters/ProgramChartFilters.component";
import { Loader } from "@components/Loader";
import { Title } from "@components/Title";
import { Client, DatavizMapper, formatToLocalDate } from "@justplayfair/model";
import { reportService } from "@services/report/report.service";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { CardSection } from "@components/CardSection";
import { SkillsRatesChart } from "../../../../components/Consult/chart/SkillsRates.component";
import { ComparisonBarChart } from "../../../../components/Consult/chart/ComparisonChart.component";
import { RateDetailsChart } from "../../../../components/Consult/chart/RateDetails.component";
import {
  FiltersValue,
  isFiltered,
} from "../../../../components/Consult/ChartFilters/ChartFilters.component";
import { CommentTextEditor } from "../../components/CommentTextEditor/CommentTextEditor.component";
import { dateFilterToDateSearchQueryField } from "../../../../dataviz/filters.utilities";

export function ProgramDatavizTabContent() {
  const { t } = useTranslation();

  const { programId } = useParams<{
    programId: string;
  }>();

  const [isEditingComment, setIsEditingComment] = useState(false);
  const [downloadingReport, setDownloadingReport] = useState(false);
  const [group1Filters, setGroup1Filters] = useState<FiltersValue>({});
  const [group2Filters, setGroup2Filters] = useState<FiltersValue>({});

  const [{ data, fetching }] = Client.useProgramDashboardQuery({
    variables: { programId },
    requestPolicy: "cache-only",
  });
  const [{ fetching: fetchingSavingNote }, updateProgramNote] =
    Client.useSaveProgramNoteMutation();

  const [{ data: actualRates, fetching: fetchingSkillsRatess }] =
    Client.useGetSkillsRatesQuery({
      variables: {
        filters: {
          groupArgs: [
            {
              programId,
              labelIds: group1Filters.labelIds,
              date: dateFilterToDateSearchQueryField(group1Filters),
              comparison: {
                programId,
                withCompanyStats: group2Filters.compareToCompany,
                labelIds: group2Filters.labelIds,
                date: dateFilterToDateSearchQueryField(group2Filters),
              },
            },
          ],
        },
      },
      requestPolicy: "network-only",
    });

  const radarData = useMemo(() => {
    if (!actualRates) {
      return undefined;
    }
    return DatavizMapper.radarDataMapper(actualRates.getSkillsRates, t);
  }, [t, actualRates]);

  const [{ data: groupRateDetails, fetching: fetchingDetails }] =
    Client.useGetRateDetailsQuery({
      variables: {
        filters: {
          groupArgs: [
            {
              programId,
              labelIds: group1Filters.labelIds,
              date: dateFilterToDateSearchQueryField(group1Filters),
              comparison: {
                programId,
                labelIds: group2Filters.labelIds,
                date: dateFilterToDateSearchQueryField(group2Filters),
                withCompanyStats: group2Filters.compareToCompany,
              },
            },
          ],
        },
      },
      requestPolicy: "network-only",
    });

  const detailsBarsData = useMemo(() => {
    if (!groupRateDetails) {
      return undefined;
    }
    return groupRateDetails.getRateDetails.map((rateDetails) =>
      DatavizMapper.byKeyBarDataMapper(rateDetails, t)
    );
  }, [groupRateDetails, t]);

  const comparisonBarsData = useMemo(() => {
    if (!groupRateDetails) {
      return undefined;
    }

    return groupRateDetails.getRateDetails
      .filter((rateDetail) => rateDetail.statName) // Dont display group details data
      .map((details) => DatavizMapper.byKeyBarDataComparisonMapper(details, t));
  }, [groupRateDetails, t]);

  if (fetching) {
    return <Loader size="medium" />;
  }
  if (!data?.program) {
    return <Title size={6}>Introuvable</Title>;
  }

  async function handleSaveComment(noteValue?: string) {
    await updateProgramNote({ programId, noteValue });
  }
  const isComparison = isFiltered(group2Filters);

  async function handleOnGenerateReport() {
    try {
      setDownloadingReport(true);
      await reportService.downloadProgramReport({
        groupArgs: {
          programId,
          labelIds: group1Filters.labelIds,
          date: dateFilterToDateSearchQueryField(group1Filters),
          comparison: isComparison
            ? {
                programId,
                labelIds: group2Filters.labelIds,
                date: dateFilterToDateSearchQueryField(group2Filters),
                withCompanyStats: group2Filters.compareToCompany,
                traineeId: group2Filters.comparisonTraineeId,
              }
            : undefined,
        },
        debug: false,
      });
    } finally {
      setDownloadingReport(false);
    }
  }

  return (
    <div className="space-y-4">
      <CardSection
        title={t("trainer.dashboard.title.comments")}
        action={
          !isEditingComment ? (
            <div className="w-44">
              <Button
                title={t("common.button.edit.label")}
                onClick={() => {
                  setIsEditingComment(true);
                }}
                variant="secondary"
              >
                {t("common.button.edit.label")}
              </Button>
            </div>
          ) : undefined
        }
      >
        <CommentTextEditor
          content={data.program.note?.value || undefined}
          variables={{}}
          getTemplateFn={(_) => ""}
          fallbackMessage={t("trainer.dashboard.text.noCommentYet")}
          editable={true}
          isEditing={isEditingComment}
          loading={fetchingSavingNote}
          onSave={handleSaveComment}
          onSetEditing={setIsEditingComment}
        />
      </CardSection>

      <div className="w-full border-t-2 border-gray-200" />

      <CardSection title={t("trainer.dashboard.title.filterMembers")}>
        <ProgramFilters
          companyId={data.program.companyId}
          programId={programId}
          nbMembersInGroup1={actualRates?.getSkillsRates[0]?.nbMembersInGroup}
          nbMembersInGroup2={actualRates?.getSkillsRates[1]?.nbMembersInGroup}
          setGroup1Filters={setGroup1Filters}
          setGroup2Filters={setGroup2Filters}
        />
      </CardSection>
      <div className="flex justify-end px-2">
        <div className="w-44">
          <Button
            variant="secondary"
            title={t("common.button.generateAReport.label")}
            onClick={handleOnGenerateReport}
          >
            {t("common.button.generateAReport.label")}
          </Button>
        </div>
      </div>
      <CardSection
        title={`${t("trainer.dashboard.text.at")} ${formatToLocalDate(
          new Date()
        )}`}
      >
        <div className="space-y-8">
          <SkillsRatesChart
            skillsRatesData={radarData}
            fetching={fetchingSkillsRatess}
          />
          <div className="w-full">
            <Title size={6}>{t("trainer.dashboard.text.details")}</Title>
            <RateDetailsChart
              name={"Groupe 1"}
              detailsData={detailsBarsData}
              fetching={fetchingDetails}
              height={900}
              testId="programRateDetailsChart"
            />
          </div>
          {isComparison && (
            <div className="w-full">
              <Title size={6}>
                {t("trainer.dashboard.text.rateComparison")}
              </Title>
              <ComparisonBarChart
                comparisonData={comparisonBarsData}
                fetching={fetchingDetails}
                height={700}
              />
            </div>
          )}
        </div>
      </CardSection>
      {downloadingReport && <Loader size="medium" mode="overlay" />}
    </div>
  );
}
