import classNames from "clsx";
import { MouseEvent } from "react";
import { IconComponent } from "../Icon/Icon.model";
import { ReactComponent as SpinnerSvg } from "../Spinner/Spinner.svg";

export interface IconButtonProps {
  icon: IconComponent;
  size: "small" | "medium" | "large";
  title: string;
  disabled?: boolean;
  variant?: "secondary" | "warning" | "transparent";
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
}

export function IconButton({
  icon,
  size,
  title,
  disabled,
  variant,
  onClick,
  loading,
}: IconButtonProps) {
  const classes = classNames(
    "rounded-full border p-1 transition focus:outline-none",
    {
      // Primary
      "bg-primary text-gray-50 ": !variant,
      "hover:bg-primary-dark hover:text-white": !variant && !disabled,

      //Variant secondary
      "bg-gray-400 text-gray-50": variant === "secondary",
      "hover:text-gray-200 hover:bg-gray-500 hover:text-white":
        variant === "secondary" && !disabled,

      //Variant warning
      "bg-red-400 border-transparent": variant === "warning",
      "text-white hover:bg-red-500": variant === "warning" && !disabled,

      // Transparent
      "bg-white text-gray-600 border-gray-200": variant === "transparent",
      "hover:border-gray-300 hover:bg-opacity-10 hover:text-gray-900":
        variant === "transparent" && !disabled,

      "cursor-default": disabled,
    }
  );

  const iconClasses = classNames({
    "h-4 w-4": size === "small",
    "h-8 w-8": size === "medium",
    "h-12 w-12": size === "large",
  });
  const loaderClasses = classNames("animate-spin", {
    "h-4 w-4": size === "small",
    "h-8 w-8": size === "medium",
    "h-12 w-12": size === "large",
  });
  const iconElement = icon({ className: iconClasses });
  return (
    <button type="button" className={classes} onClick={onClick} title={title}>
      {!loading && iconElement}
      {loading && <SpinnerSvg className={loaderClasses} />}
    </button>
  );
}
