import { Chip, ChipContainer } from "@components/Chip";
import { FormikSelect } from "@components/form/Select/FormikSelect";
import { Client } from "@justplayfair/model";
import { useFormikContext } from "formik";
import { useMemo } from "react";
import { toSearchEntries } from "../../../../search/search.utilities";

interface FormikLabelSelectFieldProps {
  fieldName: string;
  editMode: boolean;
  labelCategory: Client.LabelCategory;
  label: string;
  multi?: boolean;
}
export function FormikLabelSelectField({
  fieldName,
  editMode,
  labelCategory,
  label,
  multi = false,
}: FormikLabelSelectFieldProps) {
  const {
    values: { companyId },
  } = useFormikContext<{ companyId?: string | null }>();

  const [{ data }] = Client.useSearchLabelsQuery({
    pause: companyId === undefined || companyId === null,
    variables: {
      search: {
        skip: 0,
        take: 1000,
        filters: {
          deleted: false,
          query: companyId
            ? new URLSearchParams(
                toSearchEntries({
                  companyId: companyId,
                  categories: [labelCategory],
                })
              ).toString()
            : 'company="none"', // To secure wrong search
        },
      },
    },
  });
  const labelOpts = useMemo(
    () =>
      data?.searchLabels.data.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [data]
  );

  return (
    <FormikSelect
      editMode={editMode}
      id={fieldName}
      multi={multi}
      label={label}
      options={labelOpts || []}
      renderValue={
        multi
          ? (values: string[]) =>
              values.map((val) => (
                <ChipContainer>
                  <Chip color="transparent" label={val} />
                </ChipContainer>
              ))
          : (val: string) => <Chip color="transparent" label={val} />
      }
    />
  );
}
