import { Button } from "@components/Button";
import { Card } from "@components/Card";
import { FormikInput } from "@components/form/Input/FormikInput";
import { FormikSelect } from "@components/form/Select/FormikSelect";
import { Title } from "@components/Title";
import { Client } from "@justplayfair/model";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";
import {
  PROGRAM_TYPES,
  PROGRAM_TYPES_OPTIONS,
} from "../../../../../model/Company.model";
import { FormikProgramSkillsFields } from "../../components/FormikProgramSkillsFields.component";
import { FormikTargetedLevelFields } from "../../components/FormikTargetedLevelFields.component";

const CreateGroupSchema: Yup.SchemaOf<Client.CreateProgramInput> = Yup.object()
  .shape({
    name: Yup.string().required("admin.program.label.nameMandatory"),
    companyId: Yup.string().required("admin.program.label.companyMandatory"),
    type: Yup.mixed<Client.ProgramType>()
      .oneOf(PROGRAM_TYPES)
      .defined("admin.program.label.typeMandatory"),
    skillIds: Yup.array().of(Yup.string().required()),
    durationInMonths: Yup.number().when("type", {
      is: "TRAINING",
      then: Yup.number().required("admin.program.label.durationMandatory"),
    }),
    targetedLevels: Yup.mixed(),
  })
  .required();

interface CreateProgramFormProps {
  company: Client.CompanyFieldsFragment;
  onCreateProgram: (input: Client.CreateProgramInput) => Promise<void>;
}

export function CreateProgramForm({
  company,
  onCreateProgram,
}: CreateProgramFormProps) {
  const { t } = useTranslation();
  const [{ data: capacitiesData, fetching }] = Client.useListCapacitiesQuery();

  return (
    <div>
      <Formik
        validateOnChange={false} // performance enhancment
        initialValues={{
          companyId: company.id,
          name: "",
          type: "ASSESSMENT" as Client.ProgramType,
          nbMonths: 6,
          targetedLevels: {} as Record<string, number>,
          skills: [
            { id: uuid(), name: "", capacities: [{ id: "" }] },
          ] as Client.ProgramSkillInput[],
        }}
        onSubmit={async ({ targetedLevels, nbMonths, skills, ...fields }) => {
          const targetedLevelInputs = Object.entries(targetedLevels).map(
            ([skillId, targetedRate]) => ({
              skillId,
              targetedRate,
            })
          );
          const skillInputs: Client.ProgramSkillInput[] = skills.map(
            (skill) => ({
              ...skill,
              capacities: skill.capacities.map((capacity) => ({
                ...capacity,
                label: capacity.label
                  ? { ...capacity.label, id: uuid() }
                  : undefined,
              })),
            })
          );

          await onCreateProgram({
            ...fields,
            skills: skillInputs,
            targetedLevels: targetedLevelInputs.length
              ? targetedLevelInputs
              : undefined,
          });
        }}
        validationSchema={CreateGroupSchema}
      >
        {(props) => (
          <Form className="space-y-8">
            <Card rounded={false}>
              <div className="p-8">
                <FormikInput
                  editMode={true}
                  id="name"
                  label={t("admin.program.label.name")}
                />
                <FormikSelect
                  editMode={true}
                  id="type"
                  multi={false}
                  options={PROGRAM_TYPES_OPTIONS}
                  label={t("admin.program.label.type")}
                  renderValue={(val) => t(`common.programType.${val}`)}
                />
                {props.values.type === "TRAINING" && (
                  <FormikInput
                    editMode={true}
                    id="durationInMonths"
                    label={t("admin.program.label.durationInMonths")}
                    type="number"
                  />
                )}
              </div>
            </Card>
            <Card rounded={false}>
              <div className="p-8">
                <Title size={6}>{t("admin.program.label.skills")}</Title>
                <FormikProgramSkillsFields
                  capacities={capacitiesData?.listCapacities ?? []}
                  fetching={fetching}
                  editMode={true}
                  externalAssessment={false}
                />
              </div>
            </Card>
            {props.values.type === "TRAINING" &&
              props.values.skills.length > 0 && (
                <Card rounded={false}>
                  <div className="p-8">
                    <div className="w-full max-w-4xl mx-auto">
                      <Title size={6}>
                        {t("admin.user.title.targetedLevels")}
                      </Title>
                      <div className="grid grid-cols-2 gap-4">
                        <FormikTargetedLevelFields
                          editMode={true}
                          fieldName="targetedLevels"
                        />
                      </div>
                    </div>
                  </div>
                </Card>
              )}
            <div className="flex justify-end">
              <div className="w-56">
                <Button title={t("common.button.create.label")} type="submit">
                  {t("common.button.create.label")}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
