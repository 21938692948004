import { Modal } from "@components/Modal";
import { UserList } from "@components/UserList/UserList.component";
import { Client } from "@justplayfair/model";

interface AddUserModalProps {
  isOpen: boolean;
  users: Client.UserDetailsFieldsFragment[];
  onSearchChange: (search: string) => void;
  onAdd: (userIds: string[]) => Promise<void>;
  onClose: VoidFunction;
}

export function AddUserModal({
  isOpen,
  users,
  onSearchChange,
  onAdd,
  onClose,
}: AddUserModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} width="large">
      <UserList
        users={users}
        onSelect={onAdd}
        onSearch={onSearchChange}
        noShadow
      />
    </Modal>
  );
}
