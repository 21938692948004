import { ReactNode } from "react";
import ReactModal from "react-modal";
import { XOutline } from "@graywolfai/react-heroicons";
import { IconButton } from "../Button/IconButton.component";
import { useTranslation } from "react-i18next";
import classnames from "clsx";
import { Title } from "@components/Title";

interface ModalProps {
  isOpen: boolean;
  width?: "large" | "medium" | "small";
  title?: string;
  onClose: VoidFunction;
  children: ReactNode;
}
export function Modal({
  isOpen,
  width = "large",
  title,
  onClose,
  children,
}: ModalProps) {
  const { t } = useTranslation();
  const modalClasses = classnames(
    "absolute z-20 inset-40 bg-white rounded-md focus:outline-none",
    {
      "max-w-xl h-fit-content mx-auto": width === "medium",
      "max-w-sm h-fit-content mx-auto": width === "small",
    }
  );
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => {
        onClose();
      }}
      overlayClassName="absolute inset-0 bg-black bg-opacity-40 z-10"
      className={modalClasses}
    >
      <div className="flex flex-col w-full h-full p-2 ">
        <div className="w-full flex  justify-between">
          <Title size={5}>{title}</Title>
          <IconButton
            icon={XOutline}
            size="small"
            onClick={onClose}
            variant="secondary"
            title={t("common.button.close.label")}
          />
        </div>
        <div
          className="h-full flex p-2 overflow-auto"
          style={{ maxHeight: "calc(100vh - 13rem)" }}
        >
          {children}
        </div>
      </div>
    </ReactModal>
  );
}
