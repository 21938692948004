import { FieldContainer } from "@components/form/FieldContainer";
import { LabelPosition } from "@components/form/FieldContainer/FieldContainer.component";
import { useField, useFormikContext } from "formik";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { CustomSelect, displayValue } from "../index";
import { SelectOption } from "../Select.model";

interface FormikSelectProps<M extends boolean> {
  editMode: boolean;
  id: string;
  options?: SelectOption<string>[];
  label?: string;
  labelPosition?: LabelPosition;
  multi: M;
  withSeparator?: boolean;
  renderValue?: M extends true
    ? (value: string[]) => ReactNode | string
    : (value: string) => ReactNode | string;
}

export function FormikSelect<M extends boolean>(props: FormikSelectProps<M>) {
  if (props.multi === true) {
    const multiProps = props as FormikSelectProps<true>;
    return <FormikMultiSelect {...multiProps} />;
  } else {
    const singleProps = props as FormikSelectProps<false>;
    return <FormikSingleSelect {...singleProps} />;
  }
}

function FormikSingleSelect({
  editMode,
  id,
  options,
  label,
  labelPosition = "left",
  withSeparator,
  renderValue,
}: FormikSelectProps<false>) {
  const { t } = useTranslation();

  const { submitCount } = useFormikContext();
  const [field, { error, touched }] = useField<string>(id);

  return (
    <FieldContainer
      id={id}
      label={label}
      labelPosition={labelPosition}
      withSeparator={withSeparator}
    >
      {editMode ? (
        <div className="w-full">
          <CustomSelect
            id={id}
            options={options}
            renderValue={renderValue}
            multi={false}
            isError={touched && !!error}
            {...field}
          />
          <div className="h-4 mt-0 text-xs text-red-400">
            {(touched || submitCount > 0) && error && t(error)}
          </div>
        </div>
      ) : (
        <DisplayValue
          type="single"
          value={field.value}
          options={options}
          renderValue={renderValue}
        />
      )}
    </FieldContainer>
  );
}

function FormikMultiSelect({
  editMode,
  id,
  options,
  label,
  labelPosition = "left",
  withSeparator,
  renderValue,
}: FormikSelectProps<true>) {
  const { t } = useTranslation();
  const [field, { error, touched }, { setValue, setTouched }] =
    useField<string[]>(id);

  return (
    <FieldContainer
      id={id}
      label={label}
      labelPosition={labelPosition}
      withSeparator={withSeparator}
    >
      {editMode ? (
        <div className="w-full">
          <CustomSelect
            id={id}
            options={options}
            renderValue={renderValue}
            multi={true}
            isError={touched && !!error}
            {...field}
            onChange={(values) => {
              setValue(values || []);
              setTouched(true);
            }}
          />
          <div className="h-4 mt-0 text-xs text-red-400">
            {touched && error && t(error)}
          </div>
        </div>
      ) : (
        <DisplayValue
          type="multi"
          value={field.value}
          options={options}
          renderValue={renderValue}
        />
      )}
    </FieldContainer>
  );
}

interface BaseDisplayValueProps {
  type: "multi" | "single";
  options: SelectOption<string>[] | undefined;
}
interface DisplaySingleValueProps extends BaseDisplayValueProps {
  type: "single";
  value: string;
  renderValue?: (value: string) => ReactNode | string;
}

interface DisplayMultiValueProps extends BaseDisplayValueProps {
  type: "multi";
  value: string[];
  renderValue?: (value: string[]) => ReactNode | string;
}

function DisplayValue(props: DisplaySingleValueProps | DisplayMultiValueProps) {
  if (props.type === "multi") {
    const { value, options, renderValue } = props;
    const values = options?.filter((opt) => value.includes(opt.value));
    return (
      <span className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {displayValue({
          renderValues: renderValue,
          values,
          renderValue: undefined,
          value: undefined,
        })}
      </span>
    );
  }
  const { value, options, renderValue } = props;
  const selectedValue = options?.find((opt) => opt.value === value);
  return (
    <span className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
      {displayValue({
        renderValues: undefined,
        values: undefined,
        renderValue,
        value: selectedValue,
      })}
    </span>
  );
}
