import { ChevronDownOutline } from "@graywolfai/react-heroicons";
import { Menu, Transition } from "@headlessui/react";
import classNames from "clsx";
import React, { ReactNode } from "react";
import { ButtonProps, CoreButton } from "../Button/Button.component";
import { IconButton } from "../Button/IconButton.component";
import { IconComponent } from "../Icon/Icon.model";

interface IconButtonProps {
  icon: IconComponent;
  title: string;
  disabled?: boolean;
}

interface ButtonDropdownProps {
  position: "left" | "right";
  children: ReactNode;
  button: ButtonProps;
  iconButton?: undefined;
}

interface IconDropdownProps {
  position: "left" | "right";
  children: ReactNode;
  button?: undefined;
  iconButton: IconButtonProps;
}

export function Dropdown({
  position,
  button,
  iconButton,
  children,
}: ButtonDropdownProps | IconDropdownProps) {
  return (
    <Menu as="div" className="relative ml-3 inline-block text-left">
      {({ open }) => (
        <>
          <div
            onClick={(event: any) => {
              event.stopPropagation();
            }}
          >
            {button && <DropDownButton open={open} button={button} />}
            {iconButton && <DropDownIconButton {...iconButton} />}
          </div>

          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Menu.Items
              className={classNames(
                "absolute z-10 mt-2 w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-black ring-opacity-5 focus:outline-none",
                {
                  "right-0 origin-top-right": position === "left",
                  "left-0 origin-top-left": position === "right",
                }
              )}
              role="menu"
              aria-orientation="vertical"
            >
              <div className="py-1">{children}</div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

interface DropDownButtonProps {
  button: ButtonProps;
  open: boolean;
}
function DropDownButton({
  open,
  button: { children: buttonChildren, variant, ...buttonProps },
}: DropDownButtonProps) {
  const buttonElt = (
    <CoreButton
      {...buttonProps}
      variant={variant ?? "transparent"}
      type="button"
    >
      <span className="text-gray-700">{buttonChildren}</span>
      <ChevronDownOutline
        className={classNames("-mr-1 ml-2 h-5 w-5 transition-transform", {
          "rotate-180": open,
        })}
        aria-hidden="true"
      />
    </CoreButton>
  );
  return buttonProps.disabled ? (
    buttonElt
  ) : (
    <Menu.Button as="div">{buttonElt}</Menu.Button>
  );
}

function DropDownIconButton({ icon, disabled, title }: IconButtonProps) {
  const buttonElt = (
    <IconButton
      icon={icon}
      variant="transparent"
      size="small"
      disabled={disabled}
      title={title}
    />
  );
  return disabled ? buttonElt : <Menu.Button as="div">{buttonElt}</Menu.Button>;
}
