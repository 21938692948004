import { Avatar } from "@components/Avatar";
import { Client } from "@justplayfair/model";

interface AuthenticatedUserProps {
  user: Client.MeUserFieldsFragment;
}

export function AuthenticatedUser({ user }: AuthenticatedUserProps) {
  return (
    <div className="flex">
      <div className="flex-shrink-0">
        {user && <Avatar size="small" entity={user} />}
      </div>
      <div className="ml-3">
        <div className="text-base font-medium leading-none text-white">
          {user?.name}
        </div>
        <div className="text-sm font-medium leading-none text-gray-400">
          {user?.email}
        </div>
      </div>
    </div>
  );
}
