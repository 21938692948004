import { Card } from "@components/Card";
import { Comment } from "@components/Comment";
import { PageContainer } from "@components/Layout";
import { Loader } from "@components/Loader";
import { Title } from "@components/Title";
import { Client } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAuthenticatedUser } from "../../../../hooks/authenticatedUser/useAuthenticatedUser.hook";
import { EvaluationQrCodeCard } from "./components/EvaluationQrCode.component";
import { SessionDescriptionTitle } from "./components/SessionDescriptionTitle.component";
import { SessionEvaluation } from "./SessionEvaluation/SessionEvaluation.component";

export function TraineeSessionPage() {
  const { t } = useTranslation();

  const { sessionId } = useParams<{ sessionId: string }>();

  const { user } = useAuthenticatedUser();

  const [{ data, fetching }] = Client.useGetSessionQuery({
    requestPolicy: "network-only",
    variables: { id: sessionId },
  });

  if (fetching) {
    return (
      <PageContainer title={t("trainer.dashboard.title.session")}>
        <Loader size="medium" />
      </PageContainer>
    );
  }

  if (!data?.getSession) {
    return (
      <PageContainer title={t("trainer.dashboard.title.session")}>
        <Title size={6}>Introuvable</Title>
      </PageContainer>
    );
  }

  return (
    <PageContainer
      title={<SessionDescriptionTitle session={data.getSession} />}
      backButton
    >
      <div className="space-y-8">
        {user?.company?.id && (
          <div className="flex justify-center">
            <EvaluationQrCodeCard companyId={user.company.id} />
          </div>
        )}
        <SectionComment
          sessionComment={data.getSession.sessionComment?.value || undefined}
        />
        <SessionEvaluation session={data.getSession} />
      </div>
    </PageContainer>
  );
}

interface SessionCommentProps {
  sessionComment?: string;
}
export function SectionComment({ sessionComment }: SessionCommentProps) {
  const { t } = useTranslation();

  return (
    <Card>
      <div className="px-8 py-8 space-y-4">
        <div className="flex w-full justify-between">
          <div>
            <Title size={4}>{t("trainer.session.title.sessionComment")}</Title>
          </div>
        </div>
        <Comment
          editable={false}
          content={sessionComment}
          fallbackMessage={t("trainer.session.label.noSessionComment")}
        />
      </div>
    </Card>
  );
}
