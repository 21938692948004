import { Checkbox } from "@components/form/Checkbox";
import { ID } from "@justplayfair/model";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChartFilters, FiltersValue } from "./ChartFilters.component";
import { DisplayedFilters } from "./ChartFilters.model";

interface GroupFiltersProps {
  companyId: ID;
  programId: ID;
  nbMembersInGroup1?: number;
  nbMembersInGroup2?: number;

  setGroup1Filters: (values: FiltersValue) => void;
  setGroup2Filters: (values: FiltersValue) => void;
}

export function ProgramFilters({
  companyId,
  programId,
  nbMembersInGroup1,
  nbMembersInGroup2,
  setGroup1Filters,
  setGroup2Filters,
}: GroupFiltersProps) {
  const { t } = useTranslation();

  const [compareGroups, setCompareGroups] = useState(false);

  useEffect(() => {
    if (!compareGroups) {
      setGroup2Filters({});
    } else {
      setGroup2Filters({ compareToCompany: true });
    }
  }, [compareGroups, setGroup2Filters]);

  return (
    <div className="space-y-4">
      <div className="bg-gray-50 rounded-md p-4">
        <GroupFiltersTitle
          title="Groupe 1"
          description={
            nbMembersInGroup1 !== undefined
              ? `(${nbMembersInGroup1} ${t("consult.text.persons")})`
              : undefined
          }
        />

        <ChartFilters
          forCompanyId={companyId}
          forProgramId={programId}
          activatedFields={{
            departmentLabelId: true,
            hierarchicalPositionLabelId: true,
            seniorityLabelId: true,
            businessUnitLabelId: true,
            date: false,
            firstSession: true,
            compareToCompany: false,
            comparisonTraineeId: false,
            comparisonSessionId: false,
          }}
          defaultDisplayedFilters={DisplayedFilters.GROUP}
          onFiltersChange={(filters) => {
            setGroup1Filters(filters);
          }}
        />
      </div>

      <div className="flex space-x-4 items-start pl-2">
        <div className="pt-1">
          <Checkbox
            id="compareGroups"
            checked={compareGroups}
            onChange={(event) => setCompareGroups(event.target.checked)}
          />
        </div>
        <label htmlFor="compareGroups" className="text-sm">
          <div className="text-gray-900">
            {t("consult.filter.compare")} <b>Groupe 1</b>{" "}
            {t("consult.filter.to")} <b>Groupe 2</b>
          </div>
          <div className="text-gray-500">
            {t("consult.filter.selectMembers")}
          </div>
        </label>
      </div>
      {compareGroups && (
        <div className="bg-gray-50 rounded-md p-4">
          <GroupFiltersTitle
            title="Groupe 2"
            description={
              nbMembersInGroup2 !== undefined
                ? `(${nbMembersInGroup2} ${t("consult.text.persons")})`
                : undefined
            }
          />

          <ChartFilters
            forCompanyId={companyId}
            forProgramId={programId}
            activatedFields={{
              departmentLabelId: true,
              hierarchicalPositionLabelId: true,
              seniorityLabelId: true,
              businessUnitLabelId: true,
              date: false,
              firstSession: true,
              compareToCompany: true,
              comparisonTraineeId: false,
              comparisonSessionId: false,
            }}
            onFiltersChange={(filters) => {
              setGroup2Filters(filters);
            }}
          />
        </div>
      )}
    </div>
  );
}

interface GroupFiltersTitleProps {
  title: string;
  description?: string;
}
function GroupFiltersTitle({ title, description }: GroupFiltersTitleProps) {
  return (
    <div className="flex space-x-2">
      <span className="text-gray-900 font-semibold">{title}</span>
      <span className="text-gray-600">{description}</span>
    </div>
  );
}
