import React from "react";
import ReactDOM from "react-dom";
import ReactModal from "react-modal";

import App from "./App";
import { initDates } from "./date/date.utilities";
import { configurei18n } from "./i18n";

import "./styles/tailwind.css";

ReactModal.setAppElement("#root");
configurei18n();
initDates("fr");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
