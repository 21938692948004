import { QuestionMarkCircleOutline } from "@graywolfai/react-heroicons";
import { useAuthenticatedUser } from "@hooks/authenticatedUser/useAuthenticatedUser.hook";
import { Client } from "@justplayfair/model";
import { authService } from "@services/auth";
import { fileService } from "@services/file/file.service";
import classNames from "clsx";
import { Theme } from "../Layout.model";

function getPrimaryRole(
  user?: Client.MeUserFieldsFragment
): Client.Role | undefined {
  if (!user) {
    return undefined;
  }
  if (authService.hasSomeRoles("MANAGER")) {
    return "MANAGER";
  }
  if (authService.hasSomeRoles("TRAINEE")) {
    return "TRAINEE";
  }
}

interface GetPrimaryRoleProps {
  theme: Theme;
}
export function GuideButton({ theme }: GetPrimaryRoleProps) {
  const { user } = useAuthenticatedUser();
  const primaryRole = getPrimaryRole(user);

  const [{ data }] = Client.useGetUserGuideQuery({
    pause: !primaryRole,
    variables: primaryRole ? { role: primaryRole } : undefined,
  });

  const guideMedia = data?.getUserGuide;
  if (!guideMedia) {
    return null;
  }
  const iconLinkClassnames = classNames(
    "p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white",
    {
      "focus:ring-offset-gray-800": theme === "dark",
      "focus:ring-offset-blue-800": theme === "blue",
    }
  );

  async function handleDownloadGuide({
    storageFileId,
    name,
  }: Client.FileFieldsFragment) {
    await fileService.downloadFile(storageFileId, name);
  }

  return (
    <button
      className={iconLinkClassnames}
      title="Guide"
      onClick={() => handleDownloadGuide(guideMedia)}
    >
      <span className="sr-only">Download guide</span>
      <QuestionMarkCircleOutline className="h-6 w-6" />
    </button>
  );
}
