"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateGroupReportQuery = exports.GroupReportQuerySchema = exports.validateTraineeReportQuery = exports.TraineeReportQuerySchema = exports.TraineeArgsSchema = exports.GroupArgsSchema = void 0;
const Yup = require("yup");
const DateFilterSchema = Yup.object({
    type: Yup.mixed().oneOf(["date", "first", "last"]).required(),
    date: Yup.date(),
});
const ComparisonSchema = Yup.object({
    programId: Yup.string().required(),
    withCompanyStats: Yup.boolean(),
    labelIds: Yup.array().of(Yup.string().defined()).notRequired(),
    traineeId: Yup.string(),
    sessionId: Yup.string(),
    date: DateFilterSchema,
});
exports.GroupArgsSchema = Yup.object({
    programId: Yup.string().required(),
    labelIds: Yup.array()
        .of(Yup.string().defined())
        .notRequired()
        .default(undefined),
    date: DateFilterSchema,
    comparison: ComparisonSchema.notRequired().default(undefined),
});
exports.TraineeArgsSchema = Yup.object({
    programId: Yup.string().required(),
    traineeId: Yup.string().required(),
    sessionId: Yup.string(),
    comparison: ComparisonSchema.notRequired().default(undefined),
});
exports.TraineeReportQuerySchema = Yup.object({
    traineeArgs: exports.TraineeArgsSchema.required("Trainee args mandatory"),
    groupArgs: exports.GroupArgsSchema.notRequired().default(undefined),
    debug: Yup.boolean(),
}).required();
function validateTraineeReportQuery(body) {
    const traineeReportQuery = exports.TraineeReportQuerySchema.validateSync(body, {
        abortEarly: false,
    });
    return traineeReportQuery;
}
exports.validateTraineeReportQuery = validateTraineeReportQuery;
exports.GroupReportQuerySchema = Yup.object()
    .shape({
    groupArgs: exports.GroupArgsSchema.required("Groups args is mandatory"),
    debug: Yup.boolean(),
})
    .required();
function validateGroupReportQuery(body) {
    const groupReportQuery = exports.GroupReportQuerySchema.validateSync(body, {
        abortEarly: false,
    }); // Issue with "strictFunctionTypes"
    return groupReportQuery;
}
exports.validateGroupReportQuery = validateGroupReportQuery;
