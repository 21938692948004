import { Chip } from "@components/Chip";
import { Loader } from "@components/Loader";
import {
  Table,
  TableCell,
  TableContainer,
  TableHeading,
} from "@components/Table/Table.component";
import { Client, ID, NumberUtilities } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

export function CompanyProgramsTabContent() {
  const { t } = useTranslation();
  const history = useHistory();

  const { companyId } = useParams<{
    companyId: string;
  }>();

  const [{ data, fetching }] = Client.useCompanyDashboardQuery({
    variables: { companyId },
    requestPolicy: "cache-only",
  });

  function handleClickProgram(programId: ID) {
    history.push(`/trainer/program/dashboard/${programId}`);
  }
  if (fetching) {
    return <Loader size="medium" />;
  }
  return (
    <div className="space-y-4">
      <TableContainer>
        <Table
          heading={
            <>
              <TableHeading align="left" width="30%">
                <span>
                  {t("trainer.dashboard.company.programs.heading.name")}
                </span>
              </TableHeading>
              <TableHeading align="center" width="15%">
                <span>
                  {t("trainer.dashboard.company.programs.heading.type")}
                </span>
              </TableHeading>
              <TableHeading align="center" width="15%">
                <span>
                  {t("trainer.dashboard.company.programs.heading.nbMembers")}
                </span>
              </TableHeading>
              <TableHeading align="center" width="15%">
                <span>
                  {t("trainer.dashboard.company.programs.heading.progression")}
                </span>
              </TableHeading>
            </>
          }
          testId="CompanyProgramTable"
        >
          {data?.companiesPrograms.length === 0 && (
            <tr>
              <td className="p-4 text-sm text-gray-700" colSpan={4}>
                {t("common.text.noData")}
              </td>
            </tr>
          )}
          {data &&
            data.companiesPrograms.length > 0 &&
            data.companiesPrograms.map((program) => (
              <tr
                key={program.id}
                onClick={() => {
                  handleClickProgram(program.id);
                }}
                className="transition hover:bg-gray-50 cursor-pointer"
              >
                <TableCell align="left">{program.name}</TableCell>
                <TableCell align="center">
                  <Chip
                    color="transparent"
                    label={t(`common.programType.${program.type}`)}
                  />
                </TableCell>
                <TableCell align="center">{program.nbTrainees}</TableCell>
                <TableCell align="center">
                  {program.type === "ASSESSMENT" &&
                    program.assessmentAchievement && (
                      <span
                        title={t(
                          "trainer.dashboard.title.assessmentAchievement"
                        )}
                      >
                        {NumberUtilities.toPercentage(
                          program.assessmentAchievement
                        )}
                        %
                      </span>
                    )}
                  {program.type === "TRAINING" &&
                    program.targetedLevelsAchievement && (
                      <span
                        title={t(
                          "trainer.dashboard.title.targetedLevelsAchievement"
                        )}
                      >
                        {NumberUtilities.toPercentage(
                          program.targetedLevelsAchievement
                        )}
                        %
                      </span>
                    )}
                </TableCell>
              </tr>
            ))}
        </Table>
      </TableContainer>
    </div>
  );
}
