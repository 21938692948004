import { Button } from "@components/Button";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface FormActionsProps {
  isEdit?: boolean;
  fetchingUpdate?: boolean;
  handleReset: VoidFunction;
  customActions?: ReactNode;
  setIsEdit?: (isEdit: boolean) => void;
  onDelete?: () => Promise<void>;
}
export function FormActions({
  isEdit,
  fetchingUpdate,
  handleReset,
  customActions,
  setIsEdit,
  onDelete,
}: FormActionsProps) {
  const { t } = useTranslation();
  return (
    <div className="flex justify-end">
      <div className="flex space-x-4">
        {(!isEdit || !setIsEdit) && (
          <>
            {onDelete && (
              <div className="w-32">
                <Button
                  title="Delete"
                  variant="warning"
                  loading={fetchingUpdate}
                  onClick={() => onDelete()}
                >
                  {t("common.button.delete.label")}
                </Button>
              </div>
            )}
            {customActions}
            {setIsEdit && (
              <div className="w-32">
                <Button title="Editer" onClick={() => setIsEdit(!isEdit)}>
                  {t("common.button.edit.label")}
                </Button>
              </div>
            )}
          </>
        )}

        {isEdit && setIsEdit && (
          <div className="w-32">
            <Button
              title="Annuler"
              variant="warning"
              onClick={() => {
                handleReset();
                setIsEdit(!isEdit);
              }}
            >
              {t("common.button.cancel.label")}
            </Button>
          </div>
        )}
        {(isEdit || !setIsEdit) && (
          <div className="w-32">
            <Button title="Save" loading={fetchingUpdate} type="submit">
              {t("common.button.save.label")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
