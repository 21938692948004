import { DangerousHtml } from "@components/DangerousHtml";
import { Loader } from "@components/Loader";
import {
  textDeltaToHtml,
  TextEditor,
} from "@components/TextEditor/TextEditor.component";
import Delta from "quill-delta";
import { useState } from "react";
import { useDebounce } from "react-use";
import { isEmptyEditorValue } from "../../html/editor.utilities";

interface CommentProps {
  editable: boolean;
  content?: string;
  placeholder?: string;
  fallbackMessage: string;
  loading?: boolean;
  onValueChange?: (htmlValue?: string) => void;
}
export function Comment({
  editable,
  content,
  placeholder,
  fallbackMessage,
  loading,
  onValueChange,
}: CommentProps) {
  const [value, setValue] = useState<Delta>();
  useDebounce(
    () => {
      if (editable && onValueChange) {
        onValueChange(value ? textDeltaToHtml(value) : undefined);
      }
    },
    800,
    [value]
  );

  return (
    <div>
      <div className="h-44">
        {!loading && editable && (
          <TextEditor
            content={
              placeholder && isEmptyEditorValue(placeholder, content)
                ? placeholder
                : content
            }
            onChange={(val) => setValue(val)}
          />
        )}
        {!loading && content && !editable && (
          <div className="text-gray-700 h-full overflow-y-auto">
            <DangerousHtml html={content} />
          </div>
        )}
        {!loading && !content && !editable && (
          <div className="text-gray-500">{fallbackMessage}</div>
        )}
      </div>
      {loading && <Loader size="medium" />}
    </div>
  );
}
