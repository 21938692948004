import { FormikDatePicker } from "@components/form/DatePicker/FormikDatePicker";
import { FormikInput } from "@components/form/Input/FormikInput";
import { FormikSelect } from "@components/form/Select/FormikSelect";
import { Title } from "@components/Title";
import { Client } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import { SESSION_RECCURENCE_OPTIONS } from "../../../../model/Session.model";

interface AppointmentFormFieldsProps {
  values: {
    startDate?: Date;
    recurrence?: Client.SessionRecurrenceRepeat;
    sessionStatus?: Client.SessionStatus;
  };
}
export function AppointmentFormFields({ values }: AppointmentFormFieldsProps) {
  const { t } = useTranslation();
  return (
    <div>
      <Title size={5}>{t("admin.session.title.appointment")}</Title>

      <FormikDatePicker
        editMode={
          values.sessionStatus
            ? !["DONE", "EVALUATED"].includes(values.sessionStatus)
            : true
        }
        id="startDate"
        showTimeSelect
        timeIntervals={15}
        timeCaption={t("component.datePicker.label.timeCaption")}
        timeFormat="HH:mm"
        dateFormat="dd/MM/yyyy HH:mm"
      />
      {!!values.startDate && (
        <FormikSelect
          editMode={true}
          id="recurrence"
          options={SESSION_RECCURENCE_OPTIONS}
          renderValue={(value) =>
            t(`admin.session.field.reccurence.value.${value}`)
          }
          multi={false}
          withSeparator={false}
        />
      )}
      {values.recurrence &&
        ["EVERY_WEEK", "BI_MENSUAL"].includes(values.recurrence) && (
          <FormikInput editMode={true} id="recurrenceNb" type="number" />
        )}
    </div>
  );
}
