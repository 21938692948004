import {
  ChartSquareBarOutline,
  PlusOutline,
  XOutline,
} from "@graywolfai/react-heroicons";
import { Client, ID } from "@justplayfair/model";
import { FieldArray, useField } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../components/Button";
import { IconButton } from "../../../../../components/Button/IconButton.component";
import { CustomSelect } from "../../../../../components/form/Select";
import { SelectOption } from "../../../../../components/form/Select/Select.model";
import { Toggle } from "../../../../../components/form/Toggle/Toggle.component";
import { Loader } from "../../../../../components/Loader";
import { Title } from "../../../../../components/Title";
import { Tooltip } from "../../../../../components/Tooltip";
import { useExternalSessionMappings } from "../hooks/useExternalSessionMappings.hook";

interface FormikExternalSessionsFieldProps {
  id: string;
  edit: boolean;
}
export function FormikExternalSessionsField({
  id,
  edit,
}: FormikExternalSessionsFieldProps) {
  const { t } = useTranslation();

  const { fetching: fetchingSessionMapping, sessionMappingOpts } =
    useExternalSessionMappings();
  const [{ value: simulations }, , { setValue }] = useField<
    Client.ExternalSimulationInput[] | undefined
  >(id);

  if (fetchingSessionMapping) {
    return <Loader size="medium" />;
  }
  return (
    <div className="px-2 py-4 border rounded-sm">
      <Title size={6}>{t("common.session.simulations")}</Title>
      <FieldArray name={id}>
        {({ push: pushSimulation, remove: removeSimulation }) => (
          <div className="flex flex-col gap-y-2">
            <SimulationSelectField
              edit={edit}
              sessionMappingOpts={sessionMappingOpts}
              onAddSimulation={(simuId) =>
                pushSimulation({
                  externalSessionMappingId: simuId,
                  rank: (simulations?.length || 0) + 2,
                  withDebrief: false,
                })
              }
            />
            <div className="pl-4 space-y-2">
              {simulations?.map((simulation, index) => (
                <SimulationItem
                  key={simulation.externalSessionMappingId}
                  edit={edit}
                  externalSimulation={simulation}
                  sessionMappingOpts={sessionMappingOpts}
                  onRemoveSimulation={() => removeSimulation(index)}
                  setWithDebrief={(withDebrief) => {
                    setValue(
                      simulations.map((simu) =>
                        simu.externalSessionMappingId ===
                        simulation.externalSessionMappingId
                          ? { ...simu, withDebrief }
                          : simu
                      )
                    );
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
}

interface SimulationSelectFieldProps {
  edit: boolean;
  sessionMappingOpts: SelectOption<ID>[];
  onAddSimulation: (simulationId: ID) => void;
}
function SimulationSelectField({
  edit,
  sessionMappingOpts,
  onAddSimulation,
}: SimulationSelectFieldProps) {
  const [simuId, setSimuId] = useState("");

  const { t } = useTranslation();
  return (
    <div>
      <div className="flex items-center gap-x-4">
        <div className="w-56">
          <CustomSelect
            id="simulation-select"
            multi={false}
            options={sessionMappingOpts}
            value={simuId}
            onChange={(event) => setSimuId(event.currentTarget.value)}
            disabled={!edit}
          />
        </div>
        {edit && (
          <div>
            <Button
              postIcon={PlusOutline}
              onClick={() => {
                onAddSimulation(simuId);
              }}
              title="Add simulation"
              variant="transparent"
            >
              {t("admin.session.button.add")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

interface SimulationItemProps {
  edit: boolean;
  externalSimulation: Client.ExternalSimulationInput;
  sessionMappingOpts: SelectOption<ID>[];
  onRemoveSimulation: VoidFunction;
  setWithDebrief: (activate: boolean) => void;
}
function SimulationItem({
  edit,
  externalSimulation,
  sessionMappingOpts,
  onRemoveSimulation,
  setWithDebrief,
}: SimulationItemProps) {
  const { t } = useTranslation();

  const sessionMappingOpt = sessionMappingOpts.find(
    (opt) => opt.value === externalSimulation.externalSessionMappingId
  );
  if (!sessionMappingOpt) {
    throw new Error("sessionMappingOpt is required");
  }
  return (
    <div className="flex gap-x-2 ">
      <ChartSquareBarOutline className="w-6 h-6 text-gray-400" />
      <span>{sessionMappingOpt.label}</span>
      <div className="px-2">
        <WithTooltipToggle
          id={`${externalSimulation.externalSessionMappingId}-withDebrief`}
          checked={externalSimulation.withDebrief}
          onChange={setWithDebrief}
          tooltip={t("admin.session.label.activateDebrief")}
        />
      </div>
      {edit && (
        <IconButton
          icon={XOutline}
          variant="transparent"
          title="Delete"
          size="small"
          onClick={() => onRemoveSimulation()}
        />
      )}
    </div>
  );
}

interface WithTooltipToggleProps {
  id: string;
  checked: boolean;
  tooltip: string;
  onChange: (checked: boolean) => void;
}
function WithTooltipToggle({
  id,
  checked,
  tooltip,
  onChange,
}: WithTooltipToggleProps) {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  return (
    <div ref={setReferenceElement}>
      <Toggle id={id} checked={checked} onChange={onChange} />
      <Tooltip referenceElement={referenceElement} label={tooltip} />
    </div>
  );
}
