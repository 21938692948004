import { History, Location } from "history";
import { REDIRECT_SEARCH_KEY } from "../../../services/auth/auth.service";

export function afterAuthentRedirect(history: History, location: Location) {
  const search = new URLSearchParams(location.search);

  const redirectPath = search.get(REDIRECT_SEARCH_KEY);
  if (redirectPath) {
    history.push(redirectPath);
  } else {
    history.push("/dashboard");
  }
}
