import { SelectOption } from "@components/form/Select/Select.model";
import { ProgressTimelineSession } from "@components/ProgressBar/ProgressTimeline.component";
import { Evaluation, ID, Maybe } from "@justplayfair/model";
import { isBefore } from "date-fns";
import { SESSION_STATUS_TO_STEP_STATUS } from "./TimelineModel";
type SortResult = -1 | 0 | 1;

export const SESSION_DURATION_OPTIONS: SelectOption<string>[] = [
  { value: "TWENTY" },
  { value: "THIRTY" },
  { value: "FORTYFIVE" },
  { value: "NINETY" },
];

export const SESSION_RECCURENCE_OPTIONS: SelectOption<string>[] = [
  { value: "ONCE" },
  { value: "EVERY_WEEK" },
  { value: "BI_MENSUAL" },
];

export const SESSION_TYPE_OPTIONS: SelectOption<string>[] = [
  { value: "WITH_TRAINER" },
  { value: "ONLINE_ASSESSMENT" },
];

export interface SortableSessionArg {
  id: ID;
  name: string;
  status?: Maybe<Evaluation.SessionStatus>;
  appointment?: Maybe<{
    startDate: Date;
  }>;
  evaluationDate?: Maybe<Date>;
}

export function sessionToTimelineSessionMapper(
  session: SortableSessionArg
): ProgressTimelineSession {
  const status = session.status
    ? SESSION_STATUS_TO_STEP_STATUS[session.status]
    : "todo";
  if (status === "planned") {
    if (!session.appointment?.startDate) {
      throw new Error("A planned session must have an appointment date");
    }
    return {
      id: session.id,
      name: session.name,
      status: "planned",
      appointmentDate: new Date(session.appointment.startDate),
      evaluationDate: undefined,
    };
  }
  if (status === "completed") {
    if (!session.appointment?.startDate || !session.evaluationDate) {
      throw new Error(
        "A planned session must have an appointment and evaluation dates"
      );
    }
    return {
      id: session.id,
      name: session.name,
      status: "completed",
      appointmentDate: new Date(session.appointment.startDate),
      evaluationDate: new Date(session.evaluationDate),
    };
  }
  return {
    id: session.id,
    name: session.name,
    status: "todo",
    appointmentDate: undefined,
    evaluationDate: undefined,
  };
}

export function timelineSessionSorter(
  left: SortableSessionArg,
  right: SortableSessionArg
): SortResult {
  if (left.evaluationDate && right.evaluationDate) {
    return isBefore(
      new Date(left.evaluationDate),
      new Date(right.evaluationDate)
    )
      ? -1
      : 1;
  }

  if (left.evaluationDate && !right.evaluationDate) {
    return -1;
  }

  if (!left.evaluationDate && right.evaluationDate) {
    return 1;
  }

  return sortSessionByAppointmentDate(left, right);
}

export function sortSessionByAppointmentDate(
  left: SortableSessionArg,
  right: SortableSessionArg
): SortResult {
  if (!left.appointment && !right.appointment) {
    return 0;
  }
  if (left.appointment && !right.appointment) {
    return -1;
  }
  if (!left.appointment && right.appointment) {
    return 1;
  }
  if (left.appointment && right.appointment) {
    return isBefore(
      new Date(left.appointment.startDate),
      new Date(right.appointment.startDate)
    )
      ? -1
      : 1;
  }
  return 0;
}
