import { makeAutoObservable } from "mobx";
import md5 from "md5";

import { AlertConfig } from "../../model/Alert.model";

const DEFAULT_ALERT: Omit<AlertConfig, "id"> = {
  type: "error",
  title: "Une erreur s'est produite",
  description: "Pas d'inquiètude, veuillez re-tenter votre dernière action",
};
class AlertService {
  private _alerts = new Map<string, AlertConfig>();

  constructor() {
    makeAutoObservable(this);
  }
  get alerts(): ReadonlyArray<AlertConfig> {
    return Array.from(this._alerts.values());
  }

  addDefaultAlert() {
    this.addAlert(DEFAULT_ALERT);
  }

  addAlert(config: Omit<AlertConfig, "id">) {
    const id = md5(
      `${config.title}-${config.type}-${config.description}`
    ).toString();

    if (!Array.from(this._alerts.values()).some((alert) => alert.id === id)) {
      this._alerts.set(id, { id, ...config });
      window.setTimeout(() => {
        this.dismissAlert(id);
      }, config.duration || 5000);
    }
  }

  dismissAlert(id: string) {
    this._alerts.delete(id);
  }
}

export const alertService = new AlertService();
