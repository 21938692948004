import { TraineeSessionStatus } from "@components/TraineeSessionStatus";
import { Client, ID, Maybe } from "@justplayfair/model";
import { LargeUserDescription } from "./LargeUserDescription.component";

interface UserDescriptionTitleProps {
  user: Client.UserDetailsFieldsFragment;
  programId?: ID;
  advancement?: Maybe<Client.Advancement>;
}
export function UserDescriptionTitle({
  user,
  programId,
  advancement,
}: UserDescriptionTitleProps) {
  return (
    <div className="w-full p-2 lg:flex lg:justify-between lg:items-center space-y-8 lg:space-y-0 lg:p-4">
      <LargeUserDescription user={user} programId={programId} />

      <TraineeSessionStatus advancement={advancement} />
    </div>
  );
}
