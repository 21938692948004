import { BGButtonProps, ButtonGroup } from "@components/ButtonGroup";
import { FormikChangeListener } from "@components/form/changeListener/FormikChangeListener.component";
import { FormikDatePicker } from "@components/form/DatePicker/FormikDatePicker";
import { FieldContainer } from "@components/form/FieldContainer";
import { FormikSelect } from "@components/form/Select/FormikSelect";
import { FormikToggle } from "@components/form/Toggle/FormikToggle";
import { Loader } from "@components/Loader";
import { Client, formatToLocalDate, ID } from "@justplayfair/model";
import { Form, Formik } from "formik";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toSearchEntries } from "../../../search/search.utilities";
import {
  ActivatedFields,
  createLabelOpts,
  DEFAULT_FORM_VALUES,
  DisplayedFilters,
  FormFields,
} from "./ChartFilters.model";

export interface FiltersValue {
  labelIds?: ID[];
  compareToCompany?: boolean;
  firstSession?: boolean;
  comparisonTraineeId?: ID;
  comparisonSessionId?: ID;
  date?: Date;
}

export function isFiltered({
  labelIds,
  compareToCompany,
  comparisonTraineeId,
  comparisonSessionId,
  firstSession,
  date,
}: FiltersValue): boolean {
  return (
    (!!labelIds && labelIds.length > 0) ||
    compareToCompany ||
    firstSession ||
    !!comparisonTraineeId ||
    !!comparisonSessionId ||
    !!date
  );
}

interface ChartFiltersProps {
  forCompanyId: ID;
  forProgramId: ID;
  forTraineeId?: ID;
  activatedFields: ActivatedFields;
  defaultDisplayedFilters?: DisplayedFilters;
  onFiltersChange: (filters: FiltersValue) => void;
}
export function ChartFilters({
  forCompanyId,
  forProgramId,
  forTraineeId,
  activatedFields,
  defaultDisplayedFilters = DisplayedFilters.COMPANY,
  onFiltersChange,
}: ChartFiltersProps) {
  const { t } = useTranslation();

  const [displayedFilters, setDisplayedFilters] = useState<DisplayedFilters>(
    defaultDisplayedFilters
  );

  const [{ data: labels }] = Client.useSearchLabelsQuery({
    variables: {
      search: {
        filters: {
          deleted: false,
          query: new URLSearchParams(
            toSearchEntries({
              companyId: forCompanyId,
              categories: [
                "HIERARCHICAL_POSITION",
                "SENIORITY",
                "DEPARTMENT",
                "BUSINESS_UNIT",
              ],
            })
          ).toString(),
        },
        skip: 0,
        take: 1000,
        sort: {
          name: "name",
          order: "asc",
        },
      },
    },
  });

  const [{ fetching: fetchingSessions, data: sessionsData }] =
    Client.useSessionListQuery({
      pause: !activatedFields.comparisonSessionId,
      variables: {
        search: {
          take: 10000,
          skip: 0,
          filters: {
            deleted: false,
            query: new URLSearchParams(
              toSearchEntries({
                programIds: [forProgramId],
                traineeIds: [forTraineeId],
                status: ["EVALUATED"],
              })
            ).toString(),
          },
          sort: {
            name: "evaluationDate",
            order: "asc",
          },
        },
      },
    });
  const sessionOpts = useMemo(() => {
    if (!sessionsData || !sessionsData.searchSessions.data.length) {
      return [];
    }

    return sessionsData.searchSessions.data.map((session, index) => ({
      value: session.id,
      label: `Data ${index + 1} - ${formatToLocalDate(
        new Date(session.evaluationDate)
      )}`,
    }));
  }, [sessionsData]);

  const [{ fetching: fetchingTrainees, data: trainees }] =
    Client.useUserListQuery({
      pause: activatedFields.comparisonTraineeId === false,
      variables: {
        search: {
          filters: {
            query: new URLSearchParams(
              toSearchEntries({
                companyId: forCompanyId,
                programId: forProgramId,
                roles: ["TRAINEE"],
              })
            ).toString(),
          },
          sort: { name: "name", order: "asc" },
          skip: 0,
          take: 1000,
        },
      },
    });

  const hierarchicalPositionsOpts = useMemo(() => {
    if (!labels || !labels.searchLabels.data.length) {
      return [];
    }
    return createLabelOpts(labels.searchLabels.data, "HIERARCHICAL_POSITION");
  }, [labels]);

  const seniorityOpts = useMemo(() => {
    if (!labels || !labels.searchLabels.data.length) {
      return [];
    }
    return createLabelOpts(labels.searchLabels.data, "SENIORITY");
  }, [labels]);

  const departmentOpts = useMemo(() => {
    if (!labels || !labels.searchLabels.data.length) {
      return [];
    }

    return createLabelOpts(labels.searchLabels.data, "DEPARTMENT");
  }, [labels]);

  const businessUnitOpts = useMemo(() => {
    if (!labels || !labels.searchLabels.data.length) {
      return [];
    }

    return createLabelOpts(labels.searchLabels.data, "BUSINESS_UNIT");
  }, [labels]);

  const traineeOpts = useMemo(() => {
    if (!trainees || !trainees.users.data.length) {
      return [];
    }

    return trainees.users.data
      .filter((trainee) => trainee.id !== forTraineeId)
      .map((trainee) => ({
        value: trainee.id,
        label: trainee.name,
      }));
  }, [trainees, forTraineeId]);

  function handleFormChanges({
    hierarchicalPositionLabelId,
    seniorityLabelId,
    departmentLabelId,
    businessUnitLabelId,
    date,
    firstSession,
    comparisonTraineeId,
    comparisonSessionId,
  }: FormFields) {
    switch (displayedFilters) {
      case DisplayedFilters.COMPANY:
        onFiltersChange({ compareToCompany: true });
        return;
      case DisplayedFilters.GROUP:
        onFiltersChange({
          date,
          firstSession,
          labelIds: [
            hierarchicalPositionLabelId,
            seniorityLabelId,
            departmentLabelId,
            businessUnitLabelId,
          ].filter((val): val is ID => val !== undefined && val.length > 0),
        });
        return;
      case DisplayedFilters.SESSION:
        onFiltersChange({
          comparisonSessionId,
        });
        return;
      case DisplayedFilters.TRAINEE:
        onFiltersChange({
          comparisonTraineeId,
        });
        return;
    }
  }

  function handleFilterChange(displayedFilter: DisplayedFilters) {
    setDisplayedFilters(displayedFilter);
    switch (displayedFilter) {
      case DisplayedFilters.COMPANY:
        onFiltersChange({ compareToCompany: true, labelIds: [] });
        return;
      default:
        onFiltersChange({ labelIds: [] });
        return;
    }
  }

  const isGroupFilterActivated =
    activatedFields.hierarchicalPositionLabelId ||
    activatedFields.departmentLabelId ||
    activatedFields.seniorityLabelId;

  return (
    <div className="flex items-center divide-x">
      <Formik
        initialValues={DEFAULT_FORM_VALUES}
        onSubmit={() => {
          // No need to sumbit
        }}
      >
        {({ resetForm }) => {
          const buttons = [
            activatedFields.compareToCompany
              ? {
                  label: t("consult.filter.allCompany"),
                  selected: displayedFilters === DisplayedFilters.COMPANY,
                  onClick: () => {
                    resetForm();
                    handleFilterChange(DisplayedFilters.COMPANY);
                  },
                }
              : undefined,
            isGroupFilterActivated
              ? {
                  label: t("consult.filter.group"),
                  selected: displayedFilters === DisplayedFilters.GROUP,
                  onClick: () => {
                    resetForm();
                    handleFilterChange(DisplayedFilters.GROUP);
                  },
                }
              : undefined,

            activatedFields.comparisonTraineeId
              ? {
                  label: t("consult.filter.trainee"),
                  selected: displayedFilters === DisplayedFilters.TRAINEE,
                  onClick: () => {
                    resetForm();
                    handleFilterChange(DisplayedFilters.TRAINEE);
                  },
                }
              : undefined,
            activatedFields.comparisonSessionId
              ? {
                  label: t("consult.filter.session"),
                  selected: displayedFilters === DisplayedFilters.SESSION,
                  onClick: () => {
                    resetForm();
                    handleFilterChange(DisplayedFilters.SESSION);
                  },
                }
              : undefined,
          ].filter((buttonProps) => !!buttonProps) as BGButtonProps[];

          return (
            <div className="flex flex-col xl:flex-row justify-start items-center">
              {buttons.length > 1 && (
                <div className="w-full xl:w-auto">
                  <FieldContainer
                    id="displayedFilters"
                    labelPosition="top"
                    label={t("consult.filter.compareTo")}
                    withSeparator={false}
                  >
                    <ButtonGroup buttons={buttons} size="small" />
                  </FieldContainer>
                  <div className="h-4" />
                </div>
              )}

              <Form>
                <FormikChangeListener onChange={handleFormChanges} />

                {displayedFilters === DisplayedFilters.GROUP && (
                  <div className="flex items-center flex-wrap">
                    {activatedFields.hierarchicalPositionLabelId &&
                      hierarchicalPositionsOpts.length > 0 && (
                        <div className="w-52">
                          <FormikSelect
                            labelPosition="top"
                            editMode={true}
                            id="hierarchicalPositionLabelId"
                            multi={false}
                            label={t("consult.filter.hierarchicalPosition")}
                            options={hierarchicalPositionsOpts}
                          />
                        </div>
                      )}
                    {activatedFields.seniorityLabelId &&
                      seniorityOpts.length > 0 && (
                        <div className="w-52">
                          <FormikSelect
                            labelPosition="top"
                            editMode={true}
                            id="seniorityLabelId"
                            multi={false}
                            label={t("consult.filter.seniority")}
                            options={seniorityOpts}
                          />
                        </div>
                      )}
                    {activatedFields.departmentLabelId &&
                      departmentOpts.length > 0 && (
                        <div className="w-52">
                          <FormikSelect
                            labelPosition="top"
                            editMode={true}
                            id="departmentLabelId"
                            multi={false}
                            label={t("consult.filter.department")}
                            options={departmentOpts}
                          />
                        </div>
                      )}
                    {activatedFields.businessUnitLabelId &&
                      businessUnitOpts.length > 0 && (
                        <div className="w-64">
                          <FormikSelect
                            labelPosition="top"
                            editMode={true}
                            id="businessUnitLabelId"
                            multi={false}
                            label={t("consult.filter.businessUnit")}
                            options={businessUnitOpts}
                          />
                        </div>
                      )}
                    {activatedFields.date && (
                      <div className="w-52">
                        <FormikDatePicker
                          labelPosition="top"
                          editMode={true}
                          id="date"
                          label={t("consult.filter.date")}
                          isClearable
                        />
                      </div>
                    )}
                    {activatedFields.firstSession && (
                      <div className="w-46">
                        <FormikToggle
                          labelPosition="top"
                          editMode={true}
                          id="firstSession"
                          label={t("consult.filter.firstSession")}
                        />
                      </div>
                    )}
                  </div>
                )}
                {activatedFields.comparisonTraineeId &&
                  displayedFilters === DisplayedFilters.TRAINEE && (
                    <div className="w-72">
                      {fetchingTrainees && <Loader size="small" />}
                      {!fetchingTrainees && (
                        <FormikSelect
                          labelPosition="top"
                          editMode={true}
                          id="comparisonTraineeId"
                          multi={false}
                          label={t("consult.filter.trainee")}
                          options={traineeOpts}
                        />
                      )}
                    </div>
                  )}
                {activatedFields.comparisonSessionId &&
                  displayedFilters === DisplayedFilters.SESSION && (
                    <div className="w-72">
                      {fetchingSessions && <Loader size="small" />}
                      {!fetchingSessions && (
                        <FormikSelect
                          labelPosition="top"
                          editMode={true}
                          id="comparisonSessionId"
                          multi={false}
                          label={t("consult.filter.chooseSession")}
                          options={sessionOpts}
                        />
                      )}
                    </div>
                  )}
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}
