import { OtpCheckBody } from "@justplayfair/model";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AuthLayout } from "../../../components/AuthLayout";
import { authService } from "../../../services/auth";
import { afterAuthentRedirect } from "../utilities/auth.utilities";
import { OtpCheckForm } from "./OtpCheck.form";

export function OtpCheckPage() {
  const [loading, setLoading] = useState(false);
  const [errorKey, setErrorKey] = useState<undefined | "otpError">();
  const history = useHistory();
  const location = useLocation();

  async function handleCheckOtp(fields: OtpCheckBody) {
    setLoading(true);
    try {
      await authService.verifyOtp(fields);
      afterAuthentRedirect(history, location);
    } catch (error) {
      console.error(error);
      setErrorKey("otpError");
    } finally {
      setLoading(false);
    }
  }

  return (
    <AuthLayout
      titleKey="auth.otpCheck.title"
      errorKey={errorKey}
      loading={loading}
    >
      <OtpCheckForm onCheck={handleCheckOtp} />
    </AuthLayout>
  );
}
