import {
  AuthBody,
  AuthResponse,
  AuthTokenClaims,
  OtpCheckBody,
  User,
} from "@justplayfair/model";
import { makeAutoObservable } from "mobx";
import { browserHistory } from "../../history";
import { httpClient } from "../../http/client";
import { AuthenticatedUser } from "../../model/Auth.model";
import { getTokenStorage } from "./token.storage";

const AUTH_URL = process.env.REACT_APP_AUTH_URL;
if (!AUTH_URL) {
  throw new Error("mandatory env variable 'REACT_APP_AUTH_URL'");
}

export const REDIRECT_SEARCH_KEY = "redirect";

const CHECK_REFRESH_TOKEN_INTERVAL = 60 * 1000; // 1mn

const tokenStorage = getTokenStorage();

export class AuthService {
  private authenticatedUser: AuthenticatedUser | undefined;

  constructor() {
    try {
      // Try to access user from tokenStorage
      const token = tokenStorage.getToken();
      const user = tokenStorage.getDecodedToken();
      if (token && user) {
        this.authenticatedUser = {
          token,
          user,
        };
      }

      setInterval(
        (instance) => {
          if (instance.isAuthenticated && instance.isTokenOld) {
            this.refreshToken();
          }
        },
        CHECK_REFRESH_TOKEN_INTERVAL,
        this
      );
    } catch (error) {
      console.error(error);
      this.authenticatedUser = undefined;
      tokenStorage.unset();
    }
    makeAutoObservable(this);
  }

  get isAuthenticated(): boolean {
    return !!this.authenticatedUser;
  }

  get token(): string | null {
    return this.authenticatedUser?.token || null;
  }

  get tokenClaims(): AuthTokenClaims | null {
    return this.authenticatedUser?.user || null;
  }

  get isTokenOld() {
    if (this.authenticatedUser) {
      return tokenStorage.isTokenOld(this.authenticatedUser.token);
    }
    throw new Error("[isTokenOld] Can't check: No token");
  }

  hasSomeRoles(...roles: User.Role[]) {
    return this.authenticatedUser?.user.roles.some((userRole) =>
      roles.includes(userRole)
    );
  }

  async login(fields: AuthBody) {
    const response = await httpClient.post(`${AUTH_URL}/login`, {
      json: fields,
    });
    this.setTokenFromHeaders(response.headers);
    return response.json<AuthResponse>();
  }

  async logout(redirectPath?: string) {
    try {
      await httpClient.post(`${AUTH_URL}/logout`);
    } catch (error) {
      console.warn(error);
    }
    tokenStorage.unset();
    this.authenticatedUser = undefined;

    browserHistory.push(
      redirectPath
        ? `/login?${REDIRECT_SEARCH_KEY}=${encodeURIComponent(redirectPath)}`
        : "/login"
    );
  }

  async refreshToken() {
    const response = await httpClient.get(`${AUTH_URL}/refresh`);
    if (!response?.ok) {
      this.logout();
      return;
    }
    this.setTokenFromHeaders(response.headers);
  }

  async verifyOtp(fields: OtpCheckBody) {
    const response = await httpClient.post(`${AUTH_URL}/verify-otp`, {
      json: fields,
    });
    if (!response?.ok) {
      this.logout();
      return;
    }
  }

  private setTokenFromHeaders(headers: Headers) {
    const authorizationHeader = headers.get("Authorization");

    if (!authorizationHeader) {
      throw new Error(
        'Invalid login request. "Authorization" header is missing'
      );
    }
    const jwtToken = authorizationHeader.replace("Bearer ", "");
    tokenStorage.setToken(jwtToken);
    const user = tokenStorage.getDecodedToken();

    if (!user) {
      throw new Error("Invalid token claims");
    }
    this.authenticatedUser = {
      token: jwtToken,
      user,
    };
  }

  get authenticatedUserId() {
    const authenticatedUser = this.authenticatedUser;
    if (!authenticatedUser) {
      throw new Error("Cannot access to authenticated user :(");
    }
    return authenticatedUser.user.userId;
  }
}

export const authService = new AuthService();
