"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toPercentage = exports.isNumber = exports.round = void 0;
function round(value, decimal = 2) {
    const multi = Math.pow(10, decimal);
    return Math.round((value + Number.EPSILON) * multi) / multi;
}
exports.round = round;
function isNumber(value, float) {
    return float ? Number.isFinite(value) : Number.isInteger(value);
}
exports.isNumber = isNumber;
function toPercentage(value) {
    return Math.round(value * 100);
}
exports.toPercentage = toPercentage;
