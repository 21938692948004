import { Card } from "@components/Card";
import { FormActions } from "@components/form/FormActions";
import { FormikInput } from "@components/form/Input/FormikInput";
import { FormikSelect } from "@components/form/Select/FormikSelect";
import { Title } from "@components/Title";
import { Client, ID } from "@justplayfair/model";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";
import { FormikToggle } from "../../../../../components/form/Toggle/FormikToggle";
import { PROGRAM_TYPES_OPTIONS } from "../../../../../model/Company.model";
import { FormikProgramSkillsFields } from "../../components/FormikProgramSkillsFields.component";
import { FormikTargetedLevelFields } from "../../components/FormikTargetedLevelFields.component";

const UpdateGroupSchema: Yup.SchemaOf<Client.UpdateProgramInput> = Yup.object()
  .shape({
    name: Yup.string().required("admin.program.label.nameMandatory"),
    skillIds: Yup.array().of(Yup.string().required()),
    durationInMonths: Yup.mixed().when("type", {
      is: "TRAINING",
      then: Yup.number().required("admin.program.label.durationMandatory"),
    }),
  })
  .required();

interface EditGroupFormProps {
  isEdit: boolean;
  program: Client.ProgramFieldsFragment;
  targetedLevels?: Client.TargetedLevelFieldsFragment[];
  setIsEdit: (isEdit: boolean) => void;
  onEditProgram: (input: Client.UpdateProgramInput) => Promise<void>;
  onDeleteProgram: (programId: ID) => Promise<void>;
}

export function UpdateProgramForm({
  isEdit,
  program,
  targetedLevels,
  setIsEdit,
  onEditProgram,
  onDeleteProgram,
}: EditGroupFormProps) {
  const { t } = useTranslation();

  const [{ data: capacitiesData, fetching }] = Client.useListCapacitiesQuery();

  return (
    <div>
      <Formik
        validateOnChange={false} // performance enhancment
        initialValues={{
          name: program.name,
          skills: programToSkillInputsMapper(
            program.skills,
            program.programCapacityLabels ?? []
          ),
          type: program.type,
          durationInMonths: program.durationInMonths,
          targetedLevels:
            targetedLevels?.reduce((aggr, targetedLevel) => {
              return {
                ...aggr,
                [targetedLevel.skill.id]: targetedLevel.targetedRate,
              };
            }, {} as Record<ID, number>) ?? {},
          devMode: false,
        }}
        onSubmit={async ({ devMode, targetedLevels, skills, ...fields }) => {
          if (devMode) {
            devModeExternalMappingLogger({
              skills,
              capacities: capacitiesData?.listCapacities ?? [],
            });
          }
          const targetedLevelInputs = Object.entries(targetedLevels).map(
            ([skillId, targetedRate]) => ({
              skillId,
              targetedRate,
            })
          );

          const skillInputs: Client.ProgramSkillInput[] = skills.map(
            (skill) => ({
              ...skill,
              capacities: skill.capacities?.map(capacityInputMapper) ?? [],
            })
          );

          await onEditProgram({
            ...fields,
            skills: skillInputs,
            targetedLevels: targetedLevelInputs.length
              ? targetedLevelInputs
              : undefined,
          });
        }}
        validationSchema={UpdateGroupSchema}
      >
        {(props) => (
          <Form className="space-y-8">
            <Card rounded={false}>
              <div className="p-8">
                <FormikInput
                  editMode={isEdit}
                  id="name"
                  label={t("admin.program.label.name")}
                />
                <FormikSelect
                  editMode={isEdit}
                  id="type"
                  multi={false}
                  options={PROGRAM_TYPES_OPTIONS}
                  label={t("admin.program.label.type")}
                  renderValue={(val) => t(`common.programType.${val}`)}
                />
                {props.values.type === "TRAINING" && (
                  <FormikInput
                    editMode={isEdit}
                    id="durationInMonths"
                    label={t("admin.program.label.durationInMonths")}
                    type="number"
                  />
                )}
              </div>
            </Card>
            {process.env.NODE_ENV === "development" && isEdit && (
              <FormikToggle id="devMode" label="Dev mode" editMode />
            )}

            <Card rounded={false}>
              <div className="p-8">
                <Title size={6}>{t("admin.program.label.skills")}</Title>
                <FormikProgramSkillsFields
                  capacities={capacitiesData?.listCapacities ?? []}
                  fetching={fetching}
                  editMode={isEdit}
                  externalAssessment={props.values.devMode}
                />
              </div>
            </Card>

            {props.values.type === "TRAINING" && (
              <Card rounded={false}>
                <div className="p-8">
                  <div className="w-full mx-auto">
                    <Title size={6}>
                      {t("admin.user.title.targetedLevels")}
                    </Title>
                    <div className="grid grid-cols-2 gap-4">
                      <FormikTargetedLevelFields
                        editMode={isEdit}
                        fieldName="targetedLevels"
                      />
                    </div>
                  </div>
                </div>
              </Card>
            )}
            <FormActions
              isEdit={isEdit}
              handleReset={props.handleReset}
              onDelete={() => onDeleteProgram(program.id)}
              setIsEdit={setIsEdit}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

function programToSkillInputsMapper(
  skills: Client.ProgramFieldsFragment["skills"],
  programCapacityLabels: Client.CapacityLabel[]
): Client.ProgramSkillInput[] {
  return (
    skills?.map((skill) => ({
      id: skill.id,
      name: skill.name,
      capacities:
        skill.capacities?.map((capacity) => {
          const customLabel = programCapacityLabels.find(
            (capacityLabel) => capacityLabel.capacityId === capacity.id
          );
          return {
            id: capacity.id,
            label: customLabel
              ? { id: customLabel.id, labelValue: customLabel.labelValue }
              : undefined,
            externalId: "",
          };
        }) ?? [],
    })) ?? []
  );
}

function capacityInputMapper(capacity: Client.ProgramCapacityInput) {
  const label = capacity.label
    ? capacity.label.id
      ? capacity.label
      : { id: uuid(), labelValue: capacity.label.labelValue }
    : undefined;
  return {
    id: capacity.id,
    label,
  };
}

interface DevModeExternalMappingLoggerArgs {
  skills: Client.ProgramSkillInput[];
  capacities: Client.ListCapacitiesQuery["listCapacities"];
}
function devModeExternalMappingLogger({
  skills,
  capacities,
}: DevModeExternalMappingLoggerArgs) {
  const externalIdCapacityMapping = skills
    .flatMap((skill) => skill.capacities)
    .reduce((aggr, capacity) => {
      const dbCapacity = capacities.find(({ id }) => id === capacity.id);
      if (!dbCapacity) {
        throw new Error(`Capacity ${capacity.id} not found in capacities list`);
      }
      return { ...aggr, [dbCapacity.nameKey]: (capacity as any).externalId };
    }, {});

  console.log("[DEBUG]", { externalIdCapacityMapping });
}
