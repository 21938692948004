import { PageContainer } from "@components/Layout";
import { Loader } from "@components/Loader";
import { Tabs } from "@components/Tabs";
import { Title } from "@components/Title";
import { ClipboardListSolid, UsersSolid } from "@graywolfai/react-heroicons";
import { useAuthenticatedUser } from "@hooks/authenticatedUser/useAuthenticatedUser.hook";
import { Client } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router";
import { ClientMembersTabContent } from "./tabContents/ClientMembersTabContent.component";
import { ClientProgramsTabContent } from "./tabContents/ClientProgramsTabContent.component";

export function ClientDashboardPage() {
  const { t } = useTranslation();
  const { user, fetching: fetchingUser } = useAuthenticatedUser();
  const [{ data, fetching }] = Client.useCompanyDashboardQuery({
    pause: !user?.company?.id,
    variables: user?.company?.id
      ? {
          companyId: user?.company?.id,
        }
      : undefined,
    requestPolicy: "network-only",
  });

  if (fetchingUser || fetching) {
    return (
      <PageContainer title={t("client.dashboard.title.dashboard")}>
        <Loader size="medium" />
      </PageContainer>
    );
  }
  if (!data?.company) {
    return (
      <PageContainer title={t("client.dashboard.title.dashboard")}>
        <Title size={6}>Introuvable</Title>
      </PageContainer>
    );
  }
  return (
    <PageContainer title={data.company.name}>
      <div className="px-4 pb-8">
        <Tabs
          items={[
            {
              label: t("trainer.dashboard.tab.programs"),
              icon: ClipboardListSolid,
              link: `/client/dashboard`,
            },
            {
              label: t("trainer.dashboard.tab.members"),
              icon: UsersSolid,
              link: `/client/dashboard/company/tab/members`,
            },
          ]}
        />
      </div>
      <div className="px-8 space-y-8">
        <Switch>
          <Route
            path="/client/dashboard"
            exact
            component={ClientProgramsTabContent}
          />
          <Route
            path="/client/dashboard/company/tab/members"
            component={ClientMembersTabContent}
          />
        </Switch>
      </div>
    </PageContainer>
  );
}
