import { Button } from "@components/Button";
import { Comment } from "@components/Comment";
import { FormikDatePicker } from "@components/form/DatePicker/FormikDatePicker";
import { FormikInput } from "@components/form/Input/FormikInput";
import { Loader } from "@components/Loader";
import {
  Table,
  TableCell,
  TableHeading,
} from "@components/Table/Table.component";
import { Title } from "@components/Title";
import {
  Client,
  formatToLocalDate,
  parseJSONDate,
  DatavizMapper,
} from "@justplayfair/model";
import { Form, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TemplateVariables } from "src/html/editor.utilities";
import { useTraineeLastRates } from "./hooks/useTraineLastRates";
import {
  evaluationDataToInitialValues,
  evaluationRecordsToEvaluationData,
  FormValues,
  sanitizedRateValue,
} from "./SessionEvaluation.utilities";

interface SessionEvaluationFormProps {
  session: NonNullable<Client.GetSessionQuery["getSession"]>;
  isEdit: boolean;
  onEvaluationCompleted: VoidFunction;
}
export function SessionEvaluationForm({
  session,
  isEdit,
  onEvaluationCompleted,
}: SessionEvaluationFormProps) {
  const { t } = useTranslation();

  const [inSessionSkillIds, setInSessionSkillIds] = useState<string[]>([]);

  const [{ fetching: savingEvaluation }, evaluateSession] =
    Client.useEvaluateSessionMutation();
  const [{ fetching: savingComment }, saveEvaluationComment] =
    Client.useSaveEvaluationCommentMutation();
  const [{ fetching: savingEvaluationReview }, saveEvaluationReview] =
    Client.useSaveEvaluationReviewMutation();

  const { traineeLastRates } = useTraineeLastRates({
    pause: inSessionSkillIds?.length === 0,
    traineeId: session?.trainee?.id || "",
    skillIds: inSessionSkillIds,
    requestPolicy: "network-only",
  });

  useEffect(() => {
    const skillIds = session.skills?.map(({ id }) => id);
    if (skillIds) {
      setInSessionSkillIds(skillIds);
    }
  }, [session]);

  const evaluationData = useMemo(() => {
    const evaluations = session.evaluations;
    if (!evaluations) {
      return null;
    }
    return evaluationRecordsToEvaluationData(evaluations);
  }, [session]);

  const initialDataValues = useMemo(() => {
    if (!evaluationData) {
      return null;
    }
    return evaluationDataToInitialValues(evaluationData);
  }, [evaluationData]);

  const defaultEvaluationDate = useMemo(() => {
    if (session.evaluationDate) {
      return parseJSONDate(session.evaluationDate);
    }

    if (session.appointment?.startDate) {
      return parseJSONDate(session.appointment.startDate);
    }
    return new Date();
  }, [session]);

  if (!initialDataValues) {
    return <Loader size="large" />;
  }
  const maxRate = session.trainee?.company?.maxRate || 4;

  async function handleSaveEvaluation({
    evaluationDate,
    rates,
    evaluationComment,
    evaluationReview,
  }: FormValues & {
    evaluationDate: Date;
    evaluationComment?: string;
    evaluationReview?: string;
  }) {
    if (savingEvaluation || savingComment || savingEvaluationReview) {
      //Prevent multiple submit
      return;
    }
    const evaluationInput = Object.entries(rates).flatMap(
      ([, capacityEvaluation]) =>
        Object.entries(capacityEvaluation).map(
          ([evaluationId, evaluation]) => ({
            evaluationId,
            skillId: evaluation.skill.id,
            capacityId: evaluation.capacity.id,
            evaluation: {
              iaRating: sanitizedRateValue(evaluation.iaRating, maxRate),
              trainerRating: sanitizedRateValue(
                evaluation.trainerRating,
                maxRate
              ),
              globalRating: sanitizedRateValue(
                evaluation.globalRating,
                maxRate
              ),
            },
          })
        )
    );
    const response = await evaluateSession({
      evaluationDate,
      input: evaluationInput,
      sessionId: session.id,
    });
    if (evaluationComment) {
      const commentResponse = await saveEvaluationComment({
        evaluationId: session.id,
        noteValue: evaluationComment,
      });

      if (commentResponse.error) {
        return;
      }
    }
    if (evaluationReview) {
      const commentResponse = await saveEvaluationReview({
        reviewId: session.id,
        noteValue: evaluationReview,
      });

      if (commentResponse.error) {
        return;
      }
    }
    if (!response.error) {
      onEvaluationCompleted();
    }
  }
  return (
    <Formik
      initialValues={{
        ...initialDataValues,
        evaluationDate: defaultEvaluationDate,
        evaluationComment: session.evaluationComment?.value ?? undefined,
        evaluationReview: session.evaluationReview?.value ?? undefined,
      }}
      onSubmit={async (values) => {
        await handleSaveEvaluation(values);
      }}
    >
      {({ values, setFieldValue, resetForm, isSubmitting }) => (
        <Form>
          <div className="w-64">
            <FormikDatePicker
              editMode={isEdit}
              id="evaluationDate"
              label={t("trainer.session.label.date")}
            />
          </div>
          <div className="grid grid-cols-1 gap-2 2xl:gap-6 sm:grid-cols-2 pt-4 pb-8">
            {evaluationData &&
              Object.entries(evaluationData).map(
                ([skillsEvaluationId, skillsEvaluation]) => (
                  <div
                    key={skillsEvaluationId}
                    className="col-span-1 space-y-4"
                  >
                    <Title size={5}>{t(skillsEvaluation.name)}</Title>

                    <div className="border border-gray-100 rounded shadow">
                      <Table
                        heading={
                          <>
                            <TableHeading align="left" width="30%">
                              COMPÉTENCE
                            </TableHeading>
                            <TableHeading align="center">Ma note</TableHeading>
                            <TableHeading align="center">Note IA</TableHeading>
                            <TableHeading align="center">Globale</TableHeading>
                          </>
                        }
                      >
                        {Object.entries(skillsEvaluation.capacities).map(
                          ([capacityId, capacityEvaluation]) => {
                            const lastRate =
                              traineeLastRates?.[skillsEvaluationId]?.[
                                capacityEvaluation.capacity.id
                              ];
                            return (
                              <tr key={capacityEvaluation.capacity.nameKey}>
                                <TableCell align="left" dense>
                                  {DatavizMapper.formatLabel(
                                    DatavizMapper.translatePrefixedKey(
                                      capacityEvaluation.capacity.nameKey,
                                      t
                                    )
                                  )}
                                </TableCell>
                                <TableCell align="center" dense>
                                  <FormikInput
                                    editMode={isEdit}
                                    id={`rates.${skillsEvaluationId}.${capacityId}.trainerRating`}
                                    type="number"
                                    step=".01"
                                    min={0}
                                    max={maxRate}
                                    helperText={
                                      lastRate?.trainerRating ? (
                                        <span
                                          className="cursor-help"
                                          title={formatToLocalDate(
                                            new Date(lastRate.evaluationDate)
                                          )}
                                        >
                                          {t("trainer.session.label.lastRate")}{" "}
                                          <b>{lastRate.trainerRating}</b>
                                        </span>
                                      ) : (
                                        <span />
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell align="center" dense>
                                  <FormikInput
                                    editMode={isEdit}
                                    id={`rates.${skillsEvaluationId}.${capacityId}.iaRating`}
                                    type="number"
                                    step=".01"
                                    min={0}
                                    max={maxRate}
                                    helperText={
                                      lastRate?.iaRating ? (
                                        <span
                                          className="cursor-help"
                                          title={formatToLocalDate(
                                            new Date(lastRate.evaluationDate)
                                          )}
                                        >
                                          {t("trainer.session.label.lastRate")}{" "}
                                          <b>{lastRate.iaRating}</b>
                                        </span>
                                      ) : (
                                        <span />
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell align="center" dense>
                                  <FormikInput
                                    editMode={isEdit}
                                    id={`rates.${skillsEvaluationId}.${capacityId}.globalRating`}
                                    type="number"
                                    step=".01"
                                    min={0}
                                    max={maxRate}
                                    helperText={
                                      lastRate?.globalRating ? (
                                        <span
                                          className="cursor-help"
                                          title={formatToLocalDate(
                                            new Date(lastRate.evaluationDate)
                                          )}
                                        >
                                          {t("trainer.session.label.lastRate")}{" "}
                                          <b>{lastRate.globalRating}</b>
                                        </span>
                                      ) : (
                                        <span />
                                      )
                                    }
                                  />
                                </TableCell>
                              </tr>
                            );
                          }
                        )}
                      </Table>
                    </div>
                  </div>
                )
              )}
          </div>
          <div className="pt-8 pb-4 border-t border-gray-200 space-y-4">
            <Title size={5}>
              {t("trainer.session.title.evaluationComment")}
            </Title>
            <Comment
              editable={isEdit}
              content={
                isEdit
                  ? values.evaluationComment
                  : session.evaluationComment?.value ?? ""
              }
              placeholder={getCommentPlaceholder({
                name: session.trainee?.name ?? "",
              })}
              fallbackMessage={t("trainer.session.label.noEvaluationComment")}
              onValueChange={(val) => setFieldValue("evaluationComment", val)}
            />
          </div>
          <div className="pt-8 pb-4 border-t border-gray-200 space-y-4">
            <Title size={5}>
              {t("trainer.session.title.evaluationReview")}
            </Title>
            <Comment
              editable={isEdit}
              content={
                isEdit
                  ? values.evaluationReview
                  : session.evaluationReview?.value ?? ""
              }
              fallbackMessage={t("trainer.session.label.noEvaluationReview")}
              onValueChange={(val) => setFieldValue("evaluationReview", val)}
            />
          </div>
          {isEdit && (
            <div className="flex justify-end">
              <div className="flex space-x-4">
                <div className="w-32">
                  <Button
                    title={t("common.button.cancel.label")}
                    variant="transparent"
                    onClick={() => {
                      resetForm();
                      onEvaluationCompleted();
                    }}
                  >
                    {t("common.button.cancel.label")}
                  </Button>
                </div>
                <div className="w-33">
                  <Button
                    title={t("common.button.save.label")}
                    type="submit"
                    loading={savingEvaluation || savingComment || isSubmitting}
                  >
                    {t("common.button.save.label")}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
}

function getCommentPlaceholder(variables: TemplateVariables) {
  return `<p>
    <b>Comment ${variables.name} garantit son impact :</b>
    <br/>
    <br/>
    <br/>
    <b>Comment ${variables.name} peut décupler son impact :</b>
    <br/><br/>
    <b>Observations</b>
  </p>`;
}
