import { IconComponent } from "@components/Icon/Icon.model";
import classnames from "clsx";

export interface BGButtonProps {
  label: string;
  title?: string;
  loading?: boolean;
  preIcon?: IconComponent;
  postIcon?: IconComponent;
  selected?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
interface ButtonGroupProps {
  buttons: BGButtonProps[];
  size?: "small" | "normal";
}
export function ButtonGroup({ buttons, size = "normal" }: ButtonGroupProps) {
  const firstClasses = " rounded-l-md ";
  const defaultClasses = "-ml-px";
  const lastClasses = "-ml-px rounded-r-md";

  return (
    <span className="relative h-full w-full z-0 inline-flex shadow-sm rounded-md">
      {buttons.map((button, index, arr) => (
        <button
          type="button"
          className={classnames(
            "relative inline-flex items-center text-sm whitespace-nowrap border border-gray-300 font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary",
            {
              [firstClasses]: index === 0,
              [defaultClasses]: index !== 0 && index !== arr.length - 1,
              [lastClasses]: index === arr.length - 1,
              "px-4 py-2": size === "normal",
              "px-1 py-1 2xl:px-2 xl:py-2": size === "small",
              "bg-white text-gray-700 hover:bg-gray-100": !button.selected,
              "bg-primary text-gray-100 hover:bg-primary-dark": button.selected,
            }
          )}
          onClick={!button.selected ? button.onClick : undefined}
        >
          {button.label}
        </button>
      ))}
    </span>
  );
}
