import { FieldContainer } from "@components/form/FieldContainer";
import { LabelPosition } from "@components/form/FieldContainer/FieldContainer.component";
import { useField } from "formik";
import { ReactNode } from "react";
import { Toggle } from "../Toggle.component";

interface FormikToggleProps {
  editMode: boolean;
  id: string;
  label?: string;
  labelPosition?: LabelPosition;
  withSeparator?: boolean;
  renderValue?: (value: boolean) => ReactNode;
}

export function FormikToggle({
  editMode,
  id,
  label,
  labelPosition = "left",
  withSeparator = false,
  renderValue,
}: FormikToggleProps) {
  const [field, , { setValue, setTouched }] = useField(id);

  return (
    <FieldContainer
      id={id}
      label={label}
      labelPosition={labelPosition}
      withSeparator={withSeparator}
    >
      {editMode ? (
        <Toggle
          id={id}
          {...field}
          checked={field.value}
          onChange={(value) => {
            setValue(value);
            setTouched(true);
          }}
        />
      ) : (
        <span className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {renderValue ? renderValue(field.value) : field.value}
        </span>
      )}
    </FieldContainer>
  );
}
