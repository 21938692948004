interface GetProgressArgs {
  min: number;
  max: number;
  value: number;
  offset?: number;
}
export function getProgress({ min, max, value, offset }: GetProgressArgs) {
  const normalizedValue = Math.round(((value - min) * 100) / (max - min));
  const label =
    min === 0 && max === 100
      ? `${normalizedValue}%`
      : `${value}/${max - (offset || 0)}`;

  return { normalizedValue, label };
}
