import { CogOutline } from "@graywolfai/react-heroicons";
import { Client, ID } from "@justplayfair/model";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useInterval } from "react-use";
import { Button } from "../../../../../components/Button";
import { Card } from "../../../../../components/Card";
import { PageContainer } from "../../../../../components/Layout";
import { Loader } from "../../../../../components/Loader";
import { Title } from "../../../../../components/Title";
import { SessionDescriptionTitle } from "../components/SessionDescriptionTitle.component";

const EXTERNAL_APPOINTEMENT_URL = process.env.REACT_APP_ESPACE_RDV_URL;
if (!EXTERNAL_APPOINTEMENT_URL) {
  throw new Error("REACT_APP_ESPACE_RDV_URL is not defined");
}

export function SessionEndingPage() {
  const { t } = useTranslation();

  const { externalSimulationId } = useParams<{ externalSimulationId: ID }>();

  const [{ data, fetching }, executeQuery] =
    Client.useExternalAssessmentStateQuery({
      variables: { externalSimulationId: externalSimulationId },
    });

  const [isReload, setIsReload] = useState(false);
  useInterval(
    () => {
      setIsReload(true);
      if (!fetching) {
        executeQuery({ requestPolicy: "network-only" });
      }
    },
    data?.getExternalAssessmentState?.status !== "EVALUATED" ? 3000 : null
  );

  if (!isReload && fetching) {
    return (
      <PageContainer title={t("trainee.dashboard.title.onlineAssessment")}>
        <Loader size="medium" />
      </PageContainer>
    );
  }

  if (!data?.getExternalAssessmentState) {
    return (
      <PageContainer title={t("trainee.dashboard.title.onlineAssessment")}>
        <Title size={6}>Introuvable</Title>
      </PageContainer>
    );
  }

  return (
    <PageContainer
      title={
        <SessionDescriptionTitle
          session={data.getExternalAssessmentState.session}
        />
      }
      backButton="/trainee/dashboard"
    >
      <div className="flex items-center justify-center">
        <div className="max-w-md">
          <Card>
            <div className="space-y-4 p-4">
              <Title size={4}>{`${t(
                "trainee.sessionEnding.text.simulation"
              )} "${data.getExternalAssessmentState.name}" ${t(
                "trainee.sessionEnding.text.finished"
              )}`}</Title>

              <div className="max-w-xs">
                {data.getExternalAssessmentState.status === "EVALUATED" ? (
                  <EvaluatedSessionAction
                    assessmentState={data.getExternalAssessmentState}
                  />
                ) : (
                  <div className="flex gap-x-2 items-center">
                    <span className="text-gray-800 text-base">
                      {t("trainee.sessionEnding.text.waitForResult")}
                    </span>
                    <CogOutline className="animate-spin h-5 w-5 text-gray-600" />
                  </div>
                )}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </PageContainer>
  );
}

interface EvaluatedSessionActionProps {
  assessmentState: Client.ExternalAssessmentStateFieldsFragment;
}
function EvaluatedSessionAction({
  assessmentState,
}: EvaluatedSessionActionProps) {
  const { t } = useTranslation();

  if (assessmentState.withDebrief) {
    return (
      <div className="space-y-2">
        <div className="text-gray-800 text-base">
          {t("trainee.sessionEnding.text.pleaseTakeAnAppointment")}
        </div>
        <div>
          <Button
            link={{
              to: EXTERNAL_APPOINTEMENT_URL!,
              external: true,
            }}
            title={t("trainee.sessionEnding.button.appointment")}
          >
            {t("trainee.sessionEnding.button.appointment")}
          </Button>
        </div>
      </div>
    );
  }
  if (assessmentState.nextUrl) {
    return (
      <div className="space-y-2">
        <div className="text-gray-800 text-base">
          {t("trainee.sessionEnding.text.pleaseContinueToTheNextSimulation")}
        </div>
        <div>
          <Button
            link={{
              external: true,
              to: `${assessmentState.nextUrl}`,
            }}
            title={t("trainee.sessionEnding.button.nextSimulation")}
          >
            {t("trainee.sessionEnding.button.nextSimulation")}
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div className="space-y-2">
      <div className="text-gray-800 text-base">
        {t("trainee.sessionEnding.text.youCanConsultResults")}
      </div>
      <div>
        <Button
          link={{ to: `/trainee/session/${assessmentState.session.id}` }}
          title={t("trainee.sessionEnding.button.consult")}
        >
          {t("trainee.sessionEnding.button.consult")}
        </Button>
      </div>
    </div>
  );
}
