import { SessionRest } from "@justplayfair/model";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../components/Button";
import { DatePicker } from "../../../../../components/form/DatePicker";
import { Modal } from "../../../../../components/Modal";
import { exportSessionService } from "../../../../../services/session/exportSession.service";
import { startOfMonth, endOfMonth } from "date-fns";

interface ExportSessionsModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

export function ExportSessionsModal({
  isOpen,
  onClose,
}: ExportSessionsModalProps) {
  const { t } = useTranslation();

  const [dateRange, setDateRange] = useState<SessionRest.ExportSessionBody>({
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  });

  const [isLoading, setIsLoading] = useState(false);

  async function handleExportSessions() {
    setIsLoading(true);
    try {
      await exportSessionService.export(dateRange);
      onClose();
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      width="medium"
      title={t("admin.session.exportModal.title")}
    >
      <div className="pt-8 space-y-4">
        <div>
          <DateRangeSelector
            dateRange={dateRange}
            onDateRangeChange={setDateRange}
          />
        </div>
        <div>
          <Button
            onClick={() => {
              handleExportSessions();
            }}
            title={t("admin.session.exportModal.button.export")}
            loading={isLoading}
          >
            {t("admin.session.exportModal.button.export")}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

interface DateRangeSelectorProps {
  dateRange: SessionRest.ExportSessionBody;
  onDateRangeChange: (dateRange: SessionRest.ExportSessionBody) => void;
}
function DateRangeSelector({
  dateRange: { start, end },
  onDateRangeChange,
}: DateRangeSelectorProps) {
  return (
    <div className="flex space-x-8">
      <div>
        <DatePicker
          id="date-start"
          value={start}
          onChange={(newStart) => {
            onDateRangeChange({ start: newStart, end });
          }}
        />
      </div>
      <div>
        <DatePicker
          id="date-end"
          value={end}
          onChange={(newEnd) => {
            onDateRangeChange({ start, end: newEnd });
          }}
        />
      </div>
    </div>
  );
}
