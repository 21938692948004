import { Button } from "@components/Button";
import { IconButton } from "@components/Button/IconButton.component";
import { FieldContainer } from "@components/form/FieldContainer";
import { FormActions } from "@components/form/FormActions";
import { FormikInput } from "@components/form/Input/FormikInput";
import {
  CloudUploadOutline,
  DocumentDownloadOutline,
  UserOutline,
  XOutline,
} from "@graywolfai/react-heroicons";
import { Client, ID } from "@justplayfair/model";
import { fileService } from "@services/file/file.service";
import { Form, Formik } from "formik";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface UserInfoProps {
  user: Client.MeUserFieldsFragment;
  fetchingUploadResume: boolean;
  onUpdateUser: (input: Client.UpdateUserInputUser) => Promise<void>;
  onUploadResume: (file: File | null) => Promise<void>;
}
export function UserInfo({
  user,
  fetchingUploadResume,
  onUpdateUser,
  onUploadResume,
}: UserInfoProps) {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);

  const withResumeField = useMemo(
    () => user.roles.some((userRole) => ["TRAINEE"].includes(userRole)),
    [user]
  );

  function handleUploadResume(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files?.length === 1) {
      onUploadResume(event.target.files[0]);
    }
  }
  async function handleDownloadResume(fileId: ID) {
    await fileService.downloadFile(fileId, user.resume?.name);
  }

  function handleDeleteResume() {
    if (window.confirm("Étes vous sûr de vouloir supprimer")) {
      onUploadResume(null);
    }
  }

  return (
    <Formik
      initialValues={{
        name: user.name,
        email: user.email,
      }}
      onSubmit={async (values) => {
        if (!isEdit) {
          throw new Error("Should not submit a non edited profile");
        }
        await onUpdateUser(values);
        setIsEdit(false);
      }}
    >
      {(props) => (
        <Form>
          <div>
            <FormikInput
              editMode={false}
              id="email"
              label={t("common.user.email")}
            />
            <FormikInput
              editMode={isEdit}
              id="name"
              label={t("common.user.name")}
              icon={UserOutline}
            />
            {withResumeField && (
              <FieldContainer
                id="user-resume"
                label={t("common.user.resume")}
                labelPosition="left"
              >
                <div className="flex w-full justify-between">
                  {user.resume && (
                    <DeletableResumeField
                      resume={user.resume}
                      onClick={handleDownloadResume}
                      onDelete={handleDeleteResume}
                    />
                  )}
                  <div className="w-44 mt-2">
                    <Button
                      id="user-resume"
                      type="file"
                      accept="application/pdf"
                      variant="transparent"
                      title={t("profile.button.uploadResume.label")}
                      preIcon={CloudUploadOutline}
                      onChange={(event) => handleUploadResume(event)}
                      loading={fetchingUploadResume}
                    >
                      {t("profile.button.uploadResume.label")}
                    </Button>
                  </div>
                </div>
              </FieldContainer>
            )}
          </div>

          <FormActions
            isEdit={isEdit}
            handleReset={props.handleReset}
            setIsEdit={setIsEdit}
          />
        </Form>
      )}
    </Formik>
  );
}

interface DeletableResumeFieldProps {
  resume: NonNullable<Client.MeUserFieldsFragment["resume"]>;
  onClick: (fileId: ID) => void;
  onDelete: VoidFunction;
}
function DeletableResumeField({
  resume,
  onClick,
  onDelete,
}: DeletableResumeFieldProps) {
  const { t } = useTranslation();
  return (
    <div className="flex items-center space-x-1">
      <button
        onClick={() => onClick(resume.storageFileId)}
        className="flex font-medium text-primary hover:text-primary-dark"
      >
        <DocumentDownloadOutline className="h-5 w-5" />
        {t("common.text.download")}
      </button>
      <div>
        <IconButton
          icon={XOutline}
          title="Delete"
          onClick={() => onDelete()}
          size="small"
          variant="transparent"
        ></IconButton>
      </div>
    </div>
  );
}
