import {
  ChevronLeftOutline,
  ChevronRightOutline,
} from "@graywolfai/react-heroicons";
import { User } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";

interface PaginationProps {
  pageInfo: User.PageInfo | null;
  onPrevious: VoidFunction;
  onNext: VoidFunction;
  testId?: string;
}
export function Pagination({
  pageInfo,
  onPrevious,
  onNext,
  testId = "Pagination",
}: PaginationProps) {
  const { t } = useTranslation();
  return (
    <div
      className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
      data-testid={testId}
    >
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          {pageInfo && (
            <p
              className="text-sm text-gray-700"
              data-testid={`${testId}-pageInfo`}
            >
              {pageInfo.count === 0 && t("common.text.noData")}
              {pageInfo.count > 0 && (
                <>
                  {t("common.pagination.showing")}
                  <span className="font-medium"> {pageInfo.from + 1} </span>
                  {t("common.pagination.to")}
                  <span className="font-medium"> {pageInfo.to} </span>
                  {t("common.pagination.of")}
                  <span className="font-medium"> {pageInfo.count} </span>
                  {t("common.pagination.results")}
                </>
              )}
            </p>
          )}
        </div>
        <div>
          {pageInfo && pageInfo.count > 0 && (
            <nav
              className="relative z-0 inline-flex shadow-sm space-x-2"
              aria-label="Pagination"
            >
              <Button
                variant="transparent"
                title="Previous"
                onClick={() => onPrevious()}
                disabled={!pageInfo?.hasPrevious}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftOutline className="h-5 w-5" />
              </Button>
              <Button
                variant="transparent"
                title="Next"
                onClick={() => onNext()}
                disabled={!pageInfo?.hasNext}
              >
                <span className="sr-only">Next</span>
                <ChevronRightOutline className="h-5 w-5" />
              </Button>
            </nav>
          )}
        </div>
      </div>
    </div>
  );
}
