import {
  ActivateBody,
  AskRenewPasswordBody,
  ChangePasswordBody,
  User,
} from "@justplayfair/model";
import { httpClient } from "../../http/client";

const USER_URL = process.env.REACT_APP_USER_URL;
if (!USER_URL) {
  throw new Error("mandatory env variable 'REACT_APP_USER_URL'");
}

class UserService {
  async getUserToken(token: string) {
    return httpClient.get(`${USER_URL}/tokenUser/${token}`).json<User.User>();
  }

  async activate(fields: ActivateBody) {
    await httpClient.post(`${USER_URL}/activate`, { json: fields }).json();
  }

  async askRenewPassword(fields: AskRenewPasswordBody) {
    await httpClient.post(`${USER_URL}/askRenew`, {
      json: fields,
    });
  }

  async changePassword(fields: ChangePasswordBody) {
    await httpClient.post(`${USER_URL}/changePassword`, {
      json: fields,
    });
  }
}

export const userService = new UserService();
