import { FormActions } from "@components/form/FormActions";
import { FormikFileButton } from "@components/form/FormikFileButton";
import { FormikInput } from "@components/form/Input/FormikInput";
import { Loader } from "@components/Loader";
import { CloudUploadOutline } from "@graywolfai/react-heroicons";
import { Client, ID, SUPPORTED_MIMES } from "@justplayfair/model";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

function getTestFileType(mediaId?: ID) {
  return function testFileType(value?: { type: string }) {
    return (
      (!mediaId && !!value && SUPPORTED_MIMES.includes(value.type)) ||
      (!!mediaId && (!value || SUPPORTED_MIMES.includes(value.type)))
    );
  };
}
function getMediaSchema(mediaId?: ID): Yup.SchemaOf<Client.MediaInput> {
  return Yup.object()
    .shape({
      name: Yup.string().required("admin.mediaLibrary.label.nameMandatory"),
      description: Yup.string(),
      labels: Yup.array(),
      file: mediaId
        ? Yup.mixed().test(
            "fileFormat",
            "admin.mediaLibrary.label.requireType",
            getTestFileType(mediaId)
          )
        : Yup.mixed()
            .test(
              "fileFormat",
              "admin.mediaLibrary.label.requireType",
              getTestFileType(mediaId)
            )
            .required("admin.mediaLibrary.label.fileMandatory"),
    })
    .required();
}

interface MediaFormProps {
  mediaId?: ID;
  fetchSaving: boolean;
  onSaveMedia: (input: Client.MediaInput) => Promise<void>;
  onDeleteMedia: (id: ID) => Promise<void>;
}
export function MediaForm({
  mediaId,
  fetchSaving,
  onSaveMedia,
  onDeleteMedia,
}: MediaFormProps) {
  const { t } = useTranslation();

  const [{ data: mediaData, fetching: fetchingMedia }] =
    Client.useGetMediaQuery({
      pause: !mediaId,
      variables: mediaId ? { mediaId } : undefined,
    });

  const [previewFile, setPreviewFile] = useState<Client.FileFieldsFragment>();

  useEffect(() => {
    setPreviewFile(mediaData?.getMedia ?? undefined);
  }, [mediaData]);

  if (fetchSaving || (mediaId && fetchingMedia)) {
    return <Loader size="medium" mode="overlay" />;
  }

  return (
    <div>
      <Formik
        initialValues={{
          name: mediaData?.getMedia?.name ?? "",
          description: mediaData?.getMedia?.description ?? "",
          file: null,
        }}
        onSubmit={async ({ file, ...fields }) => {
          await onSaveMedia({
            ...fields,
            upload: { file },
            labels: ["EDUCATIONAL_RESOURCE"],
          });
        }}
        validationSchema={getMediaSchema(mediaId)}
      >
        {(props) => (
          <Form className="bg-white shadow p-2">
            <div>
              <FormikInput
                editMode={true}
                id="name"
                label={t("admin.mediaLibrary.label.name")}
              />
              <FormikInput
                editMode={true}
                id="description"
                label={t("admin.mediaLibrary.label.description")}
              />

              <FormikFileButton
                existingFile={previewFile}
                editMode={true}
                id="file"
                variant="secondary"
                title={t("admin.mediaLibrary.label.file")}
                label={t("admin.mediaLibrary.label.file")}
                preIcon={CloudUploadOutline}
                loading={fetchSaving}
                onResetFile={() => setPreviewFile(undefined)}
              />
            </div>
            <FormActions
              isEdit={true}
              handleReset={props.handleReset}
              onDelete={mediaId ? () => onDeleteMedia(mediaId) : undefined}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
