import { Client } from "@justplayfair/model";
import { RequestPolicy } from "@urql/core";
import { useEffect, useState } from "react";

interface UseTraineeLastRatesArgs {
  pause?: boolean;
  traineeId: string;
  skillIds: string[];
  requestPolicy: RequestPolicy;
}

interface TraineeLastRates {
  [skillId: string]: {
    [capacityId: string]: {
      evaluationDate: string;
      trainerRating: number | null;
      iaRating: number | null;
      globalRating: number | null;
    };
  };
}
export function useTraineeLastRates({
  pause,
  traineeId,
  skillIds,
  requestPolicy,
}: UseTraineeLastRatesArgs) {
  const [traineeLastRates, setTraineeLastRates] = useState<TraineeLastRates>();

  const [{ data }] = Client.useTraineeLastSkillsEvaluationsQuery({
    pause,
    variables: {
      input: {
        traineeId,
        skillIds,
      },
    },
    requestPolicy,
  });

  useEffect(() => {
    if (!data) {
      setTraineeLastRates(undefined);
      return;
    }
    const indexedRates: TraineeLastRates = {};
    for (const lastSkillRates of data.traineeLastSkillsEvaluations) {
      for (const { capacityId, ...rates } of lastSkillRates.capacityRates) {
        indexedRates[lastSkillRates.skillId] = {
          ...(indexedRates[lastSkillRates.skillId] || []),
          [capacityId]: {
            evaluationDate: rates.evaluationDate,
            globalRating: rates.globalRating || null,
            iaRating: rates.iaRating || null,
            trainerRating: rates.trainerRating || null,
          },
        };
      }
    }
    setTraineeLastRates(indexedRates);
  }, [data]);

  return { traineeLastRates };
}
