import { css, Global } from "@emotion/react";
import { sanitizeHtml } from "@justplayfair/model";
import Quill from "quill";
import type Delta from "quill-delta";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import "quill/dist/quill.snow.css";
import { useLayoutEffect, useRef } from "react";

export interface TextEditorProps {
  content?: string;
  onChange: (delta?: Delta) => void;
  height?: number | string;
  width?: number | string;
  placeholder?: string;
}

export function TextEditor({
  content,
  onChange,
  height = "100%",
  width = "100%",
  placeholder,
}: TextEditorProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const quillRef = useRef<Quill>();

  useLayoutEffect(() => {
    if (!containerRef.current) {
      throw new Error("no ref");
    }
    if (quillRef.current) {
      return;
    }
    quillRef.current = new Quill(containerRef.current, {
      modules: {
        toolbar: [[{ header: [1, 2, false] }], ["bold", "italic", "underline"]],
      },
      placeholder,
      theme: "snow",
    });
    if (content) {
      const contentDelta = htmlToTextDelta(quillRef.current, content);

      quillRef.current.setContents(contentDelta);
    }
    quillRef.current.on("text-change", (delta, oldContents, source) => {
      const contents = quillRef.current?.getContents();
      onChange(contents);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div style={{ width, height }} className="jpf-text-editor">
      <Global
        styles={css`
          .jpf-text-editor {
            .ql-toolbar {
              height: 44px;
              background-color: #ececec;
              border-radius: 0.2rem 0.2rem 0 0;

              &.ql-snow {
                border-color: #dedede;
              }
            }
            .ql-container {
              height: calc(100% - 44px);
              background-color: white;
              border-radius: 0 0 0.2rem 0.2rem;

              &.ql-snow {
                border-color: #dedede;
              }
            }
          }
        `}
      />
      <div ref={containerRef} />
    </div>
  );
}

export function textDeltaToHtml(delta: Delta) {
  const converter = new QuillDeltaToHtmlConverter(delta.ops, {
    encodeHtml: true,
  });
  return converter.convert();
}

function htmlToTextDelta(quillInstance: Quill, html: string): Delta {
  return quillInstance.clipboard.convert(sanitizeHtml(html) as any);
}
