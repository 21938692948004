import { Loader } from "@components/Loader";
import { Title } from "@components/Title";
import { TraineeSessionTable } from "@components/TraineeSessionTable";
import { useAuthenticatedUser } from "@hooks/authenticatedUser/useAuthenticatedUser.hook";
import { Client } from "@justplayfair/model";
import { useParams } from "react-router";
import { toSearchEntries } from "../../../../search/search.utilities";

export function SessionTabContent() {
  const { user: authenticatedUser, fetching: fetchingUser } =
    useAuthenticatedUser();

  const { programId, traineeId } = useParams<{
    programId: string;
    traineeId: string;
  }>();

  const [{ data, fetching }] = Client.useTraineeDetailsDashboardQuery({
    variables: {
      programId,
      traineeId,
      sessionSearch: {
        filters: {
          query: new URLSearchParams(
            toSearchEntries({
              programIds: [programId],
              traineeIds: [traineeId],
            })
          ).toString(),
        },
        take: 1000,
        skip: 0,
      },
    },
    requestPolicy: "cache-only",
  });

  if (fetching || fetchingUser) {
    return <Loader size="medium" />;
  }
  if (!data?.userInProgram) {
    return <Title size={6}>Introuvable</Title>;
  }

  return (
    <div>
      <TraineeSessionTable
        sessions={data.searchSessions.data || []}
        forRole={
          authenticatedUser?.roles.includes("TRAINER")
            ? "TRAINER"
            : authenticatedUser?.roles.includes("MANAGER")
            ? "MANAGER"
            : "TRAINEE"
        }
      />
    </div>
  );
}
