import { PageContainer } from "@components/Layout";
import { Loader } from "@components/Loader";
import { Tabs } from "@components/Tabs";
import { Title } from "@components/Title";
import { UserDescriptionTitle } from "@components/UserDescription";
import {
  PresentationChartLineSolid,
  VideoCameraSolid,
} from "@graywolfai/react-heroicons";
import { Client } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import { Route, Switch, useParams } from "react-router-dom";
import { toSearchEntries } from "../../../search/search.utilities";
import { TraineeDatavizTabContent } from "./tabContents/TraineeDatavizTabContent.component";
import { SessionTabContent } from "./tabContents/SessionTabContent.component";

export function TraineeDetails() {
  const { t } = useTranslation();

  const { programId, traineeId } = useParams<{
    programId: string;
    traineeId: string;
  }>();

  const [{ data, fetching }] = Client.useTraineeDetailsDashboardQuery({
    variables: {
      programId,
      traineeId,
      sessionSearch: {
        filters: {
          query: new URLSearchParams(
            toSearchEntries({
              programIds: [programId],
              traineeIds: [traineeId],
            })
          ).toString(),
        },
        take: 1000,
        skip: 0,
      },
    },
    requestPolicy: "network-only",
  });

  if (fetching) {
    return (
      <PageContainer title={t("trainer.dashboard.title.trainee")}>
        <Loader size="medium" />
      </PageContainer>
    );
  }

  if (!data?.userInProgram) {
    return (
      <PageContainer title={t("trainer.dashboard.title.trainee")}>
        <Title size={6}>Introuvable</Title>
      </PageContainer>
    );
  }

  return (
    <PageContainer
      title={
        <UserDescriptionTitle
          user={data.userInProgram}
          programId={programId}
          advancement={data.getTraineeProgramAdvancement}
        />
      }
      backButton={"/trainee/dashboard"}
    >
      <div className="px-4 pb-8">
        <Tabs
          items={[
            {
              label: t("trainer.dashboard.tab.sessions"),
              icon: VideoCameraSolid,
              link: `/trainee/details/${programId}/${traineeId}`,
            },
            {
              label: t("trainer.dashboard.tab.evaluationsData"),
              icon: PresentationChartLineSolid,
              link: `/trainee/details/${programId}/${traineeId}/tab/dataviz`,
            },
          ]}
        />
      </div>
      <div className="px-8 space-y-8">
        <Switch>
          <Route
            path={`/trainee/details/:programId/:traineeId`}
            exact
            component={SessionTabContent}
          />
          <Route
            path={`/trainee/details/:programId/:traineeId/tab/dataviz`}
            component={() => <TraineeDatavizTabContent />}
          />
        </Switch>
      </div>
    </PageContainer>
  );
}
