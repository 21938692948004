import { Button } from "@components/Button";
import { PageContainer } from "@components/Layout";
import { Loader } from "@components/Loader";
import { Title } from "@components/Title";
import { Client } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { AdminCreationButtonContainer } from "../../components/AdminCreationButtonContainer.component";
import { AdminUserList } from "./AdminUserList.component";
import { CompanyAvatar } from "./CompanyAvatar.component";
import { CompanyForm } from "./EditCompanyForm.component";
import { LabelList } from "./LabelList.component";
import { ProgramList } from "./ProgramList.component";

export function EditCompanyPage() {
  const { t } = useTranslation();
  const { companyId } = useParams<{ companyId: string }>();
  const history = useHistory();

  const [{ data, fetching }, reexecuteQuery] = Client.useGetCompanyQuery({
    variables: { id: companyId },
  });
  const [{ data: companyGroupsData, fetching: fetchingGroups }] =
    Client.useGetCompaniesProgramsQuery({
      variables: { companyIds: [companyId] },
    });

  const [, updateCompany] = Client.useUpdateCompanyAdminMutation();
  const [, deleteCompany] = Client.useDeletecompanyMutation();

  async function handleEditCompany(input: Client.UpdateCompanyInputAdmin) {
    await updateCompany({ id: companyId, input });
  }

  async function handleDeleteCompany(companyId: string) {
    if (
      window.confirm("Etes vous sûr de vouloir supprimer cette entreprise ?")
    ) {
      await deleteCompany({ id: companyId });
      history.goBack();
    }
  }

  function handleAvatarUpdated() {
    reexecuteQuery({ requestPolicy: "network-only" });
  }

  if (fetching || fetchingGroups) {
    return (
      <PageContainer
        title={t("menu.admin")}
        subtitle={t("admin.title.companies")}
      >
        <Loader size="medium" />
      </PageContainer>
    );
  }
  if (!data?.company) {
    return (
      <PageContainer
        title={t("menu.admin")}
        subtitle={t("admin.title.companies")}
      >
        <Title size={6}>Introuvable</Title>
      </PageContainer>
    );
  }

  return (
    <PageContainer
      title={t("menu.admin")}
      subtitle={t("admin.title.companies")}
      backButton="/admin/companies"
    >
      <div className="space-y-12">
        <div className="w-full max-w-4xl mx-auto space-y-8 py-4">
          <CompanyAvatar
            company={data.company}
            onAvatarUpdated={handleAvatarUpdated}
          />
          <CompanyForm
            company={data.company}
            onEditCompany={handleEditCompany}
            onDeleteCompany={handleDeleteCompany}
          />
        </div>
        <div className="w-full mx-auto space-y-8 py-4">
          <Title size={3}>{t("admin.company.title.programs")}</Title>
          <AdminCreationButtonContainer>
            <Button
              variant="secondary"
              link={{ to: `/admin/companies/${companyId}/program/create` }}
              title={t("admin.company.button.createProgram")}
            >
              {t("admin.company.button.createProgram")}
            </Button>
          </AdminCreationButtonContainer>
          {data.company?.programs?.length === 0 && (
            <Title size={6}>{t("admin.company.label.noProgramYet")}</Title>
          )}
          {!!data?.company?.programs?.length && (
            <ProgramList
              programs={companyGroupsData?.companiesPrograms || []}
            />
          )}
        </div>
        <div className="w-full mx-auto space-y-8 py-4">
          <Title size={3}>{t("admin.company.title.companyUsers")}</Title>
          <AdminCreationButtonContainer>
            <Button
              variant="secondary"
              link={{ to: `/admin/companies/${companyId}/users/create` }}
              title={t("admin.user.button.createUser")}
            >
              {t("admin.user.button.createUser")}
            </Button>
          </AdminCreationButtonContainer>
          <AdminUserList />
        </div>

        <div className="w-full mx-auto space-y-8 py-4">
          <Title size={3}>{t("admin.company.title.companyLabels")}</Title>
          <AdminCreationButtonContainer>
            <Button
              variant="secondary"
              link={{ to: `/admin/companies/${companyId}/label` }}
              title={t("admin.user.button.createLabel")}
            >
              {t("admin.label.button.createLabel")}
            </Button>
          </AdminCreationButtonContainer>
          <LabelList companyId={companyId} />
        </div>
      </div>
    </PageContainer>
  );
}
