import { Button } from "@components/Button";
import { FormikInput } from "@components/form/Input/FormikInput";
import {
  AtSymbolOutline,
  LockClosedOutline,
} from "@graywolfai/react-heroicons";
import { AuthBody } from "@justplayfair/model";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";

const LoginSchema: Yup.SchemaOf<AuthBody> = Yup.object().shape({
  email: Yup.string()
    .email("auth.error.invalidEmail")
    .required("auth.error.emailMandatory"),
  password: Yup.string().required("auth.error.passwordMandatory"),
});

interface LoginFormProps {
  onLogin: (fields: AuthBody) => void;
}

export function LoginForm({ onLogin }: LoginFormProps) {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      onSubmit={async (values) => {
        onLogin(values);
      }}
      validationSchema={LoginSchema}
    >
      {({ values }) => (
        <Form className="space-y-6" noValidate>
          <div className="flex flex-col">
            <FormikInput
              editMode={true}
              id="email"
              type="email"
              placeholder="Email address"
              icon={AtSymbolOutline}
              withSeparator={false}
            />
            <FormikInput
              editMode={true}
              id="password"
              type="password"
              placeholder="Password"
              autoComplete="current-password"
              icon={LockClosedOutline}
              withSeparator={false}
            />

            <div className="flex items-center justify-end">
              <div className="text-sm">
                <Link
                  to={`/forgot-password?email=${encodeURIComponent(
                    values.email
                  )}`}
                  className="font-medium text-primary hover:text-primary-dark"
                >
                  {t("auth.login.link.forgotPassword.label")}
                </Link>
              </div>
            </div>
          </div>

          <div>
            <Button title={t("auth.login.button.label")} type="submit">
              {t("auth.login.button.label")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
