import { NamedAvatar } from "@components/Avatar/NamedAvatar.component";
import { Button } from "@components/Button";
import { Chip, ChipContainer } from "@components/Chip";
import { Dropdown } from "@components/Dropdown";
import { FilePreview } from "@components/FilePreview";
import { PageContainer } from "@components/Layout";
import { Loader } from "@components/Loader";
import { Pagination } from "@components/Table/Pagination.component";
import {
  Table,
  TableCell,
  TableHeading,
} from "@components/Table/Table.component";
import {
  DotsVerticalOutline,
  DownloadOutline,
  PencilAltOutline,
  RefreshOutline,
  XOutline,
} from "@graywolfai/react-heroicons";
import { Client, ID } from "@justplayfair/model";
import { fileService } from "@services/file/file.service";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  byNameMediaNaturalySort,
  formatBytesToHumanReadable,
} from "../../../model/Media.model";
import { AdminCreationButtonContainer } from "../components/AdminCreationButtonContainer.component";
import { MediaFilters } from "./MediaFilters.component";

const PAGE_SIZE = 50;

export function MediaLibrary() {
  const { t } = useTranslation();
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [filterDeleted, setFilterDeleted] = useState(false);
  const [selectedType, setSelectedType] = useState<string>();
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useState<string>("");
  const [hydrated, setHydrated] = useState(false);

  const skip = useMemo(() => (page - 1) * PAGE_SIZE, [page]);

  const [{ data, fetching }, refetchSearch] = Client.useSearchMediasQuery({
    variables: {
      search: {
        take: PAGE_SIZE,
        skip,
        filters: { deleted: filterDeleted, query: searchParams },
      },
    },
  });
  const [{ fetching: fetchDeleteMedia }, deleteMedia] =
    Client.useDeleteMediaMutation();
  const [{ fetching: fetchRestoreMedia }, restoreMedia] =
    Client.useRestoreMediaMutation();

  useEffect(() => {
    const search = history.location.search;
    const searchParams = new URLSearchParams(search);
    setSelectedType(searchParams.get("type") || undefined);
    setSelectedLabels(searchParams.getAll("labels"));
    setHydrated(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const searchs = selectedLabels.map((label) => ["labels", label]);
    if (selectedType) {
      searchs.push(["type", selectedType]);
    }
    const paramsStr = new URLSearchParams(searchs).toString();
    setSearchParams(paramsStr);

    history.replace({
      search: paramsStr,
    });
  }, [history, selectedType, selectedLabels]);

  function handlePreviousPage() {
    setPage(page - 1);
  }
  function handleNextPage() {
    setPage(page + 1);
  }

  async function handleDownloadFile({
    storageFileId,
    name,
  }: Client.FileFieldsFragment) {
    await fileService.downloadFile(storageFileId, name);
  }

  async function handleDeleteMedia(mediaId: ID) {
    if (window.confirm("Etes vous sûr de vouloir supprimer ce média ?")) {
      await deleteMedia({ mediaId });
      refetchSearch();
    }
  }
  async function handleRestoreMedia(mediaId: ID) {
    if (window.confirm("Etes vous sûr de vouloir restaurer ce média ?")) {
      await restoreMedia({ mediaId });
      refetchSearch();
    }
  }
  return (
    <PageContainer title={t("admin.title.mediaLibrary")} backButton>
      <div className="w-full mx-auto space-y-8">
        <div className="flex space-x-2 justify-end">
          <AdminCreationButtonContainer>
            <Button
              title={t("admin.mediaLibrary.button.userCharter.label")}
              link={{ to: "/admin/userCharterDetails" }}
            >
              {t("admin.mediaLibrary.button.userCharter.label")}
            </Button>
          </AdminCreationButtonContainer>
          <AdminCreationButtonContainer>
            <Button
              title={t("admin.mediaLibrary.button.createGuide.label")}
              link={{ to: "/admin/guideDetails" }}
            >
              {t("admin.mediaLibrary.button.createGuide.label")}
            </Button>
          </AdminCreationButtonContainer>
          <AdminCreationButtonContainer>
            <Button
              title={t(
                "admin.mediaLibrary.button.createEducationalResource.label"
              )}
              link={{ to: "/admin/mediaResourceDetails" }}
            >
              {t("admin.mediaLibrary.button.createEducationalResource.label")}
            </Button>
          </AdminCreationButtonContainer>
        </div>
        <div className="shadow border-b border-gray-200 sm:rounded-lg">
          {hydrated && (
            <MediaFilters
              filterDeleted={filterDeleted}
              selectedType={selectedType}
              selectedLabels={selectedLabels}
              onFilterDeletedChange={setFilterDeleted}
              onSelectedTypeChange={setSelectedType}
              onSelectedLabelsChange={setSelectedLabels}
            />
          )}
          <Table
            heading={
              <>
                <TableHeading align="left">
                  {t("admin.mediaLibrary.heading.file")}
                </TableHeading>
                <TableHeading align="left">
                  {t("admin.mediaLibrary.heading.name")}
                </TableHeading>{" "}
                <TableHeading align="left">
                  {t("admin.mediaLibrary.heading.description")}
                </TableHeading>
                <TableHeading align="left">
                  {t("admin.mediaLibrary.heading.owner")}
                </TableHeading>
                <TableHeading align="center">
                  {t("admin.mediaLibrary.heading.type")}
                </TableHeading>
                <TableHeading align="left">
                  {t("admin.mediaLibrary.heading.size")}
                </TableHeading>
                <TableHeading align="center">
                  {t("admin.mediaLibrary.heading.labels")}
                </TableHeading>
                <TableHeading align="right">
                  <span className="sr-only">Download</span>
                </TableHeading>
              </>
            }
          >
            {!fetching &&
              data?.searchMedias.data
                .sort(byNameMediaNaturalySort) // sort so that "10.xxx" is after "9.xxx"
                .map((media) => (
                  <tr key={media.id}>
                    <TableCell align="left">
                      <FilePreview
                        fileType={media.type}
                        storageFileId={media.storageFileId}
                      />
                    </TableCell>

                    <TableCell align="left">{media.name}</TableCell>
                    <TableCell align="left">{media.description}</TableCell>

                    <TableCell align="center">
                      {media.owner && (
                        <NamedAvatar size="small" entity={media.owner} />
                      )}
                    </TableCell>

                    <TableCell align="center">
                      <Chip color="primary" label={media.type} />
                    </TableCell>

                    <TableCell align="left">
                      {formatBytesToHumanReadable(media.size)}
                    </TableCell>

                    <TableCell align="center">
                      <ChipContainer>
                        {media.labels.map((label, index) => (
                          <Chip
                            key={index}
                            color="transparent"
                            label={t(`common.media.label.value.${label}`)}
                          />
                        ))}
                      </ChipContainer>
                    </TableCell>

                    <TableCell align="right">
                      {fetchDeleteMedia ||
                        (fetchRestoreMedia && <Loader size="small" />)}
                      {!fetchDeleteMedia && !fetchRestoreMedia && (
                        <div className="w-36">
                          <MediaActionDropdown
                            media={media}
                            onDownloadFile={() => handleDownloadFile(media)}
                            onDeleteMedia={() => handleDeleteMedia(media.id)}
                            onRestoreMedia={() => handleRestoreMedia(media.id)}
                          />
                        </div>
                      )}
                    </TableCell>
                  </tr>
                ))}
          </Table>
          {fetching && <Loader size="large" />}
          <Pagination
            pageInfo={data?.searchMedias.pageInfo as Client.PageInfo}
            onPrevious={handlePreviousPage}
            onNext={handleNextPage}
          />
        </div>
      </div>
    </PageContainer>
  );
}

interface MediaActionDropdownProps {
  media: Client.FileFieldsFragment;
  onDownloadFile: VoidFunction;
  onDeleteMedia: VoidFunction;
  onRestoreMedia: VoidFunction;
}
function MediaActionDropdown({
  media,
  onDownloadFile,
  onDeleteMedia,
  onRestoreMedia,
}: MediaActionDropdownProps) {
  const { t } = useTranslation();

  return (
    <Dropdown
      iconButton={{
        icon: DotsVerticalOutline,
        title: "Actions",
      }}
      position="left"
    >
      <Dropdown.MenuItem
        label={t("admin.mediaLibrary.button.edit.label")}
        link={{ to: `/admin/mediaResourceDetails/${media.id}` }}
        icon={PencilAltOutline}
      />
      <Dropdown.MenuItem
        label={t("admin.mediaLibrary.button.download.label")}
        onClick={onDownloadFile}
        icon={DownloadOutline}
      />
      {!media.deleted && (
        <Dropdown.MenuItem
          label={t("admin.mediaLibrary.button.delete.label")}
          onClick={onDeleteMedia}
          icon={XOutline}
        />
      )}
      {media.deleted && (
        <Dropdown.MenuItem
          label={t("admin.mediaLibrary.button.restore.label")}
          onClick={onRestoreMedia}
          icon={RefreshOutline}
        />
      )}
    </Dropdown>
  );
}
