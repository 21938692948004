import { useTooltip } from "./useTooltip.hook";

interface TooltipProps {
  referenceElement: HTMLElement | null;
  label: string;
}
export function Tooltip({ referenceElement, label }: TooltipProps) {
  const {
    setPopperElement,
    setArrowElement,
    styles,
    attributes,
    tooltipShown,
  } = useTooltip({ referenceElement });

  return tooltipShown ? (
    <div
      ref={setPopperElement}
      className="z-50 relative mt-1"
      style={styles.popper}
      {...attributes.popper}
    >
      <div
        className="h-4 w-4 pointer-events-none -mt-1"
        ref={setArrowElement}
        style={styles.arrow}
        {...attributes.arrow}
      >
        <svg
          viewBox="0 0 32 16"
          className="fill-current stroke-current"
          color="white"
          filter="drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16))"
        >
          <path d="M16 0l16 16H0z"></path>
        </svg>
      </div>
      <div className="p-1 shadow bg-white border rounded pointer-events-none max-w-xs">
        {label}
      </div>
    </div>
  ) : null;
}
