import { Button } from "@components/Button";
import { Card } from "@components/Card";
import { PageContainer } from "@components/Layout";
import { Loader } from "@components/Loader";
import {
  Table,
  TableCell,
  TableContainer,
  TableHeading,
} from "@components/Table/Table.component";
import { Client, ID } from "@justplayfair/model";
import { useTranslation } from "react-i18next";

export function AuthorizationsPage() {
  const { t } = useTranslation();

  const [{ fetching, data }, refetchLists] = Client.useListAuthorizationsQuery({
    variables: { status: ["PENDING"] },
  });

  const [, authorize] = Client.useAcceptPermissionRequestMutation();
  const [, reject] = Client.useRejectPermissionRequestMutation();
  const [, remove] = Client.useRemovePermissionTicketMutation();

  if (fetching) {
    return (
      <PageContainer title={t("authorization.title")}>
        <Loader size="medium" />
      </PageContainer>
    );
  }

  async function handleAuthorize(requestId: ID) {
    await authorize({
      permissionRequestId: requestId,
    });
    refetchLists();
  }
  async function handleReject(requestId: ID) {
    await reject({
      permissionRequestId: requestId,
    });
    refetchLists();
  }
  async function handleRemove(ticketId: ID) {
    await remove({
      permissionTicketId: ticketId,
    });
    refetchLists();
  }

  return (
    <PageContainer title={t("authorization.title")}>
      <Card rounded={false}>
        <div className="space-y-8 p-8 min-h">
          <div className="space-y-2">
            <div className="text-gray-800">
              {t("authorization.text.requestExplaination")}
            </div>
            <TableContainer>
              <PermissionRequestTable
                permissionRequests={data?.listAuthorizationRequests || []}
                permissionTickets={data?.listMyGivenAuthorizations || []}
                onAuthorize={handleAuthorize}
                onReject={handleReject}
                onRemove={handleRemove}
              />
            </TableContainer>
          </div>
        </div>
      </Card>
    </PageContainer>
  );
}

interface PermissionRequestTableProps {
  permissionRequests: Client.ListAuthorizationsQuery["listAuthorizationRequests"];
  permissionTickets: Client.ListAuthorizationsQuery["listMyGivenAuthorizations"];
  onAuthorize: (requestId: ID) => void;
  onReject: (requestId: ID) => void;
  onRemove: (ticketId: ID) => void;
}
function PermissionRequestTable({
  permissionRequests,
  permissionTickets,
  onAuthorize,
  onReject,
  onRemove,
}: PermissionRequestTableProps) {
  const { t } = useTranslation();
  return (
    <Table
      heading={
        <>
          <TableHeading align="left">
            {t("authorization.heading.name")}
          </TableHeading>

          <TableHeading align="left">
            {t("authorization.heading.email")}
          </TableHeading>

          <TableHeading align="left">
            {t("authorization.heading.authorization")}
          </TableHeading>

          <TableHeading align="left">
            <span className="sr-only">Action</span>
          </TableHeading>
        </>
      }
    >
      {permissionRequests.length === 0 && PermissionTicketTable.length === 0 && (
        <tr>
          <td className="p-4 text-sm text-gray-700" colSpan={5}>
            {t("common.text.noData")}
          </td>
        </tr>
      )}
      {permissionRequests.map((permissionRequest) => (
        <tr key={permissionRequest.id}>
          <TableCell align="left">{permissionRequest.requester.name}</TableCell>
          <TableCell align="left">
            <span className="text-gray-500">
              {permissionRequest.requester.email}
            </span>
          </TableCell>
          <TableCell align="left">
            <span className="text-gray-500">
              {t(`common.permissionRequestStatus.${permissionRequest.status}`)}
            </span>
          </TableCell>
          <TableCell align="right">
            <div className="flex w-52 space-x-2 ml-auto">
              <Button
                title={t("authorization.button.authorize")}
                variant="transparent"
                onClick={() => {
                  onAuthorize(permissionRequest.id);
                }}
              >
                {t("authorization.button.authorize")}
              </Button>
              <Button
                title={t("authorization.button.reject")}
                variant="transparent"
                onClick={() => {
                  onReject(permissionRequest.id);
                }}
              >
                {t("authorization.button.reject")}
              </Button>
            </div>
          </TableCell>
        </tr>
      ))}
      {permissionTickets.map((ticket) => (
        <tr key={ticket.id}>
          <TableCell align="left">{ticket.user?.name}</TableCell>
          <TableCell align="left">
            <span className="text-gray-500">{ticket.user?.email}</span>
          </TableCell>
          <TableCell align="left">
            <span className="text-gray-500">
              {t(`common.permissionRequestStatus.ACCEPTED`)}
            </span>
          </TableCell>

          <TableCell align="right">
            <div className="flex w-28 space-x-2 ml-auto">
              <Button
                title={t("authorization.button.remove")}
                variant="transparent"
                onClick={() => {
                  onRemove(ticket.id);
                }}
              >
                {t("authorization.button.remove")}
              </Button>
            </div>
          </TableCell>
        </tr>
      ))}
    </Table>
  );
}

interface PermissionTicketTableProps {
  permissionTickets: Client.ListAuthorizationsQuery["listMyGivenAuthorizations"];
}
function PermissionTicketTable({
  permissionTickets,
}: PermissionTicketTableProps) {
  const { t } = useTranslation();
  return (
    <Table
      heading={
        <>
          <TableHeading align="left">
            {t("authorization.heading.name")}
          </TableHeading>

          <TableHeading align="center">
            {t("authorization.heading.email")}
          </TableHeading>

          <TableHeading align="left">
            <span className="sr-only">Action</span>
          </TableHeading>
        </>
      }
    >
      {permissionTickets.length === 0 && (
        <tr>
          <td className="p-4 text-sm text-gray-700" colSpan={5}>
            {t("common.text.noData")}
          </td>
        </tr>
      )}
      {permissionTickets.map((ticket) => (
        <tr key={ticket.id}>
          <TableCell align="left">{ticket.user?.name}</TableCell>
          <TableCell align="center">
            <span className="text-gray-500">{ticket.user?.email}</span>
          </TableCell>

          <TableCell align="right">Mettre un bouton</TableCell>
        </tr>
      ))}
    </Table>
  );
}
