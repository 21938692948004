import { Client } from "@justplayfair/model";
import { Avatar } from "./Avatar.component";
import classnames from "clsx";

export interface NamedAvatarProps {
  entity: { name: string; avatarImages?: Client.ResizedImage | null };
  size: "large" | "medium" | "small" | "mini";
}

export function NamedAvatar({ entity, size }: NamedAvatarProps) {
  const nameClasses = classnames("my-auto", {
    "text-lg pl-2": size === "medium",
    "text-xl pl-4": size === "large",
  });
  return (
    <div className="flex items-center space-x-1">
      <Avatar size={size} entity={entity} />
      <div className={nameClasses}>{entity.name}</div>
    </div>
  );
}
