import { Button } from "@components/Button";
import { IconButton } from "@components/Button/IconButton.component";
import { FormikDatePicker } from "@components/form/DatePicker/FormikDatePicker/FormikDatePicker.component";
import { PageContainer } from "@components/Layout";
import { Title } from "@components/Title";
import { PlusCircleSolid, XOutline } from "@graywolfai/react-heroicons";
import {
  Client,
  DateHoursInterval,
  dayIntervalToDateInterval,
  dateToHoursDecimal,
  dateToTimeStr,
  getWorkingDays,
} from "@justplayfair/model";
import { authService } from "@services/auth";
import classnames from "clsx";
import { set } from "date-fns";
import { FieldArray, Form, Formik } from "formik";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { PerDayAvailabilities } from "./Availabilites.model";

export function TrainerAvailabilitiesPage() {
  const { t } = useTranslation();
  const history = useHistory();

  const [selectedDays, setSelectedDays] = useState<Client.WorkingDay[]>([]);

  const trainerId = useMemo(() => authService.authenticatedUserId, []);

  const [{ data: trainerAvailabilitiesData }, refetchTrainerAvailabilities] =
    Client.useGetTrainerDayIntervalsQuery({
      variables: {
        trainerId,
      },
    });
  const [, saveAvailabilities] = Client.useSaveTrainerAvailabilitiesMutation();

  const workingDays = useMemo(() => getWorkingDays(t), [t]);

  const perDayAvailabilities = useMemo((): PerDayAvailabilities => {
    if (!trainerAvailabilitiesData) {
      return {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
      };
    }
    return workingDays.reduce((aggr, { id }) => {
      return {
        ...aggr,
        [id]: trainerAvailabilitiesData.getTrainerDayIntervals
          .filter(({ day }) => day === id)
          .map((dayInterval) => dayIntervalToDateInterval(dayInterval)),
      };
    }, {} as PerDayAvailabilities);
  }, [workingDays, trainerAvailabilitiesData]);

  function handleSelectDay(dayId: Client.WorkingDay) {
    if (selectedDays.includes(dayId)) {
      setSelectedDays(selectedDays.filter((id) => id !== dayId));
    } else {
      setSelectedDays([...selectedDays, dayId]);
    }
  }

  async function handleSaveAvailability(
    dateHoursInterval: DateHoursInterval[]
  ) {
    const availabilities = selectedDays.map((selectedDay) => ({
      day: selectedDay,
      intervals: dateHoursInterval.map(({ start, end }) => {
        const startHour = dateToHoursDecimal(start);
        const endHour = dateToHoursDecimal(end);
        return {
          startHour,
          duration: endHour - startHour,
        };
      }),
    }));

    const { error } = await saveAvailabilities({ trainerId, availabilities });
    if (!error) {
      setSelectedDays([]);
      refetchTrainerAvailabilities();
    }
  }

  function handleTerminate() {
    history.goBack();
  }
  return (
    <PageContainer title={t("menu.myAvailabilities")}>
      <div className="flex flex-grow justify-between flex-col w-3/4 h-full mx-auto">
        <div>
          <Title size={4}>{t("myAvailabilities.title.chooseDays")}</Title>
          <div className="flex justify-center space-x-4 p-8">
            {workingDays.map(({ id, label }, index) => (
              <DayChooser
                key={index}
                label={label}
                selected={selectedDays.includes(id)}
                currentAvailabilities={perDayAvailabilities[id]}
                onClick={() => handleSelectDay(id)}
              />
            ))}
          </div>
          <div className="flex justify-center">
            {selectedDays.length > 0 && (
              <HoursSelector
                currentAvailabilities={selectedDays.flatMap(
                  (day) => perDayAvailabilities[day]
                )}
                onSaveHours={handleSaveAvailability}
                onClose={() => setSelectedDays([])}
              />
            )}
          </div>
        </div>
        <div>
          <Button onClick={handleTerminate} title="terminer">
            Terminer
          </Button>
        </div>
      </div>
    </PageContainer>
  );
}

interface DayChooserProps {
  label: string;
  selected?: boolean;
  currentAvailabilities: DateHoursInterval[];
  onClick: VoidFunction;
}
function DayChooser({
  label,
  selected,
  currentAvailabilities,
  onClick,
}: DayChooserProps) {
  const classNames = classnames(
    "flex items-center justify-center w-32 h-16 border rounded cursor-pointer transition",
    {
      "text-gray-600 border-gray-300 hover:text-gray-900 hover:border-gray-500":
        !selected,
      "bg-primary text-gray-200 border-gray-600 hover:text-white hover:border-gray-900":
        selected,
    }
  );
  return (
    <div className="flex flex-col items-center space-y-2">
      <div className={classNames} onClick={onClick}>
        {label}
      </div>
      {currentAvailabilities.length > 0 && (
        <div className="bg-white p-2 rounded-sm shadow-sm space-y-2">
          {currentAvailabilities.map(({ start, end }, index) => (
            <div
              className="text-xs font-bold	text-gray-600"
              key={index}
            >{`${dateToTimeStr(start)} - ${dateToTimeStr(end)}`}</div>
          ))}
        </div>
      )}
    </div>
  );
}

const HoursSelectorSchema: Yup.SchemaOf<{
  intervals: DateHoursInterval[];
}> = Yup.object().shape({
  intervals: Yup.array()
    .of(
      Yup.object()
        .shape({
          start: Yup.date().required(
            "myAvailabilities.label.startHourRequired"
          ),
          end: Yup.date()
            .min(Yup.ref("start"), "myAvailabilities.label.endMustBeAfterStart")
            .required("myAvailabilities.label.endHourRequired"),
        })
        .required()
    )
    .required(),
});

interface HoursSelectorProps {
  currentAvailabilities: DateHoursInterval[];
  onSaveHours: (intervals: DateHoursInterval[]) => Promise<void>;
  onClose: VoidFunction;
}
function HoursSelector({
  currentAvailabilities,
  onSaveHours,
  onClose,
}: HoursSelectorProps) {
  const { t } = useTranslation();

  function createDefaultInterval() {
    return {
      start: set(new Date(), { hours: 10, minutes: 0 }),
      end: set(new Date(), { hours: 11, minutes: 30 }),
    };
  }

  return (
    <div className="flex flex-col p-2 w-4/5 bg-gray-50 border rounded cursor-pointer border-gray-300">
      <div className="flex justify-end">
        <IconButton
          icon={XOutline}
          size="small"
          onClick={onClose}
          variant="secondary"
          title={t("common.button.close.label")}
        />
      </div>
      <Formik
        initialValues={{
          intervals:
            currentAvailabilities.length > 0
              ? currentAvailabilities
              : [createDefaultInterval()],
        }}
        onSubmit={async ({ intervals }) => {
          await onSaveHours(intervals);
        }}
        validationSchema={HoursSelectorSchema}
      >
        {({ values }) => (
          <Form className="w-full p-2">
            <FieldArray name="intervals">
              {({ remove, push }) => (
                <div className="flex flex-col items-center">
                  {values.intervals.map((_, index) => (
                    <div key={index} className="flex items-center ">
                      <FormikDatePicker
                        editMode={true}
                        id={`intervals.${index}.start`}
                        label="De"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption={t(
                          "component.datePicker.label.timeCaption"
                        )}
                        timeFormat="HH:mm"
                        dateFormat="HH:mm"
                        minTime={set(new Date(), { hours: 7, minutes: 0 })}
                        maxTime={set(new Date(), { hours: 21, minutes: 0 })}
                      />
                      <FormikDatePicker
                        editMode={true}
                        id={`intervals.${index}.end`}
                        label="à"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption={t(
                          "component.datePicker.label.timeCaption"
                        )}
                        timeFormat="HH:mm"
                        dateFormat="HH:mm"
                        minTime={set(new Date(), { hours: 7, minutes: 0 })}
                        maxTime={set(new Date(), { hours: 21, minutes: 0 })}
                      />
                      <div>
                        <IconButton
                          icon={XOutline}
                          size="small"
                          title="Supprimer"
                          variant="transparent"
                          onClick={() => {
                            remove(index);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                  <div className="w-52">
                    <Button
                      preIcon={PlusCircleSolid}
                      variant="transparent"
                      title="Ajouter un intervalle"
                      onClick={() => {
                        push(createDefaultInterval());
                      }}
                    >
                      <span className="text-primary">
                        Ajouter un intervalle
                      </span>
                    </Button>
                  </div>
                </div>
              )}
            </FieldArray>
            <div className="flex justify-end">
              <div className="w-50">
                <Button
                  title={t("myAvailabilities.button.applyToSelectedDays")}
                  type="submit"
                >
                  {t("myAvailabilities.button.applyToSelectedDays")}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
