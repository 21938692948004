import { CHART_COLORS } from "@justplayfair/model";
import { OrdinalColorScaleConfig } from "@nivo/colors";
import { useTheme } from "@nivo/core";
import { LegendProps } from "@nivo/legends";
import { GridLabelProps, ResponsiveRadar } from "@nivo/radar";
import { useState } from "react";

function getColor(
  valueNames: string[],
  inverted: boolean
): OrdinalColorScaleConfig {
  if (valueNames.length > 2) {
    return { scheme: "set3" };
  }
  return !inverted
    ? [CHART_COLORS.primaryBlue, CHART_COLORS.gold]
    : [CHART_COLORS.gold, CHART_COLORS.primaryBlue];
}

interface RadarChartProps {
  indexBy: string;
  valueNames: string[];
  data: {
    [x: string]: string | number;
  }[];
  maxRate: number;
}
export function RadarChart({
  indexBy,
  valueNames,
  data,
  maxRate,
}: RadarChartProps) {
  const [orderedValueNames, setOrderedValueNames] = useState(valueNames);
  const [selectedValuesKey, setSelectedValuesKey] = useState<string>(
    valueNames[0]
  );

  const legends: LegendProps[] | undefined =
    valueNames.length > 1
      ? [
          {
            anchor: "bottom-right",
            direction: "column",
            translateX: -120,
            translateY: -50,
            itemWidth: 120,
            itemHeight: 20,
            itemTextColor: "#999",
            symbolSize: 12,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "#000",
                },
              },
            ],
            onClick: ({ label }) => {
              setSelectedValuesKey(
                selectedValuesKey === label ? valueNames[0] : `${label}`
              );

              setOrderedValueNames([
                ...orderedValueNames.filter((valueName) => valueName !== label),
                `${label}`,
              ]);
            },
          },
        ]
      : undefined;
  return (
    <ResponsiveRadar
      data={data}
      keys={orderedValueNames}
      indexBy={indexBy}
      maxValue={maxRate}
      margin={{ top: 50, right: 40, bottom: 40, left: 40 }}
      borderColor={{ from: "color" }}
      theme={{ fontFamily: "Inter, ui-sans-serif, system-ui" }}
      gridLevels={maxRate}
      gridShape="circular"
      gridLabel={GridLabelComponent}
      gridLabelOffset={20}
      enableDots={true}
      dotSize={10}
      dotColor={{ theme: "background" }}
      borderWidth={2}
      dotBorderWidth={2}
      dotBorderColor={{ from: "color" }}
      enableDotLabel={true}
      dotLabel={({ key, value }: any) => (
        <DotLabelComponent
          labelKey={key}
          value={value}
          selectedValuesKey={selectedValuesKey}
        />
      )}
      dotLabelYOffset={-12}
      colors={getColor(valueNames, valueNames[0] !== orderedValueNames[0])}
      fillOpacity={0}
      blendMode="multiply"
      animate={true}
      isInteractive={true}
      legends={legends}
    />
  );
}

function GridLabelComponent({ id, anchor }: GridLabelProps) {
  const theme = useTheme();

  const splitedLabel = id.match(/.{1,15}(\s|$)/g) || []; // Split word if more than 15 chars
  return (
    <g>
      {splitedLabel.map((label, index) => (
        <text
          key={index}
          style={theme.axis.ticks.text}
          transform={`translate(0, ${index * 16})`}
          textAnchor={anchor}
        >
          {label}
        </text>
      ))}
    </g>
  );
}

function DotLabelComponent({
  selectedValuesKey,
  labelKey,
  value,
}: {
  selectedValuesKey: string;
  labelKey: string;
  value: number;
}) {
  return selectedValuesKey === labelKey ? <>{value}</> : <></>;
}
