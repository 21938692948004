import { BarChart } from "@components/Dataviz/chart/BarChart.component";
import { Loader } from "@components/Loader";
import { DatavizMapper } from "@justplayfair/model";
import { useTranslation } from "react-i18next";

interface RateDetailsChartProps {
  name: string;
  detailsData:
    | ReturnType<typeof DatavizMapper.byKeyBarDataMapper>[]
    | undefined;
  fetching: boolean;
  height: number;
  testId?: string;
}

export function RateDetailsChart({
  name,
  detailsData,
  fetching,
  height,
  testId = "RateDetailsChart",
}: RateDetailsChartProps) {
  const { t } = useTranslation();

  if (fetching) {
    return <Loader size="medium" />;
  }

  if (!detailsData || !detailsData.some((detail) => detail.data.length > 0)) {
    return <div className="text-gray-500">{t("common.text.noData")}</div>;
  }

  return (
    <>
      {detailsData.map((barsData, index) => (
        <div key={index} style={{ height }}>
          <BarChart
            containerHeight={height}
            layout="horizontal"
            xLegend=""
            data={barsData.data}
            keys={barsData.keys}
            maxRate={barsData.maxRate}
            indexBy="key"
            withStats={
              barsData.statName ? [barsData.name, barsData.statName] : undefined
            }
            colorTheme="accentuate"
            testId={testId}
          />
        </div>
      ))}
    </>
  );
}
