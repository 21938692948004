import { AuthLayout } from "@components/AuthLayout";
import { Button } from "@components/Button";
import { FormikInput } from "@components/form/Input/FormikInput";
import { LockClosedOutline } from "@graywolfai/react-heroicons";
import { PASSWORD_REGEX } from "@justplayfair/model";
import { userService } from "@services/user/user.service";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useLocation } from "react-use";
import * as Yup from "yup";

interface NewPasswordFields {
  newPassword: string;
}
const ChangePasswordSchema: Yup.SchemaOf<NewPasswordFields> =
  Yup.object().shape({
    newPassword: Yup.string()
      .matches(PASSWORD_REGEX, "auth.common.message.mustContain")
      .required("auth.error.passwordMandatory"),
  });

export function ChangePasswordPage() {
  const { t } = useTranslation();
  const { search } = useLocation();

  const [loading, setLoading] = useState(false);
  const [errorKey, setErrorKey] = useState<
    undefined | "invalidToken" | "noToken"
  >();

  const [step, setStep] = useState<"renew" | "done">("renew");

  async function handleChangePassword({ newPassword }: NewPasswordFields) {
    setLoading(true);
    try {
      const params = new URLSearchParams(search);
      const token = params.get("token");
      if (!token) {
        console.error("No token in search params");
        setErrorKey("noToken");
        return;
      }
      await userService.changePassword({ token, newPassword });
      setStep("done");
    } catch (error) {
      console.error(error);
      setErrorKey("invalidToken");
    } finally {
      setLoading(false);
    }
  }
  return (
    <AuthLayout
      titleKey={"auth.changePassword.title"}
      renderSubtitle={
        step !== "done"
          ? t("auth.changePassword.message.instruction")
          : t("auth.changePassword.message.done")
      }
      errorKey={errorKey}
      loading={loading}
    >
      {step !== "done" && (
        <Formik
          initialValues={{
            newPassword: "",
          }}
          onSubmit={(values) => {
            handleChangePassword(values);
          }}
          validationSchema={ChangePasswordSchema}
        >
          <Form className="space-y-6" noValidate>
            <FormikInput
              editMode
              id="newPassword"
              type="password"
              autoComplete="new-password"
              placeholder="Nouveau mot de passe"
              icon={LockClosedOutline}
              withSeparator={false}
            />
            {errorKey && (
              <div className="flex items-center justify-end">
                <div className="text-sm"></div>
                <Link
                  to="/forgot-password"
                  className="font-medium text-primary hover:text-primary-dark"
                >
                  {t("auth.changePassword.link.resendEmail")}
                </Link>
              </div>
            )}
            <div>
              <Button
                title={t("auth.changePassword.button.label")}
                type="submit"
              >
                {t("auth.changePassword.button.label")}
              </Button>
            </div>
          </Form>
        </Formik>
      )}
      {step === "done" && (
        <div className="flex items-center justify-center">
          <div className="text-lg">
            <Link
              to="/login"
              className="font-medium text-primary hover:text-primary-dark"
            >
              {t("auth.changePassword.link.toLogin")}
            </Link>
          </div>
        </div>
      )}
    </AuthLayout>
  );
}
