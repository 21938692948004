"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateChangePasswordBody = exports.ChangePasswordBodyValidationSchema = exports.validateAskRenewPasswordBody = exports.AskRenewPasswordBodyValidationSchema = exports.validateActivateBody = exports.ActivateBodyValidationSchema = exports.validateOtpCheckBody = exports.OtpCheckBodyValidationSchema = exports.validateAuthBody = exports.AuthBodyValidationSchema = void 0;
const Yup = require("yup");
exports.AuthBodyValidationSchema = Yup.object()
    .shape({
    email: Yup.string().required("Email mandatory"),
    password: Yup.string().required("Password mandatory"),
})
    .required();
function validateAuthBody(body) {
    return exports.AuthBodyValidationSchema.validateSync(body, {
        abortEarly: false,
    });
}
exports.validateAuthBody = validateAuthBody;
exports.OtpCheckBodyValidationSchema = Yup.object().shape({
    otp: Yup.string().required("OTP mandatory"),
});
function validateOtpCheckBody(body) {
    return exports.OtpCheckBodyValidationSchema.validateSync(body, {
        abortEarly: false,
    });
}
exports.validateOtpCheckBody = validateOtpCheckBody;
exports.ActivateBodyValidationSchema = Yup.object()
    .shape({
    token: Yup.string().required("Token mandatory"),
    password: Yup.string().required("Password mandatory"),
})
    .required();
function validateActivateBody(body) {
    return exports.ActivateBodyValidationSchema.validateSync(body, {
        abortEarly: false,
    });
}
exports.validateActivateBody = validateActivateBody;
exports.AskRenewPasswordBodyValidationSchema = Yup.object()
    .shape({
    email: Yup.string().required("Email mandatory"),
})
    .required();
function validateAskRenewPasswordBody(body) {
    return exports.AskRenewPasswordBodyValidationSchema.validateSync(body, {
        abortEarly: false,
    });
}
exports.validateAskRenewPasswordBody = validateAskRenewPasswordBody;
exports.ChangePasswordBodyValidationSchema = Yup.object()
    .shape({
    token: Yup.string().required("Token mandatory"),
    newPassword: Yup.string().required("New password mandatory"),
})
    .required();
function validateChangePasswordBody(body) {
    return exports.ChangePasswordBodyValidationSchema.validateSync(body, {
        abortEarly: false,
    });
}
exports.validateChangePasswordBody = validateChangePasswordBody;
