import { Button } from "@components/Button";
import { DatePicker } from "@components/form/DatePicker";
import { Modal } from "@components/Modal";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface UpdateSessionDateModalProps {
  isOpen: boolean;
  loading: boolean;
  sessionDate?: Date;
  onChange: (sessionDate: Date) => void;
  onClose: VoidFunction;
}

export function UpdateSessionDateModal({
  isOpen,
  loading,
  sessionDate,
  onChange,
  onClose,
}: UpdateSessionDateModalProps) {
  const { t } = useTranslation();

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    sessionDate
  );
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      width="medium"
      title={t("trainer.dashboard.title.updateSessionDate")}
    >
      <div className="w-full flex flex-col space-y-10 p-4">
        <div>
          <DatePicker
            id="session-date"
            value={selectedDate ?? sessionDate ?? new Date()}
            onChange={(value) => {
              setSelectedDate(value);
            }}
            label={t("component.calendar.date")}
            showTimeSelect
            timeIntervals={15}
            timeCaption={t("component.datePicker.label.timeCaption")}
            timeFormat="HH:mm"
            dateFormat="dd/MM/yyyy HH:mm"
          />
        </div>
        <div className="w-full flex justify-between">
          <div className="max-w-l">
            <Button
              title={t("common.button.cancel.label")}
              variant="secondary"
              onClick={() => onClose()}
            >
              {t("common.button.cancel.label")}
            </Button>
          </div>
          <div className="max-w-l">
            <Button
              title={t("common.button.save.label")}
              onClick={() => {
                if (selectedDate) {
                  onChange(selectedDate);
                }
              }}
              disabled={!selectedDate}
              loading={loading}
            >
              {t("common.button.save.label")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
