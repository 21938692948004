import { SelectOption } from "@components/form/Select/Select.model";
import { Client } from "@justplayfair/model";

export const ROLES = [
  "ADMIN",
  "MANAGER",
  "TRAINER",
  "TRAINEE",
] as Client.Role[];

export const ROLE_OPTIONS: SelectOption<string>[] = [
  { value: "ADMIN" },
  { value: "MANAGER" },
  { value: "TRAINER" },
  { value: "TRAINEE" },
];

export const TARGETED_LEVEL_ACTIVATED = false;
