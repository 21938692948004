"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiErrorCode = void 0;
var ApiErrorCode;
(function (ApiErrorCode) {
    ApiErrorCode["API_ERROR"] = "@api/error";
    ApiErrorCode["AUTHENT_ALREADY_EXISTING_USER"] = "@authent/alreadyExistingUser";
    ApiErrorCode["AUTHENT_UNAUTHENTICATED"] = "@authent/unauthenticated";
    ApiErrorCode["AUTHENT_UNAUTHORIZED"] = "@authent/unauthorized";
    ApiErrorCode["AUTHENT_WRONG_CREDENTIALS"] = "@authent/wrongCredentials";
    ApiErrorCode["AUTHENT_ACCOUNT_DEACTIVATED"] = "@authent/accountDeactivated";
    ApiErrorCode["DATA_NOT_FOUND"] = "@data/notFound";
    ApiErrorCode["DATA_WRONG_REQUEST"] = "@data/wrongRequest";
    ApiErrorCode["DATA_ALREADY_EXISTING"] = "@data/alreadyExisting";
    ApiErrorCode["DATA_CONFLICT"] = "@data/conflict";
})(ApiErrorCode || (exports.ApiErrorCode = ApiErrorCode = {}));
