import { createElement, ReactNode } from "react";
import classnames from "clsx";

type TitleSize = 1 | 2 | 3 | 4 | 5 | 6;

interface TitleProps {
  size: TitleSize;
  variant?: boolean;
  children: ReactNode;
}

export function Title({ size, variant, children }: TitleProps) {
  const classes = classnames({
    "text-title": !variant,
    "text-white": variant,
    "text-5xl": size === 1,
    "text-4xl": size === 2,
    "text-3xl": size === 3,
    "text-2xl": size === 4,
    "text-xl": size === 5,
    "text-lg": size === 6,
  });
  const tag = `h${size}`;
  const titleProps = {
    className: classes,
  };
  return createElement(tag, titleProps, children);
}
