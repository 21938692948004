import { FormikChangeListener } from "@components/form/changeListener/FormikChangeListener.component";
import { FormikSelect } from "@components/form/Select/FormikSelect";
import { Loader } from "@components/Loader";
import { Client, formatToLocalDate, ID } from "@justplayfair/model";
import { Form, Formik } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toSearchEntries } from "../../../search/search.utilities";

interface SessionSelectorProps {
  traineeArgs: Client.TraineeArgs;
  onFiltersChange: (sessionId: ID) => void;
}
export function SessionSelector({
  traineeArgs: { programId, traineeId },
  onFiltersChange,
}: SessionSelectorProps) {
  const { t } = useTranslation();
  const [{ fetching, data }] = Client.useSessionListQuery({
    variables: {
      search: {
        take: 10000,
        skip: 0,
        filters: {
          deleted: false,
          query: new URLSearchParams(
            toSearchEntries({
              programIds: [programId],
              traineeIds: [traineeId],
              status: ["EVALUATED"],
            })
          ).toString(),
        },
        sort: {
          name: "evaluationDate",
          order: "asc",
        },
      },
    },
  });
  const sessionOpts = useMemo(() => {
    if (!data || !data.searchSessions.data.length) {
      return [];
    }

    return data.searchSessions.data.map((session, index) => ({
      value: session.id,
      label: `Data ${index + 1} - ${formatToLocalDate(
        new Date(session.evaluationDate)
      )}`,
    }));
  }, [data]);

  if (fetching) {
    return <Loader size="medium" />;
  }

  function handleChanges({ sessionId }: { sessionId: ID }) {
    onFiltersChange(sessionId);
  }

  return (
    <Formik
      initialValues={{}}
      onSubmit={() => {
        // No need to sumbit
      }}
    >
      {({ values }) => (
        <Form className="flex items-center divide-x">
          <FormikChangeListener onChange={handleChanges} />
          <div className="w-72">
            <FormikSelect
              labelPosition="top"
              editMode={true}
              id="sessionId"
              multi={false}
              label={t("consult.filter.chooseSession")}
              options={sessionOpts}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
