import { FieldContainer } from "@components/form/FieldContainer";
import { LabelPosition } from "@components/form/FieldContainer/FieldContainer.component";
import { useField } from "formik";
import { ReactNode } from "react";
import { Checkbox } from "../Checkbox.component";

interface FormikCheckboxProps {
  editMode: boolean;
  id: string;
  labelPosition?: LabelPosition;
  label?: string;
  withSeparator?: boolean;
  renderValue?: (value: boolean) => ReactNode;
}

export function FormikCheckbox({
  editMode,
  id,
  labelPosition = "right",
  label,
  withSeparator,
  renderValue,
}: FormikCheckboxProps) {
  const [field] = useField(id);

  return (
    <FieldContainer
      id={id}
      label={label}
      labelPosition={labelPosition}
      withSeparator={withSeparator}
    >
      {editMode ? (
        <Checkbox id={id} {...field} checked={field.value} />
      ) : (
        <span className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {renderValue ? renderValue(field.value) : field.value}
        </span>
      )}
    </FieldContainer>
  );
}
