import { Menu } from "@headlessui/react";
import { User } from "@justplayfair/model";
import cn from "classnames";
import classNames from "clsx";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { LinkProps } from "../Button/Button.component";
import { IconComponent } from "../Icon/Icon.model";

interface BaseMenuItemProps {
  icon?: IconComponent;
  link?: LinkProps;
  label: string | ReactNode;
  mandatoryRole?: User.Role[];
  onClick?: VoidFunction;
}

interface MenuLinkItemProps extends BaseMenuItemProps {
  link: LinkProps;
  onClick?: undefined;
}

interface MenuActionItemProps extends BaseMenuItemProps {
  link?: undefined;
  onClick: VoidFunction;
}

export type MenuItemProps = MenuLinkItemProps | MenuActionItemProps;

export function MenuItem(props: MenuItemProps) {
  const itemClasses =
    "group w-full min-w-56 flex items-center block w-full text-left px-3 py-2 typo-s-16-regular text-gray-700 hover:bg-gray-100";

  if (props.link) {
    return (
      <Menu.Item>
        {({ active }) => (
          <LinkWrapper
            link={props.link}
            className={classNames(itemClasses, {
              "bg-gray-100 text-gray-900": active,
            })}
          >
            {props.icon && (
              <props.icon
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            )}
            {props.label}
          </LinkWrapper>
        )}
      </Menu.Item>
    );
  }

  return (
    <Menu.Item>
      {({ active }) => (
        <button
          onClick={props.onClick}
          className={cn(itemClasses, {
            "bg-gray-100 text-gray-900": active,
          })}
        >
          {props.icon && <MenuItemIcon icon={props.icon} />}
          {props.label}
        </button>
      )}
    </Menu.Item>
  );
}

interface MenuItemIconProps {
  icon: IconComponent;
}
function MenuItemIcon({ icon }: MenuItemIconProps) {
  return icon({
    className: "mr-3 h-3 w-3 text-gray-500 group-hover:text-gray-600",
    "aria-hidden": "true",
  });
}

interface MenuHeaderProps {
  children: React.ReactNode;
}
export function MenuHeader({ children }: MenuHeaderProps) {
  return (
    <div className="border-b border-gray-100 border-opacity-80 p-3 last:border-0">
      {children}
    </div>
  );
}
interface MenuSectionProps {
  name?: string;
  children: React.ReactNode;
}
export function MenuSection({ name, children }: MenuSectionProps) {
  return (
    <div className="border-b border-gray-100 border-opacity-80 pb-2 last:border-0">
      {name && (
        <div className="typo-xs-semibold px-3 pt-3 pb-2 uppercase text-gray-700">
          {name}
        </div>
      )}
      {children}
    </div>
  );
}

const LinkWrapper = ({
  link,
  className,
  children,
}: {
  link: LinkProps;
  className: string;
  children: ReactNode;
}) => {
  return link.external ? (
    <a
      href={link.to}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(event: any) => {
        event.stopPropagation();
      }}
      className={className}
    >
      {children}
    </a>
  ) : (
    <Link to={link.to} className={className}>
      {children}
    </Link>
  );
};
