import { AuthTitle } from "@components/AuthTitle";
import { Loader } from "@components/Loader";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface AuthLayoutProps {
  titleKey: string;
  renderSubtitle?: ReactNode;
  errorKey?: string;
  loading?: boolean;
  children: ReactNode;
}
export function AuthLayout({
  titleKey,
  renderSubtitle,
  errorKey,
  loading,
  children,
}: AuthLayoutProps) {
  const { t } = useTranslation();
  return (
    <div>
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-4">
          <AuthTitle titleKey={titleKey}>
            {renderSubtitle && renderSubtitle}
          </AuthTitle>
          {errorKey && (
            <div className=" text-red-500">{t(`auth.error.${errorKey}`)}</div>
          )}
          {children}
        </div>
      </div>

      {loading && <Loader mode="overlay" size="large" />}
    </div>
  );
}
