import { Client, SESSION_DURATION_TO_DECIMAL_HOUR } from "@justplayfair/model";
import { addMinutes } from "date-fns";
import * as Yup from "yup";

export function createAppointmentDates(
  startDate: Date,
  duration: Client.SessionDuration,
  recurrence: Client.SessionRecurrenceInput
): Client.SessionAppointmentDates {
  const durationinMinutes = SESSION_DURATION_TO_DECIMAL_HOUR[duration] * 60;
  return {
    startDate,
    endDate: addMinutes(startDate, durationinMinutes),
    recurrence,
  };
}

export const ExternalSimulationSchema = Yup.object().shape({
  externalSessionMappingId: Yup.string().required(),
  rank: Yup.number().required(),
  withDebrief: Yup.boolean().required(),
});
