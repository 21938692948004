import { CompanyUserList } from "@components/CompanyUserList";
import { Client, ID } from "@justplayfair/model";
import { useMemo, useState } from "react";
import { useParams } from "react-router";

const PAGE_SIZE = 10;

export function ProgramMembersTabContent() {
  const { programId } = useParams<{
    programId: string;
  }>();

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const skip = useMemo(() => (page - 1) * PAGE_SIZE, [page]);
  const [sort, setSort] = useState<Client.Sort | undefined>({
    name: "name",
    order: "asc",
  });

  const [{ data, fetching }] = Client.useUserListQuery({
    variables: {
      search: {
        skip,
        take: PAGE_SIZE,
        filters: {
          query: new URLSearchParams({
            search,
            programId,
            roles: "TRAINEE",
          }).toString(),
          deleted: false,
        },
        sort,
      },
    },
  });

  return (
    <CompanyUserList
      programId={programId}
      fetching={fetching}
      users={data?.users.data}
      pageInfo={data?.users.pageInfo ?? null}
      page={page}
      setSearch={setSearch}
      setPage={setPage}
      sort={sort}
      setSort={setSort}
      getTraineeUrl={getTraineeUrl}
    />
  );
}

function getTraineeUrl(
  userId: ID,
  programId: ID | null,
  programIds: ID[]
): string | null {
  if (!programId && programIds.length > 1) {
    return null;
  }
  if (programId) {
    return `/trainer/trainee/dashboard/${programId}/${userId}`;
  }
  return `/trainer/trainee/dashboard/${programIds[0]}/${userId}`;
}
