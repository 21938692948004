import { RBCView } from "@components/Calendar/Calendar.component";
import {
  getDayDateRange,
  getMonthDateRange,
  getWeekDateRange,
} from "@justplayfair/model";
import enLocal from "date-fns/locale/en-US";
import frLocal from "date-fns/locale/fr";
import { DateRange } from "react-big-calendar";
import { registerLocale } from "react-datepicker";
const LOCAL_TO_DATE_FNS_LOCAL = {
  fr: frLocal,
  en: enLocal,
};

export function initDates(local: "fr" | "en") {
  registerLocale(local, LOCAL_TO_DATE_FNS_LOCAL[local]);
}

export function getViewDateRange(view: RBCView, date: Date): DateRange {
  switch (view) {
    case "day":
      return getDayDateRange(date);
    case "week":
      return getWeekDateRange(date);
    case "month":
      return getMonthDateRange(date);
    case "work_week":
    case "agenda":
      throw new Error("Not implemented");
  }
}
