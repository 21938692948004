import { CubeTransparentOutline } from "@graywolfai/react-heroicons";
import classnames from "clsx";
import { useTimeout } from "react-use";

interface LoaderProps {
  size: "large" | "medium" | "small";
  mode?: "default" | "overlay";
  delayMs?: number;
}

export function Loader({ size, mode = "default", delayMs = 400 }: LoaderProps) {
  const containerClassNames = classnames(
    "flex flex-grow justify-center items-center",
    {
      "fixed inset-0 bg-gray-500 bg-opacity-20": mode === "overlay",
    }
  );
  const svgClassNames = classnames("text-gray-500 animate-spin", {
    "h-6 w-6": size === "small",
    "h-12 w-12": size === "medium",
    "h-20 w-20": size === "large",
  });

  const [isReady] = useTimeout(delayMs);

  if (!isReady()) {
    return null;
  }
  return (
    <div className={containerClassNames}>
      <CubeTransparentOutline className={svgClassNames} />
    </div>
  );
}
