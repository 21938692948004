import { Img } from "@components/Img";
import { Client } from "@justplayfair/model";
import { ReactComponent as PdfSvg } from "./pdf.svg";
import { ReactComponent as VideoSvg } from "./video.svg";

interface FilePreviewProps {
  fileType: Client.FileType;
  storageFileId: string;
}
export function FilePreview({ fileType, storageFileId }: FilePreviewProps) {
  return (
    <div className="text-gray-600">
      {fileType === "PDF" && <PdfSvg className="h-10 w-10 fill-current" />}
      {fileType === "IMAGE" && (
        <Img src={storageFileId} className="h-10 w-10" alt="File preview" />
      )}
      {fileType === "VIDEO" && <VideoSvg className="h-10 w-10 fill-current" />}
    </div>
  );
}
