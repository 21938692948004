import { AddUserModal } from "@components/AddUserModal";
import { Client } from "@justplayfair/model";
import { useEffect, useState } from "react";

interface AddUserToGroupModalProps {
  isOpen: boolean;
  companyId: string;
  setIsOpen: (open: boolean) => void;
  onAdd: (userIds: string[]) => Promise<void>;
}
export function AddUserToProgramModal({
  isOpen,
  setIsOpen,
  companyId,
  onAdd,
}: AddUserToGroupModalProps) {
  const [search, setSearch] = useState("");

  const [data, reload] = Client.useUserListQuery({
    variables: {
      search: {
        take: 10000,
        skip: 0,
        filters: {
          query: new URLSearchParams({
            companyId,
            search: search,
          }).toString(),
          deleted: false,
        },
      },
    },
  });

  useEffect(() => {
    if (isOpen) {
      reload();
    }
  }, [isOpen, reload]);

  return (
    <AddUserModal
      isOpen={isOpen}
      users={data.data?.users.data || []}
      onSearchChange={setSearch}
      onAdd={onAdd}
      onClose={() => setIsOpen(false)}
    />
  );
}
