import {
  Client,
  ID,
  NumberUtilities,
  Maybe,
  Evaluation,
  ObjectUtilities,
} from "@justplayfair/model";
import { round } from "@justplayfair/model/dist/utilities/numbers.utilities";

export type EvaluationData = Record<
  string,
  { name: string; capacities: Record<string, Evaluation.Evaluation> }
>;

export interface EvaluationRecord {
  id: ID;
  trainerRating?: Maybe<number>;
  iaRating?: Maybe<number>;
  globalRating?: Maybe<number>;
  skill: { id: ID; name: string };
  capacity: { id: ID; nameKey: string };
}

export interface FormValues {
  rates: Record<string, Record<string, Client.Evaluation>>;
}

export function evaluationRecordsToEvaluationData(
  evaluations: EvaluationRecord[]
): EvaluationData {
  const evaluationData: EvaluationData = {};
  for (let evaluation of evaluations) {
    const skillName = evaluation.skill.name;
    const skillId = evaluation.skill.id;
    evaluationData[evaluation.skill.id] = {
      name: skillName,
      capacities: {
        ...evaluationData[skillId]?.capacities,
        // If initial evaluation. Use capacity ID as evaluation ID
        [evaluation.id || evaluation.capacity.id]: {
          ...evaluation,
          iaRating: ObjectUtilities.isDefined(evaluation.iaRating)
            ? round(evaluation.iaRating)
            : null,
          trainerRating: ObjectUtilities.isDefined(evaluation.trainerRating)
            ? round(evaluation.trainerRating)
            : null,
          globalRating: ObjectUtilities.isDefined(evaluation.globalRating)
            ? round(evaluation.globalRating)
            : null,
        },
      },
    };
  }
  return evaluationData;
}

export function sanitizedRateValue(value: unknown, maxRate = 4): number | null {
  if (NumberUtilities.isNumber(value, true) && value >= 0 && value <= maxRate) {
    return value;
  }
  return null;
}

export function evaluationDataToInitialValues(
  evaluationData: EvaluationData
): FormValues {
  const initialValues: FormValues = {
    rates: {},
  };

  const skillEntries = Object.entries(evaluationData);
  for (const [skillId, capacityEvaluation] of skillEntries) {
    initialValues.rates[skillId] = {
      ...capacityEvaluation.capacities,
    };
  }
  return initialValues;
}
