import { Switch } from "@headlessui/react";
import classNames from "clsx";

export interface ToggleProps {
  id: string;
  label?: string;
  checked: boolean;
  onChange: (value: boolean) => void;
}
export function Toggle({ id, label, checked, onChange }: ToggleProps) {
  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        id={id}
        checked={checked}
        onChange={onChange}
        className={classNames(
          "relative inline-flex flex-shrink-0 h-5 w-8 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200",
          { "bg-primary": checked, "bg-gray-200": !checked }
        )}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={classNames(
            "pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
            { "translate-x-3": checked, "translate-x-0": !checked }
          )}
        />
      </Switch>
      {label && (
        <label htmlFor={id} className="ml-2 block text-sm text-gray-900">
          {label}
        </label>
      )}
    </Switch.Group>
  );
}
