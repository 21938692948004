import { Client } from "@justplayfair/model";
import { QRCodeSVG } from "qrcode.react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button";
import { Title } from "../../../components/Title";

interface MfaManagementProps {
  user: Client.MeUserFieldsFragment;
  onUpdate: VoidFunction;
}
export function MfaManagement({ user, onUpdate }: MfaManagementProps) {
  const { t } = useTranslation();
  const [otpEnrollmentCode, setOtpEnrollmentCode] = useState<
    Client.OtpEnrollmentFieldsFragment | undefined
  >();

  const [{ fetching: otpSecretLoading }, generateUserOtpSecret] =
    Client.useGenerateUserOtpSecretMutation();
  const [, activateOtp] = Client.useActivateOtpMutation();

  const [{ fetching: deactivateOtpFetching }, deactivateOtp] =
    Client.useDeactivateOtpMutation();

  async function handleGenerateOtpSecret() {
    const { data } = await generateUserOtpSecret({});
    setOtpEnrollmentCode(data?.generateUserOtpSecret);
    onUpdate();
  }

  async function handleActivateOtp() {
    if (!otpEnrollmentCode) {
      throw new Error("No otpEnrollmentCode in component state");
    }
    await activateOtp({
      otpSecret: otpEnrollmentCode.otpSecret,
    });
    setOtpEnrollmentCode(undefined);

    onUpdate();
  }

  async function handleDeactivateOtp() {
    await deactivateOtp({});
    onUpdate();
  }
  return (
    <div>
      <Title size={5}>{t("profile.title.twoFactorAuthentication")}</Title>
      {!otpEnrollmentCode && (
        <MfaHelperText>
          <div>{t("profile.text.otpActivatedHelperText1")}</div>
          <div>{t("profile.text.otpActivatedHelperText2")}</div>
        </MfaHelperText>
      )}
      <div className="p-4 flex items-center justify-center space-y-4">
        {!user.mfaActivated && !otpEnrollmentCode && (
          <div className="w-72">
            <Button
              onClick={handleGenerateOtpSecret}
              loading={otpSecretLoading}
              title={t("profile.button.activateTwoFactorAuthentication.title")}
            >
              {t("profile.button.activateTwoFactorAuthentication.label")}
            </Button>
          </div>
        )}

        {otpEnrollmentCode && (
          <OtpEnrollment
            otpEnrollmentCode={otpEnrollmentCode}
            onActivate={handleActivateOtp}
          />
        )}
        {user.mfaActivated && !otpEnrollmentCode && (
          <div>
            <div className="w-72">
              <Button
                variant="warning"
                onClick={handleDeactivateOtp}
                loading={deactivateOtpFetching}
                title={t(
                  "profile.button.deactivateTwoFactorAuthentication.title"
                )}
              >
                {t("profile.button.deactivateTwoFactorAuthentication.label")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

interface OtpEnrollmentProps {
  otpEnrollmentCode: Client.OtpEnrollmentFieldsFragment;
  onActivate: VoidFunction;
}
function OtpEnrollment({ otpEnrollmentCode, onActivate }: OtpEnrollmentProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center gap-y-4">
      <QRCodeSVG value={otpEnrollmentCode.otpAuth} />
      {otpEnrollmentCode.otpSecret}
      <MfaHelperText>
        <div>{t("profile.text.mfaEnrollmentHelperText")}</div>
        <div>
          <ul className="list-disc pl-8">
            <li>
              <AppAuthenticatorLink
                link="https://support.1password.com/one-time-passwords"
                text="1Password"
              />
            </li>
            <li className="space-x-2">
              <span>Google Authenticator</span>
              <span>
                <AppAuthenticatorLink
                  link="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=fr&gl=US"
                  text="Android"
                />
                /
                <AppAuthenticatorLink
                  link="https://apps.apple.com/fr/app/google-authenticator/id388497605"
                  text="iOS"
                />
              </span>
            </li>
            <li>
              <AppAuthenticatorLink
                link="https://www.microsoft.com/en-us/security/mobile-authenticator-app"
                text="Microsoft Authenticator"
              />
            </li>
          </ul>
        </div>
        <div className="flex justify-center">
          <div className="w-72">
            <Button
              onClick={onActivate}
              title={t("profile.button.saveTwoFactorAuthentication.title")}
            >
              {t("profile.button.saveTwoFactorAuthentication.label")}
            </Button>
          </div>
        </div>
      </MfaHelperText>
    </div>
  );
}

function MfaHelperText({ children }: { children: React.ReactNode }) {
  return <div className="text-sm text-gray-800 space-y-2">{children}</div>;
}

interface AppAuthenticatorLinkProps {
  text: string;
  link: string;
}
function AppAuthenticatorLink({ text, link }: AppAuthenticatorLinkProps) {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="font-medium text-primary hover:text-primary-dark"
    >
      {text}
    </a>
  );
}
