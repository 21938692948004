import logo from "./logo.png"; // Tell webpack this JS file uses this image
import classnames from "clsx";

interface LogoProps {
  size: "small" | "large";
  // variant: "dark" | "light";
}
export function Logo({ size }: LogoProps) {
  const containerClasses = classnames({
    "w-8": size === "small",
    "w-12": size === "large",
  });

  return (
    <div className={containerClasses}>
      <img src={logo} alt="Logo" />
    </div>
  );
}
