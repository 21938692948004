import { Client } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import { Chip } from "../../../../../components/Chip";
import { DisplayDate } from "../../../../../components/DateTime";
import { Title } from "../../../../../components/Title";

interface SessionDescriptionTitleProps {
  session: Client.SessionFieldsFragment;
}
export function SessionDescriptionTitle({
  session,
}: SessionDescriptionTitleProps) {
  const { t } = useTranslation();
  return (
    <div className="w-full p-2 lg:flex lg:justify-around lg:items-center space-y-8 lg:space-y-0 lg:p-4 divide-x divide-gray-200">
      <Title size={5}>{session.name}</Title>

      <div className="flex items-center flex-grow px-4 mx-4 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-24 gap-y-2 sm:grid-cols-4">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {t("trainer.session.label.trainee")}
            </dt>
            <dd className="mt-0.5 text-sm text-gray-900">
              {session.trainee?.name}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {t("trainer.session.label.trainer")}
            </dt>
            <dd className="mt-0.5 text-sm text-gray-900">
              {session.trainer?.name}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {t("trainer.session.label.status")}
            </dt>
            <dd className="mt-0.5 text-sm text-gray-900">
              <Chip
                color="transparent"
                title={t(`common.session.status.${session.status}`)}
                label={t(`common.session.status.${session.status}`)}
              />
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {t("trainer.session.label.date")}
            </dt>
            <dd className="mt-0.5 text-sm text-gray-900 space-x-2">
              <span>
                {session.appointment?.startDate && (
                  <DisplayDate date={new Date(session.appointment.startDate)} />
                )}
                {!session.appointment?.startDate && "-"}
              </span>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
