import { Client } from "@justplayfair/model";
import { useMemo } from "react";

export function useExternalSessionMappings() {
  const [{ data: externalSessionMappings, fetching }] =
    Client.useExternalSessionMappingsQuery();

  const sessionMappingOpts = useMemo(() => {
    if (!externalSessionMappings?.getExternalSessionMappings) {
      return [];
    }
    return externalSessionMappings.getExternalSessionMappings.map(
      (mapping) => ({
        value: mapping.id,
        label: mapping.name,
      })
    );
  }, [externalSessionMappings]);
  return {
    sessionMappingOpts,
    fetching,
  };
}
