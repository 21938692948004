import { PageContainer } from "@components/Layout";
import { Loader } from "@components/Loader";
import { Client } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { AuthTokenList } from "./components/AuthTokenList.component";
import { UserForm } from "./EditUserForm.component";

export function EditUserPage() {
  const { t } = useTranslation();
  let { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [{ data, fetching }, reloadUser] = Client.useGetUserAdminQuery({
    variables: { id },
  });

  const [{ data: companiesData, fetching: fetchingCompanies }] =
    Client.useSearchCompaniesQuery({
      variables: { search: { take: 10000, skip: 0 } },
    });

  const [, updateUser] = Client.useUpdateUserAdminMutation();
  const [, deleteUser] = Client.useDeleteUserAdminMutation();
  const [, undeleteUser] = Client.useUndeleteUserAdminMutation();
  const [, revokeToken] = Client.useRevokeTokenMutation();

  async function handleEditUser(input: Client.UpdateUserInputAdmin) {
    await updateUser({ input });
  }

  async function handleDeleteUser() {
    if (
      window.confirm("Etes vous sûr de vouloir supprimer cet utilisateur ?")
    ) {
      await deleteUser({ id });
      history.goBack();
    }
  }
  async function handleUndelete() {
    if (
      window.confirm("Etes vous sûr de vouloir restituer cet utilisateur ?")
    ) {
      await undeleteUser({ id });
      history.goBack();
    }
  }

  async function handleRevokeToken(tokenId: string) {
    if (window.confirm("Etes vous sûr de vouloir révoquer ce token ?")) {
      await revokeToken({ tokenId });
      reloadUser({ requestPolicy: "network-only" });
    }
  }

  if (fetching || fetchingCompanies) {
    return (
      <PageContainer title={t("menu.admin")}>
        {<Loader size="medium" />}
      </PageContainer>
    );
  }

  return (
    <PageContainer
      title={t("menu.admin")}
      subtitle={t("admin.title.users")}
      backButton={`/admin/companies/${data?.user?.company?.id}`}
    >
      <div className="w-full max-w-4xl mx-auto">
        {!data?.user && "Introuvable"}
        {data?.user && (
          <UserForm
            user={data.user}
            companies={companiesData?.companies.data}
            onEditUser={handleEditUser}
            onDeleteUser={handleDeleteUser}
            onUndelete={handleUndelete}
          />
        )}
      </div>

      <div className="w-full max-w-4xl mx-auto">
        {data?.user && (
          <AuthTokenList
            tokens={data.user.authTokens || []}
            onRevokeToken={handleRevokeToken}
          />
        )}
      </div>
    </PageContainer>
  );
}
