import { FormikInput } from "@components/form/Input/FormikInput";
import { Loader } from "@components/Loader";
import { Client, DEFAULT_MAX_RATE } from "@justplayfair/model";
import { useFormikContext } from "formik";

interface FormikTargetedLevelFieldsProps {
  fieldName: string;
  editMode: boolean;
}
export function FormikTargetedLevelFields({
  fieldName,
  editMode,
}: FormikTargetedLevelFieldsProps) {
  const {
    values: { companyId, skills },
  } = useFormikContext<{
    companyId?: string;
    skills: Client.ProgramSkillInput[];
  }>();

  const [{ data, fetching: fetchCompany }] = Client.useGetCompanyQuery({
    pause: !companyId,
    variables: companyId
      ? {
          id: companyId,
        }
      : undefined,
  });

  return (
    <>
      {!fetchCompany &&
        skills
          .filter((skill) => skill.name)
          .map((skill, index) => (
            <FormikInput
              key={index}
              editMode={editMode}
              id={`${fieldName}.${skill.id}`}
              type="number"
              min={0}
              step=".01"
              max={data?.company?.maxRate || DEFAULT_MAX_RATE}
              label={skill.name}
              withSeparator={false}
            />
          ))}
      {fetchCompany && <Loader size="medium" />}
    </>
  );
}
