import { ReactNode } from "react";

interface AdminCreationButtonContainerProps {
  children: ReactNode;
}
export function AdminCreationButtonContainer({
  children,
}: AdminCreationButtonContainerProps) {
  return (
    <div className="flex flex-row justify-end space-x-4">
      <div className="w-56">{children}</div>
    </div>
  );
}
