import { BarData, CHART_COLORS } from "@justplayfair/model";
import { BarCustomLayerProps } from "@nivo/bar";
import { path } from "d3-path";
import { useMemo } from "react";

export const BarStats: React.FC<BarCustomLayerProps<BarData>> = ({
  bars,
  xScale,
}) => {
  const stdDeviationPaths = useMemo(() => {
    const stdDeviationData = bars
      .filter((bar) => !!bar.data.data.stats)
      .map((bar) => ({
        x: xScale(bar.data.data.stats.mean),
        y: bar.y + bar.height / 2,
        len: xScale(bar.data.data.stats.stdDeviation * 2),
      }));
    return stdDeviationData.map((data) => {
      const stdDevPath = path();
      stdDevPath.moveTo(data.x - data.len / 2, data.y);
      stdDevPath.lineTo(data.x + data.len / 2, data.y);
      stdDevPath.closePath();
      return stdDevPath.toString();
    });
  }, [bars, xScale]);

  return (
    <>
      {stdDeviationPaths.map((path, index) => (
        <path
          key={index}
          d={path}
          fill="none"
          stroke="#000"
          style={{ pointerEvents: "none" }}
        />
      ))}
      {bars
        .filter((bar) => !!bar.data.data.stats)
        .map((bar) => {
          const cx = xScale(bar.data.data.stats.mean);
          const cy = bar.y + bar.height / 2;

          return (
            <>
              <circle
                key={bar.key}
                cx={cx}
                cy={cy}
                r={4}
                fill="#fff"
                stroke={CHART_COLORS.gold}
                strokeWidth={2}
                style={{ pointerEvents: "none" }}
              />
            </>
          );
        })}
    </>
  );
};
