import { Loader } from "@components/Loader";
import {
  Table,
  TableCell,
  TableHeading,
} from "@components/Table/Table.component";
import { Client } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

export function ProgramTargetsTabContent() {
  const { programId } = useParams<{
    programId: string;
  }>();
  const [{ data, fetching }] = Client.useProgramDashboardQuery({
    variables: { programId },
    requestPolicy: "cache-only",
  });
  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          {fetching && <Loader size="medium" />}

          {!fetching && data?.program && (
            <TargetedLevelsTable program={data.program} />
          )}
        </div>
      </div>
    </div>
  );
}

interface TaregetedLevelsTabProps {
  program: NonNullable<Client.ProgramDashboardQuery["program"]>;
}
function TargetedLevelsTable({ program }: TaregetedLevelsTabProps) {
  const { t } = useTranslation();

  return (
    <Table
      heading={
        <>
          <TableHeading align="left" width="30%">
            COMPÉTENCE
          </TableHeading>
          <TableHeading align="center">Objectif de note</TableHeading>
        </>
      }
    >
      {!program?.targetedLevels?.length && (
        <tr>
          <td className="p-4 text-sm text-gray-700" colSpan={2}>
            {t("common.text.noData")}
          </td>
        </tr>
      )}
      {program.targetedLevels?.map((targetLevel) => (
        <tr key={targetLevel.id}>
          <TableCell align="left" dense>
            {targetLevel.skill.name}
          </TableCell>
          <TableCell align="center" dense>
            {targetLevel.targetedRate}
          </TableCell>
        </tr>
      ))}
    </Table>
  );
}
