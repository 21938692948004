import { ProgressBar } from "@components/ProgressBar";
import { Client } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import classnames from "clsx";
interface TraineeAdvancementProps {
  advancement: Client.Advancement;
  withBackground?: boolean;
}
export function TraineeAdvancement({
  advancement,
  withBackground,
}: TraineeAdvancementProps) {
  const { t } = useTranslation();
  const containerClasses = classnames(
    "flex flex-col justify-center w-72 h-20 px-4 py-2",
    { "bg-gray-100": withBackground }
  );
  return (
    <div className={containerClasses}>
      <div className="uppercase text-gray-600">
        {t("trainer.dashboard.text.sessionAdvancement")}
      </div>
      {advancement.nbSession === 0 && (
        <div className="italic text-sm text-gray-500">
          {t("trainer.dashboard.text.noSession")}
        </div>
      )}
      {advancement.nbSession > 0 && (
        <ProgressBar
          value={advancement.nbSessionDone}
          max={advancement.nbSession}
          withLabel
        />
      )}
    </div>
  );
}
