import { Button } from "@components/Button";
import { Checkbox } from "@components/form/Checkbox";
import { TableHeading } from "@components/Table";
import { Pagination } from "@components/Table/Pagination.component";
import {
  Table,
  TableCell,
  TableContainer,
} from "@components/Table/Table.component";
import { Title } from "@components/Title";
import { UserDescription } from "@components/UserDescription";
import { Client } from "@justplayfair/model";
import classnames from "clsx";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "../form/Input";
import { useDebounceFieldValue } from "../form/Input/useDebounceField.hook";

interface PaginationActions {
  pageInfo: Client.PageInfo;
  onPreviousPage: VoidFunction;
  onNextPage: VoidFunction;
}
export interface CustomCell<User extends Client.UserDetailsFieldsFragment> {
  headerName: string;
  align: "left" | "center" | "right";
  render: (user: User) => ReactNode;
}
export interface UserListProps<User extends Client.UserDetailsFieldsFragment> {
  users: User[];
  paginationActions?: PaginationActions;
  renderCustomCells?: CustomCell<User>[];
  sort?: { value?: Client.Sort; onChange: (sort: Client.Sort) => void };
  onSearch?: (search: string) => void;
  onSelect?: (ids: string[]) => void;
  clickable?: (user: Client.UserDetailsFieldsFragment) => boolean;
  onClick?: (user: Client.UserDetailsFieldsFragment) => void;
  customActions?: (user: User) => ReactNode;
  noShadow?: boolean;
}

export function UserList<User extends Client.UserDetailsFieldsFragment>({
  users,
  paginationActions,
  clickable,
  onClick,
  onSelect,
  onSearch,
  renderCustomCells,
  customActions,
  noShadow,
  sort,
}: UserListProps<User>) {
  const { t } = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const {
    fieldValue: search,
    debouncedValue: debouncedSearch,
    setFieldValue: setSearch,
  } = useDebounceFieldValue({ initialValue: "", debounceTime: 250 });

  useEffect(() => {
    if (onSearch) {
      onSearch(debouncedSearch);
    }
  }, [debouncedSearch, onSearch]);

  function changeSort(name: "name" | "updatedAt") {
    if (!sort) {
      throw new Error('Should not call "changeSort" if no "sort" prop defined');
    }
    sort.onChange({
      name,
      order: sort.value?.order === "desc" ? "asc" : "desc",
    });
  }

  return (
    <div
      className={classnames("w-full sm:rounded-lg", {
        "shadow border-b border-gray-200": !noShadow,
      })}
    >
      {(onSearch || onSelect) && (
        <div className="flex justify-between pb-2">
          {onSearch && (
            <div className="w-80">
              <Input
                id="search-users"
                type="search"
                placeholder={t("common.text.search")}
                value={search}
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
              />
            </div>
          )}
          {onSelect && (
            <div className="flex justify-end items-center space-x-4 py-2 px-1">
              <div className="w-24">
                <Button
                  disabled={selectedUsers.length === 0}
                  onClick={() => onSelect(selectedUsers)}
                  title={t("common.button.validate.label")}
                >
                  {t("common.button.validate.label")}
                </Button>
              </div>
              <div className="text-xs text-gray-500 overflow-auto">{`${
                selectedUsers.length
              } / ${
                paginationActions
                  ? paginationActions.pageInfo.count
                  : users.length
              } ${t("component.userList.label.selected")}`}</div>
            </div>
          )}
        </div>
      )}
      <TableContainer>
        <Table
          heading={
            <>
              {onSelect && (
                <TableHeading align="left" width="15%">
                  <span>{t("component.userList.label.select")}</span>
                </TableHeading>
              )}
              <TableHeading
                align="left"
                sort={
                  sort
                    ? {
                        order:
                          sort?.value?.name === "name"
                            ? sort.value.order
                            : undefined,
                        onChange: () => changeSort("name"),
                      }
                    : undefined
                }
              >
                {t("admin.user.heading.name")}
              </TableHeading>

              {renderCustomCells?.map((customCell, index) => (
                <TableHeading key={index} align={customCell.align}>
                  {customCell.headerName}
                </TableHeading>
              ))}

              {customActions && (
                <TableHeading align="right">
                  <span className="sr-only">Edit</span>
                </TableHeading>
              )}
            </>
          }
        >
          {users.length === 0 && (
            <Title size={3}>{t("common.text.noData")}</Title>
          )}
          {users.length > 0 &&
            users.map((user) => {
              const clickableRow =
                onClick && (clickable ? clickable(user) : true);

              const rowClasses = classnames({
                "transition hover:bg-gray-50 cursor-pointer": !!clickableRow,
              });

              return (
                <tr
                  key={user.id}
                  onClick={() => {
                    if (clickableRow) {
                      onClick?.(user);
                    }
                  }}
                  className={rowClasses}
                >
                  {onSelect && (
                    <TableCell align="left">
                      <Checkbox
                        id={`select-${user.id}`}
                        checked={selectedUsers.includes(user.id)}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setSelectedUsers([...selectedUsers, user.id]);
                          } else {
                            setSelectedUsers(
                              selectedUsers.filter(
                                (userId) => userId !== user.id
                              )
                            );
                          }
                        }}
                      />
                    </TableCell>
                  )}
                  <TableCell align="left">
                    <UserDescription user={user} />
                  </TableCell>
                  {renderCustomCells?.map((customCell, index) => (
                    <TableCell key={index} align={customCell.align}>
                      {customCell.render(user)}
                    </TableCell>
                  ))}

                  {customActions && (
                    <TableCell align="right">{customActions(user)} </TableCell>
                  )}
                </tr>
              );
            })}
        </Table>
        {paginationActions && (
          <Pagination
            pageInfo={paginationActions.pageInfo}
            onPrevious={paginationActions.onPreviousPage}
            onNext={paginationActions.onNextPage}
          />
        )}
      </TableContainer>
    </div>
  );
}
