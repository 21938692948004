import { Button } from "@components/Button";
import { CardSection } from "@components/CardSection";
import { Comment } from "@components/Comment";
import { TraineeChartFilters } from "@components/Consult/ChartFilters/TraineeChartFilters.component";
import { DangerousHtml } from "@components/DangerousHtml";
import { Loader } from "@components/Loader";
import { Title } from "@components/Title";
import { Client, DatavizMapper, ID, ReportRest } from "@justplayfair/model";
import { reportService } from "@services/report/report.service";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { dateFilterToDateSearchQueryField } from "../../../../dataviz/filters.utilities";
import { ComparisonBarChart } from "../../../../components/Consult/chart/ComparisonChart.component";
import { EvolutionBarChart } from "../../../../components/Consult/chart/EvolutionChart.component";
import { RateDetailsChart } from "../../../../components/Consult/chart/RateDetails.component";
import { SkillsRatesChart } from "../../../../components/Consult/chart/SkillsRates.component";
import {
  FiltersValue,
  isFiltered,
} from "../../../../components/Consult/ChartFilters/ChartFilters.component";
import { toSearchEntries } from "../../../../search/search.utilities";

export function TraineeDatavizTabContent() {
  const { t } = useTranslation();

  const { programId, traineeId } = useParams<{
    programId: ID;
    traineeId: ID;
  }>();

  const [downloadingReport, setDownloadingReport] = useState(false);
  const [filterSessionId, setFilterSessionId] = useState<ID>();
  const [filters, setFilters] = useState<FiltersValue>({});

  const [{ data: userData, fetching: fetchingUser }] =
    Client.useTraineeDetailsDashboardQuery({
      variables: {
        programId,
        traineeId,
        sessionSearch: {
          filters: {
            query: new URLSearchParams(
              toSearchEntries({
                programIds: [programId],
                traineeIds: [traineeId],
              })
            ).toString(),
          },
          take: 1000,
          skip: 0,
        },
      },
      requestPolicy: "cache-only",
    });

  const [{ data: evaluationNotesData }] = Client.useEvaluationNotesQuery({
    variables: {
      programId,
      traineeId,
      sessionId: filterSessionId,
    },
  });

  const [{ data: skillsRates, fetching: fetchingRadar }] =
    Client.useGetSkillsRatesQuery({
      variables: {
        filters: {
          traineesArgs: [
            {
              traineeId,
              programId,
              sessionId: filterSessionId,
              comparison: {
                programId,
                labelIds: filters.labelIds,
                withCompanyStats: filters.compareToCompany,
                traineeId: filters.comparisonTraineeId,
                sessionId: filters.comparisonSessionId,
                date: dateFilterToDateSearchQueryField(filters),
              },
            },
          ],
        },
      },
      requestPolicy: "network-only",
    });

  const radarData = useMemo(() => {
    if (!skillsRates?.getSkillsRates.length) {
      return undefined;
    }

    return DatavizMapper.radarDataMapper(skillsRates.getSkillsRates, t);
  }, [t, skillsRates]);

  const [{ data: detailsData, fetching: fetchingDetailBars }] =
    Client.useGetRateDetailsQuery({
      variables: {
        filters: {
          traineesArgs: [
            {
              traineeId,
              programId,
              sessionId: filterSessionId,
              comparison: {
                programId,
                labelIds: filters.labelIds,
                withCompanyStats: filters.compareToCompany,
                traineeId: filters.comparisonTraineeId,
                sessionId: filters.comparisonSessionId,
                date: dateFilterToDateSearchQueryField(filters),
              },
            },
          ],
        },
      },
      requestPolicy: "network-only",
    });

  const detailBarsData = useMemo(() => {
    if (!detailsData) {
      return undefined;
    }

    return detailsData.getRateDetails
      .filter((rateDetail) => rateDetail.type === "trainee") // Dont display group details data
      .map((details) => DatavizMapper.byKeyBarDataMapper(details, t));
  }, [detailsData, t]);

  const comparisonBarsData = useMemo(() => {
    if (!detailsData) {
      return undefined;
    }

    return detailsData.getRateDetails
      .filter(
        (rateDetail) => rateDetail.type === "trainee" && rateDetail.statName
      ) // Dont display group details data
      .map((details) => DatavizMapper.byKeyBarDataComparisonMapper(details, t));
  }, [detailsData, t]);

  const [{ data: evolutionData, fetching: fetchingEvolutionBars }] =
    Client.useGetSkillEvolutionsQuery({
      variables: {
        filters: {
          traineesArgs: [{ traineeId, programId }],
        },
      },
      requestPolicy: "network-only",
    });

  const evolutionBarsData = useMemo(() => {
    if (!evolutionData) {
      return undefined;
    }

    return evolutionData.getSkillEvolutions
      .map((details) => DatavizMapper.byKeyBarDataMapper(details, t))
      .filter((barsData) => barsData.keys.length > 1); // Display only if more than 1 measurement
  }, [evolutionData, t]);

  const isComparison = isFiltered(filters);

  async function handleOnGenerateReport() {
    try {
      setDownloadingReport(true);

      const queryArgs: ReportRest.TraineeReportQuery = {
        traineeArgs: {
          traineeId,
          programId,
          sessionId: filterSessionId,
          comparison: isComparison
            ? {
                programId,
                labelIds: filters.labelIds,
                withCompanyStats: filters.compareToCompany,
                traineeId: filters.comparisonTraineeId,
                sessionId: filters.comparisonSessionId,
                date: dateFilterToDateSearchQueryField(filters),
              }
            : undefined,
        },
        debug: false,
      };
      await reportService.downloadTraineeReport(queryArgs);
    } finally {
      setDownloadingReport(false);
    }
  }

  if (fetchingUser) {
    return <Loader size="large" />;
  }
  if (!userData?.userInProgram) {
    return <Title size={6}>{t("common.text.notFound")}</Title>;
  }

  if (!userData.userInProgram.company) {
    throw new Error("Wrong state. User must have a company!");
  }

  return (
    <div className="space-y-4">
      <CardSection title={t("trainer.dashboard.title.appraisal")}>
        <Comment
          editable={false}
          content={userData.traineeNoteInProgram?.value || undefined}
          fallbackMessage={t("trainer.dashboard.text.noCommentYet")}
          loading={fetchingUser}
        />
      </CardSection>

      <div className="w-full border-t-2 border-gray-200" />

      <CardSection title={t("trainer.dashboard.title.filters")}>
        <TraineeChartFilters
          forCompanyId={userData.userInProgram.company.id}
          traineeArgs={{ traineeId, programId }}
          nbMembersInGroup={skillsRates?.getSkillsRates[1]?.nbMembersInGroup}
          setFilterSessionId={setFilterSessionId}
          setGroupFilters={setFilters}
          activatedFields={{
            compareToCompany: true,
            departmentLabelId: true,
            hierarchicalPositionLabelId: true,
            businessUnitLabelId: true,
            seniorityLabelId: true,
            date: false,
            firstSession: true,
            comparisonTraineeId: false,
            comparisonSessionId: true,
          }}
        />
      </CardSection>
      <div className="flex justify-end px-2">
        <div className="w-44">
          <Button
            variant="secondary"
            title={t("common.button.generateAReport.label")}
            onClick={handleOnGenerateReport}
          >
            {t("common.button.generateAReport.label")}
          </Button>
        </div>
      </div>

      <CardSection
        title={`${t("trainer.dashboard.text.at")} ${
          skillsRates?.getSkillsRates[0]?.name
        }`}
      >
        <div className="space-y-8">
          <SkillsRatesChart
            skillsRatesData={radarData}
            fetching={fetchingRadar}
          />

          <div className="w-full">
            <Title size={6}>{t("trainer.dashboard.text.details")}</Title>
            <RateDetailsChart
              name={userData.userInProgram.name}
              detailsData={detailBarsData}
              fetching={fetchingDetailBars}
              height={900}
            />
          </div>
          {isComparison && (
            <div className="w-full">
              <Title size={6}>
                {t("trainer.dashboard.text.rateComparison")}
              </Title>
              <ComparisonBarChart
                comparisonData={comparisonBarsData}
                fetching={fetchingDetailBars}
                height={700}
              />
            </div>
          )}
          {evaluationNotesData?.evaluationComment?.value && (
            <div className="p-3 pt-0">
              <Title size={6}>
                {t("trainer.dashboard.text.lastEvaluationComment")}
              </Title>
              <div className="p-2 border rounded">
                <DangerousHtml
                  html={evaluationNotesData.evaluationComment.value}
                />
              </div>
            </div>
          )}
          {evaluationNotesData?.evaluationReview?.value && (
            <div className="p-3 pt-0">
              <Title size={6}>
                {t("trainer.dashboard.text.lastEvaluationReview")}
              </Title>
              <div className="p-2 border rounded">
                <DangerousHtml
                  html={evaluationNotesData.evaluationReview.value}
                />
              </div>
            </div>
          )}

          <div className="w-full">
            <Title size={6}>{t("trainer.dashboard.text.evolution")}</Title>
            <EvolutionBarChart
              evolutionData={evolutionBarsData}
              fetching={fetchingEvolutionBars}
              height={700}
            />
          </div>
        </div>
      </CardSection>
      {downloadingReport && <Loader size="medium" mode="overlay" />}
    </div>
  );
}
