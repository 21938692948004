import { Logo } from "@components/Logo/Logo.component";
import { UpdateNotif } from "@components/UpdateNotif";
import { BellOutline, ExternalLinkOutline } from "@graywolfai/react-heroicons";
import { useAuthenticatedUser } from "@hooks/authenticatedUser/useAuthenticatedUser.hook";
import { authService } from "@services/auth";
import classNames from "clsx";
import { Fragment, ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { FloatingAppAlert } from "../Alert/FloatingAppAlert";
import { Avatar } from "../Avatar";
import { Dropdown } from "../Dropdown";
import { AuthenticatedUser } from "./AuthenticatedUser";
import { GuideButton } from "./GuideButton/GuideButton.component";
import { getUserMenuItems, NAVBAR_LINKS } from "./Layout.config";
import { Theme } from "./Layout.model";
import { MobileMenu } from "./MobileMenu";

interface LayoutProps {
  children: ReactNode;
}

export function Layout({ children }: LayoutProps) {
  const { user, notifications } = useAuthenticatedUser();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const { t } = useTranslation();

  const isUserATrainee = authService.hasSomeRoles("TRAINEE", "MANAGER");
  const theme: Theme = isUserATrainee ? "blue" : "dark";

  const notAcquitedNotif = useMemo(
    () => notifications?.filter((notif) => notif.acquitted === false) ?? [],
    [notifications]
  );
  function handleOpenMobileMenu(isOpen: boolean) {
    setIsMobileMenuOpen(isOpen);
  }

  // const userPlanningLink = useMemo(() => {
  //   if (!user) {
  //     return undefined;
  //   }

  //   if (authService.hasSomeRoles("TRAINER")) {
  //     return "/trainer/planning";
  //   }
  //   if (authService.hasSomeRoles("TRAINEE")) {
  //     return "/trainee/planning";
  //   }
  // }, [user]);

  const navClassnames = classNames("w-full content-between", {
    "bg-gray-800": theme === "dark",
    "bg-blue-800": theme === "blue",
  });

  const navLinkClassnames = classNames(
    "px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700",
    {
      "hover:bg-gray-700": theme === "dark",
      "hover:bg-blue-700": theme === "blue",
    }
  );
  const navLinkActiveClassnames = classNames("text-white", {
    "bg-gray-900 hover:bg-gray-900": theme === "dark",
    "bg-blue-900 hover:bg-blue-900": theme === "blue",
  });
  const iconLinkClassnames = classNames(
    "p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white",
    {
      "focus:ring-offset-gray-800": theme === "dark",
      "focus:ring-offset-blue-800": theme === "blue",
    }
  );

  const menuItems = useMemo(() => getUserMenuItems(t), [t]);

  return (
    <div className="min-h-screen w-full flex flex-col">
      <nav className={navClassnames}>
        <div className="xl:w-11/12 w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Link to="/dashboard">
                  <Logo size="small" />
                </Link>
              </div>
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4  text-gray-300">
                  {NAVBAR_LINKS.filter((navbarLink) =>
                    navbarLink.mandatoryRole
                      ? authService.hasSomeRoles(...navbarLink.mandatoryRole)
                      : true
                  ).map((navbarLink, index) => (
                    <Fragment key={index}>
                      {!navbarLink.external && (
                        <NavLink
                          to={navbarLink.to}
                          isActive={navbarLink.isActive}
                          className={navLinkClassnames}
                          activeClassName={navLinkActiveClassnames}
                        >
                          {t(navbarLink.labelKey)}
                        </NavLink>
                      )}
                      {navbarLink.external && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={navbarLink.to}
                          className={navLinkClassnames}
                        >
                          <span className="space-x-2 flex items-center">
                            <span>
                              <ExternalLinkOutline className="h3 w-3" />
                            </span>
                            <span>{t(navbarLink.labelKey)}</span>
                          </span>
                        </a>
                      )}
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
            <div className="hidden md:block">
              <div className="ml-4 flex items-center md:ml-6 space-x-4">
                <div className="ml-4 flex items-center space-x-2">
                  {isUserATrainee && (
                    <Link
                      to="/notifications"
                      className={iconLinkClassnames}
                      title="Centre de notifications"
                    >
                      <span className="sr-only">View notifications</span>
                      <div className="relative">
                        <BellOutline className="h-6 w-6" aria-hidden="true" />
                        {notAcquitedNotif.length > 0 && (
                          <span className="absolute flex items-center justify-center top-0 right-0 transform translate-x-1/2 -translate-y-1/2 h-3 w-3 rounded-full ring-1 ring-white bg-red-500 text-xxs text-white">
                            {notAcquitedNotif.length}
                          </span>
                        )}
                      </div>
                    </Link>
                  )}
                  {/* {userPlanningLink && (
                    <Link
                      to={userPlanningLink}
                      className={iconLinkClassnames}
                      title="Agenda"
                    >
                      <span className="sr-only">View notifications</span>
                      <CalendarOutline className="h-6 w-6" />
                    </Link>
                  )} */}
                  <GuideButton theme={theme} />
                </div>
                <Dropdown
                  iconButton={{
                    icon: () => (
                      <div className="flex">
                        <span className="sr-only">Open user menu</span>
                        {user && <Avatar size="small" entity={user} />}
                      </div>
                    ),
                    title: "User menu",
                  }}
                  position="left"
                >
                  {menuItems
                    .filter((menuItemProps) =>
                      menuItemProps.mandatoryRole
                        ? authService.hasSomeRoles(
                            ...menuItemProps.mandatoryRole
                          )
                        : true
                    )
                    .map((menuItemProps, index) => (
                      <Dropdown.MenuItem key={index} {...menuItemProps} />
                    ))}
                </Dropdown>
              </div>
            </div>
            <MobileMenu
              isOpen={isMobileMenuOpen}
              theme={theme}
              onClickButton={handleOpenMobileMenu}
            />
          </div>
        </div>

        {/* <!--
      Mobile menu,content
    --> */}
        <div className={`md:hidden ${isMobileMenuOpen ? "block" : "hidden"}`}>
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {NAVBAR_LINKS.filter((navbarLink) =>
              navbarLink.mandatoryRole
                ? authService.hasSomeRoles(...navbarLink.mandatoryRole)
                : true
            ).map((navbarLink, index) => (
              <Fragment key={index}>
                {!navbarLink.external && (
                  <NavLink
                    key={index}
                    to={navbarLink.to}
                    isActive={navbarLink.isActive}
                    className={classNames(
                      "block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white",
                      {
                        "hover:bg-gray-700": theme === "dark",
                        "hover:bg-blue-700": theme === "blue",
                      }
                    )}
                    activeClassName={navLinkActiveClassnames}
                  >
                    {t(navbarLink.labelKey)}
                  </NavLink>
                )}
                {navbarLink.external && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={navbarLink.to}
                    className={classNames(
                      "block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white",
                      {
                        "hover:bg-gray-700": theme === "dark",
                        "hover:bg-blue-700": theme === "blue",
                      }
                    )}
                  >
                    <span className="space-x-2 flex items-center">
                      <span>
                        <ExternalLinkOutline className="h3 w-3" />
                      </span>
                      <span>{t(navbarLink.labelKey)}</span>
                    </span>
                  </a>
                )}
              </Fragment>
            ))}
          </div>
          <div className="pt-4 pb-3 border-t border-gray-700">
            <div className="flex items-center px-5 space-x-4">
              {user && <AuthenticatedUser user={user} />}

              {isUserATrainee && (
                <Link
                  to="/notifications"
                  className="p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  title="Centre de notifications"
                >
                  <span className="sr-only">View notifications</span>
                  <div className="relative">
                    <BellOutline className="h-6 w-6" aria-hidden="true" />
                    {notAcquitedNotif.length > 0 && (
                      <span className="absolute flex items-center justify-center top-0 right-0 transform translate-x-1/2 -translate-y-1/2 h-3 w-3 rounded-full ring-1 ring-white bg-red-500 text-xxs text-white">
                        {notAcquitedNotif.length}
                      </span>
                    )}
                  </div>
                </Link>
              )}
              {/* {userPlanningLink && (
                <Link
                  to={userPlanningLink}
                  className="p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                >
                  <span className="sr-only">View notifications</span>
                  <CalendarOutline className="h-6 w-6" />
                </Link>
              )} */}
            </div>
            <div className="mt-3 px-2 space-y-1">
              {menuItems
                .filter((menuItemProps) =>
                  menuItemProps.mandatoryRole
                    ? authService.hasSomeRoles(...menuItemProps.mandatoryRole)
                    : true
                )
                .map((menuItemProps, index) => (
                  <Link
                    key={index}
                    to={menuItemProps.link?.to || ""}
                    onClick={menuItemProps.onClick}
                    className=" cursor-pointer block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                  >
                    {menuItemProps.label}
                  </Link>
                ))}
            </div>
          </div>
        </div>
      </nav>
      <FloatingAppAlert />
      <UpdateNotif />
      {children}
    </div>
  );
}
