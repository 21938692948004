import { Button } from "@components/Button";
import { Chip } from "@components/Chip";
import { PageContainer } from "@components/Layout";
import { Title } from "@components/Title";
import { ArrowRightSolid } from "@graywolfai/react-heroicons";
import { Client } from "@justplayfair/model";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export function AdminPage() {
  const { t } = useTranslation();
  const [{ data: companyCount }] = Client.useCountCompaniesQuery();
  const [{ data: sessionCount }] = Client.useCountSessionsQuery({
    variables: { status: ["CREATED", "PLANNED", "IN_PROGRESS"] },
  });
  const [{ data: mediaCount }] = Client.useCountMediasQuery({
    variables: { withDeleted: false },
  });

  return (
    <PageContainer title={t("menu.admin")}>
      <div className="w-full mx-auto space-y-8">
        <AdminItem
          title={
            <>
              {t("admin.title.companies")}
              {companyCount && (
                <Chip
                  color="primary"
                  label={`${companyCount.countCompanies}`}
                  title={t("admin.text.nbCompanies")}
                />
              )}
            </>
          }
          action={
            <Button
              link={{ to: "/admin/companies" }}
              title={t("admin.dashboard.button.configureCompanies")}
              postIcon={ArrowRightSolid}
              variant="transparent"
            >
              {t("admin.dashboard.button.configureCompanies")}
            </Button>
          }
        />

        <AdminItem
          title={
            <>
              {t("admin.title.sessions")}
              {sessionCount && (
                <Chip
                  color="primary"
                  label={`${sessionCount?.countSessions}`}
                  title={t("admin.text.nbPendingSessions")}
                />
              )}
            </>
          }
          action={
            <Button
              link={{ to: "/admin/sessions" }}
              title={t("admin.dashboard.button.configureSessions")}
              postIcon={ArrowRightSolid}
              variant="transparent"
            >
              {t("admin.dashboard.button.configureSessions")}
            </Button>
          }
        />

        <AdminItem
          title={
            <>
              {t("admin.title.mediaLibrary")}
              {mediaCount && (
                <Chip
                  color="primary"
                  label={`${mediaCount?.countMedias}`}
                  title={t("admin.text.nbMedias")}
                />
              )}
            </>
          }
          action={
            <Button
              link={{ to: "/admin/mediaLibrary" }}
              title={t("admin.dashboard.button.configureMediaLibrary")}
              postIcon={ArrowRightSolid}
              variant="transparent"
            >
              {t("admin.dashboard.button.configureMediaLibrary")}
            </Button>
          }
        />
      </div>
    </PageContainer>
  );
}

interface AdminItemProps {
  title: string | ReactNode;
  action: ReactNode;
}
function AdminItem({ title, action }: AdminItemProps) {
  return (
    <div className="flex justify-between items-center py-2 align-middle min-w-full sm:px-6 lg:px-8 space-y-2 bg-white rounded-lg shadow h-28">
      <Title size={3}>{title}</Title>
      <div className="w-56">{action}</div>
    </div>
  );
}
