"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SUPPORTED_MIMES = exports.VIDEO_MIMES = exports.IMAGE_MIMES = exports.PDF_MIMES = void 0;
exports.PDF_MIMES = ["application/pdf"];
exports.IMAGE_MIMES = [
    "image/jpeg",
    "image/png",
    "image/tif",
    "image/webp",
    "image/bmp",
];
exports.VIDEO_MIMES = [
    "video/mp4",
    "video/mkv",
    "video/x-matroska",
    "video/webm",
    "video/mov",
    "video/quicktime",
    "video/avi",
    "video/x-msvideo",
    "video/mpg",
    "video/mp1",
    "video/mp2",
];
exports.SUPPORTED_MIMES = [...exports.PDF_MIMES, ...exports.IMAGE_MIMES, ...exports.VIDEO_MIMES];
