import { FieldContainer } from "@components/form/FieldContainer";
import { useField, useFormikContext } from "formik";
import classnames from "clsx";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { IconComponent } from "../../../Icon/Icon.model";
import { Input } from "../Input.component";
import { LabelPosition } from "@components/form/FieldContainer/FieldContainer.component";

interface FormikInputProps {
  editMode: boolean;
  id: string;
  label?: string;
  labelPosition?: LabelPosition;
  type?: string;
  step?: string; // Step for input number field
  placeholder?: string;
  autoComplete?: string;
  withSeparator?: boolean;
  min?: number;
  max?: number;
  autoFocus?: boolean;
  icon?: IconComponent;
  helperText?: ReactNode | string;
}
export function FormikInput({
  type = "text",
  editMode,
  id,
  label,
  labelPosition = "left",
  placeholder,
  autoComplete,
  withSeparator = true,
  step,
  min,
  max,
  autoFocus,
  icon,
  helperText,
}: FormikInputProps) {
  const { t } = useTranslation();

  const { submitCount } = useFormikContext();
  const [field, { error, touched }] = useField(id);

  const valueClasses = classnames(
    "mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2",
    {
      "text-center": type === "number",
    }
  );

  return (
    <FieldContainer
      id={id}
      label={label}
      labelPosition={labelPosition}
      withSeparator={withSeparator}
    >
      {editMode ? (
        <div className="w-full">
          <Input
            id={id}
            type={type}
            placeholder={placeholder || label}
            icon={icon}
            isError={touched && !!error}
            autoComplete={autoComplete}
            step={step}
            min={min}
            max={max}
            autoFocus={autoFocus}
            {...field}
          />
          {helperText && (
            <div className="h-4 mt-0 text-xs text-gray-600">{helperText}</div>
          )}
          <div className="h-4 mt-0 text-xs text-red-400">
            {(touched || submitCount > 0) && error && t(error)}
          </div>
        </div>
      ) : (
        <span className={valueClasses}>{field.value}</span>
      )}
    </FieldContainer>
  );
}
