import { Card } from "@components/Card";
import { CardSection } from "@components/CardSection";
import { PageContainer } from "@components/Layout";
import { Loader } from "@components/Loader";
import {
  DocumentTextOutline,
  PhotographOutline,
  VideoCameraOutline,
} from "@graywolfai/react-heroicons";
import { Client, ID } from "@justplayfair/model";
import { fileService } from "@services/file/file.service";
import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  byNameMediaNaturalySort,
  formatBytesToHumanReadable,
} from "../../../model/Media.model";
import { toSearchEntries } from "../../../search/search.utilities";

const TRAINEE_RESOURCE_TYPES: Client.FileType[] = ["IMAGE", "PDF", "VIDEO"];
const TRAINEE_RESOURCE_LABELS: Client.FileLabel[] = ["EDUCATIONAL_RESOURCE"];

export function EducationalResourcesPage() {
  const { t } = useTranslation();
  const [downloadMediaIds, setDownloadMediaIds] = useState<ID[]>([]);

  const [{ data, fetching }] = Client.useSearchMediasQuery({
    variables: {
      search: {
        take: 1000,
        skip: 0,
        filters: {
          deleted: false,
          query: new URLSearchParams(
            toSearchEntries({
              labels: TRAINEE_RESOURCE_LABELS,
              types: TRAINEE_RESOURCE_TYPES,
            })
          ).toString(),
        },
      },
    },
  });

  if (fetching) {
    return (
      <PageContainer title={t("menu.educational-resources")}>
        <Loader size="medium" />
      </PageContainer>
    );
  }
  if (!data?.searchMedias || data.searchMedias.data.length === 0) {
    return (
      <PageContainer title={t("menu.educational-resources")}>
        {t("common.text.noData")}
      </PageContainer>
    );
  }

  async function handleDownloadMedia(media: Client.FileFieldsFragment) {
    setDownloadMediaIds([...downloadMediaIds, media.id]);
    try {
      await fileService.downloadFile(media.storageFileId, media.name);
    } finally {
      setDownloadMediaIds(downloadMediaIds.filter((id) => id !== media.id));
    }
  }

  return (
    <PageContainer title={t("menu.educational-resources")}>
      <CardSection title={t("trainee.educational-resources.title.list")} flat>
        <div className="space-y-4">
          <div className="text-gray-800 text-base italic">
            <div>{t("trainee.educational-resources.text.helperText1")}</div>
            <div>{t("trainee.educational-resources.text.helperText2")}</div>
          </div>
          <div className="space-y-8">
            {data?.searchMedias.data
              .sort(byNameMediaNaturalySort) // sort so that "10.xxx" is after "9.xxx"
              .map((media) => (
                <ResourceCard
                  key={media.id}
                  type={media.type}
                  title={media.name}
                  size={media.size}
                  description={media.description ?? undefined}
                  onDownload={() => handleDownloadMedia(media)}
                  downloading={downloadMediaIds.includes(media.id)}
                />
              ))}
          </div>
        </div>
      </CardSection>
    </PageContainer>
  );
}

interface ResourceCardProps {
  type: Client.FileType;
  title: string;
  size: number;
  downloading: boolean;
  description?: string;
  onDownload: VoidFunction;
}
function ResourceCard({
  type,
  title,
  size,
  downloading,
  description,
  onDownload,
}: ResourceCardProps) {
  const { t } = useTranslation();

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    onDownload();
  }
  return (
    <div className="max-w-xl">
      <Card>
        <div className="p-4 ">
          <div className="flex flex-row space-x-4">
            <div className="leading-5 items-center">
              <ResourcePicto type={type} />
            </div>
            <div className="space-y-2">
              <div className="leading-5 text-gray-900">
                <span className="font-bold">{title}</span> -{" "}
                <span className="text-sm">
                  {formatBytesToHumanReadable(size)}
                </span>
              </div>
              <div className="text-gray-500">{description}</div>
              <div>
                {downloading && <Loader size="small" />}
                {!downloading && (
                  <button
                    title={`${t(
                      "trainee.educational-resources.text.download"
                    )} "${title}"`}
                    onClick={handleClick}
                    className=" text-indigo-600 text-sm font-medium hover:text-indigo-700"
                  >
                    {t("trainee.educational-resources.text.download")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

interface ResourcePictoProps {
  type: Client.FileType;
}
function ResourcePicto({ type }: ResourcePictoProps) {
  const pictoClasses = "h-5 w-5 text-gray-400";
  switch (type) {
    case "PDF":
      return <DocumentTextOutline className={pictoClasses} />;
    case "IMAGE":
      return <PhotographOutline className={pictoClasses} />;
    case "VIDEO":
      return <VideoCameraOutline className={pictoClasses} />;
  }
}
