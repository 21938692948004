import { PageContainer } from "@components/Layout";
import { Loader } from "@components/Loader";
import { ProgramAchievement } from "@components/Program/ProgramAchievement.component";
import { ProgramDescriptionTitle } from "@components/Program/ProgramDescriptionTitle.component";
import { ProgramSessionAdvancement } from "@components/Program/ProgramSessionAdvancement.component";
import { Tabs, TabsProps } from "@components/Tabs";
import { Title } from "@components/Title";
import {
  ClipboardCheckSolid,
  PresentationChartLineSolid,
  UsersSolid,
} from "@graywolfai/react-heroicons";
import { Client } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import { Route, Switch, useParams } from "react-router-dom";
import { ProgramTargetsTabContent } from "../../Trainer/TrainerProgramDashboard/tabContents/ProgramTargetsTabContent.component";
import { ClientProgramDatavizTabContent } from "./tabContents/ClientProgramDatavizTabContent.component";
import { ClientProgramMembersTabContent } from "./tabContents/ClientProgramMembersTabContent.component";

function getTabs(
  program: NonNullable<Client.ProgramDashboardQuery["program"]>,
  t: (key: string) => string
): TabsProps["items"] {
  const tabs = [
    {
      label: t("client.dashboard.tab.evaluationsData"),
      icon: PresentationChartLineSolid,
      link: `/client/program/dashboard/${program.id}`,
    },
  ];
  if (program.type === "TRAINING") {
    tabs.push({
      label: t("client.dashboard.tab.targets"),
      icon: ClipboardCheckSolid,
      link: `/client/program/dashboard/${program.id}/tab/targets`,
    });
  }
  tabs.push({
    label: t("client.dashboard.tab.members"),
    icon: UsersSolid,
    link: `/client/program/dashboard/${program.id}/tab/members`,
  });
  return tabs;
}

export function ClientProgramDashboardPage() {
  const { t } = useTranslation();
  const { programId } = useParams<{ programId: string }>();

  const [{ data, fetching }] = Client.useProgramDashboardQuery({
    variables: { programId },
    requestPolicy: "network-only",
  });

  if (fetching) {
    return (
      <PageContainer title={t("client.dashboard.title.program")}>
        <Loader size="medium" />
      </PageContainer>
    );
  }
  if (!data?.program) {
    return (
      <PageContainer title={t("client.dashboard.title.program")}>
        <Title size={6}>Introuvable</Title>
      </PageContainer>
    );
  }

  return (
    <PageContainer
      title={<ProgramDescriptionTitle program={data.program} />}
      backButton={`/client/dashboard`}
    >
      <div className="space-y-4 px-4 pb-12">
        <div className="flex space-x-8">
          <div className="w-96">
            {data.program.sessionsAdvancement && (
              <ProgramSessionAdvancement
                advancement={data.program.sessionsAdvancement}
              />
            )}
          </div>
          {data.program.type === "TRAINING" && (
            <div className="w-96">
              {data.program.targetedLevelsAchievement !== undefined &&
                data.program.targetedLevelsAchievement !== null && (
                  <ProgramAchievement
                    title={t(
                      "client.dashboard.title.targetedLevelsAchievement"
                    )}
                    value={data.program.targetedLevelsAchievement}
                    label={t("client.dashboard.text.targetsAchieved")}
                  />
                )}
            </div>
          )}
          {data.program.type === "ASSESSMENT" && (
            <div className="w-96">
              {data.program.assessmentAchievement !== undefined &&
                data.program.assessmentAchievement !== null && (
                  <ProgramAchievement
                    title={t("client.dashboard.title.assessmentAchievement")}
                    value={data.program.assessmentAchievement}
                    label={t("client.dashboard.text.assessmentAchieved")}
                  />
                )}
            </div>
          )}
        </div>
      </div>

      <div className="px-4 pb-8">
        <Tabs items={getTabs(data.program, t)} />
      </div>
      <div className="px-8 space-y-8">
        <Switch>
          <Route
            exact
            path="/client/program/dashboard/:programId"
            component={ClientProgramDatavizTabContent}
          />
          <Route
            exact
            path="/client/program/dashboard/:programId/tab/targets"
            component={ProgramTargetsTabContent}
          />
          <Route
            path="/client/program/dashboard/:programId/tab/members"
            component={ClientProgramMembersTabContent}
          />
        </Switch>
      </div>
    </PageContainer>
  );
}
