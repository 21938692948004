import { Title } from "@components/Title";
import classnames from "clsx";
import { ReactNode } from "react";

interface GridListProps {
  title: string;
  nbCol?: 1 | 2 | 3;
  children: ReactNode;
}
export function GridList({ title, nbCol = 3, children }: GridListProps) {
  const gridClasses = classnames(
    "mt-2 grid grid-cols-1 gap-5",
    { "sm:grid-cols-2 lg:grid-cols-3": nbCol === 3 },
    { " lg:grid-cols-2": nbCol === 2 }
  );
  return (
    <div className="space-y-4">
      <Title size={4}>{title}</Title>
      <div className={gridClasses}>{children}</div>
    </div>
  );
}
