import { Button } from "@components/Button";
import { IconButton } from "@components/Button/IconButton.component";
import { Chip } from "@components/Chip";
import { DisplayDate } from "@components/DateTime";
import { PageContainer } from "@components/Layout";
import { Loader } from "@components/Loader";
import { Title } from "@components/Title";
import { PaperAirplaneOutline, PencilSolid } from "@graywolfai/react-heroicons";
import { Client } from "@justplayfair/model";
import classNames from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { alertService } from "../../../../services/Alert/AlertService";
import { createAppointmentDates } from "../../../Admin/Sessions/SessionDetails/SessionDetails.model";
import { SectionComment } from "./SessionComment.component";
import { SessionEvaluation } from "./SessionEvaluation/SessionEvaluation.component";
import { UpdateSessionDateModal } from "./UpdateSessionDateModal/UpdateSessionDateModal.component";

export function TrainerSessionPage() {
  const { t } = useTranslation();

  const [sessionDateModalOpen, setSessionDateModalOpen] = useState(false);

  const { sessionId } = useParams<{ sessionId: string }>();

  const [{ data, fetching }, refetchSession] = Client.useGetSessionQuery({
    requestPolicy: "network-only",
    variables: { id: sessionId },
  });

  const [{ fetching: updateSessionStatusFetching }, updateSessionStatus] =
    Client.useUpdateSessionStatusMutation();

  const [{ fetching: updateSessionFetching }, updateSession] =
    Client.useUpdateSessionMutation();

  const [
    { fetching: notifyTraineeCrAvailabilityFetching },
    notifyTraineeCrAvailability,
  ] = Client.useNotifyTraineeCrAvailabilityMutation();
  function handleSessionUpdated() {
    refetchSession();
  }

  function handleEffectuateSession() {
    updateSessionStatus({ sessionId, status: "DONE" });
  }

  async function handleNotifyCrAvailibility() {
    await notifyTraineeCrAvailability({ sessionId });
    alertService.addAlert({
      type: "success",
      title: "Email envoyé au trainee",
      description: session.trainee?.email,
    });
  }

  if (fetching) {
    return (
      <PageContainer title={t("trainer.dashboard.title.session")}>
        <Loader size="medium" />
      </PageContainer>
    );
  }

  if (!data?.getSession) {
    return (
      <PageContainer title={t("trainer.dashboard.title.session")}>
        <Title size={6}>Introuvable</Title>
      </PageContainer>
    );
  }
  const session = data.getSession;

  async function handleUpdateSessionDate(startDate: Date) {
    await updateSession({
      sessionId,
      sessionInput: {
        name: session.name,
        type: session.type,
        trainerId: session.trainer?.id ?? "",
        duration: session.duration,
      },
      appointmentDates: createAppointmentDates(startDate, session.duration, {
        repeat: "ONCE",
        nb: 1,
      }),
    });
    setSessionDateModalOpen(false);
  }

  return (
    <PageContainer
      title={
        <SessionDescriptionTitle
          session={data.getSession}
          onEffectuateSession={handleEffectuateSession}
          fetching={updateSessionStatusFetching}
          onOpenSessionDateModal={() => setSessionDateModalOpen(true)}
        />
      }
      backButton
    >
      <div className="space-y-8">
        {["DONE", "EVALUATED"].includes(data.getSession?.status) && (
          <div className="flex justify-end">
            <div className="w-48">
              <Button
                title={t("trainer.session.button.notifyCrAvailibility.title")}
                variant="secondary"
                onClick={handleNotifyCrAvailibility}
                loading={notifyTraineeCrAvailabilityFetching}
                postIcon={(props) => (
                  <PaperAirplaneOutline
                    {...props}
                    className={classNames("rotate-90", props.className)}
                  />
                )}
              >
                {t("trainer.session.button.notifyCrAvailibility.label")}
              </Button>
            </div>
          </div>
        )}
        <SectionComment
          session={data.getSession}
          sessionComment={data.getSession.sessionComment?.value || undefined}
          onSessionUpdated={handleSessionUpdated}
        />
        <SessionEvaluation
          session={data.getSession}
          onSessionUpdated={handleSessionUpdated}
        />
      </div>
      <UpdateSessionDateModal
        isOpen={sessionDateModalOpen}
        loading={updateSessionFetching}
        sessionDate={
          data.getSession.appointment?.startDate
            ? new Date(data.getSession.appointment.startDate)
            : undefined
        }
        onChange={handleUpdateSessionDate}
        onClose={() => setSessionDateModalOpen(false)}
      />
    </PageContainer>
  );
}

interface SessionDescriptionTitleProps {
  session: Client.SessionFieldsFragment;
  fetching: boolean;
  onEffectuateSession: VoidFunction;
  onOpenSessionDateModal: VoidFunction;
}
function SessionDescriptionTitle({
  session,
  fetching,
  onEffectuateSession,
  onOpenSessionDateModal,
}: SessionDescriptionTitleProps) {
  const { t } = useTranslation();
  return (
    <div className="w-full p-2 lg:flex lg:justify-around lg:items-center space-y-8 lg:space-y-0 lg:p-4 divide-x divide-gray-200">
      <Title size={5}>{session.name}</Title>

      <div className="flex items-center flex-grow px-4 mx-4 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-24 gap-y-2 sm:grid-cols-4">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {t("trainer.session.label.trainee")}
            </dt>
            <dd className="mt-0.5 text-sm text-gray-900">
              {session.trainee?.name}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {t("trainer.session.label.trainer")}
            </dt>
            <dd className="mt-0.5 text-sm text-gray-900">
              {session.trainer?.name}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {t("trainer.session.label.status")}
            </dt>
            <dd className="mt-0.5 text-sm text-gray-900">
              <Chip
                color="transparent"
                title={t(`common.session.status.${session.status}`)}
                label={t(`common.session.status.${session.status}`)}
              />
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {t("trainer.session.label.date")}
            </dt>
            <dd className="mt-0.5 text-sm text-gray-900 space-x-2">
              <span>
                {session.appointment?.startDate && (
                  <DisplayDate date={new Date(session.appointment.startDate)} />
                )}
                {!session.appointment?.startDate && "-"}
              </span>
              <span>
                <IconButton
                  title={t("trainer.session.button.updateSessionDate")}
                  icon={PencilSolid}
                  onClick={() => onOpenSessionDateModal()}
                  size="small"
                  variant="transparent"
                />
              </span>
            </dd>
          </div>
        </dl>
      </div>
      {["IN_PROGRESS", "PLANNED"].includes(session.status) && (
        <div>
          <Button
            variant="secondary"
            title={t("trainer.session.button.effectuateSession")}
            onClick={onEffectuateSession}
            loading={fetching}
          >
            {t("trainer.session.button.effectuateSession")}
          </Button>
        </div>
      )}
      {["CREATED"].includes(session.status) && (
        <div>
          <Button
            disabled
            tooltip={t("trainer.session.label.needToPlanBeforeEffectuate")}
            variant="secondary"
            title=""
            onClick={onEffectuateSession}
            loading={fetching}
          >
            {t("trainer.session.button.effectuateSession")}
          </Button>
        </div>
      )}
    </div>
  );
}
