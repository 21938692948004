import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import { CaptureContext } from "@sentry/types";
import { authService } from "@services/auth";

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    release: process.env.REACT_APP_VERSION,
    environment: process.env.REACT_APP_SENTRY_ENV,
    ignoreErrors: [], // Add here errors to drop
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    beforeSend(event: Sentry.Event) {
      const tokenClaims = authService.tokenClaims;
      if (tokenClaims) {
        event.user = { username: tokenClaims.email, role: tokenClaims.roles };
      }
      return event;
    },
  });
}

export function sentryCaptureError(
  error: Error,
  captureContext?: CaptureContext
) {
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.captureException(error, captureContext);
  }
}

export function sentryReactErrorHandler(
  error: Error,
  { componentStack }: { componentStack: string }
) {
  if (process.env.NODE_ENV === "development") {
    console.error(error);
  }

  sentryCaptureError(error, {
    contexts: { react: { componentStack } },
  });
}
