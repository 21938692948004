import { Button } from "@components/Button";
import { FormikInput } from "@components/form/Input/FormikInput";
import { FormikSelect } from "@components/form/Select/FormikSelect";
import {
  AtSymbolOutline,
  CalculatorOutline,
  OfficeBuildingOutline,
} from "@graywolfai/react-heroicons";
import { Client } from "@justplayfair/model";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { FormikToggle } from "../../../../components/form/Toggle/FormikToggle";
import {
  COMPANY_TYPES,
  COMPANY_TYPE_OPTIONS,
} from "../../../../model/Company.model";

const CreateCompanySchema: Yup.SchemaOf<Client.CreateCompanyInput> =
  Yup.object().shape({
    name: Yup.string().required("admin.company.error.nameMandatory"),
    type: Yup.mixed().oneOf(COMPANY_TYPES),
    maxRate: Yup.number()
      .min(1)
      .required("admin.company.error.maxRateMandatory"),
    email: Yup.string().email("admin.company.error.invalidEmail").nullable(),
    individualDataAccessDeactivated: Yup.boolean().required(
      "admin.company.error.individualDataAccessDeactivatedMandatory"
    ),
    sessionEvaluationUrl: Yup.string().url("admin.company.error.invalidUrl"),
  });

interface CreateCompanyFormProps {
  onCreateCompany: (input: Client.CreateCompanyInput) => Promise<void>;
}

export function CreateCompanyForm({ onCreateCompany }: CreateCompanyFormProps) {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        name: "",
        type: "CUSTOMER" as Client.CompanyType,
        maxRate: 4,
        email: "",
        individualDataAccessDeactivated: false,
        sessionEvaluationUrl: "",
      }}
      onSubmit={async (values) => {
        await onCreateCompany(values);
      }}
      validationSchema={CreateCompanySchema}
    >
      {(props) => (
        <Form className="bg-white shadow overflow-hidden p-2">
          <div>
            <FormikInput
              editMode={true}
              id="name"
              label={t("common.company.name")}
              icon={OfficeBuildingOutline}
            />
            <FormikSelect
              editMode={true}
              id="type"
              multi={false}
              label={t("common.company.type.label")}
              options={COMPANY_TYPE_OPTIONS}
              renderValue={(value) => t(`common.company.type.value.${value}`)}
            />
            <FormikToggle
              editMode={true}
              id="individualDataAccessDeactivated"
              label={t("common.company.individualDataAccessDeactivated")}
            />
            <FormikInput
              editMode={true}
              id="maxRate"
              label={t("common.company.maxRate")}
              type="number"
              min={1}
              icon={CalculatorOutline}
            />
            <FormikInput
              editMode={true}
              id="email"
              label={t("common.company.email")}
              icon={AtSymbolOutline}
            />
            <FormikInput
              editMode={true}
              id="sessionEvaluationUrl"
              label={t("common.company.sessionEvaluationUrl")}
            />
          </div>
          <div className="flex justify-end">
            <div className="w-56">
              <Button title={t("common.button.create")} type="submit">
                {t("common.button.create.label")}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
