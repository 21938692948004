import { Avatar } from "@components/Avatar";
import { Button } from "@components/Button";
import { FormikDatePicker } from "@components/form/DatePicker/FormikDatePicker";
import { FormikInput } from "@components/form/Input/FormikInput";
import { FormikSelect } from "@components/form/Select/FormikSelect";
import { AtSymbolOutline, UserOutline } from "@graywolfai/react-heroicons";
import { Client, ID } from "@justplayfair/model";
import { Form, Formik } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ROLES, ROLE_OPTIONS } from "../../../../model/user.model";
import { FormikLabelSelectField } from "../components/FormikLabelSelectField.component";

type CreateUserFields = Omit<Client.UserInput, "labels"> & {
  hierarchicalPositionLabel?: ID | null;
};

const CreateUserSchema: Yup.SchemaOf<CreateUserFields> = Yup.object().shape({
  companyId: Yup.string().required("admin.user.error.companyMandatory"),
  roles: Yup.array()
    .of(Yup.mixed().oneOf(ROLES).defined())
    .required("admin.user.error.rolesMandatory")
    .min(1, "admin.user.error.rolesMandatory"),
  email: Yup.string()
    .email("admin.user.error.invalidEmail")
    .required("admin.user.error.emailMandatory"),
  name: Yup.string().required("admin.user.error.nameMandatory"),
  hiringDate: Yup.date(),
  programIds: Yup.array().of(Yup.mixed().defined()),
  hierarchicalPositionLabel: Yup.mixed(),
  departmentLabel: Yup.mixed(),
});

interface CreateUserFormProps {
  onCreateUser: (input: Client.UserInput) => Promise<void>;
  companyId?: string;
  companies?: Client.CompanyFieldsFragment[];
}

export function CreateUserForm({
  onCreateUser,
  companyId,
  companies,
}: CreateUserFormProps) {
  const { t } = useTranslation();

  const companyOptions = useMemo(
    () => (companies ? companies?.map(({ id }) => ({ value: id })) : []),
    [companies]
  );

  return (
    <Formik
      initialValues={{
        companyId: companyId ?? "",
        roles: [] as Client.Role[],
        email: "",
        name: "",
        hiringDate: "",
        programIds: [],
        hierarchicalPositionLabel: "",
        departmentLabel: "",
        businessUnitLabel: "",
      }}
      onSubmit={async ({
        hierarchicalPositionLabel,
        departmentLabel,
        businessUnitLabel,
        hiringDate,
        ...otherFields
      }) => {
        await onCreateUser({
          ...otherFields,
          hiringDate: hiringDate || undefined,
          labels:
            hierarchicalPositionLabel || departmentLabel || businessUnitLabel
              ? [
                  hierarchicalPositionLabel,
                  departmentLabel,
                  businessUnitLabel,
                ].filter((label): label is string => !!label)
              : undefined,
        });
      }}
      validationSchema={CreateUserSchema}
    >
      {(props) => {
        const companyPrograms =
          companies?.find((company) => props.values.companyId === company.id)
            ?.programs || [];

        return (
          <Form className="bg-white shadow p-2">
            <div>
              <FormikSelect
                editMode={companyId ? false : true}
                id="companyId"
                label={t("common.user.company")}
                options={companyOptions}
                multi={false}
                renderValue={(value) => {
                  const companyVal = companies?.find(
                    (company) => company.id === value
                  );
                  if (!companyVal) {
                    return;
                  }
                  return (
                    <div className="space-x-1">
                      <Avatar size="mini" entity={companyVal} />
                      <span>{companyVal.name}</span>
                    </div>
                  );
                }}
              />

              <FormikSelect
                editMode
                id="roles"
                multi
                label={t("common.user.role")}
                options={ROLE_OPTIONS}
                renderValue={(values) =>
                  values.map((val) => t(`common.role.${val}`)).join(", ")
                }
              />

              <FormikInput
                editMode
                id="email"
                label={t("common.user.email")}
                icon={AtSymbolOutline}
              />
              <FormikInput
                editMode
                id="name"
                label={t("common.user.name")}
                icon={UserOutline}
              />

              {props.values.roles.includes("TRAINEE") && (
                <>
                  <FormikDatePicker
                    editMode
                    id="hiringDate"
                    label={t("common.user.hiringDate")}
                  />

                  <FormikSelect
                    multi
                    editMode
                    id="programIds"
                    label={t("admin.user.createUser.program")}
                    options={companyPrograms.map((program) => ({
                      value: program.id,
                      label: program.name,
                    }))}
                  />

                  <FormikLabelSelectField
                    editMode
                    fieldName="hierarchicalPositionLabel"
                    labelCategory="HIERARCHICAL_POSITION"
                    label={t("admin.user.createUser.hierarchicalPosition")}
                  />

                  <FormikLabelSelectField
                    editMode
                    fieldName="departmentLabel"
                    labelCategory="DEPARTMENT"
                    label={t("admin.user.createUser.department")}
                  />

                  <FormikLabelSelectField
                    editMode
                    fieldName="businessUnitLabel"
                    labelCategory="BUSINESS_UNIT"
                    label={t("admin.user.createUser.businessUnit")}
                  />
                </>
              )}
            </div>
            <div className="flex justify-end">
              <div className="w-56">
                <Button title={t("common.button.create.label")} type="submit">
                  {t("common.button.create.label")}
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
