import { AuthLayout } from "@components/AuthLayout";
import { Button } from "@components/Button";
import { Checkbox } from "@components/form/Checkbox";
import { FormikInput } from "@components/form/Input/FormikInput";
import { Title } from "@components/Title";
import { LockClosedOutline } from "@graywolfai/react-heroicons";
import { PASSWORD_REGEX, User } from "@justplayfair/model";
import { userService } from "@services/user/user.service";
import { Field, Form, Formik } from "formik";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useAsync } from "react-use";
import * as Yup from "yup";

const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
if (!MEDIA_URL) {
  throw new Error("Mandatory env variable 'REACT_APP_MEDIA_URL'");
}
interface ActivateFields {
  password: string;
  cgu?: boolean;
}
const ActivateSchema: Yup.SchemaOf<ActivateFields> = Yup.object().shape({
  password: Yup.string()
    .matches(PASSWORD_REGEX, "auth.common.message.mustContain")
    .required("auth.error.message.passwordMandatory"),
  cgu: Yup.boolean()
    .oneOf([true], "auth.activate.message.mustAcceptCgu")
    .required("auth.activate.message.mustAcceptCgu"),
});

export function ActivatePage() {
  const { t } = useTranslation();

  const { search } = useLocation();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User.User>();
  const [errorKey, setErrorKey] = useState<
    undefined | "noTokenForActivating" | "wrongToken" | "errorActivating"
  >();
  const [step, setStep] = useState<"activate" | "done">("activate");

  const token = useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get("token");
  }, [search]);

  useAsync(async () => {
    try {
      if (!token) {
        console.error("No token in search params");
        setErrorKey("noTokenForActivating");
        return;
      }
      const user = await userService.getUserToken(token);
      setUser(user);
    } catch (error) {
      console.error(error);
      setErrorKey("errorActivating");
    } finally {
      setLoading(false);
    }
  }, [token]);

  async function handleSubmit({ password, cgu }: ActivateFields) {
    setLoading(true);
    try {
      if (!token || !user) {
        console.error("No token in search params");
        setErrorKey("noTokenForActivating");
        return;
      }
      await userService.activate({ token, password });
      setStep("done");
    } catch (error) {
      console.error(error);
      setErrorKey("errorActivating");
    } finally {
      setLoading(false);
    }
  }

  const subtitle =
    !loading && user ? (
      <div className="space-y-4">
        <div className="text-lg space-y-4">
          <Title size={3}>
            {t("auth.activate.message.welcome")} <b>{user.name}</b>
          </Title>

          <div>
            <div className="space-x-2">
              <span className="font-semibold">
                {t("auth.activate.text.email")}:
              </span>
              <span>{user.email}</span>
            </div>
            {user.company && (
              <div className="space-x-2">
                <span className="font-semibold">
                  {t("auth.activate.text.company")}:
                </span>
                <span>{user.company.name}</span>
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="whitespace-pre-line">
            <div className="text-lg font-semibold">
              {t("auth.activate.text.activateAccount")}
            </div>
            {step !== "done"
              ? t("auth.activate.message.pleaseFillPassword")
              : t("auth.activate.message.success")}
          </div>
        </div>
      </div>
    ) : undefined;

  return (
    <AuthLayout
      titleKey={"auth.activate.title"}
      renderSubtitle={subtitle}
      errorKey={errorKey}
      loading={loading}
    >
      {step !== "done" && (
        <Formik
          initialValues={{
            password: "",
            cgu: false,
          }}
          onSubmit={async (values) => {
            handleSubmit(values);
          }}
          validationSchema={ActivateSchema}
        >
          {({ errors, submitCount }) => (
            <Form className="space-y-4">
              <div>
                <FormikInput
                  editMode={true}
                  id="password"
                  type="password"
                  autoComplete="new-password"
                  placeholder="Password"
                  icon={LockClosedOutline}
                  withSeparator={false}
                />
                <div className="px-4 py-2">
                  <Field
                    id="cgu"
                    name="cgu"
                    editMode={true}
                    value="on"
                    as={Checkbox}
                    isError={submitCount > 0 && !!errors.cgu}
                    label={
                      <>
                        {t("auth.activate.message.acceptThe")}{" "}
                        <a
                          href={`${MEDIA_URL}/user-charter`}
                          target="_blank"
                          rel="noreferrer"
                          className="text-indigo-600"
                        >
                          {t("auth.activate.message.cgu")}
                        </a>
                      </>
                    }
                  />
                </div>
              </div>
              <div>
                <Button title={t("auth.activate.button")} type="submit">
                  {t("auth.activate.button")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {step === "done" && (
        <div className="flex items-center justify-center">
          <div className="text-lg">
            <Link
              to="/login"
              className="font-medium text-primary hover:text-primary-dark"
            >
              {t("auth.activate.link.toLogin")}
            </Link>
          </div>
        </div>
      )}
    </AuthLayout>
  );
}
