import { SelectOption } from "@components/form/Select/Select.model";
import { Client, ID } from "@justplayfair/model";

export enum DisplayedFilters {
  COMPANY,
  GROUP,
  SESSION,
  TRAINEE,
}

export function createLabelOpts(
  labels: Client.LabelFieldsFragment[],
  category: Client.LabelCategory
): SelectOption<string>[] {
  return labels
    .filter((label) => label.category === category)
    .map((label) => ({
      value: label.id,
      label: label.name,
    }));
}
export interface FilterFields {
  hierarchicalPositionLabelId: ID;
  seniorityLabelId: ID;
  departmentLabelId: ID;
  businessUnitLabelId: ID;
  date?: Date;
  firstSession: boolean;
  compareToCompany: boolean;
  comparisonTraineeId: ID;
  comparisonSessionId: ID;
}
export type FormFields = Pick<
  FilterFields,
  | "seniorityLabelId"
  | "hierarchicalPositionLabelId"
  | "departmentLabelId"
  | "businessUnitLabelId"
  | "comparisonTraineeId"
  | "date"
  | "firstSession"
  | "comparisonSessionId"
>;
export const DEFAULT_FORM_VALUES: FormFields = {
  hierarchicalPositionLabelId: "",
  seniorityLabelId: "",
  departmentLabelId: "",
  businessUnitLabelId: "",
  date: undefined,
  firstSession: false,
  comparisonTraineeId: "",
  comparisonSessionId: "",
};
export type ActivatedFields = Record<keyof FilterFields, boolean>;
