import classames from "clsx";
import { MouseEvent, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Client } from "@justplayfair/model";
import { Img } from "../Img";
import { getHashColor } from "./Avatar.utilities";

interface AddPictureButtonProps {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}
function AddPictureButton({ onClick }: AddPictureButtonProps) {
  const { t } = useTranslation();
  return (
    <button
      onClick={onClick}
      className="absolute bottom-0 right-1 h-8 w-8 rounded-full text-white bg-gray-400 hover:bg-gray-500 font-semibold border-4 border-white flex justify-center items-center font-mono focus:outline-none"
      title={t("common.avatar.addButton")}
    >
      +
    </button>
  );
}

interface AvatarContainerProps {
  onAddPicture?: VoidFunction;
  children: ReactNode;
}
function AvatarContainer({ onAddPicture, children }: AvatarContainerProps) {
  return (
    <div className="inline-block relative">
      {children}
      {onAddPicture && <AddPictureButton onClick={onAddPicture} />}
    </div>
  );
}

export type AvatarSize = "large" | "medium" | "small" | "mini";
export interface AvatarProps {
  entity: { name: string; avatarImages?: Client.ResizedImage | null };
  size: AvatarSize;
  onAddPicture?: VoidFunction;
}

export function Avatar({ entity, size, onAddPicture }: AvatarProps) {
  const classes = classames(
    "rounded-full overflow-hidden inline-block ring-1 ring-white uppercase text-white flex justify-center items-center",
    {
      "h-5 w-5": size === "mini",
      "h-8 w-8": size === "small",
      "h-14 w-14": size === "medium",
      "h-28 w-28": size === "large",
      "text-xl": size === "medium",
      "text-4xl": size === "large",
    }
  );
  if (!entity.avatarImages) {
    const bgColor = getHashColor(entity.name);

    return (
      <AvatarContainer onAddPicture={onAddPicture}>
        <div className={classes} style={{ backgroundColor: bgColor }}>
          {entity.name.slice(0, 1)}
        </div>
      </AvatarContainer>
    );
  }
  return (
    <AvatarContainer onAddPicture={onAddPicture}>
      <Img
        className={classes}
        src={entity.avatarImages[size === "mini" ? "small" : size]}
        alt={`${entity.name} avatar`}
      />
    </AvatarContainer>
  );
}
