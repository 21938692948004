import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Title } from "@components/Title";
import { Logo } from "@components/Logo/Logo.component";

interface AuthTitleProps {
  titleKey: string;
  children?: ReactNode;
}
export function AuthTitle({ titleKey, children }: AuthTitleProps) {
  const { t } = useTranslation();
  return (
    <div className="space-y-2">
      <div className="flex justify-center">
        <Logo size="large" />
      </div>
      <div className="mt-6 text-center font-extrabold text-gray-900">
        <Title size={2}>{t(titleKey)}</Title>
      </div>
      <div className="text-sm">{children}</div>
    </div>
  );
}
