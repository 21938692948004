import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { sentryCaptureError } from "../errors/errors.handler";
import { HttpBackendModule } from "./httpBackend";

export const APP_I18N_CODE_KEY = "i18n";

const languageDetector = new LanguageDetector(null, {
  order: ["localStorage"],
  lookupLocalStorage: APP_I18N_CODE_KEY,
  caches: ["localStorage"],
});

export async function configurei18n() {
  try {
    await i18n
      .use(new HttpBackendModule())
      .use(languageDetector)
      .use(initReactI18next)
      // for all options read: https://www.i18next.com/overview/configuration-options
      .init({
        // resources,
        fallbackLng: "fr",
        debug: process.env.NODE_ENV === "development",
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
      });
  } catch (error) {
    console.error(error);
    sentryCaptureError(error instanceof Error ? error : new Error("fail"));
  }
}
export const i18nInstance = i18n;

export function changeLanguage(languageCode: string) {
  i18n.changeLanguage(languageCode);
}
