import { CheckOutline, SelectorOutline } from "@graywolfai/react-heroicons";
import { Transition } from "@headlessui/react";
import classnames from "clsx";
import {
  ChangeEvent,
  KeyboardEvent as ReactKeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useClickAway } from "react-use";
import { Keys } from "../../../keyboard/keyboard.utilites";
import { SelectOption, SelectProps } from "./Select.model";

export function CustomSelect<M extends boolean>(props: SelectProps<string, M>) {
  if (props.multi === true) {
    const multiProps = props as SelectProps<string, true>;
    return <MultiCustomSelect {...multiProps} />;
  } else {
    const singleProps = props as SelectProps<string, false>;
    return <SingleCustomSelect {...singleProps} />;
  }
}

function SingleCustomSelect({
  id,
  value,
  defaultValue,
  options,
  label,
  renderValue,
  onChange,
  onBlur,
  required,
  isError,
  disabled,
}: SelectProps<string, false>) {
  const [isOpen, setIsOpen] = useState(false);

  const [localValue, setLocalValue] = useState(value || defaultValue);

  const ref = useRef(null);
  const selectRef = useRef<HTMLSelectElement>(null);

  function handleOnClickOption(option: SelectOption<string>) {
    if (!selectRef.current) {
      return;
    }
    // If already selected value
    if (option.value === localValue) {
      selectRef.current.value = "";
      setLocalValue(undefined);
    } else {
      selectRef.current.value = option.value;
      setLocalValue(option.value);
    }
    selectRef.current.dispatchEvent(new Event("change", { bubbles: true }));
    setIsOpen(false);
  }

  useClickAway(ref, () => {
    setIsOpen(false);
  });

  return (
    <div ref={ref} className="w-full">
      {label && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <CustomSelectUi
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        isError={isError}
        renderValue={renderValue}
        localValue={options?.find((option) => option.value === localValue)}
        options={options}
        handleOnClickOption={handleOnClickOption}
        disabled={disabled}
      />

      <select
        hidden
        ref={selectRef}
        id={id}
        name={id}
        onChange={onChange}
        onBlur={onBlur}
        value={localValue}
        required={required}
        disabled={disabled}
      >
        {options &&
          options.map((option, index) => (
            <option key={index} value={option.value} />
          ))}
      </select>
    </div>
  );
}

function MultiCustomSelect({
  id,
  value,
  defaultValue,
  onChange,
  options,
  label,
  renderValue,
  required,
  isError,
  disabled,
}: SelectProps<string, true>) {
  const [isOpen, setIsOpen] = useState(false);

  const [localValues, setLocalValues] = useState(value || defaultValue);

  const ref = useRef(null);
  const selectRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    if (localValues === value) {
      return; // SKIP
    }
    selectRef?.current?.dispatchEvent(new Event("change", { bubbles: true }));
  }, [localValues, value]);

  function handleNativeOnChange(event: ChangeEvent<HTMLSelectElement>) {
    if (!selectRef.current) {
      return;
    }
    Array.from(selectRef.current.options).forEach((optionElt) => {
      const isSelected = !!localValues?.includes(optionElt.value);
      optionElt.selected = isSelected;
    });
    if (onChange) {
      onChange(localValues);
    }
  }

  function handleOnClickOption(option: SelectOption<string>) {
    if (!selectRef.current) {
      return;
    }

    let newLocalValues = [];
    if (localValues?.includes(option.value)) {
      // Already selected value
      newLocalValues = localValues.filter(
        (localValue) => localValue !== option.value
      );
      selectRef.current.value = "";
    } else {
      selectRef.current.value = option.value;
      newLocalValues = [...(localValues || []), option.value];
    }

    setLocalValues(newLocalValues);
  }

  useClickAway(ref, () => {
    setIsOpen(false);
  });

  return (
    <div ref={ref} className="w-full">
      {label && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <CustomSelectUi
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        isError={isError}
        renderValues={renderValue}
        localValues={options?.filter((option) =>
          localValues?.includes(option.value)
        )}
        options={options}
        handleOnClickOption={handleOnClickOption}
        disabled={disabled}
      />
      <select
        hidden
        ref={selectRef}
        id={id}
        name={id}
        onChange={handleNativeOnChange}
        value={localValues}
        multiple
        required={required}
        disabled={disabled}
      >
        {options &&
          options.map((option, index) => (
            <option key={index} value={option.value} />
          ))}
      </select>
    </div>
  );
}

function getSelectedIndex(
  options?: SelectOption<string>[],
  localValue?: SelectOption<string>
) {
  const selectedIndex = options?.findIndex(
    (opt) => opt.value === localValue?.value
  );

  return selectedIndex && selectedIndex !== -1 ? selectedIndex : 0;
}
interface CustomSelectUiProps {
  setIsOpen: (open: boolean) => void;
  isOpen: boolean;
  isError?: boolean;
  disabled?: boolean;
  renderValue?: (value: string) => string | ReactNode;
  renderValues?: (values: string[]) => string | ReactNode;
  localValue?: SelectOption<string>;
  localValues?: SelectOption<string>[];
  options?: SelectOption<string>[];
  handleOnClickOption: (option: SelectOption<string>) => void;
}
function CustomSelectUi({
  setIsOpen,
  isOpen,
  isError,
  disabled,
  renderValue,
  renderValues,
  localValue,
  localValues,
  options,
  handleOnClickOption,
}: CustomSelectUiProps) {
  const { t } = useTranslation();

  const [activeOptionIndex, setActiveOptionIndex] = useState<number>(
    getSelectedIndex(options, localValue)
  );

  useEffect(() => {
    if (isOpen) {
      setActiveOptionIndex(getSelectedIndex(options, localValue));
    }
  }, [isOpen, options, localValue]);

  useEffect(() => {
    document.getElementById(`option-${activeOptionIndex}`)?.focus?.();
  }, [activeOptionIndex]);

  const handleKeyDown = useCallback(
    (event: ReactKeyboardEvent<HTMLDivElement>) => {
      switch (event.key) {
        case Keys.Enter:
          event.preventDefault();
          event.stopPropagation();

          const option = options?.[activeOptionIndex];
          if (option) {
            handleOnClickOption(option);
          }

          break;

        case Keys.ArrowDown:
          event.preventDefault();
          event.stopPropagation();
          setIsOpen(true);

          const nextIndex = activeOptionIndex + 1;
          if (nextIndex === options?.length) {
            return;
          }
          setActiveOptionIndex(nextIndex);
          break;

        case Keys.ArrowUp:
          event.preventDefault();
          event.stopPropagation();
          setIsOpen(true);

          const previousIndex = activeOptionIndex - 1;
          if (previousIndex === -1) {
            return;
          }
          setActiveOptionIndex(previousIndex);
          break;

        case Keys.Home:
        case Keys.PageUp:
          event.preventDefault();
          event.stopPropagation();
          setIsOpen(true);

          return setActiveOptionIndex(0);

        case Keys.End:
        case Keys.PageDown:
          event.preventDefault();
          event.stopPropagation();
          setIsOpen(true);

          if (options) {
            setActiveOptionIndex(options?.length - 1);
          }
          break;

        case Keys.Escape:
          event.preventDefault();
          event.stopPropagation();
          setIsOpen(false);

          break;

        case Keys.Tab:
          event.preventDefault();
          event.stopPropagation();
          break;

        default:
          if (event.key.length === 1) {
            setIsOpen(true);
            const matchinOptionIndex = options?.findIndex((_, index) => {
              if (index <= activeOptionIndex) {
                return false;
              }
              const displayedValue = document.getElementById(`option-${index}`)
                ?.textContent;

              return displayedValue
                ?.toUpperCase()
                .startsWith(event.key.toUpperCase());
            });

            if (matchinOptionIndex !== undefined && matchinOptionIndex > -1) {
              setActiveOptionIndex(matchinOptionIndex);
            }
          }
          break;
      }
    },
    [activeOptionIndex, handleOnClickOption, options, setIsOpen]
  );

  const buttonClasses = classnames(
    "relative w-full bg-white border rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-2 sm:text-sm",
    {
      "border-gray-300 focus:ring-primary": !isError,
      "border-red-400 focus:ring-red-400": isError,
      "bg-gray-100": disabled,
    }
  );
  return (
    <div className="mt-1 relative" onKeyDown={handleKeyDown}>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        type="button"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        className={buttonClasses}
        disabled={disabled}
      >
        <span className="flex items-center">
          <span className="ml-3 block truncate min-h-5">
            {localValues &&
              displayValue({
                values: localValues,
                renderValues,
                renderValue: undefined,
                value: undefined,
              })}
            {localValue &&
              displayValue({
                values: undefined,
                renderValues: undefined,
                value: localValue,
                renderValue,
              })}
          </span>
        </span>
        <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <SelectorOutline className="h-5 w-5 text-gray-400" />
        </span>
      </button>
      <Transition
        className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-20"
        show={isOpen}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <ul
          tabIndex={-1}
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-item-3"
          className="max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
        >
          {(!options || options.length === 0) && (
            <li
              role="option"
              aria-selected={false}
              className="text-gray-900 select-none relative py-2 pl-3 pr-9 cursor-text"
            >
              <div className="flex items-center">
                <span className="ml-3 block truncate">
                  {t("common.text.noData")}
                </span>
              </div>
            </li>
          )}
          {options &&
            options.length > 0 &&
            options.map((option, index) => {
              const selected = localValues?.includes(option);
              const active = activeOptionIndex === index;

              const liClasses = classnames(
                "select-none relative py-2 pl-3 pr-9",
                {
                  "text-gray-900 hover:bg-blue-200": !active,
                  "cursor-default": selected,
                  "cursor-pointer": !selected,
                  "bg-primary text-gray-100": active,
                }
              );
              return (
                <li
                  id={`option-${index}`}
                  key={index}
                  onClick={() => handleOnClickOption(option)}
                  role="option"
                  tabIndex={-1}
                  aria-selected={selected}
                  className={liClasses}
                >
                  <div className="flex items-center">
                    <span
                      className={`ml-3 block ${
                        selected ? "font-semibold" : "font-normal"
                      } truncate`}
                    >
                      {renderValues &&
                        renderValues([option.label || option.value])}
                      {renderValue && renderValue(option.label || option.value)}
                      {!renderValue &&
                        !renderValues &&
                        (option.label || option.value)}
                    </span>
                  </div>

                  {selected && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                      <CheckOutline className="h-5 w-5" />
                    </span>
                  )}
                </li>
              );
            })}
        </ul>
      </Transition>
    </div>
  );
}

interface DisplaySingleValueArgs {
  renderValue: ((value: string) => string | ReactNode) | undefined;
  value: SelectOption<string> | undefined;
  renderValues: undefined;
  values: undefined;
}
interface DisplayMultiValueArgs {
  renderValue: undefined;
  value: undefined;
  renderValues: ((values: string[]) => string | ReactNode) | undefined;
  values: SelectOption<string>[] | undefined;
}

export function displayValue(
  args: DisplaySingleValueArgs | DisplayMultiValueArgs
) {
  if (args.renderValue && args.value) {
    return args.renderValue(args.value.label ?? args.value.value);
  }

  if (args.renderValues && args.values) {
    return args.renderValues(
      args.values.map(({ label, value }) => label ?? value)
    );
  }
  return (
    (args.values?.map(({ label, value }) => label ?? value).join(", ") ||
      args.value?.label) ??
    args.value?.value
  );
}
