import { useMemo } from "react";
import classnames from "clsx";
import { getProgress } from "./Progress.model";

export interface ProgressBarProps {
  min?: number;
  max?: number;
  value: number;
  withLabel?: boolean;
}

export function ProgressBar({
  min = 0,
  max = 100,
  value,
  withLabel,
}: ProgressBarProps) {
  const { normalizedValue, label } = useMemo(
    () => getProgress({ min, max, value }),
    [min, max, value]
  );

  const barContainerClassnames = classnames("h-2 bg-gray-200 rounded-full", {
    "w-full": !withLabel,
    "w-11/12": withLabel,
  });
  return (
    <div className="flex items-center w-full space-x-2">
      <div className={barContainerClassnames}>
        <div
          className="h-full text-center text-xs text-white bg-green-500 rounded-full"
          style={{ width: `${normalizedValue}%` }}
        />
      </div>
      {withLabel && <div className="text-xs w-1/12 text-gray-600">{label}</div>}
    </div>
  );
}
