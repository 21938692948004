import { useState } from "react";
import { useAsync } from "react-use";
import { fileService } from "@services/file/file.service";

interface ImgProps {
  src: string;
  className: string;
  alt: string;
}

export function Img({ src, className, alt }: ImgProps) {
  const [objectUrl, setObjectUrl] = useState<string>();
  useAsync(async () => {
    const imgUrl = await fileService.getImage(src);
    setObjectUrl(imgUrl);
  }, [src]);
  return <img src={objectUrl} className={className} alt={alt} />;
}
