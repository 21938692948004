import { Button } from "@components/Button";
import { Card } from "@components/Card";
import { Comment } from "@components/Comment";
import { Title } from "@components/Title";
import { PencilSolid } from "@graywolfai/react-heroicons";
import { Client } from "@justplayfair/model";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface SessionCommentProps {
  session: NonNullable<Client.GetSessionQuery["getSession"]>;
  sessionComment?: string;
  onSessionUpdated: VoidFunction;
}
export function SectionComment({
  session,
  sessionComment,
  onSessionUpdated,
}: SessionCommentProps) {
  const { t } = useTranslation();
  const [editSessionNote, setEditSessionNote] = useState(false);
  const [sessionCommentValue, setSessionCommentValue] = useState(
    sessionComment || undefined
  );

  const [, updateSessionComment] = Client.useSaveSessionCommentMutation();

  async function handleSaveComment() {
    if (sessionCommentValue) {
      await updateSessionComment({
        sessionId: session.id,
        noteValue: sessionCommentValue,
      });
      setEditSessionNote(false);
      onSessionUpdated();
    }
  }
  return (
    <Card>
      <div className="px-8 py-8 space-y-4">
        <div className="flex w-full justify-between">
          <div>
            <Title size={4}>{t("trainer.session.title.sessionComment")}</Title>
          </div>
          {!editSessionNote && (
            <div className="w-30">
              <Button
                variant="secondary"
                preIcon={PencilSolid}
                title={
                  session.status !== "CREATED"
                    ? t("common.button.edit.label")
                    : ""
                }
                onClick={() => setEditSessionNote(true)}
              >
                {t("common.button.edit.label")}
              </Button>
            </div>
          )}
        </div>
        <Comment
          editable={editSessionNote}
          content={sessionCommentValue}
          fallbackMessage={t("trainer.session.label.noSessionComment")}
          onValueChange={setSessionCommentValue}
        />
        {editSessionNote && (
          <div className="flex justify-end">
            <div className="flex space-x-4">
              <div className="w-32">
                <Button
                  title={t("common.button.cancel.label")}
                  variant="transparent"
                  onClick={() => {
                    setSessionCommentValue(sessionComment);
                    setEditSessionNote(false);
                  }}
                >
                  {t("common.button.cancel.label")}
                </Button>
              </div>
              <div className="w-33">
                <Button
                  title={t("common.button.save.label")}
                  onClick={() => {
                    handleSaveComment();
                  }}
                >
                  {t("common.button.save.label")}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}
