import { Theme } from "../Layout.model";
import classnames from "clsx";
export interface MobileMenuProps {
  isOpen: boolean;
  theme: Theme;
  onClickButton: (isOpen: boolean) => void;
}

export function MobileMenu({ isOpen, theme, onClickButton }: MobileMenuProps) {
  function handleOpenMenu() {
    onClickButton(!isOpen);
  }

  const buttonClasses = classnames(
    "inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white",
    {
      "hover:bg-gray-700 focus:ring-offset-gray-800": theme === "dark",
      "hover:bg-blue-700 focus:ring-offset-blue-800": theme === "dark",
    }
  );
  return (
    <div className="-mr-2 flex md:hidden">
      <button className={buttonClasses} onClick={() => handleOpenMenu()}>
        <span className="sr-only">Open main menu</span>

        <svg
          className={`h-6 w-6 ${isOpen ? "hidden" : "block"}`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>

        <svg
          className={`h-6 w-6 ${isOpen ? "block" : "hidden"}`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  );
}
