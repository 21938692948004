import { PageContainer } from "@components/Layout";
import { Loader } from "@components/Loader";
import { Tabs } from "@components/Tabs";
import { Title } from "@components/Title";
import {
  AtSymbolOutline,
  ClipboardListSolid,
  UsersSolid,
} from "@graywolfai/react-heroicons";
import { Client } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import { Route, Switch, useParams } from "react-router-dom";
import { CompanyMembersTabContent } from "./tabContents/CompanyMembersTabContent.component";
import { CompanyProgramsTabContent } from "./tabContents/CompanyProgramsTabContent.component";

export function TrainerCompanyDashboard() {
  const { t } = useTranslation();

  const { companyId } = useParams<{ companyId: string }>();

  const [{ data, fetching }] = Client.useCompanyDashboardQuery({
    variables: { companyId },
    requestPolicy: "network-only",
  });

  if (fetching) {
    return (
      <PageContainer title={t("trainer.dashboard.title.company")}>
        <Loader size="medium" />
      </PageContainer>
    );
  }
  if (!data?.company) {
    return (
      <PageContainer title={t("trainer.dashboard.title.company")}>
        <Title size={6}>Introuvable</Title>
      </PageContainer>
    );
  }

  return (
    <PageContainer
      title={data.company && <CompanyDescriptionTitle company={data.company} />}
      backButton="/trainer/dashboard"
    >
      <div className="px-4 pb-8">
        <Tabs
          items={[
            {
              label: t("trainer.dashboard.tab.programs"),
              icon: ClipboardListSolid,
              link: `/trainer/dashboard/company/${companyId}`,
              testId: "companyProgramTab",
            },
            {
              label: t("trainer.dashboard.tab.members"),
              icon: UsersSolid,
              link: `/trainer/dashboard/company/${companyId}/tab/members`,
              testId: "companyMembersTab",
            },
          ]}
        />
      </div>
      <div className="px-8 space-y-8">
        <Switch>
          <Route
            path="/trainer/dashboard/company/:companyId"
            exact
            component={CompanyProgramsTabContent}
          />
          <Route
            path="/trainer/dashboard/company/:companyId/tab/members"
            component={CompanyMembersTabContent}
          />
        </Switch>
      </div>
    </PageContainer>
  );
}

interface CompanyDescriptionTitleProps {
  company: { name: string; email?: string | null };
}
function CompanyDescriptionTitle({ company }: CompanyDescriptionTitleProps) {
  const { t } = useTranslation();
  return (
    <div className="w-full p-4 flex items-center space-x-8 divide-x divide-gray-200">
      <Title size={3}>{company.name}</Title>
      <dl className="grid grid-cols-1 gap-x-24 gap-y-2 sm:grid-cols-3 pl-8">
        {company.email && (
          <div className="sm:col-span-1">
            <dt className="flex text-sm font-medium text-gray-500">
              <AtSymbolOutline className="h-4 w-4 mr-1" />
              {t("common.company.email")}
            </dt>
            <dd className="mt-0.5 text-sm text-gray-900">{company.email}</dd>
          </div>
        )}
      </dl>
    </div>
  );
}
