import { AuthLayout } from "@components/AuthLayout";
import { Button } from "@components/Button";
import { FormikInput } from "@components/form/Input/FormikInput";
import { AskRenewPasswordBody } from "@justplayfair/model";
import { userService } from "@services/user/user.service";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryParams } from "../../../hooks/useQueryParams.hook";
import * as Yup from "yup";

const ForgotPasswordSchema: Yup.SchemaOf<AskRenewPasswordBody> = Yup.object().shape(
  {
    email: Yup.string()
      .email("auth.error.invalidEmail")
      .required("auth.error.emailMandatory"),
  }
);

export function ForgotPasswordPage() {
  const { t } = useTranslation();
  const query = useQueryParams();

  const [loading, setLoading] = useState(false);
  const [errorKey, setErrorKey] = useState<undefined | "askRenewPassword">();

  const [step, setStep] = useState<"renew" | "emailSent">("renew");

  async function handleSendRenewPasswordMail({ email }: AskRenewPasswordBody) {
    setLoading(true);
    try {
      await userService.askRenewPassword({ email });
      setStep("emailSent");
    } catch (error) {
      console.error(error);
      setErrorKey("askRenewPassword");
    } finally {
      setLoading(false);
    }
  }
  return (
    <AuthLayout
      titleKey={
        step !== "emailSent"
          ? "auth.forgotPassword.title"
          : "auth.forgotPassword.emailSentTitle"
      }
      renderSubtitle={
        step !== "emailSent"
          ? t("auth.forgotPassword.message.renewInstruction")
          : t("auth.forgotPassword.message.emailSent")
      }
      errorKey={errorKey}
      loading={loading}
    >
      {step !== "emailSent" && (
        <Formik
          initialValues={{
            email: query.get("email") ?? "",
          }}
          onSubmit={(values) => {
            handleSendRenewPasswordMail(values);
          }}
          validationSchema={ForgotPasswordSchema}
        >
          <Form className="space-y-12" noValidate>
            <div className="flex flex-col space-y-6">
              <FormikInput
                editMode
                id="email"
                type="email"
                placeholder="Email address"
                withSeparator={false}
              />
            </div>

            <div>
              <Button
                title={t("auth.forgotPassword.button.label")}
                type="submit"
              >
                {t("auth.forgotPassword.button.label")}
              </Button>
            </div>
          </Form>
        </Formik>
      )}
    </AuthLayout>
  );
}
