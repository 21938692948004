"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.radarDataToTableData = exports.barDataToTableData = exports.byKeyBarDataComparisonMapper = exports.byKeyBarDataMapper = exports.byDateBarDataMapper = exports.radarDataMapper = exports.formatLabel = exports.translatePrefixedKey = exports.KEY_SEPARATOR = void 0;
const date_utilities_1 = require("../date/date.utilities");
const index_1 = require("../index");
exports.KEY_SEPARATOR = "$";
function translatePrefixedKey(key, t) {
    const splitted = key.split("$");
    return splitted.length === 2
        ? `${splitted[0]}${exports.KEY_SEPARATOR}${t(`${splitted[1]}.short`)}`
        : t(key);
}
exports.translatePrefixedKey = translatePrefixedKey;
function formatLabel(value) {
    if (index_1.NumberUtilities.isNumber(value, true)) {
        return `${value}`;
    }
    if ((0, date_utilities_1.isValueADate)(value)) {
        return (0, date_utilities_1.formatToLocalDate)(value);
    }
    const splitted = value.split(exports.KEY_SEPARATOR);
    return splitted.length === 2 ? splitted[1] : value;
}
exports.formatLabel = formatLabel;
function radarDataMapper(radarDataFields, t) {
    if (radarDataFields.length === 0) {
        return null;
    }
    const rateNames = Array.from(new Set(radarDataFields.flatMap(({ keys }) => keys)));
    const data = rateNames
        .flatMap((name) => {
        const userRates = radarDataFields.reduce((aggr, rates) => {
            const userData = rates.data.find(({ skillName }) => skillName === name);
            if (!userData) {
                return aggr;
            }
            return { ...aggr, [userData.name]: userData.rate };
        }, {});
        if (Object.keys(userRates).length === 0) {
            return;
        }
        return { skill: t(name), ...userRates };
    })
        .filter((d) => d !== undefined);
    return {
        keys: radarDataFields
            .filter(({ data }) => data.length > 0)
            .map(({ name }) => name),
        maxRate: radarDataFields[0].maxRate,
        data,
    };
}
exports.radarDataMapper = radarDataMapper;
function byDateBarDataMapper(barDataFields, t) {
    return {
        id: barDataFields.id,
        type: barDataFields.type,
        name: translatePrefixedKey(barDataFields.name, t),
        data: barDataFields.byDate.map((byDate) => ({
            date: (0, date_utilities_1.formatToLocalDate)(new Date(byDate.date)),
            ...Object.fromEntries(byDate.data.map(({ key, value }) => [t(key), value])),
        })),
        maxRate: barDataFields.maxRate,
        keys: barDataFields.keys.map((key) => t(key)),
    };
}
exports.byDateBarDataMapper = byDateBarDataMapper;
function byKeyBarDataMapper(barDataFields, t) {
    return {
        id: barDataFields.id,
        type: barDataFields.type,
        name: t(barDataFields.name),
        statName: barDataFields.statName,
        data: barDataFields.byKey.map((data) => ({
            key: translatePrefixedKey(data.key, t),
            isAggregate: data.isAggregate,
            ...Object.fromEntries(data.data.flatMap(({ key, value, stats }) => [
                [key, value],
                ["stats", stats],
            ])),
        })),
        maxRate: barDataFields.maxRate,
        keys: barDataFields.keys,
    };
}
exports.byKeyBarDataMapper = byKeyBarDataMapper;
function byKeyBarDataComparisonMapper(barDataFields, t) {
    if (!barDataFields.statName) {
        throw new Error('Cannot map data. missing field "statName"');
    }
    return {
        id: barDataFields.id,
        type: barDataFields.type,
        name: t(barDataFields.name),
        data: barDataFields.byKey
            .filter((data) => data.isAggregate)
            .map((data) => ({
            key: translatePrefixedKey(data.key, t),
            isAggregate: data.isAggregate,
            ...Object.fromEntries(data.data.flatMap(({ key, value, stats }) => [
                [t(barDataFields.name), value],
                [
                    barDataFields.statName,
                    stats ? index_1.NumberUtilities.round(stats.mean) : null,
                ],
            ])),
        })),
        maxRate: barDataFields.maxRate,
        keys: [t(barDataFields.name), barDataFields.statName],
    };
}
exports.byKeyBarDataComparisonMapper = byKeyBarDataComparisonMapper;
function barDataToTableData(barDatas, withStats) {
    return barDatas
        .slice()
        .reverse()
        .map((data) => {
        const statsRate = withStats
            ? !!data.stats
                ? `${index_1.NumberUtilities.round(data.stats.mean)}`
                : "-" // If no stats resolved by api. Display an empty "-"
            : undefined;
        return {
            label: data.key,
            emphasis: data.isAggregate,
            rate: `${index_1.NumberUtilities.round(data.rate)}`,
            statsRate,
        };
    });
}
exports.barDataToTableData = barDataToTableData;
function radarDataToTableData([main, comparison]) {
    return main.keys.map((key) => {
        const mainData = main.data.find(({ skillName }) => skillName === key);
        const comparisonData = comparison.data.find(({ skillName }) => skillName === key);
        return {
            label: key,
            emphasis: false,
            rate: mainData ? `${index_1.NumberUtilities.round(mainData.rate)}` : "",
            statsRate: comparisonData
                ? `${index_1.NumberUtilities.round(comparisonData.rate)}`
                : "",
        };
    });
}
exports.radarDataToTableData = radarDataToTableData;
