import { AuthTokenClaims } from "@justplayfair/model";
import decode from "jwt-decode";
const isTestEnv = process.env.NODE_ENV === "test";

const ACCESS_TOKEN_KEY = "accesstoken";
const SECONDS_BEFORE_EXP_MARGIN = 10 * 60; // 10 minutes

type StorableToken = { token: string; storedAt: number };

export type TokenStorage = {
  getToken: () => string | null;
  setToken: (token: string) => void;
  unset: () => void;
  getDecodedToken: () => AuthTokenClaims | null;
  isTokenOld: (token: string) => boolean;
};

export function getTokenStorage() {
  return isTestEnv ? MockTokenStorage : LocalTokenStorage;
}

const LocalTokenStorage: TokenStorage = {
  getToken() {
    const storedToken = window.localStorage.getItem(ACCESS_TOKEN_KEY);
    if (!storedToken) {
      return undefined;
    }
    return JSON.parse(storedToken).token;
  },
  setToken(token) {
    const storableToken: StorableToken = {
      storedAt: Date.now(),
      token,
    };
    window.localStorage.setItem(
      ACCESS_TOKEN_KEY,
      JSON.stringify(storableToken)
    );
  },
  unset() {
    window.localStorage.removeItem(ACCESS_TOKEN_KEY);
  },
  getDecodedToken<TokenClaims>() {
    const token = this.getToken();
    if (!token) {
      return null;
    }
    return decode<TokenClaims>(token);
  },

  isTokenOld(token: string) {
    const { exp } = decode<AuthTokenClaims>(token);
    return Date.now() / 1000 > exp - SECONDS_BEFORE_EXP_MARGIN;
  },
};

const MockTokenStorage: TokenStorage & {
  _items: Map<string, StorableToken>;
} = {
  _items: new Map<string, StorableToken>(),

  getToken() {
    const storedToken = this._items.get(ACCESS_TOKEN_KEY);
    return storedToken?.token || null;
  },
  setToken(token) {
    this._items.set(ACCESS_TOKEN_KEY, { storedAt: Date.now(), token });
  },
  unset() {
    this._items.delete(ACCESS_TOKEN_KEY);
  },
  getDecodedToken<TokenClaims>() {
    const token = this.getToken();
    if (!token) {
      return null;
    }
    return decode<TokenClaims>(token);
  },

  isTokenOld(token: string) {
    const { exp } = decode<AuthTokenClaims>(token);
    return Date.now() / 1000 > exp - SECONDS_BEFORE_EXP_MARGIN;
  },
};
