"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sanitizeHtml = exports.removeHtmlFormating = void 0;
const htmlSanitizer = require("sanitize-html");
const BLOCK_TAGS = ["H1", "H2", "H3", "H4"];
function removeHtmlFormating(parser, htmlDocument, htmlString) {
    const htmlDoc = parser.parseFromString(htmlString, "text/html");
    Array.from(htmlDoc.getElementsByTagName("br")).forEach((brElt) => {
        const brReplacer = htmlDocument.createTextNode(" - ");
        brElt.parentElement?.replaceChild(brReplacer, brElt);
    });
    const nodes = htmlDoc.body.childNodes;
    if (!nodes || nodes?.length === 0) {
        return "";
    }
    return Array.from(nodes)
        .map((node, index, arr) => {
        if (BLOCK_TAGS.includes(node.nodeName) && index < arr.length - 1) {
            return `${node.textContent} - `;
        }
        return node.textContent;
    })
        .join("");
}
exports.removeHtmlFormating = removeHtmlFormating;
function sanitizeHtml(html) {
    return htmlSanitizer(html);
}
exports.sanitizeHtml = sanitizeHtml;
