import { Client } from "@justplayfair/model";

export function useAuthenticatedUser() {
  const [{ fetching, data, error }, reexecuteQuery] = Client.useMeQuery();
  return {
    fetching,
    user: data?.me,
    notifications: data?.notifications,
    error,
    reexecuteQuery,
  };
}
