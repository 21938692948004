import { Loader } from "@components/Loader";
import { Title } from "@components/Title";
import { Client, ID } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { PageContainer } from "@components/Layout";
import { LabelForm, LabelFormFields } from "./LabelForm.component";

export function LabelDetailsPage() {
  const { t } = useTranslation();
  let { companyId, labelId } = useParams<{ companyId: ID; labelId?: ID }>();
  const history = useHistory();

  const [{ data, fetching: fetchingCompany }] = Client.useGetCompanyQuery({
    variables: { id: companyId },
  });
  const [{ fetching: fetchCreation }, createLabel] =
    Client.useCreateLabelMutation();
  const [{ fetching: fetchUpdate }, updateLabel] =
    Client.useUpdateLabelMutation();
  const [, deleteLabel] = Client.useDeleteLabelMutation();

  async function handleSaveLabel(input: LabelFormFields) {
    let result;
    if (!labelId) {
      result = await createLabel({ input: { ...input, companyId } });
    } else {
      result = await updateLabel({
        labelId,
        input: { ...input, companyId },
      });
    }
    if (!result.error) {
      history.push(`/admin/companies/${companyId}`);
    }
  }

  async function handleDeleteLabel(labelId: ID) {
    if (window.confirm("Etes vous sûr de vouloir supprimer ce label ?")) {
      await deleteLabel({ labelId });
      history.push(`/admin/companies/${companyId}`);
    }
  }

  if (fetchCreation || fetchUpdate || fetchingCompany) {
    return (
      <PageContainer title={t("menu.admin")}>
        {<Loader size="medium" />}
      </PageContainer>
    );
  }
  return (
    <PageContainer
      title={t("menu.admin")}
      subtitle={
        labelId
          ? t("admin.label.title.updateLabel")
          : t("admin.label.title.createLabel")
      }
      backButton={`/admin/companies/${companyId}`}
    >
      <div className="w-full max-w-4xl mx-auto">
        {!data?.company && "Introuvable"}
        {data?.company && (
          <>
            <Title size={4}>{`${t("admin.label.title.forCompany")} ${
              data.company.name
            }`}</Title>
            <LabelForm
              labelId={labelId}
              onSaveLabel={handleSaveLabel}
              onDeleteLabel={handleDeleteLabel}
            />
          </>
        )}
      </div>
    </PageContainer>
  );
}
