import { SelectOption } from "@components/form/Select/Select.model";
import { Client } from "@justplayfair/model";
import { addMonths, set } from "date-fns";

export const COMPANY_TYPES = ["ADMIN", "CUSTOMER"] as Client.CompanyType[];

export const COMPANY_TYPE_OPTIONS: SelectOption<string>[] = [
  { value: "ADMIN" },
  { value: "CUSTOMER" },
];

export const PROGRAM_TYPES = ["ASSESSMENT", "TRAINING"] as Client.ProgramType[];

export const PROGRAM_TYPES_OPTIONS: SelectOption<string>[] = [
  { value: "ASSESSMENT" },
  { value: "TRAINING" },
];

export function getTrainingDate(
  startDate?: Date,
  nbMonths?: number
): { startDate: Date; endDate: Date } {
  if (!startDate || !nbMonths) {
    throw new Error(
      'Missing mandatory field for a training program "starDate" or "nbMonths"'
    );
  }
  const cleanedStartDate = set(startDate, {
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  return {
    startDate: cleanedStartDate,
    endDate: addMonths(cleanedStartDate, nbMonths),
  };
}
