import { FormikSelect } from "@components/form/Select/FormikSelect";
import { Client } from "@justplayfair/model";
import { useFormikContext } from "formik";
import { useMemo } from "react";

interface FormikProgramTraineesSelectFieldProps {
  fieldName: string;
  editMode: boolean;
  label: string;
}
export function FormikProgramTraineesSelectField({
  fieldName,
  editMode,
  label,
}: FormikProgramTraineesSelectFieldProps) {
  const {
    values: { programId },
  } = useFormikContext<{ programId: string }>();

  const [{ data }] = Client.useGetProgramQuery({
    pause: programId.length === 0,
    variables: {
      programId,
    },
  });
  const traineeOpts = useMemo(
    () =>
      data?.program?.users?.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [data]
  );

  return (
    <FormikSelect
      editMode={editMode}
      id={fieldName}
      multi
      label={label}
      options={traineeOpts || []}
    />
  );
}
