"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JPF_PALETTE = exports.CHART_COLORS = exports.DEFAULT_MAX_RATE = void 0;
exports.DEFAULT_MAX_RATE = 4;
exports.CHART_COLORS = {
    primaryBlue: "#4252df",
    emphasisBlue: "#000e8c",
    gold: "#cd9b5a",
    lightGreen: "#60b5a5",
    pink: "#ea88b6",
    darkPurge: "#2c285b",
    red: "#e84944",
    yellow: "#ffd957",
    darkGreen: "#458075",
    purple: "#533280",
    greyRed: "#b5696d",
    salmon: "#e8646b",
    brown: "#694244",
    orange: "#fcb158",
};
exports.JPF_PALETTE = [
    exports.CHART_COLORS.primaryBlue,
    exports.CHART_COLORS.gold,
    exports.CHART_COLORS.lightGreen,
    exports.CHART_COLORS.pink,
    exports.CHART_COLORS.darkPurge,
    exports.CHART_COLORS.red,
    exports.CHART_COLORS.yellow,
    exports.CHART_COLORS.darkGreen,
    exports.CHART_COLORS.purple,
    exports.CHART_COLORS.greyRed,
    exports.CHART_COLORS.salmon,
    exports.CHART_COLORS.brown,
    exports.CHART_COLORS.orange,
    exports.CHART_COLORS.emphasisBlue,
];
