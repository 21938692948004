import classnames from "clsx";
import { ChangeEvent, FocusEvent } from "react";
import { IconComponent } from "../../Icon/Icon.model";

interface BaseInputProps {
  id: string;
  label?: string;
  type?: string;
  required?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  defaultValue?: string;
  value?: string;
  icon?: IconComponent;
  disabled?: boolean;
  isError?: boolean;
  autoComplete?: string;
  step?: string; // Step for input number field
  min?: number;
  max?: number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

interface ControlledInputProps extends BaseInputProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}
interface UnControlledInputProps extends BaseInputProps {
  defaultValue: string;
}

export type InputProps = ControlledInputProps | UnControlledInputProps;

export function Input({
  id,
  type = "text",
  label,
  placeholder,
  required,
  autoFocus,
  autoComplete,
  disabled,
  isError,
  icon,
  defaultValue,
  value,
  step,
  min,
  max,
  onChange,
  onBlur,
}: InputProps) {
  const inputClasses = classnames(
    "appearance-none rounded w-full px-3 py-2 text-gray-900 border placeholder-gray-500 focus:outline-none focus:ring-2 sm:text-sm",
    {
      "pl-10": !!icon,
      "border-gray-300 focus:ring-primary": !isError,
      "border-red-400 focus:ring-red-400": isError,
    }
  );
  return (
    <div className="text-gray-400 focus-within:text-gray-600 w-full ">
      {label && (
        <label htmlFor={id} className="sr-only">
          {label}
        </label>
      )}
      <div className="relative">
        {icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none ">
            {icon({
              className: "h-5 w-5",
            })}
          </div>
        )}
        <input
          id={id}
          name={id}
          type={type}
          defaultValue={defaultValue}
          value={value}
          placeholder={placeholder}
          required={required}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          disabled={disabled}
          step={step}
          min={min}
          max={max}
          onChange={
            onChange
              ? (event) => {
                  onChange(event);
                }
              : undefined
          }
          onBlur={onBlur}
          className={inputClasses}
        />
      </div>
    </div>
  );
}
