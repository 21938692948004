import { Card } from "@components/Card";
import { ProgressBar } from "@components/ProgressBar";
import { Title } from "@components/Title";
import { Client } from "@justplayfair/model";
import { useTranslation } from "react-i18next";

interface ProgramSessionAdvancementProps {
  advancement: Client.AdvancementFieldsFragment;
  testId?: string;
}
export function ProgramSessionAdvancement({
  advancement,
  testId = "ProgramSessionAdvancement",
}: ProgramSessionAdvancementProps) {
  const { t } = useTranslation();

  return (
    <Card>
      <div
        className="flex flex-col justify-around p-2 h-24"
        data-testid={testId}
      >
        <Title size={6}>{t("component.programSessionAdvancement.title")}</Title>

        <ProgressBar
          value={advancement.nbSessionDone}
          max={advancement.nbSession}
          withLabel
        />
      </div>
    </Card>
  );
}
