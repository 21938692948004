import { Avatar } from "@components/Avatar";
import { Button } from "@components/Button";
import { Chip, ChipContainer } from "@components/Chip";
import { FormikDatePicker } from "@components/form/DatePicker/FormikDatePicker";
import { FormActions } from "@components/form/FormActions";
import { FormikInput } from "@components/form/Input/FormikInput";
import { FormikSelect } from "@components/form/Select/FormikSelect";
import { FormikToggle } from "@components/form/Toggle/FormikToggle";
import {
  AtSymbolOutline,
  CheckCircleOutline,
  UserOutline,
  XCircleOutline,
} from "@graywolfai/react-heroicons";
import { Client, ID } from "@justplayfair/model";
import { Form, Formik } from "formik";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ROLES, ROLE_OPTIONS } from "../../../../model/user.model";
import { FormikLabelSelectField } from "../components/FormikLabelSelectField.component";
import { SendRenewPasswordEmailButton } from "./components/SendRenewPasswordEmailButton.component";

type UpdateUserFields = Omit<Client.UpdateUserInputAdmin, "labels"> & {
  hierarchicalPositionLabel?: ID | null;
};

const EditUserSchema: Yup.SchemaOf<UpdateUserFields> = Yup.object().shape({
  roles: Yup.array()
    .of(Yup.mixed().oneOf(ROLES).defined())
    .required("admin.user.error.rolesMandatory")
    .min(1, "admin.user.error.rolesMandatory"),
  email: Yup.string()
    .email("admin.user.error.invalidEmail")
    .required("admin.user.error.emailMandatory"),
  name: Yup.string().required("admin.user.error.nameMandatory"),
  hiringDate: Yup.string(),
  activated: Yup.boolean(),
  companyId: Yup.string().required(),
  programIds: Yup.array().of(Yup.mixed().defined()),
  hierarchicalPositionLabel: Yup.mixed(),
  departmentLabel: Yup.mixed(),
  mfaActivated: Yup.boolean(),
});

interface UserFormProps {
  user: Client.AdminUserDetailsFieldsFragment;
  companies?: Client.CompanyFieldsFragment[];
  onEditUser: (input: Client.UpdateUserInputAdmin) => Promise<void>;
  onDeleteUser: () => Promise<void>;
  onUndelete: () => Promise<void>;
}
export function UserForm({
  user,
  companies,
  onEditUser,
  onDeleteUser,
  onUndelete,
}: UserFormProps) {
  const { t } = useTranslation();

  const [isEdit, setIsEdit] = useState(false);

  const companyOptions = useMemo(
    () => (companies ? companies?.map(({ id }) => ({ value: id })) : []),
    [companies]
  );

  return (
    <Formik
      initialValues={{
        companyId: user.company?.id || "",
        name: user.name,
        email: user.email,
        hiringDate: user.hiringDate ? new Date(user.hiringDate) : undefined,
        roles: user.roles,
        activated: user.activated,
        mfaActivated: user.mfaActivated,
        programIds: user.programs?.map(({ id }) => id),
        hierarchicalPositionLabel: user.labels?.find(
          (label) => label.category === "HIERARCHICAL_POSITION"
        )?.id,
        departmentLabel: user.labels?.find(
          (label) => label.category === "DEPARTMENT"
        )?.id,
        businessUnitLabel: user.labels?.find(
          (label) => label.category === "BUSINESS_UNIT"
        )?.id,
      }}
      onSubmit={async ({
        hierarchicalPositionLabel,
        departmentLabel,
        businessUnitLabel,
        ...otherFields
      }) => {
        if (!isEdit) {
          throw new Error("Should not submit if not in edition mode");
        }

        await onEditUser({
          ...otherFields,
          labels:
            hierarchicalPositionLabel || departmentLabel || businessUnitLabel
              ? [
                  hierarchicalPositionLabel,
                  departmentLabel,
                  businessUnitLabel,
                ].filter((label): label is string => !!label)
              : undefined,
        });
        setIsEdit(false);
      }}
      validationSchema={EditUserSchema}
    >
      {(props) => {
        const companyPrograms =
          companies?.find((company) => props.values.companyId === company.id)
            ?.programs || [];

        return (
          <Form className="bg-white shadow overflow-hidden p-2">
            <div>
              <FormikInput
                editMode={false}
                id="email"
                label={t("common.user.email")}
                icon={AtSymbolOutline}
              />

              <FormikSelect
                editMode={isEdit}
                id="roles"
                multi
                label={t("common.user.role")}
                options={ROLE_OPTIONS}
                renderValue={(values) => (
                  <ChipContainer>
                    {values.map((val, index) => (
                      <Chip
                        key={index}
                        color="primary"
                        label={t(`common.role.${val}`)}
                      />
                    ))}
                  </ChipContainer>
                )}
              />

              <FormikSelect
                editMode={isEdit}
                id="companyId"
                label={t("common.user.company")}
                options={companyOptions}
                multi={false}
                renderValue={(value) => {
                  const companyVal = companies?.find(
                    (company) => company.id === value
                  );
                  if (!companyVal) {
                    return;
                  }
                  return (
                    <div className="space-x-1">
                      <Avatar size="mini" entity={companyVal} />
                      <span>{companyVal.name}</span>
                    </div>
                  );
                }}
              />
              <FormikInput
                editMode={isEdit}
                id="name"
                label={t("common.user.name")}
                icon={UserOutline}
              />
              {props.values.roles.includes("TRAINEE") && (
                <>
                  <FormikDatePicker
                    editMode={isEdit}
                    id="hiringDate"
                    label={t("common.user.hiringDate")}
                  />

                  <FormikSelect
                    multi
                    editMode={isEdit}
                    id="programIds"
                    label={t("common.trainingPrograms.name")}
                    options={companyPrograms.map((program) => ({
                      value: program.id,
                      label: program.name,
                    }))}
                  />
                  <FormikLabelSelectField
                    editMode={isEdit}
                    fieldName="hierarchicalPositionLabel"
                    labelCategory="HIERARCHICAL_POSITION"
                    label={t("admin.user.createUser.hierarchicalPosition")}
                  />

                  <FormikLabelSelectField
                    editMode={isEdit}
                    fieldName="departmentLabel"
                    labelCategory="DEPARTMENT"
                    label={t("admin.user.createUser.department")}
                  />

                  <FormikLabelSelectField
                    editMode={isEdit}
                    fieldName="businessUnitLabel"
                    labelCategory="BUSINESS_UNIT"
                    label={t("admin.user.createUser.businessUnit")}
                  />
                </>
              )}

              <FormikToggle
                editMode={isEdit}
                id="activated"
                label={t("common.user.activated")}
                renderValue={(val) => {
                  return val === true ? (
                    <CheckCircleOutline className="h-8 w-8 text-green-500" />
                  ) : (
                    <XCircleOutline className="h-8 w-8 text-gray-500" />
                  );
                }}
              />

              <FormikToggle
                editMode={isEdit && user.mfaActivated}
                id="mfaActivated"
                label={t("common.user.mfaActivated")}
                renderValue={(val) => {
                  return val === true ? (
                    <CheckCircleOutline className="h-8 w-8 text-green-500" />
                  ) : (
                    <XCircleOutline className="h-8 w-8 text-gray-500" />
                  );
                }}
              />
            </div>

            <FormActions
              isEdit={isEdit}
              handleReset={props.handleReset}
              customActions={
                <>
                  {props.values.activated && (
                    <div className="">
                      <SendRenewPasswordEmailButton userEmail={user.email} />
                    </div>
                  )}
                  {user.deleted !== null ? (
                    <Button
                      variant="secondary"
                      title={"Restituer"}
                      onClick={onUndelete}
                    >
                      Restituer
                    </Button>
                  ) : undefined}
                </>
              }
              onDelete={user.deleted === null ? onDeleteUser : undefined}
              setIsEdit={setIsEdit}
            />
          </Form>
        );
      }}
    </Formik>
  );
}
