import { BarChart } from "@components/Dataviz/chart/BarChart.component";
import { Loader } from "@components/Loader";
import { DatavizMapper } from "@justplayfair/model";
import { useTranslation } from "react-i18next";

interface EvolutionBarProps {
  evolutionData:
    | ReturnType<typeof DatavizMapper.byKeyBarDataMapper>[]
    | undefined;
  fetching: boolean;
  height: number;
}

export function EvolutionBarChart({
  evolutionData,
  fetching,
  height,
}: EvolutionBarProps) {
  const { t } = useTranslation();

  if (fetching) {
    return <Loader size="medium" />;
  }

  if (
    !evolutionData ||
    !evolutionData.some((evolution) => evolution.data.length > 0)
  ) {
    return <div className="text-gray-500">{t("common.text.noData")}</div>;
  }
  return (
    <>
      {evolutionData.map((evolution, index) => (
        <div key={index} style={{ height }}>
          <BarChart
            containerHeight={height}
            layout="vertical"
            xLegend=""
            data={evolution.data}
            keys={evolution.keys}
            maxRate={evolution.maxRate}
            indexBy="key"
            colorTheme="default"
          />
        </div>
      ))}
    </>
  );
}
