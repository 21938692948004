import { PageContainer } from "@components/Layout";
import { Loader } from "@components/Loader";
import { Tabs } from "@components/Tabs";
import { Title } from "@components/Title";
import { UserDescriptionTitle } from "@components/UserDescription";
import {
  PresentationChartLineSolid,
  VideoCameraSolid,
} from "@graywolfai/react-heroicons";
import { Client } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import { Route, Switch, useParams } from "react-router-dom";
import { SessionTabContent } from "./tabContents/SessionTabContent.component";
import { DatavizTabContent } from "./tabContents/TraineeDatavizTabContent.component";

export function TrainerTraineeDashboard() {
  const { t } = useTranslation();

  const { programId, traineeId } = useParams<{
    programId: string;
    traineeId: string;
  }>();

  const [{ data, fetching }] = Client.useTrainerTraineeDashboardQuery({
    variables: { traineeId },
    requestPolicy: "network-only",
  });

  if (fetching) {
    return (
      <PageContainer title={t("trainer.dashboard.title.trainee")}>
        <Loader size="medium" />
      </PageContainer>
    );
  }

  if (!data?.user) {
    return (
      <PageContainer title={t("trainer.dashboard.title.trainee")}>
        <Title size={6}>Introuvable</Title>
      </PageContainer>
    );
  }

  return (
    <PageContainer
      title={
        <UserDescriptionTitle
          user={data.user}
          programId={programId}
          advancement={data.user.advancement}
        />
      }
      backButton={`/trainer/program/dashboard/${programId}/tab/members`}
    >
      <div className="px-4 pb-8">
        <Tabs
          items={[
            {
              label: t("trainer.dashboard.tab.sessions"),
              icon: VideoCameraSolid,
              link: `/trainer/trainee/dashboard/${programId}/${traineeId}`,
            },
            {
              label: t("trainer.dashboard.tab.evaluationsData"),
              icon: PresentationChartLineSolid,
              link: `/trainer/trainee/dashboard/${programId}/${traineeId}/tab/dataviz`,
            },
          ]}
        />
      </div>
      <div className="px-8 space-y-8">
        <Switch>
          <Route
            path="/trainer/trainee/dashboard/:programId/:traineeId"
            exact
            component={SessionTabContent}
          />
          <Route
            path="/trainer/trainee/dashboard/:programId/:traineeId/tab/dataviz"
            component={DatavizTabContent}
          />
        </Switch>
      </div>
    </PageContainer>
  );
}
