import { Card } from "@components/Card";
import { Title } from "@components/Title";
import { Client } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import { ReplaySimulationsDropdown } from "../../../../../components/ReplaySimulationsDropdown";
import { useAuthenticatedUser } from "../../../../../hooks/authenticatedUser/useAuthenticatedUser.hook";
import { SessionEvaluationForm } from "../../../../Trainer/TrainerTraineeDashboard/Session/SessionEvaluation/SessionEvaluation.form";

interface SessionEvaluationProps {
  session: NonNullable<Client.GetSessionQuery["getSession"]>;
}
export function SessionEvaluation({ session }: SessionEvaluationProps) {
  const { t } = useTranslation();
  const isEvaluation = session.status === "EVALUATED";
  const { user } = useAuthenticatedUser();
  return (
    <Card>
      <div className="px-8 py-8">
        <div className="flex w-full justify-between">
          <div className="flex gap-x-4">
            <Title size={4}>
              {t("trainer.session.title.mySessionEvaluation")}{" "}
              {user?.company?.maxRate}
            </Title>

            {!!session.externalSessionReplayLinks?.length && (
              <ReplaySimulationsDropdown
                replayUrls={session.externalSessionReplayLinks}
                position="right"
              />
            )}
          </div>
        </div>
        {isEvaluation && (
          <SessionEvaluationForm
            session={session}
            isEdit={false}
            onEvaluationCompleted={() => {}}
          />
        )}
        {!isEvaluation && (
          <div className="space-y-4  h-44">
            <div className="text-gray-500">
              {t("trainer.session.label.noEvaluationYet")}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}
