import { Card } from "@components/Card";
import { FormActions } from "@components/form/FormActions";
import { FormikInput } from "@components/form/Input/FormikInput";
import { FormikSelect } from "@components/form/Select/FormikSelect";
import { Loader } from "@components/Loader";
import { SessionDetails } from "@components/SessionDetails";
import { Client, ID, parseJSONDate } from "@justplayfair/model";
import { Form, Formik } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  SESSION_DURATION_OPTIONS,
  SESSION_TYPE_OPTIONS,
} from "../../../../model/Session.model";
import { AppointmentFormFields } from "./AppointmentFormFields";
import { FormikExternalSessionsField } from "./components/FormikExternalSessionsField.component";
import {
  createAppointmentDates,
  ExternalSimulationSchema,
} from "./SessionDetails.model";

const TRAINER_UPDATE_AUTHORIZED_STATUS: Client.SessionStatus[] = [
  "CREATED",
  "PLANNED",
];
const UpdateSessionSchema = Yup.object().shape({
  name: Yup.string().required("admin.session.error.nameMandatory"),
  duration: Yup.mixed()
    .oneOf(["TWENTY", "THIRTY", "FORTYFIVE", "NINETY"])
    .required("admin.session.error.durationMandatory"),
  type: Yup.mixed()
    .oneOf(["ONLINE_ASSESSMENT", "WITH_TRAINER"])
    .required("admin.session.error.typeMandatory"),
  externalSimulations: Yup.mixed().when("type", {
    is: "WITH_TRAINER",
    then: Yup.array().optional(),
    otherwise: Yup.array()
      .of(ExternalSimulationSchema.required())
      .required("admin.session.error.externalSimulationsMandatory"),
  }),
  trainerId: Yup.string().required("admin.session.error.trainerMandatory"),
  startDate: Yup.date(),
});

interface UpdateSessionFormProps {
  sessionId: ID;
  trainers: Client.UserDetailsFieldsFragment[];
  onUpdateSession: (
    input: Client.UpdateSessionInput,
    appointmenDates?: Client.SessionAppointmentDates
  ) => Promise<void>;
  onDeleteSession: (sessionId: ID) => Promise<void>;
}

export function UpdateSessionForm({
  sessionId,
  trainers,
  onUpdateSession,
  onDeleteSession,
}: UpdateSessionFormProps) {
  const { t } = useTranslation();

  const [{ data: sessionData, fetching: fetchingSession }] =
    Client.useGetSessionQuery({
      variables: { id: sessionId },
    });

  const trainersOpts = useMemo(
    () =>
      trainers.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [trainers]
  );

  if (fetchingSession) {
    return <Loader size="medium" />;
  }
  if (!sessionData?.getSession) {
    throw new Error("Session not found");
  }
  return (
    <div className="space-y-2">
      {sessionData?.getSession && (
        <Card>
          <div className="flex justify-center py-2 px-4">
            <SessionDetails
              session={sessionData.getSession}
              omitFields={
                TRAINER_UPDATE_AUTHORIZED_STATUS.includes(
                  sessionData.getSession.status
                )
                  ? ["duration", "trainer"]
                  : ["duration"]
              }
            />
          </div>
        </Card>
      )}
      <Formik
        initialValues={{
          name: sessionData.getSession.name ?? "",
          type: sessionData.getSession.type ?? "WITH_TRAINER",
          externalSimulations:
            sessionData.getSession.externalSimulations ?? null,
          programId: sessionData.getSession.program?.id || "",
          duration:
            sessionData.getSession.duration ??
            ("THIRTY" as Client.SessionDuration),
          trainerId: sessionData.getSession.trainer?.id ?? "",
          startDate: sessionData.getSession.appointment?.startDate
            ? parseJSONDate(sessionData?.getSession?.appointment?.startDate)
            : undefined,
          recurrence: "ONCE" as Client.SessionRecurrenceRepeat,
          recurrenceNb: 1,
        }}
        onSubmit={async ({
          programId,
          startDate,
          recurrence,
          recurrenceNb,
          ...fields
        }) => {
          let appointmentDates: Client.SessionAppointmentDates | undefined =
            undefined;
          if (startDate) {
            appointmentDates = createAppointmentDates(
              startDate,
              fields.duration,
              { repeat: recurrence, nb: recurrenceNb }
            );
          }

          await onUpdateSession(fields, appointmentDates);
        }}
        validationSchema={UpdateSessionSchema}
      >
        {(props) => {
          return (
            <Form className="space-y-2">
              <div className="flex space-x-2">
                <div className="w-3/4 bg-white shadow p-2">
                  <FormikInput
                    editMode={true}
                    id="name"
                    label={t("common.session.name")}
                  />
                  <FormikSelect
                    editMode={true}
                    id="type"
                    multi={false}
                    label={t("common.session.type.label")}
                    options={SESSION_TYPE_OPTIONS}
                    renderValue={(value) =>
                      t(`common.session.type.value.${value}`)
                    }
                  />

                  {props.values.type !== "WITH_TRAINER" && (
                    <FormikExternalSessionsField
                      id="externalSimulations"
                      edit={false}
                    />
                  )}

                  <FormikSelect
                    editMode={true}
                    id="duration"
                    multi={false}
                    label={t("common.session.duration.label")}
                    options={SESSION_DURATION_OPTIONS}
                    renderValue={(value) =>
                      t(`common.session.duration.value.${value}`)
                    }
                  />
                  {sessionData.getSession?.status &&
                    TRAINER_UPDATE_AUTHORIZED_STATUS.includes(
                      sessionData.getSession.status
                    ) && (
                      <FormikSelect
                        editMode={true}
                        id="trainerId"
                        multi={false}
                        label={t("common.session.trainer")}
                        options={trainersOpts}
                      />
                    )}
                </div>
                <div className="w-1/4 bg-white shadow p-2">
                  <AppointmentFormFields
                    values={{
                      recurrence: props.values.recurrence,
                      startDate: props.values.startDate,
                      sessionStatus:
                        sessionData?.getSession?.status || undefined,
                    }}
                  />
                </div>
              </div>
              <FormActions
                handleReset={props.handleReset}
                onDelete={() => onDeleteSession(sessionId)}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
