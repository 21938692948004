import { SelectOption } from "@components/form/Select/Select.model";
import { Client } from "@justplayfair/model";

export const SESSION_STATUS_OPTIONS: SelectOption<Client.SessionStatus>[] = [
  { label: "common.session.status.CREATED", value: "CREATED" },
  { label: "common.session.status.PLANNED", value: "PLANNED" },
  { label: "common.session.status.IN_PROGRESS", value: "IN_PROGRESS" },
  { label: "common.session.status.DONE", value: "DONE" },
  { label: "common.session.status.EVALUATED", value: "EVALUATED" },
];
