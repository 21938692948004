import React from "react";
import {
  Redirect,
  Route,
  RouteProps,
  Router,
  Switch,
  useLocation,
} from "react-router-dom";
import { Layout } from "./components/Layout";
import { Loader } from "./components/Loader";
import { browserHistory } from "./history";
import { AdminPage } from "./pages/Admin/Admin.page";
import { CompaniesPage } from "./pages/Admin/Company/Companies.page";
import { CreateCompanyPage } from "./pages/Admin/Company/CreateCompany/CreateCompany.page";
import { CreateProgramPage } from "./pages/Admin/Company/EditCompany/CreateProgram/CreateProgram.page";
import { EditCompanyPage } from "./pages/Admin/Company/EditCompany/EditCompany.page";
import { UpdateProgramPage } from "./pages/Admin/Company/EditCompany/UpdateProgram/UpdateProgram.page";
import { LabelDetailsPage } from "./pages/Admin/Company/EditCompany/LabelDetails/LabelDetails.page";
import { MediaDetailsPage } from "./pages/Admin/MediaLibrary/MediaDetails/MediaDetails.page";
import { MediaLibrary } from "./pages/Admin/MediaLibrary/MediaLibrary.page";
import { SessionDetailsPage } from "./pages/Admin/Sessions/SessionDetails/SessionDetails.page";
import { SessionList } from "./pages/Admin/Sessions/SessionList/SessionList.page";
import { CreateUserPage } from "./pages/Admin/User/CreateUser/CreateUser.page";
import { EditUserPage } from "./pages/Admin/User/EditUser/EditUser.page";
import { LoginPage } from "./pages/Auth";
import { ActivatePage } from "./pages/Auth/Activate/Activate.page";
import { ChangePasswordPage } from "./pages/Auth/ChangePassword/ChangePasswordPage.page";
import { ForgotPasswordPage } from "./pages/Auth/ForgotPassword/ForgotPassword.page";
import { ClientProgramDashboardPage } from "./pages/Client/ClientProgramDashboard/ClientProgramDashboard.page";
import { ClientDashboardPage } from "./pages/Client/dashboard/ClientDashboard.page";
import { ProfilePage } from "./pages/Profile";
import { TraineeDashboardPage } from "./pages/Trainee/TraineeDashboard.page";
import { TraineeSessionPage } from "./pages/Trainee/TraineeDetails/Session/TraineeSession.page";
import { TraineeDetails } from "./pages/Trainee/TraineeDetails/TraineeDetails.page";
import { TrainerAvailabilitiesPage } from "./pages/Trainer/Availabilitites/Availabilites.page";
import { TrainerCompanyDashboard } from "./pages/Trainer/TrainerCompanyDashboard/TrainerCompanyDashboard.page";
import { TrainerDashboardPage } from "./pages/Trainer/TrainerDashboard.page";
import { TrainerProgramDashboard } from "./pages/Trainer/TrainerProgramDashboard/TrainerProgramDashboard.page";
import { TrainerSessionPage } from "./pages/Trainer/TrainerTraineeDashboard/Session/TrainerSession.page";
import { TrainerTraineeDashboard } from "./pages/Trainer/TrainerTraineeDashboard/TrainerTraineeDashboard.page";
import { authService } from "./services/auth";
import { EducationalResourcesPage } from "./pages/Trainee/EducationalResources/EducationalResourcesPage.page";
import { NotificationPage } from "./pages/Notifications/Notifications.page";
import { AuthorizationsPage } from "./pages/Authorizations/Authorizations.page";
import { ErrorPage } from "./pages/Error/Error.page";
import { GuideDetailsPage } from "./pages/Admin/MediaLibrary/GuideDetails/GuideDetails.page";
import { UserCharterDetailsPage } from "./pages/Admin/MediaLibrary/UserCharterDetails/UserCharterDetails.page";
import { ClientTraineeDetails } from "./pages/Client/ClientTraineeDetails/ClientTraineeDetails.page";
import { SessionEndingPage } from "./pages/Trainee/TraineeDetails/Session/SessionEnding/SessionEnding.page";
import { OtpCheckPage } from "./pages/Auth/OtpCheck";

const LOGIN_PATH = "/login";

function isMandatoryDataLoaded() {
  return true;
}

interface ProtectedRouteProps extends RouteProps {
  isAuthorized?: boolean;
}

function ProtectedRoute({
  isAuthorized = true,
  ...props
}: ProtectedRouteProps) {
  const currentLocation = useLocation();
  const isAuthenticated = true;

  if (
    (!isAuthenticated || !isAuthorized) &&
    LOGIN_PATH !== currentLocation.pathname
  ) {
    return (
      <Redirect
        to={{
          pathname: LOGIN_PATH,
          state: { redirectPathOnAuthentication: currentLocation.pathname },
        }}
      />
    );
  } else {
    return isMandatoryDataLoaded() ? (
      <Route {...props} />
    ) : (
      <Loader size="large" />
    );
  }
}

function ToDashboard() {
  if (authService.hasSomeRoles("MANAGER")) {
    return (
      <Redirect
        from="/dashboard"
        to={{
          pathname: "/client/dashboard",
        }}
      />
    );
  }
  if (authService.hasSomeRoles("TRAINER")) {
    return (
      <Redirect
        from="/dashboard"
        to={{
          pathname: "/trainer/dashboard",
        }}
      />
    );
  }
  if (authService.hasSomeRoles("TRAINEE")) {
    return (
      <Redirect
        from="/dashboard"
        to={{
          pathname: "/trainee/dashboard",
        }}
      />
    );
  }
  if (authService.hasSomeRoles("ADMIN", "SUPER_ADMIN")) {
    return (
      <Redirect
        from="/dashboard"
        to={{
          pathname: "/admin",
        }}
      />
    );
  }
  return <div>Not implemented for your role :(</div>;
}

function ConnectedRoutes() {
  return (
    <Layout>
      <Switch>
        <ProtectedRoute
          exact
          path="/profile"
          component={ProfilePage}
          isAuthorized={true}
        />

        {/* ADMIN */}
        <ProtectedRoute
          exact
          path="/admin"
          component={AdminPage}
          isAuthorized={authService.hasSomeRoles("ADMIN")}
        />
        <ProtectedRoute
          exact
          path="/admin/users/create"
          component={CreateUserPage}
          isAuthorized={authService.hasSomeRoles("ADMIN")}
        />
        <ProtectedRoute
          exact
          path="/admin/users/:id"
          component={EditUserPage}
          isAuthorized={authService.hasSomeRoles("ADMIN")}
        />
        <ProtectedRoute
          exact
          path="/admin/companies"
          component={CompaniesPage}
          isAuthorized={authService.hasSomeRoles("ADMIN")}
        />
        <ProtectedRoute
          exact
          path="/admin/companies/create"
          component={CreateCompanyPage}
          isAuthorized={authService.hasSomeRoles("ADMIN")}
        />
        <ProtectedRoute
          exact
          path="/admin/companies/:companyId/users/create"
          component={CreateUserPage}
          isAuthorized={authService.hasSomeRoles("ADMIN")}
        />
        <ProtectedRoute
          exact
          path="/admin/companies/:companyId"
          component={EditCompanyPage}
          isAuthorized={authService.hasSomeRoles("ADMIN")}
        />
        <ProtectedRoute
          exact
          path="/admin/companies/:companyId/program/create"
          component={CreateProgramPage}
          isAuthorized={authService.hasSomeRoles("ADMIN")}
        />
        <ProtectedRoute
          exact
          path="/admin/companies/:companyId/program/:programId"
          component={UpdateProgramPage}
          isAuthorized={authService.hasSomeRoles("ADMIN")}
        />
        <ProtectedRoute
          exact
          path="/admin/companies/:companyId/label/:labelId?"
          component={LabelDetailsPage}
          isAuthorized={authService.hasSomeRoles("ADMIN")}
        />

        <ProtectedRoute
          exact
          path="/admin/sessions"
          component={SessionList}
          isAuthorized={authService.hasSomeRoles("ADMIN")}
        />
        <ProtectedRoute
          exact
          path="/admin/sessions/details/:sessionId?"
          component={SessionDetailsPage}
          isAuthorized={authService.hasSomeRoles("ADMIN")}
        />
        <ProtectedRoute
          exact
          path="/admin/mediaLibrary"
          component={MediaLibrary}
          isAuthorized={authService.hasSomeRoles("ADMIN")}
        />
        <ProtectedRoute
          exact
          path="/admin/mediaResourceDetails/:id?"
          component={MediaDetailsPage}
          isAuthorized={authService.hasSomeRoles("ADMIN")}
        />
        <ProtectedRoute
          exact
          path="/admin/guideDetails/:id?"
          component={GuideDetailsPage}
          isAuthorized={authService.hasSomeRoles("ADMIN")}
        />
        <ProtectedRoute
          exact
          path="/admin/userCharterDetails/:id?"
          component={UserCharterDetailsPage}
          isAuthorized={authService.hasSomeRoles("ADMIN")}
        />

        {/* TRAINER */}
        <ProtectedRoute
          exact
          path="/trainer/dashboard"
          component={TrainerDashboardPage}
          isAuthorized={authService.hasSomeRoles("TRAINER")}
        />
        <ProtectedRoute
          path="/trainer/dashboard/company/:companyId"
          component={TrainerCompanyDashboard}
          isAuthorized={authService.hasSomeRoles("TRAINER")}
        />
        <ProtectedRoute
          path="/trainer/program/dashboard/:programId"
          component={TrainerProgramDashboard}
          isAuthorized={authService.hasSomeRoles("TRAINER")}
        />
        <ProtectedRoute
          path="/trainer/trainee/dashboard/:programId/:traineeId"
          component={TrainerTraineeDashboard}
          isAuthorized={authService.hasSomeRoles("TRAINER")}
        />
        <ProtectedRoute
          path="/trainer/session/:sessionId"
          component={TrainerSessionPage}
          isAuthorized={authService.hasSomeRoles("TRAINER")}
        />
        <ProtectedRoute
          exact
          path="/trainer/availabilities"
          component={TrainerAvailabilitiesPage}
          isAuthorized={authService.hasSomeRoles("TRAINER")}
        />
        {/* <ProtectedRoute
          exact
          path="/trainer/planning"
          component={TrainerPlanningPage}
          isAuthorized={authService.hasSomeRoles("TRAINER")}
        /> */}

        {/* CLIENT */}
        <ProtectedRoute
          path="/client/dashboard"
          component={ClientDashboardPage}
          isAuthorized={authService.hasSomeRoles("MANAGER")}
        />

        <ProtectedRoute
          path="/client/program/dashboard/:programId"
          component={ClientProgramDashboardPage}
          isAuthorized={authService.hasSomeRoles("MANAGER")}
        />
        <ProtectedRoute
          path="/client/trainee/details/:programId/:traineeId"
          render={() => <ClientTraineeDetails />}
          isAuthorized={authService.hasSomeRoles("MANAGER")}
        />

        {/* TRAINEE */}
        <ProtectedRoute
          path="/trainee/dashboard"
          component={TraineeDashboardPage}
          isAuthorized={authService.hasSomeRoles("TRAINEE")}
        />
        <ProtectedRoute
          path="/trainee/details/:programId/:traineeId"
          render={() => <TraineeDetails />}
          isAuthorized={authService.hasSomeRoles("TRAINEE")}
        />
        <ProtectedRoute
          path="/trainee/session/:sessionId"
          component={TraineeSessionPage}
          isAuthorized={authService.hasSomeRoles("TRAINEE")}
        />

        <ProtectedRoute
          path="/educational-resources"
          component={EducationalResourcesPage}
          isAuthorized={authService.hasSomeRoles("TRAINEE")}
        />

        {/* <ProtectedRoute
          path="/trainee/planning"
          component={TraineePlanningPage}
          isAuthorized={authService.hasSomeRoles("TRAINEE")}
        /> */}
        <ProtectedRoute
          path="/trainee/external-session/ending/:externalSimulationId"
          component={SessionEndingPage}
          isAuthorized={authService.hasSomeRoles("TRAINEE")}
        />

        {/* NOTIFICATIONS */}
        <ProtectedRoute
          exact
          path="/notifications"
          component={NotificationPage}
        />

        {/* AUTHORIZATIONS */}
        <ProtectedRoute
          exact
          path="/authorizations"
          component={AuthorizationsPage}
        />

        <ToDashboard />
        <Redirect from="*" to="/dashboard" />
      </Switch>
    </Layout>
  );
}

function Routes() {
  return (
    <Router history={browserHistory}>
      <Switch>
        <Route exact path={LOGIN_PATH} component={LoginPage} />
        <Route exact path="/check-otp" component={OtpCheckPage} />
        <Route exact path="/activate" component={ActivatePage} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route exact path="/change-password" component={ChangePasswordPage} />

        <Route
          exact
          path="/maintenance"
          render={() => <ErrorPage mode="maintenance" />}
        />

        <Route component={ConnectedRoutes} />
      </Switch>
    </Router>
  );
}
export default Routes;
