import { Loader } from "@components/Loader";
import { Title } from "@components/Title";
import { Client } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { PageContainer } from "@components/Layout";
import { CreateProgramForm } from "./CreateProgramForm.component";

export function CreateProgramPage() {
  const { t } = useTranslation();
  let { companyId } = useParams<{ companyId: string }>();
  const history = useHistory();

  const [{ data, fetching: fetchingCompany }] = Client.useGetCompanyQuery({
    variables: { id: companyId },
  });
  const [{ fetching }, createProgram] = Client.useCreateProgramMutation();

  async function handleCreateGroup(input: Client.CreateProgramInput) {
    const result = await createProgram({ input });
    if (!result.error) {
      history.push(
        `/admin/companies/${data?.company?.id}/program/${result.data?.createProgram.id}`
      );
    }
  }

  if (fetching || fetchingCompany) {
    return (
      <PageContainer title={t("menu.admin")}>
        {<Loader size="medium" />}
      </PageContainer>
    );
  }
  return (
    <PageContainer
      title={t("menu.admin")}
      subtitle={t("admin.company.title.createProgram")}
      backButton={`/admin/companies/${companyId}`}
    >
      <div className="w-full max-w-4xl mx-auto">
        {!data?.company && "Introuvable"}
        {data?.company && (
          <>
            <Title size={4}>{`${t("admin.program.label.forCompany")} ${
              data.company.name
            }`}</Title>
            <CreateProgramForm
              company={data.company}
              onCreateProgram={handleCreateGroup}
            />
          </>
        )}
      </div>
    </PageContainer>
  );
}
