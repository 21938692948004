import { Loader } from "@components/Loader";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Provider as UrqlProvider } from "urql";
import { sentryReactErrorHandler } from "./errors/errors.handler";
import { urqlClient } from "./graphql/client";
import { ErrorPage } from "./pages/Error/Error.page";
import Routes from "./routes";

function App() {
  return (
    <ErrorBoundary
      FallbackComponent={() => <ErrorPage />}
      onError={sentryReactErrorHandler}
    >
      <Suspense fallback={<Loader size="large" mode="overlay" />}>
        <UrqlProvider value={urqlClient}>
          <Routes />
        </UrqlProvider>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
