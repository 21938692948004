import { Chip, ChipContainer } from "@components/Chip";
import { DisplayDateTime } from "@components/DateTime";
import { Toggle } from "@components/form/Toggle/Toggle.component";
import { Loader } from "@components/Loader";
import { Pagination } from "@components/Table/Pagination.component";
import {
  Table,
  TableCell,
  TableHeading,
} from "@components/Table/Table.component";
import { UserDescription } from "@components/UserDescription";
import {
  CheckCircleOutline,
  XCircleOutline,
} from "@graywolfai/react-heroicons";
import { Client, User } from "@justplayfair/model";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Input } from "../../../../components/form/Input";
import { useDebounceFieldValue } from "../../../../components/form/Input/useDebounceField.hook";

const PAGE_SIZE = 10;

export function AdminUserList() {
  const { t } = useTranslation();
  let { companyId } = useParams<{ companyId?: string }>();

  const [filterDeleted, setFilterDeleted] = useState(false);
  const [page, setPage] = useState(1);
  const [userListSort, setUserListSort] = useState<Client.Sort>({
    name: "name",
    order: "asc",
  });
  const {
    fieldValue: search,
    debouncedValue: debouncedSearch,
    setFieldValue: setSearch,
  } = useDebounceFieldValue({ initialValue: "", debounceTime: 250 });

  const skip = useMemo(() => (page - 1) * PAGE_SIZE, [page]);

  const [{ data, fetching }] = Client.useUserListQuery({
    variables: {
      search: {
        take: PAGE_SIZE,
        skip,
        filters: {
          query: companyId
            ? new URLSearchParams({
                companyId,
                search: debouncedSearch,
              }).toString()
            : undefined,
          deleted: filterDeleted,
        },
        sort: userListSort,
      },
    },
  });

  function handlePreviousPage() {
    setPage(page - 1);
  }

  function handleNextPage() {
    setPage(page + 1);
  }

  return (
    <div className="shadow border-b border-gray-200 sm:rounded-lg">
      <div className="flex gap-x-2 pb-2">
        <div className="w-80">
          <Input
            id="search-users"
            type="search"
            placeholder={t("common.text.search")}
            value={search}
            onChange={(event) => {
              setPage(1);
              setSearch(event.target.value);
            }}
          />
        </div>
        <Toggle
          id="filterDeleted"
          checked={filterDeleted}
          onChange={(value) => setFilterDeleted(value)}
          label={t("common.toggle.displayDeleted")}
        />
      </div>
      <Table
        heading={
          <>
            <TableHeading
              align="left"
              sort={{
                order: userListSort.order,
                onChange: () =>
                  setUserListSort({
                    name: "name",
                    order: userListSort.order === "desc" ? "asc" : "desc",
                  }),
              }}
            >
              {t("admin.user.heading.name")}
            </TableHeading>
            <TableHeading align="left">
              {t("admin.user.heading.role")}
            </TableHeading>
            <TableHeading align="center">
              {t("admin.user.heading.activated")}
            </TableHeading>
            <TableHeading align="center">
              {t("admin.user.heading.deleted")}
            </TableHeading>
            <TableHeading align="left">
              {t("admin.user.heading.labels")}
            </TableHeading>
            <TableHeading align="left">
              {t("admin.user.heading.updatedAt")}
            </TableHeading>

            <TableHeading align="right">
              <span className="sr-only">Edit</span>
            </TableHeading>
          </>
        }
      >
        {!fetching &&
          data?.users.data.map((user) => (
            <tr key={user.id}>
              <TableCell align="left">
                <UserDescription user={user} />
              </TableCell>
              <TableCell align="left">
                <ChipContainer>
                  {user.roles.map((role, index) => (
                    <Chip
                      key={index}
                      color="primary"
                      label={t(`common.role.${role}`)}
                    />
                  ))}
                </ChipContainer>
              </TableCell>

              <TableCell align="left">
                {user.activated ? (
                  <CheckCircleOutline className="h-6, h-6 text-green-500 mx-auto" />
                ) : (
                  <XCircleOutline className="h-6, h-6 text-gray-500 mx-auto" />
                )}
              </TableCell>

              <TableCell align="left">
                {user.deleted ? (
                  <DisplayDateTime date={new Date(user.deleted)} />
                ) : (
                  <XCircleOutline className="h-6, h-6 text-gray-500 mx-auto" />
                )}
              </TableCell>

              <TableCell align="left">
                <ChipContainer>
                  {user.labels?.map((label, index) => (
                    <Chip
                      key={index}
                      color="transparent"
                      label={label.name}
                      title={t(`common.label.${label.category}`)}
                    />
                  ))}
                </ChipContainer>
              </TableCell>
              <TableCell align="left">
                <DisplayDateTime date={new Date(user.updatedAt)} />
              </TableCell>
              <TableCell align="right">
                <Link
                  className="text-indigo-600 hover:text-indigo-900"
                  to={`/admin/users/${user.id}`}
                >
                  {t("common.button.edit.label")}
                </Link>
              </TableCell>
            </tr>
          ))}
      </Table>
      {fetching && <Loader size="large" />}
      <Pagination
        pageInfo={data?.users.pageInfo as User.PageInfo}
        onPrevious={handlePreviousPage}
        onNext={handleNextPage}
      />
    </div>
  );
}
