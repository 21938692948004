import { Button } from "@components/Button";
import { Card } from "@components/Card";
import { Title } from "@components/Title";
import { PencilSolid } from "@graywolfai/react-heroicons";
import { Client } from "@justplayfair/model";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReplaySimulationsDropdown } from "../../../../../components/ReplaySimulationsDropdown";
import { SessionEvaluationForm } from "./SessionEvaluation.form";

interface SessionEvaluationProps {
  session: NonNullable<Client.GetSessionQuery["getSession"]>;
  onSessionUpdated: VoidFunction;
}
export function SessionEvaluation({
  session,
  onSessionUpdated,
}: SessionEvaluationProps) {
  const { t } = useTranslation();
  const [displaySessionEvaluation, setDisplaySessionEvaluation] = useState(
    session.status === "EVALUATED"
  );
  const [editSessionEvaluation, setEditSessionEvaluation] = useState(false);

  function handleCreateEvaluation() {
    setDisplaySessionEvaluation(true);
    setEditSessionEvaluation(true);
  }
  function handleEvaluationCompleted() {
    setEditSessionEvaluation(false);
    onSessionUpdated();
  }

  return (
    <Card>
      <div className=" p-4 2xl:p-8">
        <div className="flex w-full justify-between">
          <div className="flex gap-x-4">
            <Title size={4}>
              {t("trainer.session.title.sessionEvaluation")}
            </Title>
            {!!session.externalSessionReplayLinks?.length && (
              <ReplaySimulationsDropdown
                replayUrls={session.externalSessionReplayLinks}
                position="right"
              />
            )}
          </div>
          {displaySessionEvaluation && !editSessionEvaluation && (
            <div className="w-30">
              <Button
                variant="secondary"
                preIcon={PencilSolid}
                title={t("common.button.edit.label")}
                onClick={() => setEditSessionEvaluation(true)}
              >
                {t("common.button.edit.label")}
              </Button>
            </div>
          )}
          {!displaySessionEvaluation && (
            <div className="w-30">
              <Button
                disabled={session.status === "CREATED"}
                tooltip={
                  session.status === "CREATED"
                    ? t("trainer.session.label.needToPlanBeforeEvaluate")
                    : undefined
                }
                variant="secondary"
                preIcon={PencilSolid}
                onClick={handleCreateEvaluation}
                title={
                  session.status !== "CREATED"
                    ? t("trainer.session.button.evaluate")
                    : ""
                }
              >
                {t("trainer.session.button.evaluate")}
              </Button>
            </div>
          )}
        </div>
        {displaySessionEvaluation && (
          <SessionEvaluationForm
            session={session}
            isEdit={editSessionEvaluation}
            onEvaluationCompleted={handleEvaluationCompleted}
          />
        )}
        {!displaySessionEvaluation && (
          <div className="space-y-4  h-44">
            <div className="text-gray-500">
              {t("trainer.session.label.noEvaluationYet")}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}
