import { Button } from "@components/Button";
import { IconButton } from "@components/Button/IconButton.component";
import { FormikInput } from "@components/form/Input/FormikInput";
import { FormikSelect } from "@components/form/Select/FormikSelect";
import { Loader } from "@components/Loader";
import { PlusOutline, XOutline } from "@graywolfai/react-heroicons";
import { Client } from "@justplayfair/model";
import { FieldArray, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

interface FormikProgramSkillsFieldsProps {
  capacities: Client.ListCapacitiesQuery["listCapacities"];
  editMode: boolean;
  externalAssessment: boolean;
  fetching: boolean;
}
export function FormikProgramSkillsFields({
  capacities,
  externalAssessment,
  editMode,
  fetching,
}: FormikProgramSkillsFieldsProps) {
  const { t } = useTranslation();
  const {
    values: { skills },
  } = useFormikContext<{ skills: Client.ProgramSkillInput[] }>();

  if (fetching) {
    return <Loader size="small" />;
  }
  return (
    <div>
      <FieldArray name="skills">
        {({ push: pushSkill, remove: removeSkill }) => (
          <div className="space-y-4">
            {skills.map((skill, index) => (
              <div key={index} className="pl-2 pr-4 pt-4 border relative">
                {editMode && (
                  <div className="absolute right-1 top-1">
                    <IconButton
                      icon={XOutline}
                      variant="transparent"
                      title={t("common.button.delete.label")}
                      size="small"
                      onClick={() => removeSkill(index)}
                    />
                  </div>
                )}
                <FormikInput
                  editMode={editMode}
                  id={`skills.${index}.name`}
                  label={t("admin.program.label.skillName")}
                  withSeparator={true}
                />
                <div className="sm:grid px-4 py-5 sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <div className="flex flex-col items-start text-sm font-medium text-gray-500 space-y-2">
                    <div>{t("admin.program.label.evaluatedCapacity")}</div>
                    {editMode && (
                      <div className="text-xs">
                        {t("admin.program.label.capacityHelperText")}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col items-center justify-center mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <SkillCapacities
                      skillIndex={index}
                      capacities={capacities}
                      capacityInputs={skill.capacities}
                      externalAssessment={externalAssessment}
                      editMode={editMode}
                    />
                  </div>
                </div>{" "}
              </div>
            ))}
            {editMode && (
              <div className="flex justify-end">
                <div className="w-64">
                  <Button
                    variant="secondary"
                    title={t("admin.program.button.addAnotherSkill")}
                    onClick={() =>
                      pushSkill({ id: uuid(), name: "", capacities: [{}] })
                    }
                  >
                    {t("admin.program.button.addAnotherSkill")}
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </FieldArray>
    </div>
  );
}

interface SkillCapacitiesProps {
  skillIndex: number;
  capacityInputs: Client.ProgramCapacityInput[];
  capacities: Client.CapacityFieldsFragment[];
  externalAssessment: boolean;
  editMode: boolean;
}
function SkillCapacities({
  skillIndex,
  capacityInputs,
  capacities,
  externalAssessment,
  editMode,
}: SkillCapacitiesProps) {
  const { t } = useTranslation();
  return (
    <FieldArray name={`skills.${skillIndex}.capacities`}>
      {({ push: pushCapacity, remove: removeCapacity }) => (
        <div className="space-y-4 w-full">
          {capacityInputs.map((_, capacityIndex) => {
            const fieldIdPrefix = `skills.${skillIndex}.capacities.${capacityIndex}`;

            return (
              <div key={capacityIndex}>
                <div className="w-full flex items-center justify-center mt-1 text-sm text-gray-900 sm:mt-0">
                  <div className="w-3/6">
                    <FormikSelect
                      editMode={editMode}
                      labelPosition="top"
                      label={
                        !editMode
                          ? t("admin.program.label.capacity")
                          : undefined
                      }
                      id={`${fieldIdPrefix}.id`}
                      options={capacities
                        .map(({ id, nameKey }) => ({
                          value: id,
                          label: t(`${nameKey}.short`),
                        }))
                        .sort(
                          (left, right) => left.label.localeCompare(right.label) // Compromis pour trier les élements de la liste malgrès l'i18n
                        )}
                      renderValue={(val) => <span title={val}>{val}</span>}
                      withSeparator={false}
                      multi={false}
                    />
                    <div className="h-4" />
                  </div>
                  <div className="w-2/6">
                    <FormikInput
                      editMode={editMode}
                      labelPosition="top"
                      label={
                        !editMode ? t("admin.program.label.label") : undefined
                      }
                      placeholder={t("admin.program.label.renameCapacity")}
                      id={`${fieldIdPrefix}.label.labelValue`}
                      helperText={t("admin.program.label.renameHere")}
                    />
                  </div>

                  <div className="w-1/6">
                    {editMode && (
                      <>
                        <IconButton
                          icon={XOutline}
                          size="small"
                          title={t("common.button.delete.label")}
                          variant="transparent"
                          onClick={() => removeCapacity(capacityIndex)}
                        />

                        <div className="h-4" />
                      </>
                    )}
                  </div>
                </div>
                {externalAssessment && editMode && (
                  <div className="w-48">
                    <FormikInput
                      editMode={editMode}
                      labelPosition="left"
                      placeholder={t("admin.program.label.externalId")}
                      id={`${fieldIdPrefix}.externalId`}
                    />
                  </div>
                )}
              </div>
            );
          })}

          {editMode && (
            <div className="w-72">
              <Button
                preIcon={PlusOutline}
                variant="secondary"
                title={t("admin.program.button.addACapacity")}
                onClick={() => pushCapacity({ id: uuid() })}
              >
                {t("admin.program.button.addACapacity")}
              </Button>
            </div>
          )}
        </div>
      )}
    </FieldArray>
  );
}
