import { Exchange, makeOperation, Operation } from "urql";
import { fromValue, map, mergeMap, pipe } from "wonka";

export const fetchOptionsExchange = (
  fn: (fetchOptions: any) => RequestInit
): Exchange => ({ forward }) => (ops$) => {
  return pipe(
    ops$,
    mergeMap((operation: Operation) => {
      const result = fn(operation.context.fetchOptions);
      return pipe(
        fromValue(result),
        map((fetchOptions: RequestInit | (() => RequestInit)) => {
          return makeOperation(operation.kind, operation, {
            ...operation.context,
            fetchOptions,
          });
        })
      );
    }),
    forward
  );
};
