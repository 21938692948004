import { Loader } from "@components/Loader";
import { ApiErrorCode, Client } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { PageContainer } from "@components/Layout";
import { CreateUserForm } from "./CreateUser.form";
import { getCodesFromError } from "../../../../graphql/error.config";
import { alertService } from "@services/Alert/AlertService";

export function CreateUserPage() {
  const { t } = useTranslation();
  const { companyId } = useParams<{ companyId?: string }>();
  const history = useHistory();

  const [{ data: companiesData, fetching: fetchingCompanies }] =
    Client.useSearchCompaniesQuery({
      variables: { search: { take: 10000, skip: 0 } },
    });

  const [{ fetching }, createUser] = Client.useCreateUserMutation();

  async function handleCreateUser(input: Client.UserInput) {
    const result = await createUser({ input });
    if (!result.error) {
      if (companyId) {
        history.goBack();
      } else {
        history.push(`/admin/users`);
      }
    } else {
      const errorCodes = getCodesFromError(result.error);
      if (errorCodes.includes(ApiErrorCode.AUTHENT_ALREADY_EXISTING_USER)) {
        alertService.addAlert({
          type: "error",
          title: "Une erreur s'est produite",
          description: t("common.error.userAlreadyExistWithThisEmail"),
        });
      }
    }
  }

  if (fetchingCompanies) {
    return (
      <PageContainer title={t("menu.admin")}>
        {<Loader size="medium" />}
      </PageContainer>
    );
  }
  return (
    <PageContainer
      title={t("menu.admin")}
      subtitle={t("admin.user.title.createUser")}
      backButton={`/admin/companies/${companyId}`}
    >
      <div className="w-full max-w-4xl mx-auto">
        <CreateUserForm
          companyId={companyId}
          companies={companiesData?.companies.data}
          onCreateUser={handleCreateUser}
        />
      </div>
      {fetching && <Loader mode="overlay" size="medium" />}
    </PageContainer>
  );
}
