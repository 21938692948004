import { IconComponent } from "@components/Icon/Icon.model";
import { CalendarOutline, ClockOutline } from "@graywolfai/react-heroicons";
import classnames from "clsx";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export interface DatePickerProps {
  id: string;
  label?: string;
  value: Date | null;
  icon?: IconComponent;
  isError?: boolean;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeIntervals?: number;
  timeCaption?: string;
  dateFormat?: string;
  timeFormat?: string;
  minTime?: Date;
  maxTime?: Date;
  isClearable?: boolean;
  onChange: (date: Date) => void;
}

export function DatePicker({
  id,
  label,
  value,
  icon,
  isError,
  onChange,
  showTimeSelect,
  showTimeSelectOnly,
  timeIntervals,
  timeCaption,
  dateFormat,
  timeFormat,
  minTime,
  maxTime,
  isClearable,
}: DatePickerProps) {
  const inputClasses = classnames(
    "pl-10 appearance-none rounded w-full px-3 py-2 text-gray-900 border placeholder-gray-500 focus:outline-none focus:ring-2 sm:text-sm",
    {
      "border-gray-300 focus:ring-primary": !isError,
      "border-red-400 focus:ring-red-400": isError,
    }
  );
  return (
    <div className="text-gray-600  focus-within:text-gray-700 w-full">
      {label && <label htmlFor={id}>{label}</label>}
      <div className="relative">
        <div className="absolute z-10 inset-y-0 left-0 pl-3 flex items-center pointer-events-none ">
          {icon &&
            icon({
              className: "h-5 w-5",
            })}
          {!icon && !showTimeSelectOnly && (
            <CalendarOutline className="h-5 w-5" />
          )}
          {!icon && showTimeSelectOnly && <ClockOutline className="h-5 w-5" />}
        </div>

        <ReactDatePicker
          selected={value}
          id={id}
          portalId="jpf-datepicker"
          name={id}
          className={inputClasses}
          onChange={(date: Date) => onChange(date)}
          showTimeSelect={showTimeSelect}
          showTimeSelectOnly={showTimeSelectOnly}
          timeIntervals={timeIntervals}
          timeCaption={timeCaption}
          dateFormat={dateFormat}
          timeFormat={timeFormat}
          minTime={minTime}
          maxTime={maxTime}
          autoComplete="off"
          isClearable={isClearable}
        />
      </div>
    </div>
  );
}
