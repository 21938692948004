import { Button, FileButtonProps } from "@components/Button/Button.component";
import { IconButton } from "@components/Button/IconButton.component";
import { FilePreview } from "@components/FilePreview";
import { ReactComponent as PdfSvg } from "@components/FilePreview/pdf.svg";
import { ReactComponent as VideoSvg } from "@components/FilePreview/video.svg";
import { FieldContainer } from "@components/form/FieldContainer";
import { DownloadOutline, XOutline } from "@graywolfai/react-heroicons";
import {
  Client,
  IMAGE_MIMES,
  PDF_MIMES,
  VIDEO_MIMES,
} from "@justplayfair/model";
import { fileService } from "@services/file/file.service";
import { useField, useFormikContext } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LabelPosition } from "../FieldContainer/FieldContainer.component";

type FormikFileButtonProps = {
  label: string;
  labelPosition?: LabelPosition;
  withSeparator?: boolean;
  editMode: boolean;
  existingFile?: Client.FileFieldsFragment;
  onResetFile: VoidFunction;
} & Pick<
  FileButtonProps,
  "id" | "variant" | "title" | "preIcon" | "postIcon" | "loading"
>;

export function FormikFileButton({
  id,
  label,
  labelPosition = "left",
  withSeparator,
  editMode,
  existingFile,
  variant,
  title,
  preIcon,
  postIcon,
  loading,
  onResetFile,
}: FormikFileButtonProps) {
  const { t } = useTranslation();

  const { submitCount } = useFormikContext();
  const [field, { error, touched }, { setValue, setTouched }] = useField(id);

  function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files?.length === 1) {
      const file = event.target.files[0];
      setTouched(true);
      setValue(file, true);
    }
  }

  function handleDownloadFile() {
    if (existingFile) {
      fileService.downloadFile(existingFile.storageFileId);
    }
  }
  function handleDelete() {
    setTouched(true);
    setValue(undefined);
    onResetFile();
  }

  return (
    <FieldContainer
      id={id}
      label={label}
      labelPosition={labelPosition}
      withSeparator={withSeparator}
    >
      {editMode && !existingFile && (
        <div>
          <div className="flex space-x-4">
            {field.value && <LocalFilePreview file={field.value} />}
            <Button
              id={id}
              type="file"
              variant={variant}
              title={title}
              preIcon={preIcon}
              postIcon={postIcon}
              onChange={handleFileChange}
              loading={loading}
              accept="video/*,image/*,.pdf,.mkv,.avi,.mov"
            >
              {t("component.fileButton.label.file")}
            </Button>
          </div>
          <div className="h-4 mt-0 text-xs text-red-400">
            {(touched || submitCount > 0) && error && t(error)}
          </div>
        </div>
      )}
      <div>
        {existingFile && (
          <div className="flex space-x-2 items-center">
            <div className="h-10 w-10">
              <FilePreview
                storageFileId={existingFile.storageFileId}
                fileType={existingFile.type}
              />
            </div>
            <div>
              <Button
                title={t("component.fileButton.label.download")}
                variant="secondary"
                preIcon={DownloadOutline}
                onClick={handleDownloadFile}
              >
                {t("component.fileButton.label.download")}
              </Button>
            </div>
            <div>
              <IconButton
                icon={XOutline}
                title="Delete"
                onClick={() => handleDelete()}
                size="small"
                variant="transparent"
              ></IconButton>
            </div>
          </div>
        )}
      </div>
    </FieldContainer>
  );
}

interface LocalFilePreviewProps {
  file: File;
}
export function LocalFilePreview({ file }: LocalFilePreviewProps) {
  const [previewImageUrl, setPreviewImageUrl] = useState();

  useEffect(() => {
    if (IMAGE_MIMES.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImageUrl(reader.result as any);
      };
      reader.readAsDataURL(file);
    }
  }, [file]);
  return (
    <div className="flex text-gray-600">
      {PDF_MIMES.includes(file.type) && (
        <PdfSvg className="h-10 w-10 fill-current" />
      )}
      {IMAGE_MIMES.includes(file.type) && (
        <div className="flex items-center h10 w-10">
          <img src={previewImageUrl} alt="preview" />
        </div>
      )}
      {VIDEO_MIMES.includes(file.type) && (
        <VideoSvg className="h-10 w-10 fill-current" />
      )}
      <div className="flex items-center w-32 truncate"> {file.name}</div>
    </div>
  );
}
