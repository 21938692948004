import { Avatar } from "@components/Avatar";
import { Chip, ChipContainer } from "@components/Chip";
import { Checkbox } from "@components/form/Checkbox";
import { Loader } from "@components/Loader";
import { Pagination } from "@components/Table/Pagination.component";
import {
  Table,
  TableCell,
  TableHeading,
} from "@components/Table/Table.component";
import { Client, ID, Maybe } from "@justplayfair/model";
import classnames from "clsx";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Input } from "../form/Input";
import { useDebounceFieldValue } from "../form/Input/useDebounceField.hook";
import { TraineeAdvancement } from "../TraineeAdvancement";

type UserFields = Client.WithAdvancementUserFieldsFragment & {
  permissionRequestStatus?: Maybe<Client.PermissionRequestStatus>;
};

interface Selectable {
  selectedUsers: ID[];
  onSelect: (userIds: ID[]) => void;
}
interface CompanyUserListProps {
  programId?: ID;
  users?: UserFields[];
  pageInfo: Client.PageInfo | null;
  fetching: boolean;
  page: number;
  sort?: Client.Sort;
  selectable?: Selectable;
  setSort: (sort?: Client.Sort) => void;
  setSearch: (search: string) => void;
  setPage: (page: number) => void;
  getTraineeUrl?: (
    userId: ID,
    programId: ID | null,
    programIds: ID[]
  ) => string | null;
}

export function CompanyUserList({
  programId,
  users,
  pageInfo,
  sort,
  selectable,
  page,
  fetching,
  setSort,
  setSearch,
  setPage,
  getTraineeUrl,
}: CompanyUserListProps) {
  const { t } = useTranslation();

  const {
    fieldValue: search,
    debouncedValue: debouncedSearch,
    setFieldValue: setSearchValue,
  } = useDebounceFieldValue({ initialValue: "", debounceTime: 250 });

  useEffect(() => {
    setSearch(debouncedSearch);
  }, [debouncedSearch, setSearch]);

  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 space-y-2">
        <div className="w-80">
          <Input
            id="search-users"
            type="search"
            placeholder={t("common.text.search")}
            value={search}
            onChange={(event) => {
              setPage(1);
              setSearchValue(event.target.value);
            }}
            autoFocus
          />
        </div>
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <UserTable
            programId={programId}
            users={users}
            pageInfo={pageInfo}
            sort={sort}
            selectable={selectable}
            page={page}
            fetching={fetching}
            setSort={setSort}
            setPage={setPage}
            getTraineeUrl={getTraineeUrl}
          />
        </div>
      </div>
    </div>
  );
}

interface UserTableProps {
  programId?: ID;
  users?: UserFields[];
  pageInfo: Client.PageInfo | null;
  fetching: boolean;
  page: number;
  sort?: Client.Sort;
  selectable?: Selectable;
  setSort: (sort?: Client.Sort) => void;
  setPage: (page: number) => void;
  getTraineeUrl?: (
    userId: ID,
    programId: ID | null,
    programIds: ID[]
  ) => string | null;
}

function UserTable({
  programId,
  users,
  pageInfo,
  fetching,
  page,
  sort,
  selectable,
  setSort,
  setPage,
  getTraineeUrl,
}: UserTableProps) {
  const { t } = useTranslation();
  const history = useHistory();

  if (fetching) {
    return <Loader size="medium" />;
  }
  if (!users) {
    return <div>"No data"</div>;
  }
  function handleClick(user: Client.UserDetailsFieldsFragment) {
    if (!getTraineeUrl) {
      return;
    }
    const url = getTraineeUrl(
      user.id,
      programId ?? null,
      user.programs?.map(({ id }) => id) ?? []
    );

    if (url) {
      history.push(url);
    }
  }
  return (
    <>
      <Table
        heading={
          <>
            {selectable && (
              <TableHeading align="left">
                <span className="sr-only">
                  {t("component.userList.label.select")}
                </span>
              </TableHeading>
            )}
            <TableHeading
              align="left"
              sort={{
                order: sort && sort.name === "name" ? sort.order : undefined,
                onChange: () =>
                  setSort({
                    name: "name",
                    order: sort && sort.order === "desc" ? "asc" : "desc",
                  }),
              }}
            >
              {t("common.user.name")}
            </TableHeading>
            <TableHeading align="left">{t("common.user.email")}</TableHeading>
            {!programId && (
              <TableHeading align="center">
                {t("trainer.dashboard.text.programs")}
              </TableHeading>
            )}
            <TableHeading align="left">
              {t("trainer.dashboard.text.sessionAdvancement")}
            </TableHeading>
          </>
        }
      >
        {users.length > 0 &&
          users.map((user) => {
            const clickableRow =
              getTraineeUrl &&
              (!!programId ||
                (!!user.programs?.length && user.programs.length === 1));

            return (
              <UserTableRow
                key={user.id}
                user={user}
                programId={programId}
                onClick={!!clickableRow ? () => handleClick(user) : undefined}
                selectable={selectable}
              />
            );
          })}
      </Table>
      <Pagination
        pageInfo={pageInfo}
        onPrevious={() => {
          setPage(page - 1);
        }}
        onNext={() => {
          setPage(page + 1);
        }}
        testId="CompanyUserListPagination"
      />
    </>
  );
}
interface UserTableRowProps {
  user: UserFields;
  selectable?: Selectable;
  programId?: ID;
  onClick?: VoidFunction;
}
function UserTableRow({
  user,
  selectable,
  programId,
  onClick,
}: UserTableRowProps) {
  const rowClasses = classnames({
    "transition hover:bg-gray-50 cursor-pointer": !!onClick,
  });
  return (
    <tr
      key={user.id}
      onClick={
        onClick
          ? () => {
              onClick();
            }
          : undefined
      }
      className={rowClasses}
    >
      {selectable && (
        <TableCell align="left">
          {!user.permissionRequestStatus && (
            <Checkbox
              id={`select-${user.id}`}
              checked={selectable.selectedUsers.includes(user.id)}
              onChange={(event) => {
                if (!selectable) {
                  throw new Error(`selectable should be defined`);
                }
                if (event.target.checked) {
                  selectable.onSelect([...selectable.selectedUsers, user.id]);
                } else {
                  selectable.onSelect(
                    selectable.selectedUsers.filter(
                      (userId) => userId !== user.id
                    )
                  );
                }
              }}
            />
          )}
        </TableCell>
      )}
      <TableCell align="left">
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0">
            <Avatar size="small" entity={user} />
          </div>
          <div>
            <div className="text-gray-900">{user.name}</div>
          </div>
        </div>
      </TableCell>
      <TableCell align="left">{user.email}</TableCell>
      {!programId && (
        <TableCell align="center">
          <ChipContainer>
            {programId && (
              <Chip
                color="primary"
                label={
                  user.programs?.find((program) => program.id === programId)
                    ?.name ?? "error"
                }
              />
            )}
            {!programId &&
              user.programs?.map((program) => (
                <Chip key={program.id} color="primary" label={program.name} />
              ))}
          </ChipContainer>
        </TableCell>
      )}
      <TableCell align="left">
        {!programId && user.advancement && (
          <TraineeAdvancement advancement={user.advancement} />
        )}
        {programId && (
          <ProgramTraineeAdvancement
            programId={programId}
            traineeId={user.id}
          />
        )}
      </TableCell>
    </tr>
  );
}

interface ProgramTraineeAdvancementProps {
  programId: ID;
  traineeId: ID;
}
function ProgramTraineeAdvancement({
  programId,
  traineeId,
}: ProgramTraineeAdvancementProps) {
  const [{ data, fetching }] = Client.useTraineeProgramAdvancementQuery({
    variables: {
      traineeArgs: {
        programId,
        traineeId,
      },
    },
  });

  if (fetching) {
    return <Loader size="small" />;
  }
  if (!data) {
    return null;
  }

  return <TraineeAdvancement advancement={data.getTraineeProgramAdvancement} />;
}
