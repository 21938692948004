import { CustomSelect } from "@components/form/Select";
import { Client } from "@justplayfair/model";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SESSION_STATUS_OPTIONS } from "../../../../model/Evaluation.model";

interface SessionFiltersProps {
  companies: Client.CompanyFieldsFragment[];
  selectedCompanyIds: string[];
  selectedProgramIds: string[];
  selectedTraineeIds: string[];
  selectedStatus: string[];
  onSelectedCompanyChange: (values: string[]) => void;
  onSelectedProgramsChange: (values: string[]) => void;
  onSelectedTraineesChange: (values: string[]) => void;
  onSelectedStatusChange: (values: string[]) => void;
}
export const SessionFilters = observer(
  ({
    companies,
    selectedCompanyIds,
    selectedProgramIds,
    selectedTraineeIds,
    selectedStatus,
    onSelectedCompanyChange,
    onSelectedProgramsChange,
    onSelectedTraineesChange,
    onSelectedStatusChange,
  }: SessionFiltersProps) => {
    const { t } = useTranslation();

    const [{ data: programsData }] = Client.useGetCompaniesProgramsQuery({
      variables: {
        companyIds: selectedCompanyIds,
      },
    });

    const companyOpts = useMemo(
      () =>
        companies.map((company) => ({
          value: company.id,
          label: company.name,
        })),
      [companies]
    );

    const programOpts = useMemo(
      () =>
        programsData?.companiesPrograms.map((program) => ({
          value: program.id,
          label: program.name,
        })) ?? [],
      [programsData]
    );

    const traineeOpts = useMemo(
      () =>
        programsData?.companiesPrograms
          .filter((program) => selectedProgramIds.includes(program.id))
          .flatMap((program) => program.users || [])
          .map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      [programsData, selectedProgramIds]
    );

    // Reset if parent filter is reset
    useEffect(() => {
      if (programOpts.length === 0 && selectedProgramIds.length > 0) {
        onSelectedProgramsChange([]);
      }
    }, [programOpts, selectedProgramIds, onSelectedProgramsChange]);

    // Reset if parent filter is reset
    useEffect(() => {
      if (traineeOpts?.length === 0 && selectedTraineeIds.length > 0) {
        onSelectedTraineesChange([]);
      }
    }, [traineeOpts, selectedTraineeIds, onSelectedTraineesChange]);

    return (
      <div className="flex bg-gray-200 p-2 justify-between">
        <div className="flex space-x-2">
          <div className="w-56">
            <CustomSelect
              id="selected-companies"
              value={selectedCompanyIds}
              onChange={(values) => onSelectedCompanyChange(values || [])}
              renderValue={(values) =>
                values.length > 0
                  ? values.join(", ")
                  : t("common.text.allValues")
              }
              options={companyOpts}
              label={t("admin.session.filter.forCompanies")}
              multi
            />
          </div>
          {selectedCompanyIds.length > 0 && (
            <div className="w-56">
              <CustomSelect
                id="selected-programs"
                value={selectedProgramIds}
                onChange={(values) => onSelectedProgramsChange(values || [])}
                renderValue={(values) =>
                  values.length > 0
                    ? values.join(", ")
                    : t("common.text.allValues")
                }
                options={programOpts}
                label={t("admin.session.filter.forPrograms")}
                multi
              />
            </div>
          )}
          {selectedProgramIds.length > 0 && (
            <div className="w-56">
              <CustomSelect
                id="selected-trainees"
                value={selectedTraineeIds}
                onChange={(values) => onSelectedTraineesChange(values || [])}
                options={traineeOpts}
                renderValue={(values) =>
                  values.length > 0
                    ? values.map((value) => t(value)).join(", ")
                    : t("common.text.allValues")
                }
                label={t("admin.session.filter.forTrainees")}
                multi
              />
            </div>
          )}
        </div>
        <div>
          <div className="w-56">
            <CustomSelect
              id="selected-status"
              value={selectedStatus}
              onChange={(values) =>
                onSelectedStatusChange(values as Client.SessionStatus[])
              }
              options={SESSION_STATUS_OPTIONS}
              renderValue={(values) =>
                values.length > 0
                  ? values.map((value) => t(value)).join(", ")
                  : t("common.text.allValues")
              }
              label={t("admin.session.filter.status")}
              multi
            />
          </div>
        </div>
      </div>
    );
  }
);
