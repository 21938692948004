import { Avatar } from "@components/Avatar";
import { Chip, ChipContainer } from "@components/Chip";
import { OfficeBuildingOutline } from "@graywolfai/react-heroicons";
import { Client, formatToLocalDate, ID } from "@justplayfair/model";
import { useTranslation } from "react-i18next";

interface LargeUserDescriptionProps {
  user: Client.UserDetailsFieldsFragment;
  programId?: ID;
}
export function LargeUserDescription({
  user,
  programId,
}: LargeUserDescriptionProps) {
  return (
    <div className="flex justify-between w-fit-content space-x-4 divide-x divide-gray-200">
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          <Avatar size="medium" entity={user} />
        </div>
        <div>
          <div className="text-gray-900 text-xl font-bold">{user.name}</div>
          <div className="text-base lg:flex space-x-8">
            <div className="flex text-gray-500 text-sm items-center space-x-1">
              {user.email}
            </div>
          </div>
        </div>
      </div>
      <UserMetaInfo user={user} programId={programId} />
    </div>
  );
}
interface UserMetaInfoProps {
  user: Client.UserDetailsFieldsFragment;
  programId?: ID;
}
function UserMetaInfo({ user, programId }: UserMetaInfoProps) {
  const { t } = useTranslation();

  const programs = programId
    ? user.programs?.filter((program) => program.id === programId)
    : user.programs;

  const departmentLabel = user.labels?.find(
    (label) => label.category === "DEPARTMENT"
  );
  const hierarchicalPositionLabel = user.labels?.find(
    (label) => label.category === "HIERARCHICAL_POSITION"
  );
  const businessUnit = user.labels?.find(
    (label) => label.category === "BUSINESS_UNIT"
  );

  return (
    <div className="flex items-center px-4 sm:px-6">
      <dl className="grid grid-cols-1 gap-x-24 gap-y-2 sm:grid-cols-3">
        {user.company && (
          <div className="sm:col-span-1">
            <dt className="flex text-sm font-medium text-gray-500">
              <OfficeBuildingOutline className="h-4 w-4 mr-1" />
              {t("component.userDescription.label.company")}
            </dt>
            <dd className="mt-0.5 text-sm text-gray-900">
              {user.company?.name}
            </dd>
          </div>
        )}
        {departmentLabel && (
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {t("component.userDescription.label.department")}
            </dt>
            <dd className="mt-0.5 text-sm text-gray-900">
              {departmentLabel.name}
            </dd>
          </div>
        )}
        {user.hiringDate && (
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {t("component.userDescription.label.hiringDate")}
            </dt>
            <dd className="mt-0.5 text-sm text-gray-900">
              {formatToLocalDate(new Date(user.hiringDate))}
            </dd>
          </div>
        )}
        {programs && (
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {t("component.userDescription.label.program")}
            </dt>
            <dd className="mt-0.5 text-sm text-gray-900">
              <ChipContainer>
                {programs.map((program) => (
                  <Chip key={program.id} color="primary" label={program.name} />
                ))}
              </ChipContainer>
            </dd>
          </div>
        )}
        {hierarchicalPositionLabel && (
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {t("component.userDescription.label.hierarchicalPosition")}
            </dt>
            <dd className="mt-0.5 text-sm text-gray-900">
              {hierarchicalPositionLabel.name}
            </dd>
          </div>
        )}
        {businessUnit && (
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              {t("component.userDescription.label.businessUnit")}
            </dt>
            <dd className="mt-0.5 text-sm text-gray-900">
              {businessUnit.name}
            </dd>
          </div>
        )}
      </dl>
    </div>
  );
}
