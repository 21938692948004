import { Avatar } from "@components/Avatar";
import { Loader } from "@components/Loader";
import { Client } from "@justplayfair/model";
import { Suspense, useState } from "react";
import { AvatarImageModalLazy } from "../../../Profile/AvatarImageModal";

interface CompanyAvatarProps {
  company: Client.CompanyFieldsFragment;
  onAvatarUpdated: VoidFunction;
}
export function CompanyAvatar({
  company,
  onAvatarUpdated,
}: CompanyAvatarProps) {
  const [showAvatarImageModal, setShowAvatarImageModal] = useState(false);
  const [avatarImageModalLoading, setAvatarImageModalLoading] = useState(false);

  const [, uploadAvatar] = Client.useUploadCompanyAvatarMutation();
  const [, rmAvatar] = Client.useRmCompanyAvatarMutation();

  function handleAddImage() {
    setShowAvatarImageModal(true);
  }

  async function handleSaveAvatar(file: File, cropData: Client.CropData) {
    try {
      setAvatarImageModalLoading(true);
      await uploadAvatar({ companyId: company.id, file, cropData });
    } finally {
      setAvatarImageModalLoading(false);
    }
    onAvatarUpdated();
    setShowAvatarImageModal(false);
  }

  async function handleRmAvatar() {
    try {
      setAvatarImageModalLoading(true);
      await rmAvatar({ companyId: company.id });
    } finally {
      setAvatarImageModalLoading(false);
    }
    onAvatarUpdated();
    setShowAvatarImageModal(false);
  }
  return (
    <div>
      <Avatar size="large" entity={company} onAddPicture={handleAddImage} />
      <Suspense fallback={<Loader size="small" />}>
        <AvatarImageModalLazy
          isOpen={showAvatarImageModal}
          loading={avatarImageModalLoading}
          resizedImages={company.avatarImages}
          onSave={handleSaveAvatar}
          onRmAvatar={handleRmAvatar}
          onClose={() => setShowAvatarImageModal(false)}
        />
      </Suspense>
    </div>
  );
}
