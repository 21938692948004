import { FormActions } from "@components/form/FormActions";
import { FormikInput } from "@components/form/Input/FormikInput";
import { FormikSelect } from "@components/form/Select/FormikSelect";
import {
  AtSymbolOutline,
  CalculatorOutline,
} from "@graywolfai/react-heroicons";
import { Client } from "@justplayfair/model";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { FormikToggle } from "../../../../components/form/Toggle/FormikToggle";
import {
  COMPANY_TYPES,
  COMPANY_TYPE_OPTIONS,
} from "../../../../model/Company.model";

const EditCompanySchema: Yup.SchemaOf<Client.UpdateCompanyInputAdmin> =
  Yup.object()
    .shape({
      name: Yup.string().required("admin.company.name.mandatory"),
      type: Yup.mixed().oneOf(COMPANY_TYPES),
      maxRate: Yup.number()
        .min(1)
        .required("admin.company.error.maxRateMandatory"),
      email: Yup.string().email("admin.company.error.invalidEmail").nullable(),
      individualDataAccessDeactivated: Yup.boolean().required(
        "admin.company.error.individualDataAccessDeactivatedMandatory"
      ),
      sessionEvaluationUrl: Yup.string().url("admin.company.error.invalidUrl"),
    })
    .required();

interface CompanyFormProps {
  company: Client.CompanyFieldsFragment;
  onEditCompany: (input: Client.UpdateCompanyInputAdmin) => Promise<void>;
  onDeleteCompany: (companyId: string) => Promise<void>;
}
export function CompanyForm({
  company,
  onEditCompany,
  onDeleteCompany,
}: CompanyFormProps) {
  const { t } = useTranslation();

  const [isEdit, setIsEdit] = useState(false);

  return (
    <Formik
      initialValues={{
        name: company.name,
        type: company.type,
        maxRate: company.maxRate,
        email: company.email,
        individualDataAccessDeactivated:
          company.individualDataAccessDeactivated,
        sessionEvaluationUrl: company.sessionEvaluationUrl,
      }}
      onSubmit={async (values) => {
        if (!isEdit) {
          throw new Error("Should not submit if not in edition mode");
        }

        await onEditCompany(values);
        setIsEdit(false);
      }}
      validationSchema={EditCompanySchema}
    >
      {(props) => (
        <Form className="bg-white shadow overflow-hidden p-2">
          <div>
            <FormikInput
              editMode={isEdit}
              id="name"
              label={t("common.company.name")}
            />
            <FormikSelect
              editMode={isEdit}
              id="type"
              multi={false}
              label={t("common.company.type.label")}
              options={COMPANY_TYPE_OPTIONS}
              renderValue={(value) => t(`common.company.type.value.${value}`)}
            />
            <FormikToggle
              editMode={isEdit}
              id="individualDataAccessDeactivated"
              label={t("common.company.individualDataAccessDeactivated")}
              renderValue={(val) => {
                return val === true
                  ? t("admin.company.label.accessDeactivated")
                  : t("admin.company.label.accessActivated");
              }}
            />
            <FormikInput
              editMode={isEdit}
              id="maxRate"
              label={t("common.company.maxRate")}
              type="number"
              min={1}
              icon={CalculatorOutline}
            />
            <FormikInput
              editMode={isEdit}
              id="email"
              label={t("common.company.email")}
              icon={AtSymbolOutline}
            />
            <FormikInput
              editMode={isEdit}
              id="sessionEvaluationUrl"
              label={t("common.company.sessionEvaluationUrl")}
            />
          </div>
          <FormActions
            isEdit={isEdit}
            handleReset={props.handleReset}
            onDelete={() => onDeleteCompany(company.id)}
            setIsEdit={setIsEdit}
          />
        </Form>
      )}
    </Formik>
  );
}
