import { NamedAvatar } from "@components/Avatar/NamedAvatar.component";
import { Button } from "@components/Button";
import { Chip } from "@components/Chip";
import { DisplayDateTime } from "@components/DateTime";
import { PageContainer } from "@components/Layout";
import { Loader } from "@components/Loader";
import { Pagination } from "@components/Table/Pagination.component";
import {
  Table,
  TableCell,
  TableHeading,
} from "@components/Table/Table.component";
import { Client, ID } from "@justplayfair/model";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { toSearchEntries } from "../../../../search/search.utilities";
import { AdminCreationButtonContainer } from "../../components/AdminCreationButtonContainer.component";
import { ExportSessionsModal } from "./ExportSessions/ExportSessions.modal";
import { SessionFilters } from "./SessionFilters.component";

const PAGE_SIZE = 10;

export function SessionList() {
  const { t } = useTranslation();
  const history = useHistory();

  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<Client.Sort>({
    name: "traineeName",
    order: "asc",
  });

  const [selectedCompanyIds, setSelectedCompanyIds] = useState<string[]>([]);
  const [selectedProgramIds, setSelectedProgramIds] = useState<string[]>([]);
  const [selectedTraineeIds, setSelectedTraineeIds] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useState<string>("");

  const [hydrated, setHydrated] = useState(false);

  const skip = useMemo(() => (page - 1) * PAGE_SIZE, [page]);

  const companyType: Client.CompanyType = "CUSTOMER";
  const [{ data: companiesData }] = Client.useSearchCompaniesQuery({
    variables: {
      search: {
        filters: {
          deleted: false,
          query: new URLSearchParams({ type: companyType }).toString(),
        },
        skip: 0,
        take: 10000,
      },
    },
  });

  const [{ data, fetching }] = Client.useSessionListQuery({
    variables: {
      search: {
        take: PAGE_SIZE,
        skip,
        filters: { deleted: false, query: searchParams },
        sort,
      },
    },
  });

  useEffect(() => {
    const search = history.location.search;
    const searchParams = new URLSearchParams(search);
    setSelectedCompanyIds(searchParams.getAll("companyIds"));
    setSelectedProgramIds(searchParams.getAll("programIds"));
    setSelectedTraineeIds(searchParams.getAll("traineeIds"));
    setSelectedStatus(searchParams.getAll("status"));
    setHydrated(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const paramsStr = new URLSearchParams(
      toSearchEntries({
        companyIds: selectedCompanyIds,
        programIds: selectedProgramIds,
        traineeIds: selectedTraineeIds,
        status: selectedStatus,
      })
    ).toString();
    setSearchParams(paramsStr);

    history.replace({
      search: paramsStr,
    });
  }, [
    history,
    selectedCompanyIds,
    selectedProgramIds,
    selectedTraineeIds,
    selectedStatus,
  ]);

  function handlePreviousPage() {
    setPage(page - 1);
  }
  function handleNextPage() {
    setPage(page + 1);
  }

  function handleSelectedCompaniesChange(companyIds: ID[]) {
    setSelectedCompanyIds(companyIds);
  }

  function handleSelectedGoupsChange(programIds: ID[]) {
    setSelectedProgramIds(programIds);
  }

  function handleSelectedTraineesChange(traineeIds: ID[]) {
    setSelectedTraineeIds(traineeIds);
  }

  function handleSelectedStatusChange(status: string[]) {
    setSelectedStatus(status);
  }

  return (
    <PageContainer title={t("admin.title.sessions")} backButton="/admin">
      <div className="w-full mx-auto space-y-8">
        <div className="flex flex-row justify-between space-x-4">
          <Button
            variant="secondary"
            title={t("admin.session.button.export")}
            onClick={() => {
              setIsExportModalOpen(true);
            }}
          >
            {t("admin.session.button.export")}
          </Button>

          <AdminCreationButtonContainer>
            <Button
              title={t("admin.session.button.createSession")}
              link={{ to: "/admin/sessions/details" }}
            >
              {t("admin.session.button.createSession")}
            </Button>
          </AdminCreationButtonContainer>
        </div>
        <div className="shadow border-b border-gray-200 sm:rounded-lg">
          {hydrated && (
            <SessionFilters
              selectedCompanyIds={selectedCompanyIds}
              selectedProgramIds={selectedProgramIds}
              selectedTraineeIds={selectedTraineeIds}
              selectedStatus={selectedStatus}
              companies={companiesData?.companies.data ?? []}
              onSelectedCompanyChange={handleSelectedCompaniesChange}
              onSelectedProgramsChange={handleSelectedGoupsChange}
              onSelectedTraineesChange={handleSelectedTraineesChange}
              onSelectedStatusChange={handleSelectedStatusChange}
            />
          )}
          <Table
            heading={
              <>
                <TableHeading align="left">
                  {t("admin.session.heading.company")}
                </TableHeading>

                <TableHeading align="center">
                  {t("admin.session.heading.program")}
                </TableHeading>

                <TableHeading
                  align="left"
                  sort={{
                    order: sort.name === "traineeName" ? sort.order : undefined,
                    onChange: () =>
                      setSort({
                        name: "traineeName",
                        order: sort.order === "desc" ? "asc" : "desc",
                      }),
                  }}
                >
                  {t("admin.session.heading.trainee")}
                </TableHeading>

                <TableHeading
                  align="left"
                  sort={{
                    order: sort.name === "trainerName" ? sort.order : undefined,
                    onChange: () =>
                      setSort({
                        name: "trainerName",
                        order: sort.order === "desc" ? "asc" : "desc",
                      }),
                  }}
                >
                  {t("admin.session.heading.trainer")}
                </TableHeading>

                <TableHeading align="center">
                  {t("admin.session.heading.name")}
                </TableHeading>

                <TableHeading
                  align="center"
                  sort={{
                    order: sort.name === "status" ? sort.order : undefined,
                    onChange: () =>
                      setSort({
                        name: "status",
                        order: sort.order === "desc" ? "asc" : "desc",
                      }),
                  }}
                >
                  {t("admin.session.heading.status")}
                </TableHeading>

                <TableHeading
                  align="left"
                  sort={{
                    order:
                      sort.name === "appointmentDate" ? sort.order : undefined,
                    onChange: () =>
                      setSort({
                        name: "appointmentDate",
                        order: sort.order === "desc" ? "asc" : "desc",
                      }),
                  }}
                >
                  {t("admin.session.heading.dates")}
                </TableHeading>

                <TableHeading align="right">
                  <span className="sr-only">Edit</span>
                </TableHeading>
              </>
            }
          >
            {!fetching &&
              data?.searchSessions.data.map((session) => (
                <tr key={session.id}>
                  <TableCell align="left">
                    {session.trainee?.company && (
                      <NamedAvatar
                        size="small"
                        entity={session.trainee.company}
                      />
                    )}
                  </TableCell>

                  <TableCell align="center">
                    {session.program && (
                      <Chip color="primary" label={session.program.name} />
                    )}
                  </TableCell>

                  <TableCell align="left">
                    {session.trainee && (
                      <NamedAvatar size="small" entity={session.trainee} />
                    )}
                  </TableCell>

                  <TableCell align="center">
                    {session.trainer && (
                      <NamedAvatar size="small" entity={session.trainer} />
                    )}
                  </TableCell>

                  <TableCell align="center">{session.name}</TableCell>

                  <TableCell align="center">
                    {session.status && (
                      <Chip
                        color="transparent"
                        label={t(`common.session.status.${session.status}`)}
                      />
                    )}
                  </TableCell>

                  <TableCell align="left">
                    <div className="text-xs text-gray-700">
                      {session.appointment?.startDate && (
                        <div>
                          <span className="font-bold">
                            {t("admin.session.label.appointment")}
                          </span>{" "}
                          <DisplayDateTime
                            date={new Date(session.appointment.startDate)}
                          />
                        </div>
                      )}
                      {session.evaluationDate && (
                        <div>
                          <span className="font-bold">
                            {t("admin.session.label.evaluation")}
                          </span>{" "}
                          <DisplayDateTime
                            date={new Date(session.evaluationDate)}
                          />
                        </div>
                      )}
                    </div>
                  </TableCell>

                  <TableCell align="right">
                    <Link
                      className="text-indigo-600 hover:text-indigo-900"
                      to={`/admin/sessions/details/${session.id}`}
                    >
                      {t("common.button.edit.label")}
                    </Link>
                  </TableCell>
                </tr>
              ))}
          </Table>
          {fetching && <Loader size="large" />}
          <Pagination
            pageInfo={data?.searchSessions.pageInfo ?? null}
            onPrevious={handlePreviousPage}
            onNext={handleNextPage}
          />
        </div>
      </div>
      <ExportSessionsModal
        isOpen={isExportModalOpen}
        onClose={() => {
          setIsExportModalOpen(false);
        }}
      />
    </PageContainer>
  );
}
