import { PageHeader } from "@components/PageHeader";
import { FunctionComponent, ReactNode } from "react";

interface AdminContainerProps {
  title: string | ReactNode;
  subtitle?: string;
  backButton?: boolean | string;
}

export const PageContainer: FunctionComponent<AdminContainerProps> = ({
  title,
  subtitle,
  backButton,
  children,
}) => {
  return (
    <div className="flex flex-grow flex-col bg-gray-100 ">
      <PageHeader title={title} subtitle={subtitle} backButton={backButton} />

      <div className="flex flex-col flex-grow w-full 2xl:w-10/12 2xl:px-8 px-2 xl:w-11/12 mx-auto py-12">
        {children}
      </div>
    </div>
  );
};
