import {
  ArrowNarrowDownOutline,
  ArrowNarrowUpOutline,
} from "@graywolfai/react-heroicons";
import { Client } from "@justplayfair/model";
import classNames from "clsx";
import { ReactNode } from "react";

interface SortIndicatorProps {
  order: Client.SortOrder;
}
function SortIndicator({ order }: SortIndicatorProps) {
  const icoClassName = "h-3 w-3 inline";
  return (
    <span>
      {order === "asc" && <ArrowNarrowUpOutline className={icoClassName} />}
      {order === "desc" && <ArrowNarrowDownOutline className={icoClassName} />}
    </span>
  );
}
interface TableHeadingProps {
  align: "left" | "center" | "right";
  width?: string;
  sort?: { order?: Client.SortOrder; onChange: VoidFunction };
  children: ReactNode;
}

export function TableHeading({
  align,
  width,
  sort,
  children,
}: TableHeadingProps) {
  const classes = classNames(
    "px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider",
    {
      "text-left": align === "left",
      "text-center": align === "center",
      "text-right": align === "right",
      "cursor-pointer hover:text-gray-700": sort,
    }
  );
  return (
    <th
      scope="col"
      style={{ width }}
      className={classes}
      onClick={sort?.onChange}
    >
      {children}
      {sort?.order && <SortIndicator order={sort.order} />}
    </th>
  );
}

interface TableColProps {
  align: "left" | "center" | "right";
  dense?: boolean;
  children: ReactNode;
}
export function TableCell({ align, dense, children }: TableColProps) {
  const cellClassnames = classNames({
    "px-6 py-4": !dense,
    "px-2 py-1": dense,
    "text-left": align === "left",
    "text-center": align === "center",
    "text-right": align === "right",
  });
  return (
    <td className={cellClassnames}>
      <div className="text-sm text-gray-900">{children}</div>
    </td>
  );
}

interface TableProps {
  heading: ReactNode;
  children: ReactNode;
  testId?: string;
}
export function Table({ heading, children, testId = "Table" }: TableProps) {
  return (
    <table className="min-w-full divide-y divide-gray-200" data-testid={testId}>
      <thead>
        <tr>{heading}</tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">{children}</tbody>
    </table>
  );
}

interface TableContainerProps {
  children: ReactNode;
}
export function TableContainer({ children }: TableContainerProps) {
  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          {children}
        </div>
      </div>
    </div>
  );
}
