import { ExternalLinkOutline } from "@graywolfai/react-heroicons";
import { Client } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import { Dropdown } from "../Dropdown";

interface ReplaySimulationsDropdownProps {
  replayUrls: Client.ExternalSessionReplayLink[];
  position: "right" | "left";
}
export function ReplaySimulationsDropdown({
  replayUrls,
  position,
}: ReplaySimulationsDropdownProps) {
  const { t } = useTranslation();
  return (
    <Dropdown
      button={{
        children: t("component.replaySimulationDropdown.button.label"),
        title: "Replay Simulation",
      }}
      position={position}
    >
      {replayUrls.map((replayUrl) => (
        <Dropdown.MenuItem
          label={replayUrl.name}
          link={{ to: replayUrl.url, external: true }}
          icon={ExternalLinkOutline}
        />
      ))}
    </Dropdown>
  );
}
