import { Alert } from "@components/Alert/Alert.component";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInterval } from "react-use";
import { httpClient } from "../../http/client";

const minutes = (nb: number) => nb * 60 * 1000;

async function fetchVersion() {
  try {
    const response = await httpClient.get("/VERSION");
    const version = await response.text();
    return version;
  } catch (error) {
    console.log("[ERROR]", error instanceof Error ? error.message : error);
    return "";
  }
}
const JPF_APP_VERSION_KEY = "JPF_APP_VERSION";

const getLocalStorageVersion = () => {
  const storedVersion = window.localStorage.getItem(JPF_APP_VERSION_KEY);
  if (!storedVersion) {
    return undefined;
  }
  return JSON.parse(storedVersion).version;
};

const setLocalStorageVersion = (value: string) => {
  const storableVersion = {
    storedAt: Date.now(),
    version: value,
  };
  return window.localStorage.setItem(
    JPF_APP_VERSION_KEY,
    JSON.stringify(storableVersion)
  );
};

export function UpdateNotif() {
  const { t } = useTranslation();

  const [deployed, setDeployed] = useState<string>("");
  const [outdated, setOutdated] = useState(false);

  async function testIfOutdated() {
    if (!navigator.onLine) {
      return;
    }
    const installedVersion = getLocalStorageVersion();
    const remoteVersion = await fetchVersion();
    const isOutdated = installedVersion !== remoteVersion;
    const isFirstInstall = !installedVersion && remoteVersion;
    // Lors de la première installation on met à jour silencieusement le localStorage
    if (isFirstInstall) {
      setLocalStorageVersion(remoteVersion);
    } else if (isOutdated) {
      // Affichage du bandeau
      setOutdated(true);
      setDeployed(remoteVersion);
    }
  }

  // Run initially
  useEffect(() => {
    testIfOutdated();
  }, []);

  useInterval(() => {
    testIfOutdated();
  }, minutes(1));

  async function reload() {
    if (window.confirm(t("component.updateNotif.loseChangesOnReload"))) {
      setLocalStorageVersion(deployed);
      window.location.reload();
    }
  }

  return outdated ? (
    <div className="fixed pt-2 pl-2">
      <Alert
        type="warning"
        title={t("component.updateNotif.updateAvailable")}
        description={t("component.updateNotif.appOutDated")}
        action={{ name: t("component.updateNotif.reload"), callback: reload }}
      ></Alert>
    </div>
  ) : null;
}
