import { useState } from "react";
import { useDebounce } from "react-use";

interface UseDebounceHookArgs {
  initialValue: string;
  debounceTime: number;
}
export function useDebounceFieldValue({ initialValue, debounceTime }: UseDebounceHookArgs) {
  const [fieldValue, setFieldValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(fieldValue);
  useDebounce(
    () => {
      setDebouncedValue(fieldValue);
    },
    debounceTime,
    [fieldValue]
  );

  return { debouncedValue, fieldValue, setFieldValue };
}
