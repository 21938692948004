import { Card } from "@components/Card";
import { CompanyUserList } from "@components/CompanyUserList";
import { Client, ID } from "@justplayfair/model";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { useAuthenticatedUser } from "../../../../hooks/authenticatedUser/useAuthenticatedUser.hook";

const PAGE_SIZE = 10;

export function ClientProgramMembersTabContent() {
  const { programId } = useParams<{
    programId: string;
  }>();

  const { user, fetching: fetchingUser } = useAuthenticatedUser();

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const skip = useMemo(() => (page - 1) * PAGE_SIZE, [page]);
  const [sort, setSort] = useState<Client.Sort | undefined>({
    name: "name",
    order: "asc",
  });

  const [{ data, fetching }] = Client.useWithAdvancementClientUserListQuery({
    pause: !user,
    variables: user?.company?.id
      ? {
          companyId: user.company.id,
          search: {
            skip,
            take: PAGE_SIZE,
            filters: {
              query: new URLSearchParams({
                search,
                programId,
                roles: "TRAINEE",
              }).toString(),
              deleted: false,
            },
            sort,
          },
        }
      : undefined,
  });

  return (
    <Card>
      <div className="space-y-2 p-4">
        <CompanyUserList
          programId={programId}
          fetching={fetching || fetchingUser}
          users={data?.users.data}
          pageInfo={data?.users.pageInfo ?? null}
          page={page}
          setSearch={setSearch}
          setPage={setPage}
          sort={sort}
          setSort={setSort}
          getTraineeUrl={
            data?.company?.individualDataAccessDeactivated === false
              ? getTraineeUrl
              : undefined
          }
        />
      </div>
    </Card>
  );
}

function getTraineeUrl(
  userId: ID,
  programId: ID | null,
  programIds: ID[]
): string | null {
  if (!programId && programIds.length > 1) {
    return null;
  }
  if (programId) {
    return `/client/trainee/details/${programId}/${userId}`;
  }
  return `/client/trainee/details/${programIds[0]}/${userId}`;
}
