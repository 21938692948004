import { Chip } from "@components/Chip";
import { DangerousHtml } from "@components/DangerousHtml";
import { DisplayDateTime } from "@components/DateTime";
import {
  Table,
  TableCell,
  TableHeading,
} from "@components/Table/Table.component";
import { Client, removeHtmlFormating } from "@justplayfair/model";
import classnames from "clsx";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button } from "../Button";
import { ReplaySimulationsDropdown } from "../ReplaySimulationsDropdown";

const parser = new DOMParser();

interface SessionListProps {
  sessions: Client.ListSessionFieldsFragment[];
  forRole: Client.Role;
}

const ALLOW_TO_ACCESS_SESSION_ROLES: Client.Role[] = ["TRAINEE", "TRAINER"];

export function TraineeSessionTable({ sessions, forRole }: SessionListProps) {
  const { t } = useTranslation();
  const history = useHistory();

  function handleSessionRowClick(session: Client.ListSessionFieldsFragment) {
    switch (forRole) {
      case "TRAINEE":
        history.push(`/trainee/session/${session.id}`);
        return;
      case "TRAINER":
        history.push(`/trainer/session/${session.id}`);
        return;
      default:
        throw new Error(`Row should not be clickable for role ${forRole}`);
    }
  }

  return (
    <div className="-my-2 sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="shadow border-b border-gray-200 sm:rounded-lg">
          <Table
            heading={
              <>
                <TableHeading align="left">
                  {t("trainer.dashboard.heading.sessionName")}
                </TableHeading>

                <TableHeading align="center">
                  {t("trainer.dashboard.heading.status")}
                </TableHeading>

                <TableHeading align="left">
                  {t("trainer.dashboard.heading.date")}
                </TableHeading>

                <TableHeading align="left">
                  {t("trainer.dashboard.heading.trainer")}
                </TableHeading>

                <TableHeading align="left" width="40%">
                  {t("trainer.dashboard.heading.comment")}
                </TableHeading>

                {["TRAINEE", "TRAINER"].includes(forRole) && (
                  <TableHeading align="left" width="15%">
                    <span className="sr-only">Actions</span>
                  </TableHeading>
                )}
              </>
            }
          >
            {sessions.length === 0 && (
              <tr>
                <td className="p-4 text-sm text-gray-700" colSpan={5}>
                  {t("common.text.noData")}
                </td>
              </tr>
            )}
            {sessions.map((session) => {
              const isPendingExternalSession =
                forRole === "TRAINEE" &&
                session.status !== "EVALUATED" &&
                !!session.externalSessionLink;

              const clickable =
                ALLOW_TO_ACCESS_SESSION_ROLES.includes(forRole) &&
                !isPendingExternalSession;
              const rowClasses = classnames("transition", {
                "hover:bg-gray-50 cursor-pointer": clickable,
              });
              return (
                <tr
                  key={session.id}
                  className={rowClasses}
                  onClick={
                    clickable && !isPendingExternalSession
                      ? () => {
                          handleSessionRowClick(session);
                        }
                      : undefined
                  }
                >
                  <TableCell align="left">{session.name}</TableCell>
                  <TableCell align="center">
                    <Chip
                      color="transparent"
                      label={t(`common.session.status.${session.status}`)}
                    />
                  </TableCell>
                  <TableCell align="left">
                    {session.appointment?.startDate && (
                      <DisplayDateTime
                        date={new Date(session.appointment.startDate)}
                      />
                    )}
                  </TableCell>
                  <TableCell align="left">{session.trainer?.name}</TableCell>
                  <TableCell align="left">
                    <div className="h-10">
                      {session.sessionComment?.value && (
                        <DangerousHtml
                          html={removeHtmlFormating(
                            parser,
                            document,
                            session.sessionComment.value
                          )}
                          hideOverflow
                        />
                      )}
                      {!session.sessionComment?.value && "-"}
                    </div>
                  </TableCell>
                  {["TRAINEE", "TRAINER"].includes(forRole) && (
                    <TableCell align="left">
                      <ActionCellContent
                        isPendingExternalSession={isPendingExternalSession}
                        session={session}
                      />
                    </TableCell>
                  )}
                </tr>
              );
            })}
          </Table>
        </div>
      </div>
    </div>
  );
}

interface ActionCellContentProps {
  isPendingExternalSession: boolean;
  session: Client.ListSessionFieldsFragment;
}
function ActionCellContent({
  isPendingExternalSession,
  session,
}: ActionCellContentProps) {
  const { t } = useTranslation();

  return (
    <div className="flex justify-end">
      {isPendingExternalSession && (
        <Button
          title={t("trainee.dashboard.button.joinSession.title")}
          variant="transparent"
          link={{
            external: true,
            to: `${session.externalSessionLink}`,
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {t("trainee.dashboard.button.joinSession.label")}
        </Button>
      )}
      {!isPendingExternalSession &&
        !!session.externalSessionReplayLinks?.length && (
          <ReplaySimulationsDropdown
            replayUrls={session.externalSessionReplayLinks}
            position="left"
          />
        )}
    </div>
  );
}
