import { CustomSelect } from "@components/form/Select";
import { Toggle } from "@components/form/Toggle/Toggle.component";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import {
  FILE_LABEL_OPTIONS,
  FILE_TYPE_OPTIONS,
} from "../../../model/Media.model";

interface MediaFiltersProps {
  filterDeleted: boolean;
  selectedType?: string;
  selectedLabels: string[];
  onFilterDeletedChange: (value: boolean) => void;
  onSelectedTypeChange: (value: string) => void;
  onSelectedLabelsChange: (values: string[]) => void;
}
export const MediaFilters = observer(
  ({
    filterDeleted,
    selectedType,
    selectedLabels,
    onFilterDeletedChange,
    onSelectedTypeChange,
    onSelectedLabelsChange,
  }: MediaFiltersProps) => {
    const { t } = useTranslation();

    return (
      <div className="flex bg-gray-200 p-2 justify-between">
        <div className="flex space-x-4">
          <Toggle
            id="filterDeleted"
            checked={filterDeleted}
            onChange={(value) => onFilterDeletedChange(value)}
            label={t("common.toggle.displayDeleted")}
          />
          <div className="w-56">
            <CustomSelect
              id="selected-type"
              value={selectedType || ""}
              onChange={(event) => onSelectedTypeChange(event.target.value)}
              renderValue={(value) => t(`common.media.type.value.${value}`)}
              options={FILE_TYPE_OPTIONS}
              label={t("admin.mediaLibrary.filter.withType")}
              multi={false}
            />
          </div>

          <div className="w-56">
            <CustomSelect
              id="selected-labels"
              value={selectedLabels}
              onChange={(values) => onSelectedLabelsChange(values || [])}
              renderValue={(values) =>
                values.length > 0
                  ? values
                      .map((label) => t(`common.media.label.value.${label}`))
                      .join(", ")
                  : t("common.text.allValues")
              }
              options={FILE_LABEL_OPTIONS}
              label={t("admin.mediaLibrary.filter.forLabels")}
              multi
            />
          </div>
        </div>
      </div>
    );
  }
);
