import { FormActions } from "@components/form/FormActions";
import { FormikInput } from "@components/form/Input/FormikInput";
import { FormikSelect } from "@components/form/Select/FormikSelect";
import { Loader } from "@components/Loader";
import { Client, ID } from "@justplayfair/model";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  LABEL_CATEGORIES,
  LABEL_CATEGORIES_OPTIONS,
} from "../../../../../model/Label.model";

export type LabelFormFields = Omit<Client.LabelInput, "companyId">;

const LabelSchema: Yup.SchemaOf<LabelFormFields> = Yup.object()
  .shape({
    name: Yup.string().required("admin.label.text.nameMandatory"),
    category: Yup.mixed()
      .oneOf(LABEL_CATEGORIES)
      .defined()
      .required("admin.label.text.categoryMandatory"),
  })
  .required();

interface LabelFormProps {
  labelId?: ID;
  onSaveLabel: (input: LabelFormFields) => Promise<void>;
  onDeleteLabel: (labelId: ID) => Promise<void>;
}

export function LabelForm({
  labelId,
  onSaveLabel,
  onDeleteLabel,
}: LabelFormProps) {
  const { t } = useTranslation();

  const [
    { data: labelData, fetching: fetchingLabel },
  ] = Client.useGetLabelQuery({
    pause: !labelId,
    variables: labelId ? { labelId } : undefined,
  });

  if (labelId && fetchingLabel) {
    return <Loader size="medium" mode="overlay" />;
  }

  return (
    <div>
      <Formik
        initialValues={{
          name: labelData?.getLabel?.name ?? "",
          category:
            labelData?.getLabel?.category ?? ("CUSTOM" as Client.LabelCategory),
        }}
        onSubmit={async (values) => {
          if (labelData?.getLabel?.protected) {
            throw new Error("Canot update a protected label");
          }
          await onSaveLabel(values);
        }}
        validationSchema={LabelSchema}
      >
        {(props) => (
          <Form className="bg-white shadow p-2">
            <div>
              <FormikInput
                editMode={!labelData?.getLabel?.protected}
                id="name"
                label={t("admin.label.text.name")}
              />
              <FormikSelect
                editMode={!labelData?.getLabel?.protected}
                id="category"
                multi={false}
                options={LABEL_CATEGORIES_OPTIONS}
                label={t("admin.label.text.category")}
                renderValue={(val) => t(`common.label.${val}`)}
              />
            </div>

            <FormActions
              isEdit={!labelData?.getLabel?.protected}
              handleReset={props.handleReset}
              onDelete={labelId ? () => onDeleteLabel(labelId) : undefined}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
