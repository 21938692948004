import { ContentDisposition } from "../../http/content-disposition";
import { saveAs } from "file-saver";
import ky from "ky";
import { authService } from "../auth";

const FILE_URL = process.env.REACT_APP_FILE_URL;
if (!FILE_URL) {
  throw new Error("Mandatory env variable 'REACT_APP_FILE_URL'");
}
class FileService {
  async getBlob(src: string): Promise<{ filename?: string; blob: Blob }> {
    const response = await ky.get(`${FILE_URL}`, {
      searchParams: { src },
      mode: "cors",
      headers: {
        authorization: `Bearer ${authService.token}`,
      },
    });
    const filename = this.getFilename(response.headers);

    const blob = await response.blob();
    return { filename, blob };
  }

  async downloadFile(src: string, fallbackFilename?: string) {
    const { filename, blob } = await this.getBlob(src);
    saveAs(blob, filename ?? fallbackFilename);
  }

  async getImage(src: string): Promise<string> {
    const { blob } = await this.getBlob(src);

    return window.URL.createObjectURL(blob);
  }

  getFilename(headers: Headers): string | undefined {
    const cdHeader = headers.get("Content-Disposition");
    if (!cdHeader) {
      return "file";
    }
    const cd = ContentDisposition.parse(cdHeader);

    return cd.parameters["filename"];
  }
}

export type FileServiceType = FileService;
export const fileService = new FileService();
