import { PageContainer } from "@components/Layout";
import { Client, ID } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { OperationResult } from "urql";
import { UserCharterForm } from "./UserCharter.form";

export function UserCharterDetailsPage() {
  const { t } = useTranslation();
  const history = useHistory();

  const { id } = useParams<{ id?: string }>();

  const [{ fetching: fetchUpload }, uploadMedia] =
    Client.useUploadMediaMutation();

  const [{ fetching: fetchUpdate }, updateMedia] =
    Client.useUpdateMediaMutation();

  const [{ fetching: fetchDelete }, deleteMedia] =
    Client.useDeleteMediaMutation();

  async function handleSaveMedia(mediaInput: Client.MediaInput) {
    let result: OperationResult;
    if (id) {
      result = await updateMedia({
        updateMedianput: {
          name: mediaInput.name,
          description: mediaInput.description,
          upload: mediaInput.upload,
          mediaId: id,
        },
      });
    } else {
      result = await uploadMedia({ mediaInput });
    }
    if (!result.error) {
      history.push("/admin/mediaLibrary");
    }
  }

  async function handleDeleteMedia(mediaId: ID) {
    await deleteMedia({ mediaId });
    history.push("/admin/mediaLibrary");
  }

  return (
    <PageContainer
      title={t("menu.admin")}
      subtitle={
        id
          ? t("admin.mediaLibrary.title.updateGuide")
          : t("admin.mediaLibrary.title.createGuide")
      }
      backButton="/admin/mediaLibrary"
    >
      <div className="w-full max-w-4xl mx-auto">
        <UserCharterForm
          mediaId={id}
          fetchSaving={fetchUpload || fetchUpdate || fetchDelete}
          onSaveMedia={handleSaveMedia}
          onDeleteMedia={handleDeleteMedia}
        />
      </div>
    </PageContainer>
  );
}
