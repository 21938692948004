import { useSendChangePasswordEmailMutation } from "@justplayfair/model/dist/generated/client";
import { useTranslation } from "react-i18next";
import { Button } from "@components/Button";
import { MailOutline, PaperAirplaneOutline } from "@graywolfai/react-heroicons";

interface SendRenewPasswordEmailButtonProps {
  userEmail: string;
}
export function SendRenewPasswordEmailButton({
  userEmail,
}: SendRenewPasswordEmailButtonProps) {
  const { t } = useTranslation();

  const [{ fetching, data: sendEmailResponse }, sendChangePasswordEmail] =
    useSendChangePasswordEmailMutation();

  function handleSendChangePasswordEmail() {
    sendChangePasswordEmail({ userEmail });
  }
  return (
    <div>
      <Button
        variant="secondary"
        onClick={handleSendChangePasswordEmail}
        loading={fetching}
        preIcon={!sendEmailResponse ? MailOutline : undefined}
        postIcon={sendEmailResponse ? PaperAirplaneOutline : undefined}
        title={t("admin.user.createUser.sendChangePasswordEmail.title")}
        tooltip={sendEmailResponse?.sendChangePasswordEmail}
      >
        {t("admin.user.createUser.sendChangePasswordEmail.label")}
      </Button>
    </div>
  );
}
