import { useFormikContext } from "formik";
import { useEffect, useRef } from "react";

interface FormikChangeListenerProps<Values> {
  onChange: (values: Values) => void;
}
export function FormikChangeListener<Values extends Record<string, any>>({
  onChange,
}: FormikChangeListenerProps<Values>) {
  const { values } = useFormikContext<Values>();
  const ref = useRef(values);
  useEffect(() => {
    if (ref.current !== values) {
      ref.current = values;
      onChange(values);
    }
  }, [values, onChange]);
  return null;
}
