import { RadarChart } from "@components/Dataviz/chart/RadarChart.component";
import { Loader } from "@components/Loader";
import { DatavizMapper } from "@justplayfair/model";
import { useTranslation } from "react-i18next";

interface SkillsRatesChartProps {
  skillsRatesData: ReturnType<typeof DatavizMapper.radarDataMapper> | undefined;
  fetching: boolean;
}
export function SkillsRatesChart({
  skillsRatesData,
  fetching,
}: SkillsRatesChartProps) {
  const { t } = useTranslation();

  if (fetching) {
    return <Loader size="medium" />;
  }
  if (!skillsRatesData || !skillsRatesData.data.length) {
    return <div className="text-gray-500">{t("common.text.noData")}</div>;
  }
  return (
    <div className="w-full max-w-5xl h-96">
      <RadarChart
        valueNames={skillsRatesData.keys}
        indexBy="skill"
        data={skillsRatesData.data}
        maxRate={skillsRatesData.maxRate}
      />
    </div>
  );
}
