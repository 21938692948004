import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { CHART_COLORS, DatavizMapper } from "@justplayfair/model";

interface RateTableRow {
  label: string;
  rate: string;
  statsRate?: string;
  emphasis?: boolean;
}

export interface RateComparisonTableProps {
  rowHeight: number;
  colNames: [col1: string, col2: string];
  data: RateTableRow[];
  withLabel?: boolean;
}

type CellAlign = "left" | "center" | "right";

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Table = styled.table`
  border-collapse: collapse;
  text-indent: 0;
  border-spacing: 2px;
  border: none;
`;

const Th = styled.th<{
  align: CellAlign;
  width?: string;
  isTitle?: boolean;
  noBorder?: boolean;
}>`
  padding: 3px 6px;
  text-align: ${({ align }) => align};
  font-size: 12px;
  line-height: 16px;
  color: #efefef;
  letter-spacing: 0.05em;
  border: ${({ noBorder }) => (noBorder ? "none" : "1px solid #e5e7eb")};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ width }) => (width ? `width: ${width};` : undefined)}

  background-color: ${({ isTitle }) =>
    isTitle ? " rgba(79, 93, 218, .2);" : " rgba(79, 93, 218, .1);"};

  text-transform: ${({ isTitle }) => (isTitle ? "uppercase" : "")};

  &:nth-child(1) {
    color: ${({ isTitle }) => (isTitle ? "#374151" : "#4F5DDA")};
  }
  &:nth-child(2) {
    color: ${({ isTitle }) => (isTitle ? "#374151" : "#A76A36")};
  }

  &:empty {
    background-color: white;
    border-top: none;
    border-left: none;
  }
`;
const Tbody = styled.tbody`
  background-color: white;
`;

const Tr = styled.tr<{ bold?: boolean }>`
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  display: table-row;
`;

const Td = styled.td<{ align: CellAlign; height: number; noBorder?: boolean }>`
  height: ${({ height }) => `${height}px`}; // ~ height of a chart bar
  white-space: nowrap;
  padding: 2px 6px;
  font-size: 11px;
  color: #333;
  text-align: ${({ align }) => align};
  border: ${({ noBorder }) => (noBorder ? "none" : "1px solid #e5e7eb")};
`;

export function RateComparisonTable({
  rowHeight,
  colNames,
  data,
  withLabel,
}: RateComparisonTableProps) {
  const { t } = useTranslation();
  return (
    <TableContainer>
      <Table>
        <thead>
          <Tr>
            {withLabel && <Th align="right" noBorder />}
            <Th scope="col" colSpan={2} align="center" isTitle>
              Average
            </Th>
          </Tr>
          <tr>
            {withLabel && <Th align="right" noBorder />}
            <Th scope="col" align="center">
              {colNames[0]}
            </Th>
            <Th scope="col" align="center">
              {colNames[1]}
            </Th>
          </tr>
        </thead>
        <Tbody>
          {data.length === 0 && (
            <Tr bold>
              <Td align="center" colSpan={3} height={30}>
                {t("common.text.noData")}
              </Td>
            </Tr>
          )}
          {data.map((row, index) => (
            <Tr key={index} bold={row.emphasis}>
              {withLabel && (
                <Td align="right" height={rowHeight} noBorder>
                  {DatavizMapper.formatLabel(row.label)}
                </Td>
              )}
              <Td align="center" height={rowHeight}>
                {row.rate}
              </Td>
              <Td align="center" height={rowHeight}>
                {row.statsRate}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <div className="flex justify-center">
        <Legend colNames={colNames} />
      </div>
    </TableContainer>
  );
}

interface LegendProps {
  colNames: [col1: string, col2: string];
}
function Legend({ colNames }: LegendProps) {
  const textStyle = {
    fontFamily: "Inter, ui-sans-serif, system-ui",
    fontSize: "10px",
    fill: "rgb(51, 51, 51)",
  };
  return (
    <svg
      width="180"
      height="40"
      viewBox="0 0 180 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="5" width="22" height="10" fill={CHART_COLORS.emphasisBlue} />
      <text x="30" y="15" height="10" style={textStyle}>
        {colNames[0]}
      </text>

      <circle
        cx="10.5"
        cy="29.5"
        r="6.5"
        fill="white"
        stroke={CHART_COLORS.gold}
        strokeWidth="2"
      />
      <text x="30" y="35" height="10" style={textStyle}>
        {colNames[1]}
      </text>
    </svg>
  );
}
