import classnames from "clsx";
import { ReactNode } from "react";

export type LabelPosition = "left" | "right" | "top";
interface FieldContainerProps {
  label?: string;
  labelPosition: LabelPosition;
  id: string;
  withSeparator?: boolean;

  children: ReactNode;
}
export function FieldContainer({
  label,
  id,
  labelPosition,
  withSeparator = true,
  children,
}: FieldContainerProps) {
  const containerClassnames = classnames("", {
    "border-b border-gray-200 last:border-b-0": withSeparator,
  });
  const gridClassnames = classnames("sm:grid px-4 py-5", {
    "sm:grid-cols-3 sm:gap-4 sm:px-6": !!label && labelPosition !== "top",

    "sm:grid-cols-2": !label || labelPosition === "top",
  });

  const labelContainerClassnames = classnames(
    "flex items-center text-sm font-medium text-gray-500",
    {
      "sm:col-span-2": labelPosition !== "left",
      "h-12": labelPosition !== "top",
    }
  );
  const inputClassnames = classnames(
    "flex items-center mt-1 h-12 text-sm text-gray-900 sm:mt-0",
    { "sm:col-span-2": labelPosition !== "right" }
  );
  return (
    <div className={containerClassnames}>
      <dl>
        <div className={gridClassnames}>
          {label && labelPosition !== "right" && (
            <dt className={labelContainerClassnames}>
              <label htmlFor={id}>{label}</label>
            </dt>
          )}
          <dd className={inputClassnames}>{children}</dd>
          {label && labelPosition === "right" && (
            <dt className={labelContainerClassnames}>
              <label htmlFor={id}>{label}</label>
            </dt>
          )}
        </div>
      </dl>
    </div>
  );
}
