import { Loader } from "@components/Loader";
import { Client } from "@justplayfair/model";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { PageContainer } from "@components/Layout";
import { CreateCompanyForm } from "./CreateCompany.form";

export function CreateCompanyPage() {
  const { t } = useTranslation();
  const history = useHistory();

  const [{ fetching }, createCompany] = Client.useCreateCompanyMutation();

  async function handleCreateUser(input: Client.CreateCompanyInput) {
    const result = await createCompany({ input });
    if (!result.error) {
      history.push(`/admin/companies`);
    }
  }

  if (fetching) {
    return (
      <PageContainer title={t("menu.admin")}>
        {<Loader size="medium" />}
      </PageContainer>
    );
  }
  return (
    <PageContainer
      title={t("menu.admin")}
      subtitle={t("admin.company.title.createCompany")}
      backButton="/admin/companies"
    >
      <div className="w-full max-w-4xl mx-auto">
        <CreateCompanyForm onCreateCompany={handleCreateUser} />
      </div>
    </PageContainer>
  );
}
