import { Transition } from "@headlessui/react";
import { observer } from "mobx-react-lite";
import { alertService } from "@services/Alert/AlertService";
import { Alert } from "../Alert.component";

export const FloatingAppAlert = observer(() => {
  const alerts = alertService.alerts;
  return (
    <Transition
      show={alerts.length > 0}
      enter="transition ease-out duration-300 transform"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="transition ease-in duration-75 transform"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div className="fixed p-4 max-w-4xl space-y-2">
        {alerts.map((alert) => (
          <Alert
            key={alert.id}
            type={alert.type}
            title={alert.title}
            description={alert.description}
            onDismiss={() => {
              alertService.dismissAlert(alert.id);
            }}
          />
        ))}
      </div>
    </Transition>
  );
});
