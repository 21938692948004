import * as Yup from "yup";
import { OtpCheckBody } from "@justplayfair/model";
import { Form, Formik } from "formik";
import { FormikInput } from "../../../components/form/Input/FormikInput";
import { LockClosedOutline } from "@graywolfai/react-heroicons";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button";

const OtpCheckSchema: Yup.SchemaOf<OtpCheckBody> = Yup.object().shape({
  otp: Yup.string().required("auth.error.otpMandatory"),
});

interface OtpCheckFormProps {
  onCheck: (fields: OtpCheckBody) => void;
}
export function OtpCheckForm({ onCheck }: OtpCheckFormProps) {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{
        otp: "",
      }}
      onSubmit={async (values) => {
        onCheck(values);
      }}
      validationSchema={OtpCheckSchema}
    >
      <Form className="flex flex-col items-center gap-y-6" noValidate>
        <div className="flex flex-col items-center">
          <div className="w-60">
            <FormikInput
              editMode={true}
              id="otp"
              type="string"
              label={t("auth.otpCheck.otp.label")}
              placeholder={t("auth.otpCheck.otp.placeholder")}
              icon={LockClosedOutline}
              labelPosition="top"
              withSeparator={false}
              autoFocus
            />
          </div>
          <div className="text-sm text-gray-800">
            {t("auth.otpCheck.text.helperText")}
          </div>
        </div>

        <div className="w-44">
          <Button title={t("auth.otpCheck.button.title")} type="submit">
            {t("auth.otpCheck.button.label")}
          </Button>
        </div>
      </Form>
    </Formik>
  );
}
