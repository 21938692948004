import { Avatar } from "@components/Avatar";
import { Card } from "@components/Card";
import { Title } from "@components/Title";
import { Client } from "@justplayfair/model";
import { LinkProps } from "../Button/Button.component";

interface CompanyCardProps {
  company: Client.CompanyFieldsFragment;
  link?: LinkProps;
  onClick?: VoidFunction;
  flat?: boolean;
  testId?: string;
}
export function CompanyCard({
  company,
  onClick,
  link,
  flat,
  testId = "CompanyCard",
}: CompanyCardProps) {
  return (
    <Card flat={flat} link={link} onClick={onClick}>
      <div className="space-y-4 p-4" data-testid={testId}>
        <div className="flex items-center text-sm font-medium text-gray-900 space-x-4">
          <Avatar size="medium" entity={company} />
          <Title size={5}>{company.name}</Title>
        </div>
      </div>
    </Card>
  );
}
