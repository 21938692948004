import { FiltersValue } from "@components/Consult/ChartFilters/ChartFilters.component";
import { Dataviz } from "@justplayfair/model";

export function dateFilterToDateSearchQueryField(
  filters: FiltersValue
): Dataviz.DateFilterInput {
  return filters.date
    ? { type: "date", date: filters.date }
    : filters.firstSession
    ? { type: "first" }
    : { type: "last" };
}
