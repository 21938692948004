/* eslint-disable no-control-regex */

const DISPOSITION_TYPE_REGEXP =
  /^([!#$%&'*+.0-9A-Z^_`a-z|~-]+)[\x09\x20]*(?:$|;)/;

const PARAM_REGEXP =
  /[\x09\x20]*([!#$%&'*+.0-9A-Z^_`a-z|~-]+)[\x09\x20]*=[\x09\x20]*("(?:[\x20!\x23-\x5b\x5d-\x7e\x80-\xff]|\\[\x20-\x7e])*"|[!#$%&'*+.0-9A-Z^_`a-z|~-]+)[\x09\x20]*/g;

export interface ContentDispositionType {
  type: string;
  parameters: Record<string, string>;
}
function parse(contentDispositionHeader: string): ContentDispositionType {
  const match = DISPOSITION_TYPE_REGEXP.exec(contentDispositionHeader);
  if (!match) {
    throw new TypeError("invalid type format");
  }
  const type = match[1].toLowerCase();

  let paramMatch;
  let parameters: ContentDispositionType["parameters"] = {};

  while ((paramMatch = PARAM_REGEXP.exec(contentDispositionHeader))) {
    parameters[paramMatch[1]] = paramMatch[2]?.replaceAll('"', "");
  }

  return {
    type,
    parameters,
  };
}

export const ContentDisposition = {
  parse,
};
